import m from 'mithril'
import {format_iso_to_date, format_percentage} from '@bitstillery/common/lib/format'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Amount, Icon, Spinner, Tippy} from '@bitstillery/common/components'

import {Link} from '../discover'

import {CaseInfoPanelAttrs} from './case_info_panel'

import {accountIconBySlug} from '@/accounts'
import {$s} from '@/app'
import {
    GetStockRecordsRequest,
    GetStockRecordsResponse,
    StockApi,
    StockRecord,
    StockSearchMode,
} from '@/factserver_api/stock_api'

type BottleStockAttrs = Pick<CaseInfoPanelAttrs, 'bottle_artkey' | 'ignore_ref' | 'up_is_up' | 'bottle_mode' | 'case_artkey'>

export class BottleStock extends MithrilTsxComponent<BottleStockAttrs> {
    stock_api = new StockApi()
    is_loading = true
    stock_records: StockRecord[] = []
    total = 0
    attrs: BottleStockAttrs
    bottle_artkey: number

    constructor(vnode: m.Vnode<BottleStockAttrs>) {
        super()
        this.attrs = vnode.attrs
        this.bottle_artkey = this.attrs.bottle_artkey

        if (vnode.attrs.up_is_up) {
            this.query_stock_for_product()
        } else {
            this.query_all_stock()
        }
    }

    _query_stock(data: GetStockRecordsRequest): void {
        this.is_loading = true
        this.stock_api.get_stock_records(data).subscribe({
            next: (response: GetStockRecordsResponse) => {
                this.stock_records = response.stock_items
                this.total = response.total
                this.is_loading = false
                m.redraw()
            },
        })
    }

    query_all_stock(): void {
        const data = {
            bottle_artkey: this.bottle_artkey,
            case_artkey: this.attrs.case_artkey,
            ignore_ref: this.attrs.ignore_ref,
            mode: StockSearchMode.NOT_INVOICED,
            all_accounts: true,
            limit: 10,
        }
        this._query_stock(data)
    }

    query_stock_for_product(): void {
        const data = {
            bottle_artkey: this.attrs.bottle_artkey,
            mode: StockSearchMode.NOT_INVOICED,
            limit: 10,
        }
        this._query_stock(data)
    }

    view(): m.Children {
        return (
            <div className="c-bottle-stock collection-widget">
                <div className="header">
                    <div className="title"><Icon name="stock" type="info" />Stock</div>
                </div>
                <div className="content">
                    {this.total === 0 ? (
                        <div class="c-empty-message">{this.is_loading ? <Spinner /> : 'Not in stock.'}</div>
                    ) : (
                        <div>
                            <table className={'table table-condensed'}>
                                <thead className="thead-default">
                                    <tr>
                                        <th>Lot</th>
                                        <th>Warehouse</th>
                                        <th>Entry date</th>
                                        <th className="number">
                                            <Tippy content="Number of cases in stock">
                                                <i class="glyphicon glyphicon-home" />
                                            </Tippy>
                                        </th>
                                        <th className="number">
                                            <Tippy content="Number of cases in purchase">
                                                <i class="glyphicon glyphicon-shopping-cart" />
                                            </Tippy>
                                        </th>
                                        <th className="number">
                                            <Tippy content="Number of cases in sales">
                                                <i class="glyphicon glyphicon-screenshot" />
                                            </Tippy>
                                        </th>
                                        <th className="number">
                                            <Tippy content="Number of cases available">
                                                <i class="fa fa-shield-alt" />
                                            </Tippy>
                                        </th>
                                        <th className="number">
                                            <Tippy content="Throughput">
                                                <i class="glyphicon glyphicon-flash" />
                                            </Tippy>
                                        </th>
                                        <th>Ref</th>
                                        <th className="number">Btl / cs</th>
                                        <th>Gift box</th>
                                        <th>Tax label</th>
                                        <th>Cus.</th>
                                        <th className="price">Bought for / cs</th>
                                        {this.attrs.bottle_mode && <th className="price">Bought for / btl</th>}
                                        <th>#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.stock_records.map((stock_item) => (
                                        <tr>
                                            <td data-item-artkey={stock_item.artkey}>
                                                {accountIconBySlug(
                                                    stock_item.account_slug,
                                                    stock_item.account_name,
                                                )}
                                                <Link
                                                    target="_blank"
                                                    href={`/stock/manage/${stock_item.item_reference}`}
                                                >
                                                    {stock_item.item_lot}
                                                </Link>
                                            </td>
                                            <td>{stock_item.warehouse_name}</td>
                                            <td>{format_iso_to_date(stock_item.item_entry_date)}</td>
                                            <td className="number">{stock_item.item_number_of_cases_in_stock}</td>
                                            <td className="number">{stock_item.item_number_of_cases_in_purchase}</td>
                                            <td className="number">{stock_item.item_number_of_cases_in_sales}</td>
                                            <td className="number">{stock_item.item_number_of_cases_available}</td>
                                            <td className="number">
                                                {stock_item.item_throughput
                                                    ? format_percentage(stock_item.item_throughput)
                                                    : '-'}
                                            </td>
                                            <td>{stock_item.bottle_refill_status}</td>
                                            <td className="number">{stock_item.case_number_of_bottles}</td>
                                            <td>{stock_item.case_gift_box_type}</td>
                                            <td>{stock_item.case_tax_label}</td>
                                            <td>{stock_item.case_customs_status}</td>
                                            <td className="price">
                                                <Amount
                                                    amount={stock_item.item_was_bought_for}
                                                    excise={stock_item.case_excise_nl}
                                                    currency={stock_item.purchase_order_was_bought_in}
                                                    rate={stock_item.purchase_order_bought_against_rate}
                                                    display_currency={$s.currencies.default}
                                                />
                                            </td>
                                            {this.attrs.bottle_mode && (
                                                <td className={'price'}>
                                                    <Amount
                                                        amount={
                                                            stock_item.item_was_bought_for /
                                                            stock_item.case_number_of_bottles
                                                        }
                                                        excise={stock_item.bottle_excise_nl}
                                                        currency={stock_item.purchase_order_was_bought_in}
                                                        rate={stock_item.purchase_order_bought_against_rate}
                                                        display_currency={$s.currencies.default}
                                                    />
                                                </td>
                                            )}
                                            <td>
                                                <Link
                                                    target="_blank"
                                                    href={`/stock/manage/${stock_item.item_reference}`}
                                                >
                                                    <i className="fa fa-expand-arrows-alt" />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
