import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {notifier} from '@bitstillery/common/app'
import {proxy} from '@bitstillery/common/lib/proxy'
import {FieldSelect, FieldSelectMany} from '@bitstillery/common/components'
import {SelectOption} from '@bitstillery/common/types/field'
import {api} from '@bitstillery/common/app'

import {UserDropDown} from '../components/users'

import {PDFHelper} from './pdf_helper'

import {DocumentType, EmailApi} from '@/factserver_api/email_api'
import {RelationApi} from '@/factserver_api/relation_api'
import {PrimaryButton} from '@/components/buttons'
import {CheckBox} from '@/components/html_components'
import {GetContactPersonForRelationResponse} from '@/factserver_api/fact2server_api'

interface PDFEmailerAttrs {
    supplier: {
        artkey: number
        name: string
        emailaddress_financial_documents?: string | null
        emailaddress_rfp_documents?: string | null
    }
    sales_order?: {
        artkey: number
        was_created_by: {
            name: string | null
        }
    }
    purchase_order?: {
        artkey: number
        was_created_by: {
            name: string | null
        }
    }
    pdf_file_name: string
    pdf_helper: PDFHelper<unknown>
    document_type: DocumentType
}

export class PDFEmailer extends MithrilTsxComponent<PDFEmailerAttrs> {
    email_api = new EmailApi()
    relation_api = new RelationApi()

    contact_persons: GetContactPersonForRelationResponse[] = []
    make_email_address_default = false
    relation_artkey: number

    email_data = proxy({
        from_user_artkey: '',
        to_addresses: {
            options: [] as SelectOption[],
            selected: '',
        },
        cc_addresses: {
            options: [] as SelectOption[],
            selection: [],
        },
    })

    constructor(vnode: m.Vnode<PDFEmailerAttrs>) {
        super()
        this.relation_artkey = vnode.attrs.supplier.artkey
    }

    async oncreate(vnode: m.Vnode<PDFEmailerAttrs>) {
        const {status_code, result} = await api.get<GetContactPersonForRelationResponse[]>(`discover/relations/${this.relation_artkey}/contact-persons`)
        if (status_code > 299) {
            return
        }
        this.contact_persons = result
        this.email_data.to_addresses.options = result
            .map((contact) => {
                return {
                    label: `${contact.name} (${contact.emailaddress})`,
                    value: contact.emailaddress,
                }
            })
        // optional supplier email addresses:
        if (vnode.attrs.supplier.emailaddress_financial_documents) {
            this.email_data.to_addresses.options.push({
                value: vnode.attrs.supplier.emailaddress_financial_documents,
                label: `Default for ${vnode.attrs.supplier.name} (${vnode.attrs.supplier.emailaddress_financial_documents})`,
            })
            this.email_data.to_addresses.selected = vnode.attrs.supplier.emailaddress_financial_documents
        }
        if (vnode.attrs.supplier.emailaddress_rfp_documents) {
            this.email_data.to_addresses.options.push({
                value: vnode.attrs.supplier.emailaddress_rfp_documents,
                label: `Default for ${vnode.attrs.supplier.name} (${vnode.attrs.supplier.emailaddress_rfp_documents})`,
            })
            this.email_data.to_addresses.selected = vnode.attrs.supplier.emailaddress_rfp_documents
        }

        this.email_data.cc_addresses.options = this.email_data.to_addresses.options
    }

    email_pdf(vnode: m.Vnode<PDFEmailerAttrs>): void {
        if (this.make_email_address_default) {
            this.update_default_email_address(vnode)
        }
        const request_data = {
            content_type: 'application/pdf',
            content: vnode.attrs.pdf_helper.pdf_as_base64_encoded(),
            document_name: vnode.attrs.pdf_file_name,
            document_type: vnode.attrs.document_type,
            email_to: this.email_data.to_addresses.selected,
            email_addresses_cc: this.email_data.cc_addresses.selection,
            sales_order_artkey: vnode.attrs.sales_order?.artkey,
            purchase_order_artkey: vnode.attrs.purchase_order?.artkey,
            from_user_artkey: this.email_data.from_user_artkey ? +this.email_data.from_user_artkey : undefined,
        }

        this.email_api
            .send_document_to(request_data)
            .subscribe({
                next: () => {
                    notifier.notify('Email successfully sent', 'info')
                    this.email_data.to_addresses.selected = ''
                    this.email_data.cc_addresses.selection.splice(0, this.email_data.cc_addresses.selection.length)
                    m.redraw()
                },
                error: () => notifier.notify('Cannot sent email', 'danger'),
            })
    }

    update_default_email_address(vnode: m.Vnode<PDFEmailerAttrs>): void {
        if (vnode.attrs.sales_order) {
            this.relation_api.set_emailaddress_financial_documents(
                vnode.attrs.supplier.artkey, this.email_data.to_addresses.selected,
            ).subscribe({
                next: () => notifier.notify(`Made email address ${this.email_data.to_addresses.selected} the default`, 'info'),
                error: () => notifier.notify('Unable to update the default email address', 'danger'),
            })
        } else if (vnode.attrs.purchase_order) {
            this.relation_api.set_emailaddress_rfp_documents(
                vnode.attrs.supplier.artkey, this.email_data.to_addresses.selected,
            ).subscribe({
                next: () => notifier.notify(`Made email address ${this.email_data.to_addresses.selected} the default`, 'info'),
                error: () => notifier.notify('Unable to update the default email address', 'danger'),
            })
        }
    }

    view(vnode: m.Vnode<PDFEmailerAttrs>): m.Children {
        return [
            <FieldSelect
                label={'Email to'}
                model={[this.email_data.to_addresses, 'selected']}
                options={this.email_data.to_addresses.options}
                placeholder={'Select contact to email to'}
            />,
            <CheckBox
                help="Make this address default for these documents"
                label="Default address for these documents"
                value={this.make_email_address_default}
                onchange={() => (this.make_email_address_default = !this.make_email_address_default)}
            />,

            <FieldSelectMany
                model={this.email_data.cc_addresses.selection}
                options={this.email_data.cc_addresses.options}
                placeholder={'Select contact cc'}
                label={'Email cc'}
            />,

            <UserDropDown
                label="Use from address"
                model={[this.email_data, 'from_user_artkey']}
            />,

            <PrimaryButton
                disabled={
                    !this.email_data.to_addresses.selected ||
                    !vnode.attrs.pdf_helper.pdf
                }
                onclick={() => this.email_pdf(vnode)}
                icon_class={'glyphicon glyphicon-send'}
            >
                {' '}
                Mail
            </PrimaryButton>,
        ]
    }
}
