/** llm:tested*/

import m from 'mithril'
import {Amount, FieldMoney, Link} from '@bitstillery/common/components'
import {Spinner} from '@bitstillery/common/components'
import {debounce, object_to_query_string} from '@bitstillery/common/lib/utils'
import {api, notifier} from '@bitstillery/common/app'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {button_with_icon, icon_button} from '@/components/_buttons'
import {pricelist_link} from '@/data/components/pricelist_link'
import {specs, tax_label, customs_status, number , text, gift_box_type} from '@/components/inputs'
import {validate_bottle_gtin} from '@/components/validate_gtin'
import api_ls from '@/api'
import {colgroup} from '@/components/table/base'
import {Product} from '@/models/products'
import {CountriesSelect} from '@/components/html_components'
import {AutocompleteInput} from '@/components/collection/autocomplete_input'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {PurchaseOrderItem} from '@/models/purchase_orders'
import {Bottle} from '@/models/bottles'
import {$m, $s} from '@/app'
import {link_from_bottle} from '@/market/pricelists/components/discover_link'

interface AddItemAttrs {
    purchase_order: any
    on_added_to_order?: () => void
}

export class AddItem extends MithrilTsxComponent<AddItemAttrs> {
    private product: any
    private purchase_order_item: any
    private product_name: any
    private splis: any
    private purchase_order: any
    private hide_from_pricelist_for_countries_selected: any
    private hide_from_pricelist_for_suppliers_selected: any
    private show_all_splis: any
    private loading: any
    private error_message: any
    private bottle_gtin_found: any
    private debounced_get_bottle_prices: any
    private product_management_api: ProductManagementApi
    private search_bar_controller: any
    private on_added_to_order?: () => void
    private is_loading_bottles: boolean

    constructor(vnode: m.Vnode<AddItemAttrs>) {
        super()
        this.product = window.prop(new Product())
        this.purchase_order_item = window.prop(new PurchaseOrderItem())
        this.product_name = window.prop('')
        this.splis = window.prop([])
        this.purchase_order = vnode.attrs.purchase_order
        this.hide_from_pricelist_for_countries_selected = window.prop([])
        this.hide_from_pricelist_for_suppliers_selected = window.prop([])
        this.init()

        this.show_all_splis = window.prop(false)
        this.loading = window.prop(false)
        this.error_message = window.prop('')
        this.bottle_gtin_found = window.prop(false)

        this.debounced_get_bottle_prices = debounce(450, this.get_bottle_prices_and_pallet_layout.bind(this))
        this.product_management_api = new ProductManagementApi()
        this.search_bar_controller = null
        this.on_added_to_order = vnode.attrs.on_added_to_order
        this.is_loading_bottles = false
    }

    init() {
        this.purchase_order_item().purchase_order = this.purchase_order
        this.purchase_order_item().customs_status('T2')
        this.purchase_order_item().was_bought_for('')
        this.purchase_order_item().suggested_price_per_case(null)
        this.purchase_order_item().number_of_bottles_per_case('')
        this.purchase_order_item().number_of_cases('')
        this.purchase_order_item().gift_box_type('')
        this.purchase_order_item().remark('')
        this.purchase_order_item().tax_label('')
        this.purchase_order_item().cases_per_pallet('')
        this.purchase_order_item().cases_per_pallet_layer('')
        this.purchase_order_item().country_of_origin('')
        this.purchase_order_item().bottle_gtin_code('')
        this.purchase_order_item().was_bought_for(null)
        this.purchase_order_item().bottle_artkey(null)
        this.hide_from_pricelist_for_countries_selected([])
        this.hide_from_pricelist_for_suppliers_selected([])
    }

    after_update_product(selected_product: any) {
        if (!selected_product) {
            this.product().artkey('')
            this.product().name('')
            this.product().bottles([])
        } else {
            this.product_name(selected_product.name)
            this.product().artkey(selected_product.artkey)
            this.product().name(selected_product.name)
            this.product().default_country_code(selected_product.default_country_code)

            this.is_loading_bottles = true
            this.product_management_api.get_bottles_for_product(selected_product.artkey).subscribe({
                next: (response) => {
                    this.is_loading_bottles = false
                    this.product().bottles(response.map((bottle: any) =>
                        new Bottle(bottle.artkey, bottle.volume, bottle.alcohol_percentage, bottle.refill_status),
                    ))
                    m.redraw()
                },
                error: () => {
                    this.is_loading_bottles = false
                    m.redraw()
                },
            })

            this.purchase_order_item().country_of_origin(this.product().default_country_code())
        }

        this.splis([])
    }

    async set_bottle(bottle_artkey: string) {
        if (bottle_artkey) {
            this.purchase_order_item().bottle_artkey(bottle_artkey)
            await this.get_bottle_prices()
        }
    }

    async get_bottle_prices_and_pallet_layout() {
        await this.get_bottle_prices()
        await this.get_pallet_layout()
    }

    async get_bottle_prices() {
        if (this.purchase_order_item().bottle_artkey()) {
            const {result} = await api.get(
                `discover/bottle/${this.purchase_order_item().bottle_artkey()}/supplier-price-list-items`,
            )
            this.splis(result)
            m.redraw()
        }
    }

    async get_pallet_layout() {
        if (!this.purchase_order_item().bottle_artkey() || !this.purchase_order_item().number_of_bottles_per_case()) {
            return
        }

        const request = {
            bottle_artkey: this.purchase_order_item().bottle_artkey(),
            number_of_bottles: this.purchase_order_item().number_of_bottles_per_case(),
            customs_status: this.purchase_order_item().customs_status(),
        }

        if (this.purchase_order_item().gift_box_type()) {
            request.gift_box_type = this.purchase_order_item().gift_box_type()
        }

        const request_string = object_to_query_string(request)
        const {result} = await api.get('discover/cases/find-pallet-layout?' + request_string)

        this.purchase_order_item().cases_per_pallet(result.cases_per_pallet)
        this.purchase_order_item().cases_per_pallet_layer(result.cases_per_pallet_layer)
        m.redraw()
    }

    look_up_specs_on_bottle_gtin() {
        this.error_message('')

        if (this.purchase_order_item().bottle_gtin_code() === '' || this.purchase_order_item().bottle_gtin_code() === null) {
            this.error_message('Please fill in a bottle gtin before the look up.')
            return
        }

        const invalid_message = validate_bottle_gtin(this.purchase_order_item().bottle_gtin_code())
        if (invalid_message) {
            this.error_message(invalid_message)
        } else {
            const data = {bottle_gtin: this.purchase_order_item().bottle_gtin_code()}
            api_ls.call('matcher.look_up_specs_at_bottle_gtin', data, this.handle_bottle_gtin_specs_look_up.bind(this))
        }
    }

    handle_bottle_gtin_specs_look_up(resp: any) {
        if ('message' in resp) {
            this.error_message(resp.message)
        } else {
            this.bottle_gtin_found(true)

            const bottle = {
                artkey: resp.result[5],
                alcohol_percentage: resp.result[3],
                volume: resp.result[2],
                refill_status: resp.result[4],
            }

            const category = {
                name: resp.result[1],
            }

            const prod = {
                name: resp.result[0],
                bottles: [bottle],
                product_category: category,
            }

            this.after_update_product(prod)
        }
    }

    add_to_order() {
        this.loading(true)
        this.error_message('')

        const data = {
            product_name: this.product().name(),
            purchase_order_artkey: this.purchase_order_item().purchase_order().artkey(),
            bottle_artkey: this.purchase_order_item().bottle_artkey(),
            was_bought_for: this.purchase_order_item().was_bought_for(),
            suggested_price_per_case: this.purchase_order_item().suggested_price_per_case(),
            refers_to_artkey: this.purchase_order_item().refers_to_artkey(),
            number_of_bottles_per_case: this.purchase_order_item().number_of_bottles_per_case(),
            tax_label: this.purchase_order_item().tax_label(),
            gift_box_type: this.purchase_order_item().gift_box_type(),
            customs_status: this.purchase_order_item().customs_status(),
            availability_date: this.purchase_order_item().availability_date(),
            number_of_cases: this.purchase_order_item().number_of_cases(),
            cases_per_pallet: this.purchase_order_item().cases_per_pallet(),
            cases_per_pallet_layer: this.purchase_order_item().cases_per_pallet_layer(),
            remark: this.purchase_order_item().remark(),
            bottle_gtin_code: this.purchase_order_item().bottle_gtin_code(),
            case_gtin_code: this.purchase_order_item().case_gtin_code(),
            country_of_origin: this.purchase_order_item().country_of_origin(),
            hide_from_pricelist_for_suppliers: this.hide_from_pricelist_for_suppliers_selected().map((artkey: string) => +artkey),
            hide_from_pricelist_for_countries: this.hide_from_pricelist_for_countries_selected(),
        }

        api_ls.call('purchase.purchase_order_item.add_item_to_purchase_order', data, 
            () => {
                $m.common.observable.broadcast('purchase_order_updated_' + this.purchase_order().artkey(), 'Go fetch')
                this.init()
                this.splis([])
                this.product(new Product())
                this.product_name('')
                this.search_bar_controller.set_and_submit_search_text('')
                if (this.on_added_to_order) {
                    this.on_added_to_order()
                }
                notifier.notify('The item was added successfully.', 'success')
            },
        )
    }

    view() {
        return <div class="panel panel-default">
            <div class="panel-body fieldset largest">
                <div class="field-group">
                    <AutocompleteInput
                        label="Product"
                        placeholder="Select a product"
                        default_search_text={this.product_name()}
                        on_get_suggestions$={(filter_text: string) => this.product_management_api.get_simple_products(filter_text)}
                        suggestion_as_label={(simple_product: any) => simple_product.name}
                        on_selected={(simple_product: any) => this.after_update_product(simple_product)}
                        search_bar_controller={(sbc: any) => this.search_bar_controller = sbc}
                    />
                </div>

                <div class="field-group">
                    {this.is_loading_bottles && <Spinner />}
                    {!this.is_loading_bottles && specs(this.product().bottles, this.purchase_order_item().bottle, {
                        after_update: this.set_bottle.bind(this),
                        label: 'Specs',
                    })}

                    {number(this.purchase_order_item().number_of_bottles_per_case, {
                        label: 'Bottles per case',
                        min: 1,
                        after_update: this.debounced_get_bottle_prices,
                        required: true,
                    })}

                    {number(this.purchase_order_item().number_of_cases, {
                        label: 'Cases',
                        min: 1,
                        required: true,
                        after_update: this.debounced_get_bottle_prices,
                    })}

                    <FieldMoney
                        currency={[this.purchase_order(), 'was_bought_in']}
                        label="Price per case"
                        model={[this.purchase_order_item(), 'was_bought_for']}
                        required={true}
                    />

                    <FieldMoney
                        currency={[this.purchase_order(), 'was_bought_in']}
                        label="Suggested sales price"
                        model={[this.purchase_order_item(), 'suggested_price_per_case']}
                        required={true}
                    />

                    {gift_box_type(this.purchase_order_item().gift_box_type, {
                        label: 'Giftbox',
                        after_update: this.debounced_get_bottle_prices,
                    })}
                </div>

                <div class="field-group">
                    <div class="field">
                        <label>Bottle GTIN</label>
                        <div class="control">
                            <input
                                value={this.purchase_order_item().bottle_gtin_code()}
                                oninput={(ev: any) => {
                                    if (ev.target.value === '') {
                                        this.purchase_order_item().bottle_gtin_code(null)
                                    } else {
                                        this.purchase_order_item().bottle_gtin_code(ev.target.value)
                                    }
                                    this.bottle_gtin_found(false)
                                }}
                            />
                            {icon_button('search', {
                                onclick: this.look_up_specs_on_bottle_gtin.bind(this),
                                disabled: !this.purchase_order_item().bottle_gtin_code(),
                            }, {
                                class: 'btn-default',
                            })}
                        </div>
                    </div>

                    {text(this.purchase_order_item().case_gtin_code, {
                        label: 'Case GTIN',
                    })}

                    {customs_status(this.purchase_order_item().customs_status, {
                        label: 'Customs status',
                    })}

                    {tax_label(this.purchase_order_item().tax_label, {
                        label:'Tax Label',
                    })}

                    <CountriesSelect
                        label="Country of origin"
                        model={[this.purchase_order_item(), 'country_of_origin']}
                    />

                    {text(this.purchase_order_item().remark, {
                        label: 'Remark',
                    })}
                </div>

                <div class="field-group no-fill flex-end">
                    {number(this.purchase_order_item().cases_per_pallet, {
                        label:'Cases per Pallet',
                        min:1,
                    })}

                    {number(this.purchase_order_item().cases_per_pallet_layer, {
                        label:'Cases per Pallet Layer',
                        min: 1,
                    })}

                    {button_with_icon('Add Product', 'plus', {
                        onclick: this.add_to_order.bind(this),
                    }, {
                        class: 'btn-primary',
                    })}
                </div>

                {this.splis().length > 0 && [
                    <div class="splis">
                        <table class="table table-condensed table-hover">
                            {colgroup([
                                {name: 'Button', width: 5},
                                {name: 'Supplier', width: 35},
                                {name: 'GB', width: 15},
                                {name: 'Btl / cs', width: 15},
                                {name: 'Price per bottle', width: 15},
                                {name: 'Price per case', width: 15},
                                {name: 'Remark', width: 25},
                            ])}

                            <thead class="thead-default">
                                <tr>
                                    <th>
                                        <span class="pull-left">
                                            {this.purchase_order_item().bottle() ?
                                                link_from_bottle(this.purchase_order_item().bottle()) :
                                                null}
                                        </span>
                                    </th>
                                    <th>Supplier</th>
                                    <th>GB</th>
                                    <th class="number">Btl / cs</th>
                                    <th class="price">Price per bottle</th>
                                    <th class="price">Price per case</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.splis().map((spli: any) => {
                                    const row_classes = [
                                        $m.data.company_type_class(spli.supplier_company_type),
                                        spli.supplier_artkey === this.purchase_order().supplier().artkey() ?
                                            'is-current-supplier' : '',
                                    ].join(' ')

                                    return <tr class={row_classes} key={spli.artkey}>
                                        <td>
                                            <Link
                                                href={pricelist_link.from_bottle_json(spli.supplier_price_list_artkey, {
                                                    product: {
                                                        name: spli.product_name,
                                                    },
                                                    volume: spli.volume,
                                                    alcohol_percentage: spli.alcohol_percentage,
                                                })}
                                                class="pull-left"
                                            >
                                                <span class="glyphicon glyphicon-new-window" />
                                            </Link>
                                        </td>
                                        <td>{spli.supplier_name}</td>
                                        <td>{spli.gift_box_type}</td>
                                        <td class="number">{spli.number_of_bottles_per_case}</td>
                                        <td class="price">
                                            <Amount
                                                amount={spli.price_per_bottle}
                                                currency={spli.currency}
                                                display_currency={$s.currencies.default}
                                            />
                                        </td>
                                        <td class="price">
                                            <Amount
                                                amount={spli.price_per_case}
                                                currency={spli.currency}
                                                display_currency={$s.currencies.default}
                                            />
                                        </td>
                                        <td>{spli.aux_info}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        {this.splis().length > 2 &&
                            <div onclick={() => this.show_all_splis(!this.show_all_splis())}>
                                {this.show_all_splis() ?
                                    <span class="glyphicon glyphicon-chevron-up" /> :
                                    <span class="glyphicon glyphicon-chevron-down" />}
                            </div>
                        }
                    </div>,
                ]}

                {this.error_message() &&
                    <div class="alert alert-danger alert-dismissible" role="alert">
                        <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-label="Close"
                            onclick={() => this.error_message('')}
                        >
                            <span>×</span>
                        </button>
                        <span>{this.error_message()}</span>
                    </div>
                }
            </div>
        </div>
    }
}
