import {displayable_float, format_iso_to_fixed_date_format} from '@bitstillery/common/lib/format'

export interface Product {
    artkey: number
    name: string
    category: string
}

export interface Bottle {
    artkey: number
    alcohol_percentage: string | number
    refill_status: string
    cbs_code: string
    volume: string | number
    product: Product
}

export interface Case {
    artkey: number
    article_code: string
    customs_status: string
    gift_box_type: string
    item_tags: string[] | null
    number_of_bottles: number
    bottle: Bottle
    tax_label: string
    best_before_date: string
}

export interface Item {
    case: Case
    cases_per_pallet: number
    bottle_lot: string | null
    country_of_origin: string
    purchase_order_country_of_origin: string
    lot: string
}

export function to_specs(
    bottle: Pick<Bottle, 'alcohol_percentage' | 'refill_status' | 'volume'>,
    decimal_locale: string,
): string {
    return to_specs_from_values(bottle.volume, bottle.refill_status, bottle.alcohol_percentage, decimal_locale)
}

export function to_specs_from_values(volume: string, refill_status: string, alcohol_percentage: string, decimal_locale: string): string {
    return `${displayable_float(volume, 1, decimal_locale)}cl / ${displayable_float(
        alcohol_percentage,
        1,
        decimal_locale,
    )}% / ${refill_status.toUpperCase()}`
}

/** Specs with number of bottles per case prepended, and several features appended. '/' seperated. */
export function to_specs_with_features(
    any_case: Pick<Case, 'gift_box_type' | 'number_of_bottles' | 'tax_label' | 'item_tags' | 'best_before_date'>,
    bottle: Pick<Bottle, 'alcohol_percentage' | 'refill_status' | 'volume'>,
    decimal_locale: string,
): string {
    let specs = `${any_case.number_of_bottles} / ${to_specs(bottle, decimal_locale)}`
    const features = []
    if (any_case.gift_box_type) {
        features.push(any_case.gift_box_type)
    }
    if (any_case.tax_label) {
        features.push(any_case.tax_label)
    }
    if (any_case.item_tags) {
        features.concat(any_case.item_tags)
    }
    if (any_case.best_before_date) {
        features.push(`BBD: ${format_iso_to_fixed_date_format(any_case.best_before_date)}`)
    }
    if (features.length > 0) {
        specs = `${specs} / ${features.join('/')}`
    }
    return specs
}
