/** llm:tested */
import m from 'mithril'
import {map} from 'prelude-ls'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import {GraphFunction} from './models'
import {create_time_line_graph} from './components/graph_utils'
import {GraphProductTable} from './components/graph_product_table'
import {GraphDataManager} from './components/graph_data_manager'

import api from '@/api'
import inputs from '@/components/inputs'
import {afterUpdate} from '@/_utils'
import {Box} from '@/components/adminlte/box'
import {MithrilProp} from '@/types/utils'

interface ItemStockedGraphAttrs {
    canvas_id: string
}

interface DataPoint {
    x: string
    y: number
    cumulative: number
    rolling_average: number
    number_of_cases: number
}

interface Dataset {
    record: any
    label: string
    backgroundColor: string
    borderColor: string
    lineTension: number
    spanGaps: boolean
    data: DataPoint[]
}

function create_prop<T>(initialValue: T): MithrilProp<T> {
    let value = initialValue
    const prop = function(newValue?: T) {
        if (arguments.length) {
            value = newValue as T
            return value
        }
        return value
    } as MithrilProp<T>
    return prop
}

export class ItemStockedGraph extends MithrilTsxComponent<any> {
    private canvas_id: string
    private accumulate_graph_data: MithrilProp<boolean>
    private graph_function: MithrilProp<GraphFunction>
    private line_chart: MithrilProp<any>
    private graph_data: any
    // LLM_NOTE: This function is not defined in the livescript file.
    private set_accumulate_graph_data: (value: boolean) => void = () => {
        // Implementation of set_accumulate_graph_data if needed
    }

    constructor(vnode: m.Vnode<ItemStockedGraphAttrs>) {
        super()
        this.canvas_id = vnode.attrs.canvas_id

        // Create properties using create_prop instead of window.prop
        const prop = create_prop(false)
        this.accumulate_graph_data = afterUpdate(
            (value: boolean) => {
                this.set_accumulate_graph_data(value)
            },
            prop,
        )

        this.graph_function = create_prop(GraphFunction.NUMBER_OF_CASES)
        this.line_chart = create_prop(null)
        this.graph_data = new GraphDataManager()
    }

    set_aggregation_level() {
        // LLM_NOTE: This function is not defined in the livescript file.
        // this.aggregation_level(val)
        this.requery_datasets_in_graph()
    }

    set_graph_function(val: any) {
        this.graph_function(val)
        this.update_graph_data_with_graph_function()
    }

    update_graph_data_with_graph_function() {
        for (const dataset of this.graph_data.data().datasets) {
            for (const data of dataset.data) {
                if (this.graph_function() === GraphFunction.ROLLING_AVERAGE) {
                    data.y = data.rolling_average
                } else if (this.graph_function() === GraphFunction.CUMULATIVE) {
                    data.y = data.cumulative
                } else if (this.graph_function() === GraphFunction.NUMBER_OF_CASES) {
                    data.y = data.number_of_cases
                }
            }
        }
        this.line_chart().update()
    }

    requery_datasets_in_graph() {
        const cases_to_recalculate = map((dataset: any) => ({
            case_artkey: dataset.item_stocked_record.artkey,
            record: dataset.item_stocked_record,
        }), this.graph_data.datasets)

        this.graph_data = new GraphDataManager()
        for (const case_to_recalculate of cases_to_recalculate) {
            this.query_data_for_case(case_to_recalculate.case_artkey, case_to_recalculate.record)
        }
        this.line_chart().update()
    }

    on_update_is_in_graph(case_artkey: string, record: any, value: boolean) {
        if (value) {
            this.query_data_for_case(case_artkey, record)
        } else {
            this.graph_data.remove_dataset_for_case_artkey(case_artkey)
            this.line_chart().update()
        }
    }

    query_data_for_case(case_artkey: string, record: any) {
        const data = {
            case_artkey: case_artkey,
        }

        api.callAndThen('diana.item_stocked.datapoints_in_period', data, {
            success: (resp: any) => {
                const color = this.graph_data.fetch_available_color_for_graph(case_artkey)
                const dataset: Dataset = {
                    record: record,
                    label: `${record.product_name} ${record.volume} ${record.alcohol_percentage} ${record.number_of_bottles_per_case}/${record.customs_status}/${record.refill}/${record.gift_box_type}/${record.tax_label}`,
                    backgroundColor: color.background,
                    borderColor: color.border,
                    lineTension: 0,
                    spanGaps: true,
                    data: map((data_point: any) => ({
                        x: data_point.timestamp.split('T')[0],
                        y: data_point.cumulative,
                        cumulative: data_point.cumulative,
                        rolling_average: data_point.rolling_average,
                        number_of_cases: data_point.number_of_cases,
                    }), resp.result),
                }
                this.graph_data.add_dataset(dataset)
                this.update_graph_data_with_graph_function()
                this.line_chart().update()
            },
        })
    }

    oncreate_line_chart() {
        const target = document.getElementById(this.canvas_id) as HTMLCanvasElement
        if (!target) return

        this.line_chart(create_time_line_graph(this.graph_data.data(), target))
        return this.line_chart()
    }

    view() {
        return [
            <Box
                cls=".box-info.bg-gray-light"
                title="Stocked items per month in case units"
                body={() => {
                    if (this.graph_data.data() !== null) {
                        return (
                            <canvas
                                id={this.canvas_id}
                                height="50px"
                                oncreate={() => this.oncreate_line_chart()}
                            />
                        )
                    }
                }}
                loading={() => this.graph_data.data() === null}
                collapsed={() => false}
            />,

            <div className="field">
                <div className="field-label">Graph function</div>
                <div className="field-value">
                    {inputs.select(this.graph_function, GraphFunction.choices, {
                        onchange: this.set_graph_function.bind(this),
                        empty_option: false,
                    })}
                </div>
            </div>,

            <GraphProductTable
                api_name="diana.item_stocked.list_cases_stocked"
                on_update_graph_with_record_artkey_and_record={this.on_update_is_in_graph.bind(this)}
                artkeys_in_graph={this.graph_data.case_artkeys_in_data.bind(this.graph_data)}
            />,
        ]
    }
}
