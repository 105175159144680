import m from 'mithril'
import {
    $m as _$m,
    $s as _$s,
    App,
    store,
    events,
} from '@bitstillery/common/app'
import {current_account_slug, set_account_styles} from '@bitstillery/common/account/account'

import {models, ModelsDiscover} from '@/models/models'
import {persistent, volatile, session, StateDiscover} from '@/lib/state'
import i18n_tags from '@/locales/locales'
// eslint-disable-next-line import/order
import {DEFAULT_ROUTE, routes} from '@/routes'
import 'summernote/dist/summernote'

import {config} from '@/lib/config'

// The app-specific exports:
export const $m = Object.assign(_$m, models) as unknown as ModelsDiscover
export const $s = _$s as StateDiscover

store.load(persistent, volatile, session)

class AppDiscover extends App {

    init() {
        super.init(config, models, store, i18n_tags)
        const requested_account = /\?account=(.*)/.exec(window.location.toString())
        if (requested_account && requested_account.length === 2) {
            const url_params = new URLSearchParams(requested_account[0])
            const account = url_params.get('account')
            if (account) {
                $s.session.account.slug = account
                store.save()
            }
        }

        set_account_styles(current_account_slug())
        events.once('app.route', () => {
            const $app = document.querySelector('.app') as HTMLElement
            m.route($app, DEFAULT_ROUTE, routes)
        })

        if ($s.identity.token) {
            events.emit('identity.login')
        } else {
            events.emit('app.route')
        }
    }
}

export const app = new AppDiscover()
globalThis.app = app
app.init()

// [fix] - moderate; do not use a m global here
window.m = require('mithril')

// [fix] - moderate; remove jquery; depends on bootstrap-sass, bootstrap-toggle & summernote
window.$ = window.jQuery = require('jquery')
// [fix] - moderate; old tooltip/popover from few livescript occurences & summernote
require('@/vendor/bootstrap-mini/tooltip')
require('@/vendor/bootstrap-mini/popover')
// [fix] - moderate; old modals; use .c-modal instead
require('@/vendor/bootstrap-mini/modal')
// [fix] - moderate; used in old accordeons
require('@/vendor/bootstrap-mini/collapse')
require('@/vendor/bootstrap-mini/transition')
// [fix] - moderate; used in summernote dropdowns
require('@/vendor/bootstrap-mini/dropdown')
