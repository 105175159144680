import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DataCard, Amount, AmountUnit, Spinner, ButtonGroup, Button, Lot} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/lib/specs'
import {api} from '@bitstillery/common/app'

import {FilterType, SearchFilterJson} from './models'

import {BottleThroughputResponse, ExploreSalesHistoryType, SalesHistoryResponse} from '@/factserver_api/fact2server_api'
import {accountIconBySlug} from '@/accounts'
import {Link} from '@/components/discover'
import {Pager} from '@/components/pager/pager'
import {Panel} from '@/components/panel'
import {BottleThroughput} from '@/components/market_info/bottle_throughput'
import {formatDate} from '@/_utils'

interface ShowSalesHistoryAttrs {
    sales_history: Array<any>
    sales_history_total: number
    sales_history_margin: number
    count: number
    limit: number
    fetch_page: (page_index: number) => void
    filter_type: FilterType
    show_specs: boolean
    loading: boolean
    title: string
    toolbar?: JSX.Element
    collapsible?: boolean
    collapsed?: boolean
    download_callback?: () => void
    unique_name: string
    header: () => string
    search_filters: Array<SearchFilterJson>
}

/**
 * Component to display sales history in the market explore view.
 */
export class ShowSalesHistory extends MithrilTsxComponent<ShowSalesHistoryAttrs> {
    throughputs: Array<{
        search_filter: SearchFilterJson
        throughput: BottleThroughputResponse | null
    }>

    async oninit(vnode: m.Vnode<ShowSalesHistoryAttrs>) {
        await this._query_bottle_throughput(vnode.attrs)
    }

    async _query_bottle_throughput(attrs: ShowSalesHistoryAttrs) {
        this.throughputs = []
        for (const search_filter of attrs.search_filters) {
            const params = {
                product_artkey: search_filter.product_artkey,
                volume: search_filter.bottle_volume,
                alcohol_percentage: search_filter.bottle_alcohol_percentage,
            }
            if (search_filter.bottle_refill_status) {
                params['refill_status'] = search_filter.bottle_refill_status
            }
            if (!params.product_artkey || !params.volume || !params.alcohol_percentage) {
                const throughput_entry = {
                    search_filter: search_filter,
                    throughput: null,
                }
                this.throughputs.push(throughput_entry)
                continue
            }
            const quantity_sold_url = 'discover/bottles/quantity-sold'
            const response = await api.get(quantity_sold_url, params)
            const throughput = response.result as BottleThroughputResponse
            const throughput_entry = {
                search_filter: search_filter,
                throughput: throughput,
            }
            this.throughputs.push(throughput_entry)
        }
        m.redraw()
    }

    view(vnode: m.Vnode<ShowSalesHistoryAttrs>) {
        const {
            count,
            limit,
            fetch_page,
            filter_type,
            show_specs,
            loading,
            title,
            toolbar,
            collapsible,
            collapsed,
            download_callback,
            header,
            sales_history,
            sales_history_total,
            sales_history_margin,
        } = vnode.attrs

        // Show product column only if filter type is neither BOTH nor PRODUCT
        let show_product = filter_type !== FilterType.BOTH && filter_type !== FilterType.PRODUCT
        // Show relation column only if filter type is neither BOTH nor RELATION
        let show_relation = filter_type !== FilterType.BOTH && filter_type !== FilterType.RELATION
        let show_specs_flag = show_specs && show_product

        const sales_history_toolbar = count > 0 ? (
            <Pager
                page_size={limit}
                count={count}
                fetch_page={fetch_page}
                display_page_count={false}
            />
        ) : undefined

        return (
            <div className="c-market-explore-sales-history">
                <Panel
                    title={title}
                    toolbar={toolbar || sales_history_toolbar}
                    collapsible={collapsible}
                    collapsed={collapsed}
                    download_callback={count > 0 ? download_callback : undefined}
                >
                    {loading ? (
                        <Spinner />
                    ) : sales_history.length > 0 ? (
                        [
                            <div className={'flex c-data-card-container'}>
                                <DataCard
                                    model={{
                                        data: [
                                            {label: 'Sales history for', value: header},
                                            {
                                                label: 'Total sales value',
                                                value: (
                                                    <Amount
                                                        amount={+sales_history_total}
                                                        currency={'EUR'}
                                                    />
                                                ),
                                            },
                                            {
                                                label: 'Total margin',
                                                value: (
                                                    <Amount
                                                        amount={+sales_history_margin}
                                                        currency={'EUR'}
                                                    />
                                                ),
                                            },
                                        ],
                                    }}
                                    type="dense"
                                />
                                {this.throughputs.map((throughput_entry, index) => (
                                    <DataCard
                                        key={index}
                                        model={{
                                            data: [
                                                {label: 'Throughput for', value: throughput_entry.search_filter.product_name},
                                                {
                                                    label: throughput_entry.throughput
                                                        ? <BottleThroughput prefix="Sold" type="Long" throughput={throughput_entry.throughput} />
                                                        : 'Please select volume and alcohol percentage.',
                                                    hide_value: true,
                                                },
                                            ],
                                        }}
                                        type="dense"
                                    />
                                ))}
                            </div>,
                            <SalesHistoryTable
                                sales_history={sales_history}
                                show_specs={show_specs_flag}
                                show_product={show_product}
                                show_relation={show_relation}
                            />,
                        ]
                    ) : (
                        'No results'
                    )}
                </Panel>
            </div>
        )
    }
}

interface SalesHistoryTableAttrs {
    sales_history: Array<SalesHistoryResponse>
    show_specs: boolean
    show_product: boolean
    show_relation: boolean
}

/**
 * Component to render the sales history table with dynamic columns.
 */
class SalesHistoryTable extends MithrilTsxComponent<SalesHistoryTableAttrs> {
    view(vnode: m.Vnode<SalesHistoryTableAttrs>) {
        const {sales_history, show_specs, show_product, show_relation} = vnode.attrs

        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Sales Order</th>
                        {show_product && <th>Product</th>}
                        <th>Cases</th>
                        <th>Purchase Price</th>
                        <th>Sales Price</th>
                        {show_relation && <th>Buyer</th>}
                        <th>Status</th>
                        <th>Warehouse</th>
                        <th>Sales Manager</th>
                        <th className="actions">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sales_history.map(record => (
                        <tr key={record.sales_order_reference}>
                            <td>
                                <div className="td-group">
                                    <span className="header">
                                        {accountIconBySlug(record.account_slug, record.account_name)}
                                        <Link
                                            target="_blank"
                                            href={`/sales-orders/manage/${record.sales_order_reference}`}
                                        >
                                            {record.sales_order_reference}
                                        </Link>
                                    </span>
                                    {show_specs && (
                                        <span className="details">
                                            {to_specs({
                                                bottle_volume: record.bottle_volume,
                                                bottle_alcohol_percentage: record.bottle_alcohol_percentage,
                                                bottle_refill_status: record.bottle_refill_status,
                                                case_customs_status: record.case_customs_status,
                                                case_gift_box_type: record.case_gift_box_type,
                                                case_number_of_bottles: record.case_number_of_bottles,
                                                case_tax_label: record.case_tax_label,
                                            })}
                                        </span>
                                    )}
                                </div>
                            </td>
                            {show_product && <td>{record.product_name}</td>}
                            <td className="center">{record.sales_order_item_number_of_cases}</td>
                            <td>
                                <AmountUnit
                                    case_amount={+(record.sales_order_item_total_euro_was_bought_for || '0') / record.sales_order_item_number_of_cases}
                                    case_number_of_bottles={record.case_number_of_bottles}
                                    currency={'EUR'}
                                    excise={+record.case_excise_nl}
                                    display_currency={'EUR'}
                                />
                            </td>
                            <td>
                                <AmountUnit
                                    case_amount={+record.sales_order_item_price_per_case_excl_excise}
                                    case_amount_excise={+record.sales_order_item_price_per_case_incl_excise}
                                    case_number_of_bottles={record.case_number_of_bottles}
                                    currency={record.sales_order_was_sold_in}
                                    rate={record.sales_order_sold_against_rate}
                                />
                            </td>
                            {show_relation && <td>{record.supplier_name}</td>}
                            <td>
                                <div className="td-group">
                                    <span>{record.sales_order_sales_order_status}</span>
                                    <span className="details">{formatDate(record.sales_order_latest_status_update)}</span>
                                </div>
                            </td>
                            <td>
                                {record.sales_history_type === ExploreSalesHistoryType.SOI && <Lot lot_number={record.item_lot} />}
                                {record.sales_history_type === ExploreSalesHistoryType.TBO && 'TBO'}
                            </td>
                            <td>{record.sales_manager_name}</td>
                            <td className="actions">
                                <div className="actions-group no-click">
                                    <ButtonGroup>
                                        <Button
                                            onclick={() => window.open(`#!/stock/manage/${record.item_reference}`)}
                                            icon="stock"
                                            tip={`Manage Stock item ${record.item_reference}`}
                                        />
                                        <Button
                                            onclick={() => window.open(`#!/purchase-orders/manage/${record.purchase_order_reference}`)}
                                            icon="cart"
                                            tip={`Open related Purchase Order ${record.purchase_order_reference}`}
                                        />
                                    </ButtonGroup>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }
}
