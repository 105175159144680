/** llm:tested */
import m from 'mithril'
import {current_account_slug} from '@bitstillery/common/account/account'
import {classes} from '@bitstillery/common/lib/utils'
import {continents, countries} from '@bitstillery/common/lib/countries'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'

import {EmailProcessFlow, EmailBatch} from './models'

import {accountIcon} from '@/accounts'
import api from '@/api'
import {RelationDropDownData} from '@/factserver_api/relation_api'
import {button_with_icon} from '@/components/_buttons'
import {Collection} from '@/components/collection/collection'
import SearchInput from '@/components/collection/search_input'
import {CollectionTable} from '@/components/collection_table'
import {icon_with_popover} from '@/components/icon'
import {checkbox} from '@/components/inputs'
import {languages} from '@/components/languages'
import {FilterSidebar} from '@/offer/components/relation_selection_sidebar'
import {ProcessManageOffer} from '@/offer/offers/processes'
import {Offer} from '@/models/offers'
import {$m} from '@/app'

interface RelationResp {
    artkey: string
    name: string
    country_code: string
    city: string
    contact_languages: string[]
    sales_manager: {
        profile: {
            name: string
        }
    }
    company_type: string
    client_status: string
    offer_already_emailed: boolean
    portal_level: string
}

export class EmailRecipientSelection extends MithrilTsxComponent<null> {
    email_batch_artkey: string
    offer: any
    offer_artkey: string
    recipient_list: string[]
    relations: any
    search_input_ctrl: any
    email_batch: any

    constructor() {
        super()
        this.email_batch_artkey = m.route.param('artkey')

        this.offer = window.prop(new Offer())
        this.offer_artkey = m.route.param('offer')
        if (this.offer_artkey) {
            this.query_offer(this.offer_artkey)
        }

        this.recipient_list = []

        this.relations = new Collection({
            api_function_name: 'pricelistbatch.get_suppliers',
            filter_function: this.is_match,
            sort_order: [
                {name: 'name', direction: 'asc'},
            ],
            default_sort_by: 'name',
            default_sort_order: 'asc',
            additional_params: this.additional_params,
        })

        // Initialize relation filters with their default values
        if (typeof this.relations.filters === 'undefined') {
            this.relations.filters = {
                sales_account: window.prop([current_account_slug()]),
                buyers: window.prop('true'),
                client_status: window.prop(['Client', 'Prospect']),
                company_type: window.prop([]),
                currency: window.prop([]),
                customs_status_visibility: window.prop([]),
                language: window.prop([]),
                portal_level: window.prop([]),
                price_list_frequency: window.prop([]),
                purchase_manager: window.prop([]),
                relation_filter: window.prop('all'),
                sales_manager: window.prop([]),
                suppliers: window.prop(''),
                active_sales_promotions: window.prop([]),
                active_vouchers_artkeys: window.prop([]),
                operates_online: window.prop(''),
                should_receive_offer_mails: window.prop('true'),
                should_receive_purchase_mails: window.prop(''),
            }

            for (const continent_code of Object.keys(continents)) {
                this.relations.filters[`countries_per_continent_${continent_code}`] = window.prop([])
            }
        }

        this.search_input_ctrl = new SearchInput.controller({
            collection: this.relations,
            placeholder: 'Search for relation, country, sales manager and status',
            autocomplete: true,
            suggestions: [],
        })

        this.email_batch = new EmailBatch()

        api.callAndThen('email.batch.get',
            {offer_email_batch_artkey: this.email_batch_artkey, include_suppliers: true},
            {
                success: (resp: any) => {
                    this.email_batch.from_js(resp.result)
                    for (const supplier of this.email_batch.suppliers()) {
                        this.recipient_list.push(supplier.artkey)
                    }
                    this.relations.query()
                },
                failure: () => {
                    notifier.notify('Unknown batch.', 'danger')
                    m.route.set('/offer/email')
                },
            },
        )

        $m.common.observable.subscribe('suppliers_updated', this, this.relations.requery)
        RelationDropDownData.names().subscribe((names: string[]) => this.search_input_ctrl.set_suggestions(names))
    }

    additional_params = () => {
        const all_selected_countries: string[] = Object.keys(continents).map(code =>
            this.relations.filters[`countries_per_continent_${code}`](),
        ).reduce((selected_countries, continent_list) => selected_countries.concat(continent_list), [])

        return {
            offer_email_batch_artkey: this.email_batch_artkey,
            sales_account: this.relations.filters['sales_account'](),
            buyers: this.relations.filters['buyers'](),
            client_status: this.relations.filters['client_status'](),
            company_type: this.relations.filters['company_type'](),
            country: all_selected_countries,
            currency: this.relations.filters['currency'](),
            customs_status_visibility: this.relations.filters['customs_status_visibility'](),
            language: this.relations.filters['language'](),
            portal_level: this.relations.filters['portal_level'](),
            price_list_frequency: this.relations.filters['price_list_frequency'](),
            purchase_manager: this.relations.filters['purchase_manager'](),
            relation_filter: this.relations.filters['relation_filter'](),
            sales_manager: this.relations.filters['sales_manager'](),
            suppliers: this.relations.filters['suppliers'](),
            active_sales_promotions: this.relations.filters['active_sales_promotions'](),
            active_vouchers_artkeys: this.relations.filters['active_vouchers_artkeys'](),
            operates_online: this.relations.filters['operates_online'](),
            should_receive_offer_mails: this.relations.filters['should_receive_offer_mails'](),
            should_receive_purchase_mails: this.relations.filters['should_receive_purchase_mails'](),
        }
    }

    get_process_flow() {
        return this.offer_artkey ? EmailProcessFlow.OFFERS : EmailProcessFlow.MAILS
    }

    is_match = (relation: any, term: string) => {
        return (
            relation.name.toLowerCase().indexOf(term) > -1 ||
            relation.sales_manager.profile.name.toLowerCase().indexOf(term) > -1 ||
            (relation.company_type || '').toLowerCase().indexOf(term) > -1 ||
            (relation.client_status || '').toLowerCase().indexOf(term) > -1 ||
            (countries[relation.country_code] || '').toLowerCase().indexOf(term) > -1
        )
    }

    check_relation = (supplier_artkey: string) => {
        if (!this.recipient_list.includes(supplier_artkey)) {
            this.recipient_list.push(supplier_artkey)
        } else {
            const index = this.recipient_list.indexOf(supplier_artkey)
            if (index > -1) {
                this.recipient_list.splice(index, 1)
            }
        }
        m.redraw()
    }

    save = () => {
        const data = {
            offer_email_batch_artkey: this.email_batch.artkey() || null,
            suppliers: this.recipient_list,
        }
        api.call('pricelistbatch.set_suppliers', data, this.handle_save)
    }

    handle_save = (result: any) => {
        if (!result.success) {
            notifier.notify(result.message, 'danger')
        } else {
            notifier.notify('Successfully updated recipient.', 'success')
            if (this.get_process_flow() === EmailProcessFlow.MAILS) {
                m.route.set(`/crm/email/${this.email_batch_artkey}`)
            } else {
                m.route.set(`/offer/offers/${this.offer_artkey}/email/${this.email_batch_artkey}/email-send`)
            }
        }
    }

    query_offer = (artkey: string) => {
        const data = {artkey}
        api.callAndThen('offer.get_offer', data, {
            success: (resp: any) => {
                this.offer(new Offer(resp.result))
            },
            failure: () => {
                notifier.notify('Unknown offer.', 'danger')
                m.route.set('/offer/offers')
            },
        })
    }

    sort_by_checked = () => {
        for (const item of this.relations.items()) {
            item.checked = this.recipient_list.includes(item.artkey)
        }
        this.relations.sort_by('checked')
        this.relations.ascending(!this.relations.ascending())
        this.relations.filter_items()
    }

    toggle_all_relations = (value?: boolean) => {
        if (value !== undefined) {
            if (value) {
                for (const relation of this.relations.search_result()) {
                    if (!this.recipient_list.includes(relation.artkey)) {
                        this.recipient_list.push(relation.artkey)
                    }
                }
            } else {
                for (const relation of this.relations.search_result()) {
                    const index = this.recipient_list.indexOf(relation.artkey)
                    if (index > -1) {
                        this.recipient_list.splice(index, 1)
                    }
                }
            }
        } else {
            if (this.relations.search_result().length === 0) return false
            for (const relation of this.relations.search_result()) {
                if (!this.recipient_list.includes(relation.artkey)) {
                    return false
                }
            }
            return true
        }
    }

    view() {
        return <div class={classes('.c-mail-recipient-selection', 'view', {process: this.get_process_flow() === EmailProcessFlow.OFFERS})}>
            {this.get_process_flow() === EmailProcessFlow.OFFERS && (
                <ProcessManageOffer
                    active="email_recipients"
                    context={{
                        offer_artkey: this.offer_artkey,
                        email_batch_artkey: this.email_batch_artkey,
                    }}
                />
            )}
            {this.get_process_flow() === EmailProcessFlow.MAILS && (
                <div class="btn-toolbar">
                    <button
                        class="btn btn-default"
                        type="button"
                        onclick={() => m.route.set(`/crm/email/${this.email_batch_artkey}`)}
                    >
                        <span class="glyphicon glyphicon-arrow-left"/> Back to email recipients list
                    </button>
                </div>
            )}

            <div class="filter-sidebar-wrapper step-content">
                <FilterSidebar relations={this.relations}/>

                <div class="filter-result">
                    <div class="row mb-1">
                        <div class="col-lg-4">
                            {SearchInput.view(this.search_input_ctrl)}
                        </div>
                        <div class="col-lg-8 btn-toolbar">
                            {button_with_icon('Save selection', 'save', {
                                class: 'btn-success',
                                onclick: () => this.save(),
                            })}
                        </div>
                    </div>

                    <div class="mail recipients">
                        {checkbox(this.toggle_all_relations, {
                            label: 'All relations',
                            help: 'Select all relations in the filter result',
                        })}

                        <CollectionTable
                            collection={this.relations}
                            options={{
                                search_table_style: true,
                                sticky_header: true,
                                with_buttons: true,
                                autoscale: true,
                                unique_name: 'email_relations',
                            }}
                            columns={[
                                {
                                    width: 2,
                                    name: 'Make recipient',
                                    field: 'checked',
                                    sort_function: this.sort_by_checked,
                                    default_visible: true,
                                    function: (relation: RelationResp) => (
                                        <div>
                                            <input
                                                type="checkbox"
                                                id={relation.artkey}
                                                onclick={() => this.check_relation(relation.artkey)}
                                                checked={this.recipient_list.includes(relation.artkey)}
                                            />
                                            <label for={relation.artkey}/>
                                        </div>
                                    ),
                                },
                                {
                                    width: 8,
                                    name: 'Relation',
                                    field: 'name',
                                    sort: true,
                                    ellipsis: true,
                                    default_visible: true,
                                    function: (relation: any) => (
                                        <div>
                                            <span class="mr-05">{accountIcon(relation.sales_account)}</span>
                                            {relation.name}
                                        </div>
                                    ),
                                },
                                {
                                    width: 6,
                                    name: 'Location',
                                    field: 'country_code',
                                    sort: true,
                                    default_visible: true,
                                    function: (relation: RelationResp) => (
                                        <div>
                                            {countries[relation.country_code] + ', ' + relation.city}
                                        </div>
                                    ),
                                },
                                {
                                    width: 4,
                                    name: 'Languages',
                                    field: 'contact_languages',
                                    sort: true,
                                    default_visible: true,
                                    function: (relation: RelationResp) => {
                                        const language_list: string[] = []
                                        for (const language of relation.contact_languages) {
                                            if (language) {
                                                language_list.push(languages[language])
                                            } else {
                                                language_list.push('-')
                                            }
                                        }
                                        return language_list ? language_list.sort().join(', ') : '-'
                                    },
                                },
                                {
                                    width: 4,
                                    name: 'Sales manager',
                                    field: 'sales_manager.profile.name',
                                    sort: true,
                                    default_visible: true,
                                },
                                {
                                    width: 2,
                                    name: 'Portal level',
                                    field: 'portal_level',
                                    sort: true,
                                    default_visible: true,
                                },
                                {
                                    width: 2,
                                    name: 'Emailed',
                                    field: 'offer_already_emailed',
                                    sort: true,
                                    function: (relation: RelationResp) => (
                                        relation.offer_already_emailed ?
                                            icon_with_popover({
                                                iconId: 'glyphicon-envelope',
                                                title: 'Already emailed',
                                                content: `The offer in this email batch has already been emailed to ${relation.name}.`,
                                            })
                                            : null
                                    ),
                                },
                                {
                                    width: 1,
                                    header: '',
                                    name: 'Column selector',
                                    value: ' ',
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}
