import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Icon, RatingStars, Tippy} from '@bitstillery/common/components'
import {format_iso_to_date, format_iso_to_date_time, titleize} from '@bitstillery/common/lib/format'
import {DateTime} from 'luxon'
import {proxy} from '@bitstillery/common/lib/proxy'
import {AccountSlug} from '@bitstillery/common/account/account'
import {api, config} from '@bitstillery/common/app'
import {Amount} from '@bitstillery/common/components'

import {accountIcon} from '@/accounts'
import {Link} from '@/components/discover'
import {ClipboardCopy} from '@/components/clipboard'
import {InlineEditableText, Percent} from '@/components/html_components'
import {GetFastSalesOrderWithItemsResponse, is_origin_loendersloot, SalesApi} from '@/factserver_api/sales_api'
import {
    GetBuyFromAccountPurchaseOrderResponse,
    GetBuyFromAccountSalesOrderResponse,
    SalesOrderRatingResponse,
} from '@/factserver_api/fact2server_api'

export interface DetailsAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    buy_from_account_sales_orders_response: GetBuyFromAccountSalesOrderResponse[] | null
    buy_from_account_purchase_orders_response: GetBuyFromAccountPurchaseOrderResponse[] | null
}

export class Details extends MithrilTsxComponent<DetailsAttrs> {

    sales_order_api = new SalesApi()
    buy_from_account_sales_orders_response: GetBuyFromAccountSalesOrderResponse[] | null
    buy_from_account_purchase_orders_response: GetBuyFromAccountPurchaseOrderResponse[] | null

    data = proxy({
        has_feedback: false,
        feedback_loading: false,
        feedback: {
            closed: false,
            comment: '',
            loading: true,
            rating: 0,
        },
    })

    async oncreate(vnode: m.Vnode<DetailsAttrs>) {
        Promise.all([
            this.sales_order_api.get_buy_from_account_sales_orders(vnode.attrs.sales_order.artkey),
            this.sales_order_api.get_buy_from_account_purchase_orders(vnode.attrs.sales_order.artkey),
            this.fetch_ratings(vnode.attrs.sales_order.artkey),
        ],
        ).then((result) => {
            this.buy_from_account_sales_orders_response = result[0]
            this.buy_from_account_purchase_orders_response = result[1]
        },
        )
    }

    async fetch_ratings(artkey: number) {
        const {result} = await api.get<SalesOrderRatingResponse>(`discover/sales-orders/${artkey}/rating`)
        if (result.rating) {
            Object.assign(this.data.feedback, {
                comment: result.comment || '',
                loading: false,
                rating: result.rating,
            })
            this.data.feedback.loading = false
            this.data.feedback.rating = result.rating
            this.data.feedback.comment = result.comment || ''
        }
    }

    update_remark(vnode: m.Vnode<DetailsAttrs>): void {
        this.sales_order_api.update_sales_order_remark(vnode.attrs.sales_order.artkey, vnode.attrs.sales_order.remark).subscribe({
            next: () => {
                m.redraw()
            },
            error: () => {
            },
        })
    }

    view(vnode: m.Vnode<DetailsAttrs>): m.Children {
        const sales_order = vnode.attrs.sales_order
        const origin_loendersloot = is_origin_loendersloot(sales_order)

        const sales_order_portal_link =
            sales_order.account.slug === AccountSlug.ETR
                ? `${config.a2bc_portal_base_url}#!/orders/${sales_order.artkey}`
                : `${config.portal_base_url}#!/orders/${sales_order.artkey}`

        const planned_loading_time = sales_order.planned_loading_date
            ? DateTime.fromISO(sales_order.planned_loading_date).toISOTime()
            : null
        return (
            <div className={'c-sales-order-details'}>
                <div className={'col-sm-6'}>
                    <dl className={'dl-horizontal'}>
                        <dt>Reference</dt>
                        <dd>{sales_order.reference} <ClipboardCopy text={sales_order.reference} /></dd>
                        <dt>Created on</dt>
                        <dd>{format_iso_to_date_time(sales_order.date)}</dd>
                        <dt>Created by</dt>
                        <dd>{sales_order.was_handled_by.profile.name}</dd>
                        {this.buy_from_account_sales_orders_response && this.buy_from_account_sales_orders_response.length > 0 && <dt>Buy from account</dt>}
                        {this.buy_from_account_sales_orders_response && this.buy_from_account_sales_orders_response.length > 0 && <dd>
                            {this.buy_from_account_sales_orders_response.map((so) =>
                                <div>
                                    <Link
                                        href={`/sales-orders/manage/${so.artkey}?account=${so.account_slug}`}
                                    >
                                        {so.reference}{` (${so.sales_order_status})`}
                                        {accountIcon({
                                            slug: so.account_slug || '',
                                            name: so.account_name || '',
                                        })}
                                    </Link></div>
                                ,
                            )}
                        </dd>}
                        {this.buy_from_account_purchase_orders_response && <dd>
                            {this.buy_from_account_purchase_orders_response.map((po) =>
                                <div><Link
                                    href={`/purchase-orders/manage/${po.artkey}?account=${po.account_slug}`}
                                >
                                    {po.reference}{` (${po.status})`}
                                    {accountIcon({
                                        slug: po.account_slug || '',
                                        name: po.account_name || '',
                                    })}
                                </Link></div>
                                ,
                            )}
                        </dd>}
                        <dt>Sales manager</dt>
                        <dd>{sales_order.supplier.sales_manager.profile.name}</dd>
                        <dt>Status</dt>
                        <dd>{sales_order.combined_status}</dd>
                        {sales_order.is_invoiced_on && (
                            <span>
                                <dt>Invoiced on</dt>
                                <dd>{format_iso_to_date(sales_order.is_invoiced_on)}</dd>
                                <dt>Invoice number</dt>
                                <dd>{sales_order.invoice_number}</dd>
                                <dt>Sent to FIS on</dt>
                                <dd>{format_iso_to_date(sales_order.was_sent_to_financial_information_system_on)}</dd>
                            </span>
                        )}

                        <dt>Relation</dt>
                        <dd>
                            <Link href={`/crm/relations/${sales_order.supplier.artkey}/edit`}>
                                {accountIcon({
                                    slug: sales_order.account.slug,
                                    name: sales_order.account.name,
                                })}
                                &nbsp;
                                {sales_order.supplier.name}
                            </Link>
                        </dd>
                        <dt>Currency</dt>
                        <dd>{sales_order.was_sold_in}</dd>
                        {sales_order.sold_against_rate !== 1 && (
                            <span>
                                <dt>Exchange rate</dt>
                                <dd>{sales_order.sold_against_rate}</dd>
                            </span>
                        )}

                        <dt>Origin</dt>
                        <dd>{sales_order.origin.name || '-'}</dd>
                        <dt>Destination</dt>
                        <dd>{sales_order.destination_location || sales_order.destination.name}</dd>
                        <dt>Incoterm</dt>
                        <dd>{sales_order.incoterm_and_location}</dd>
                        <dt>Req. delivery date</dt>
                        <dd>
                            {sales_order.requested_delivery_date
                                ? format_iso_to_date(sales_order.requested_delivery_date)
                                : 'As soon as possible'}
                        </dd>
                        <dt>Green transport</dt>
                        {sales_order.delivery.external_reference === null && <dd>-</dd>}
                        {sales_order.delivery.external_reference && (
                            <dd>{`${sales_order.delivery.external_reference} - ${
                                sales_order.delivery.status || ''
                            }`}</dd>
                        )}

                        <dt>Rating</dt>
                        {this.data.feedback.rating ? <dd className="fl">
                            <RatingStars
                                model={this.data.feedback}
                                read_only={true}
                                size="s"
                            />
                            {!!this.data.feedback.comment && <Icon
                                className="ml-05"
                                name="info"
                                size="s"
                                tip={() => this.data.feedback.comment}
                                type="info"
                            />}
                        </dd> : <dd className="fl">-</dd>}

                        <dt>Remarks</dt>
                        <dd>
                            <InlineEditableText
                                model={[vnode.attrs.sales_order, 'remark']}
                                onsave={() => this.update_remark(vnode)}
                                oncancel={(original_text) => vnode.attrs.sales_order.remark = original_text}
                            />
                        </dd>
                    </dl>
                </div>

                <div className={'col-sm-6'}>
                    <dl className={'dl-horizontal'}>
                        <dt>Insurance</dt>
                        <dd>{sales_order.insurance_type.name}</dd>
                        <dt>Number of cases</dt>
                        <dd>{sales_order.number_of_confirmed_cases}</dd>
                        <dt>Total purchased value</dt>
                        <dd>
                            <Amount amount={sales_order.euro_purchasing_value} currency={'EUR'} />
                        </dd>
                        <dt>Product excl. excise</dt>
                        <dd>
                            <Amount
                                amount={sales_order.product_total_excl_excise}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                        </dd>
                        <dt>Turnover</dt>
                        <dd>
                            <Amount
                                amount={sales_order.turnover}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                            &nbsp;
                            <Tippy content={'The turnover contains the product total excl excise, plus additionals of type revenue and discount.'}>
                                <span className={'glyphicon glyphicon-info-sign'} />
                            </Tippy>
                        </dd>
                        <dt>Total margin</dt>
                        <dd>
                            <Amount
                                amount={sales_order.margin}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                        </dd>
                        <dt>Margin (%)</dt>
                        <dd>
                            <Percent value={sales_order.margin_percentage / 100} />
                        </dd>
                        <dt>Order excl. excise</dt>
                        <dd>
                            <Amount
                                amount={sales_order.was_sold_for_excl_excise}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                        </dd>
                        <dt>Excise</dt>
                        <dd>
                            <Amount
                                amount={sales_order.excise_total}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                        </dd>
                        <dt>Order incl. excise</dt>
                        <dd>
                            <Amount
                                amount={sales_order.was_sold_for}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                        </dd>
                        <dt>VAT</dt>
                        <dd>
                            <Amount
                                amount={sales_order.vat_total}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                        </dd>
                        <dt>Grand Total</dt>
                        <dd>
                            <Amount
                                amount={sales_order.was_sold_for_incl_vat}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                        </dd>
                        <dt>Portal link</dt>
                        <dd>
                            <a href={sales_order_portal_link} target={'_blank'}>
                                {accountIcon({
                                    slug: sales_order.account.slug,
                                    name: sales_order.account.name,
                                })}{' '}
                                Open in portal{' '}
                            </a>
                            <ClipboardCopy text={sales_order_portal_link} label="link" />
                        </dd>

                        {origin_loendersloot && sales_order.sent_pre_advice_to_loendersloot_on && <dt>Sent pre-advice on</dt>}
                        {origin_loendersloot && sales_order.sent_pre_advice_to_loendersloot_on && <dd>{format_iso_to_date_time(sales_order.sent_pre_advice_to_loendersloot_on)}</dd>}
                        {origin_loendersloot && sales_order.pre_advice_reference && <dt>Pre-advice</dt>}
                        {origin_loendersloot && sales_order.pre_advice_reference && <dd>{sales_order.pre_advice_reference
                            ? <span>{sales_order.pre_advice_reference} <ClipboardCopy text={sales_order.pre_advice_reference} /></span>
                            : ''}
                        </dd>}
                        {origin_loendersloot && sales_order.pre_advice_cancelled_on && <dt>Pre-advice cancelled</dt>}
                        {origin_loendersloot && sales_order.pre_advice_cancelled_on && <dd>{format_iso_to_date_time(sales_order.pre_advice_cancelled_on)}
                        </dd>}
                        {origin_loendersloot && sales_order.pre_advice_confirmed_on && <dt>Pre-advice confirmed</dt>}
                        {origin_loendersloot && sales_order.pre_advice_confirmed_on && <dd>{format_iso_to_date_time(sales_order.pre_advice_confirmed_on)}
                        </dd>}

                        {origin_loendersloot && sales_order.sent_to_loendersloot_on && <dt>Sent to loendersloot on</dt>}
                        {origin_loendersloot && sales_order.sent_to_loendersloot_on && <dd>{format_iso_to_date_time(sales_order.sent_to_loendersloot_on)}</dd>}
                        {origin_loendersloot && sales_order.warehouse_reference && <dt>Warehouse reference</dt>}
                        {origin_loendersloot && sales_order.warehouse_reference && <dd>{sales_order.warehouse_reference
                            ? <span>{sales_order.warehouse_reference} <ClipboardCopy text={sales_order.warehouse_reference} /></span>
                            : ''}
                        </dd>}
                        {origin_loendersloot && sales_order.sent_to_loendersloot_on && <dt>Estimated loading date</dt>}
                        {origin_loendersloot && sales_order.sent_to_loendersloot_on && <dd>{format_iso_to_date(sales_order.estimated_loading_date)}</dd>}
                        {origin_loendersloot && <dt>Planned loading date</dt>}
                        {origin_loendersloot && planned_loading_time && !planned_loading_time.startsWith('00:00:00') && (
                            <dd>{format_iso_to_date_time(sales_order.planned_loading_date)}</dd>
                        )}
                        {origin_loendersloot && planned_loading_time && planned_loading_time.startsWith('00:00:00') && (
                            <dd>{format_iso_to_date(sales_order.planned_loading_date)}</dd>
                        )}
                        {origin_loendersloot && !planned_loading_time && <dd>-</dd>}
                        {origin_loendersloot && sales_order.warehouse_status && <dt>Warehouse status</dt>}
                        {origin_loendersloot && sales_order.warehouse_status && <dd>
                            {titleize(sales_order.warehouse_status || '')} -{' '}
                            {format_iso_to_date_time(sales_order.last_warehouse_message)}
                        </dd>}
                        <dt>Delivery Green</dt>
                        <dd>
                            {sales_order.delivery.estimated_weight_in_kilos && (
                                <span>Weight: {sales_order.delivery.estimated_weight_in_kilos} kg. </span>
                            )}
                            {sales_order.delivery.estimated_number_of_pallets && (
                                <span>Pallets: {sales_order.delivery.estimated_number_of_pallets}. </span>
                            )}
                            {sales_order.delivery.pickup_timeslot_from && (
                                <span>Pick up: {format_iso_to_date(sales_order.delivery.pickup_timeslot_from)}</span>
                            )}
                        </dd>
                    </dl>
                </div>
            </div>
        )
    }
}
