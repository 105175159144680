/** llm:tested */
import {isFunction, deref} from '@bitstillery/common/utils'
// eslint-disable-next-line no-unused-vars
import m from 'mithril'
import {Icon} from '@bitstillery/common/components/ui/icon/icon'

import {a} from '@/_utils'

interface PagerArgs {
    count: () => number
    page_size: number | (() => number)
    offset: number | ((offset: number) => void)
    loading?: () => boolean
    onpagechange?: (page: number) => void
    small?: boolean
}

export const pager = (args: PagerArgs) => {
    const count = +args.count()
    const page_size = +(deref(args.page_size))
    const selected = 1 + (deref(args.offset)) / page_size
    const pages = Math.ceil(count / page_size)
    const loading = args.loading || (() => false)

    const goto = (page: number) => {
        const offset = (page - 1) * page_size
        if ((deref(args.offset)) !== offset) {
            if (isFunction(args.offset)) {
                (args.offset as (offset: number) => void)(offset)
            } else {
                args.offset = offset
            }

            if (args.onpagechange) {
                args.onpagechange(page)
            }

            let base_url = window.location.href
            let query_string = ''
            // Check if there is a '?' behind the '#', then we have a query string.
            if (base_url.indexOf('?', base_url.indexOf('#')) >= 0) {
                const url_parts = base_url.split('?')
                // Parse out the query string part (behind the last '?').
                query_string = url_parts[url_parts.length - 1]
                // Get the URL part before the last '?'.
                base_url = url_parts.slice(0, -1).join('?')
            }

            // Overwrite (or set) the page query parameter.
            const params = new URLSearchParams(query_string)
            params.set('page', page.toString())

            // Append the parameters back to the URL.
            window.history.pushState({}, '', `${base_url}?${params.toString()}`)
        }
    }

    // Show at most 5 page links centered around the currently selected page.
    const max_links = 5
    const half_links = Math.floor(max_links / 2)
    const page_links =
        pages <= max_links
            ? [...Array(pages)].map((_, i) => i + 1)
            : selected <= half_links
                ? [...Array(max_links)].map((_, i) => i + 1)
                : selected >= pages - half_links
                    ? [...Array(max_links)].map((_, i) => pages - max_links + i + 1)
                    : [...Array(max_links)].map((_, i) => selected - half_links + i)

    const classes = ['pagination']

    return <div class="c-pager">
        {loading() && <div class="loading">Loading...</div>}
        {count === 0 && <div class="no-results">No results</div>}
        {count > 0 && [
            pages > 1 && <nav aria-label="Page navigation">
                <ul class={classes.join(' ')}>
                    {a([
                        <li>
                            <a onclick={() => goto(1)}>
                                <Icon name={'chevronLeftDouble'} />
                            </a>
                        </li>,
                        <li class={selected <= 1 ? 'disabled' : ''}>
                            <a
                                aria-label="Previous"
                                onclick={() => selected > 1 && goto(selected - 1)}
                            >
                                <Icon name={'chevronLeft'} />
                            </a>
                        </li>,
                        ...page_links.map(page => {
                            const clazz = page === selected ? 'active' : ''
                            return <li class={clazz}>
                                <a onclick={() => goto(page)}>
                                    {page}
                                </a>
                            </li>
                        }),
                        <li class={selected >= pages ? 'disabled' : ''}>
                            <a
                                aria-label="Next"
                                onclick={() => selected < pages && goto(selected + 1)}
                            >
                                <Icon name={'chevronRight'} />
                            </a>
                        </li>,
                        <li>
                            <a onclick={() => goto(pages)}>
                                <Icon name={'chevronRightDouble'} />
                            </a>
                        </li>,
                    ])}
                </ul>
            </nav>,
            <div class="result-count">
                {`${count} result${count > 1 ? 's' : ''}`}
            </div>,
        ]}
    </div>
}
