/** llm:tested */

import m from 'mithril'
import {Spinner} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {notifier} from '@bitstillery/common/app'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Tab, TabPanel, Tabs} from '@bitstillery/common/components'

import {EmailProcessFlow} from '../models'

import api_ls from '@/api'
import {button_with_icon} from '@/components/_buttons'
import {show_confirmation} from '@/components/confirmation'
import {formatDateTime} from '@/_utils'
import {EmailBatchRecipients} from '@/email/details/email_batch_recipients'
import {EmailBatchSentEmails} from '@/email/details/email_batch_sent_emails'
import {EmailBatchStatus, EmailBatchType} from '@/email/models'
import {ProcessManageOffer} from '@/offer/offers/processes'

type EmailBatchData = {
    artkey: string
    offer_artkey: string
    status: string
    batch_type: string
    subject: string
    created_on: string
    created_by: {
        profile: {
            name: string
            emailaddress: string
        }
    }
    use_sent_from_user: {
        profile: {
            emailaddress: string
        }
    } | null
    is_pending: boolean
    next_run: string
}

export class CrmEmailBatch extends MithrilTsxComponent<any> {
    artkey: any
    offer_artkey: string

    data = proxy({
        email_batch: null! as EmailBatchData,
        is_offer: false,
        number_of_recipients: 0,
        number_of_emails: 0,
        tabs: {
            active: m.route.param('tabId') ? m.route.param('tabId') : 'recipients',
        },
    })

    constructor() {
        super()
        this.artkey = window.prop(m.route.param('artkey'))
        this.offer_artkey = m.route.param('offer')
    }

    get_process_flow() {
        if (this.offer_artkey) {
            return EmailProcessFlow.OFFERS
        }
        return EmailProcessFlow.MAILS
    }

    oncreate() {
        this.fetch_email_batch()
    }

    fetch_email_batch() {
        api_ls.callAndThen('email.batch.get', {offer_email_batch_artkey: this.artkey()}, {
            success: (resp: any) => {
                this.data.email_batch = resp.result
                this.data.is_offer = this.data.email_batch.offer_artkey !== undefined
                this.data.number_of_recipients = resp.number_of_recipients
                this.data.number_of_emails = resp.number_of_emails
            },
        })
    }

    view() {
        if (!this.data.email_batch) {
            return <Spinner />
        }

        return <div class="c-communication-batches view process">
            {this.get_process_flow() === EmailProcessFlow.OFFERS && (
                <ProcessManageOffer
                    active="email_send"
                    context={{
                        offer_artkey: this.data.email_batch?.offer_artkey,
                        email_batch_artkey: this.data.email_batch?.artkey,
                    }}
                />
            )}

            <div class="step-content">
                <div class="btn-toolbar">
                    {this.get_process_flow() === EmailProcessFlow.MAILS &&
                        <button class="btn btn-default" type="button" onclick={() => m.route.set('/crm/email')}>
                            <span class="glyphicon glyphicon-arrow-left"></span> Back to list
                        </button>
                    }

                    {this.data.email_batch && [EmailBatchStatus.UNDER_CONSTRUCTION, EmailBatchStatus.WAITING_FOR_NEXT_RUN].includes(this.data.email_batch?.status || '') && [
                        this.get_process_flow() === EmailProcessFlow.MAILS && [
                            button_with_icon('Edit email', 'edit', {
                                class: 'btn-default',
                                onclick: () => {
                                    if (this.data.is_offer) {
                                        m.route.set(`/offer/offers/${this.data.email_batch?.offer_artkey}/email/${this.artkey()}/edit`)
                                    } else {
                                        m.route.set(`/crm/email/${this.artkey()}/edit`)
                                    }
                                },
                            }),
                            button_with_icon('Select recipients', 'user', {
                                class: 'btn-default',
                                onclick: () => {
                                    if (this.data.is_offer) {
                                        m.route.set(`/offer/offers/${this.data.email_batch?.offer_artkey}/email/${this.artkey()}/recipient-selection`)
                                    } else {
                                        m.route.set(`/crm/email/${this.artkey()}/recipient-selection`)
                                    }
                                },
                            }),
                            button_with_icon('Delete batch', 'remove', {
                                class: 'btn-danger pull-right',
                                onclick: () => show_confirmation({
                                    title: 'Confirm email batch delete',
                                    message: 'Are you sure you want to delete this email batch?',
                                    unique_name: 'delete_email_batch',
                                    onconfirm: () => {
                                        api_ls.callAndThen('pricelistbatch.delete', {offer_email_batch_artkey: this.artkey()}, {
                                            success: () => {
                                                notifier.notify('Successfully deleted offer email batch.', 'success')
                                                if (this.data.is_offer) {
                                                    m.route.set(`/offer/offers/${this.data.email_batch?.offer_artkey}/email/create`)
                                                } else {
                                                    m.route.set('/crm/email')
                                                }
                                            },
                                        })
                                    },
                                }),
                            }),
                        ],
                        !this.data.email_batch?.is_pending && (
                            button_with_icon('Send email to all recipients', 'send', {
                                class: 'btn-success',
                                onclick: () => show_confirmation({
                                    title: 'Confirm email dispatch',
                                    message: `Are you ready to email ${this.data.number_of_recipients} recipients?`,
                                    unique_name: 'dispatch',
                                    onconfirm: () => {
                                        api_ls.callAndThen('email.batch.confirm_for_dispatch', {email_batch_artkey: this.data.email_batch?.artkey}, {
                                            success: () => {
                                                notifier.notify('Successfully dispatched offer email batch!', 'success')
                                                this.data.email_batch.is_pending = true
                                                if (this.data.is_offer) {
                                                    m.route.set(`/offer/offers/${this.data.email_batch?.offer_artkey}/email/${this.artkey()}/email-send`)
                                                } else {
                                                    m.route.set('/crm/email')
                                                }
                                            },
                                        })
                                    },
                                }),
                            },
                            )),
                    ]}
                </div>

                <div class="field">
                    {this.data.email_batch?.use_sent_from_user &&
                        <div class="row">
                            <label class="control-label col-sm-2">From email address</label>
                            <div class="col-sm-10">{this.data.email_batch?.use_sent_from_user.profile.emailaddress}</div>
                        </div>
                    }
                    <div class="row">
                        <label class="control-label col-sm-2">Subject</label>
                        <div class="col-sm-10">{this.data.email_batch?.subject}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Created on</label>
                        <div class="col-sm-10">{formatDateTime(this.data.email_batch?.created_on)}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Created by</label>
                        <div class="col-sm-10">{this.data.email_batch?.created_by.profile.name}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Status</label>
                        <div class="col-sm-10">{EmailBatchStatus.choices[this.data.email_batch?.status]}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Type</label>
                        <div class="col-sm-10">{EmailBatchType.choices[this.data.email_batch?.batch_type]}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Related offer</label>
                        <div class="col-sm-10">{this.data.is_offer ? 'Yes' : 'No'}</div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Scheduled to be sent?</label>
                        <div class="col-sm-10">
                            {this.data.email_batch?.is_pending ? 'Yes' :
                                this.data.email_batch?.status === EmailBatchStatus.UNDER_CONSTRUCTION ?
                                    'No, click \'Send email to all recipients\' to enable' :
                                    'Has been sent'}
                        </div>
                    </div>
                    <div class="row">
                        <label class="control-label col-sm-2">Scheduled for</label>
                        <div class="col-sm-10">{formatDateTime(this.data.email_batch?.next_run)}</div>
                    </div>
                </div>

                <Tabs
                    className="c-communication-batches-tabs"
                    data={this.data}
                    type="default"
                >
                    <Tab
                        icon="profile"
                        id='recipients'
                        link={`/crm/email/${this.artkey()}?tabId=recipients`}
                        text={`Relations (${this.data.number_of_recipients})`}
                    >
                        <TabPanel>
                            <EmailBatchRecipients email_batch={() => this.data.email_batch} />
                        </TabPanel>
                    </Tab>
                    <Tab
                        icon="email"
                        id='sent'
                        link={`/crm/email/${this.artkey()}?tabId=sent`}
                        text={`Emails (${this.data.number_of_emails})`}
                    >
                        <TabPanel>
                            <EmailBatchSentEmails email_batch={() => this.data.email_batch} />
                        </TabPanel>
                    </Tab>
                </Tabs>
            </div>
        </div>
    }
}
