/* eslint-disable import/extensions */
import {merge_deep} from '@bitstillery/common/lib/utils'
import commonLocaleEn from '@bitstillery/common/locales/en-GB.json'
import commonLocaleNl from '@bitstillery/common/locales/nl.json'

import portalLocaleEn from './en-GB.json'
import portalLocaleNl from './nl.json'

export default {
    'en-GB': {translation: merge_deep(portalLocaleEn, commonLocaleEn)},
    nl: {translation: merge_deep(portalLocaleNl, commonLocaleNl)},
}
