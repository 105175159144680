import {DateTime} from 'luxon'
import {api} from '@bitstillery/common/app'

import {GetMarginThresholdResponse} from '@/factserver_api/margins_api'
import {$s} from '@/app'

export async function bind() {
    const {status_code, result: margin_thresholds} = await api.post<GetMarginThresholdResponse[]>('margin_threshold.get_all', {})
    if (status_code > 299) {
        return
    }
    const threshold = margin_thresholds.filter((item) => {
        const item_start_date_as_date_time = DateTime.fromISO(item.start_date)
        const item_end_date_as_date_time = item.end_date ? DateTime.fromISO(item.end_date) : null
        const is_active =
            item_start_date_as_date_time < DateTime.now() &&
            (item_end_date_as_date_time === null || item_end_date_as_date_time > DateTime.now())
        return is_active
    }).at(0)

    Object.assign($s.margin, {
        approval: Number(threshold?.end_needs_approval_range || 0),
        low: Number(threshold?.end_low_margin_range || 0),
        target: Number(threshold?.start_target_margin_range || 0),
    })
}
