import {make_route, redirect_to, require_auth, require_no_auth} from '@bitstillery/common/lib/router'
import {AuthForgotPassword, AuthResetPassword, LayoutLanding} from '@bitstillery/common/components'

import {LayoutDiscover} from './components/layout'

// Module: crm
import CrmLeadsList from '@/crm/leads/list'
import {LeadEdit as CrmLeadsEdit} from '@/crm/leads/edit'
import CrmLeadsView from '@/crm/leads/view'
import CrmUploadsUpload from '@/crm/upload/upload_crm_file'
// Module: misc
import UserExactAuth from '@/user/exact_auth'
import DashboardFigures from '@/dashboard/figures_dashboard'
import Login from '@/components/pages/login/login'
import DashboardPortal from '@/dashboard/portal_dashboard'
// Module: email
import {EmailRecipientSelection} from '@/email/email_recipient_selection'
import {EmailUpsert} from '@/email/email_edit'
import {CrmEmailBatchList} from '@/email/batches'
import {CrmEmailBatch} from '@/email/details/email_batch'
import LoenderslootMessages from '@/email/loendersloot_messages'
// module: my_discover
import MyDiscoverDashboard from '@/my_discover/dashboard/dashboard'
import MyDiscoverProfile from '@/my_discover/profile'
import MyDiscoverDownloads from '@/my_discover/downloads'
import MyDiscoverColleagues from '@/my_discover/colleagues/colleagues'
// Module: offer
import {OfferList} from '@/offer/offers/offer_list'
import {OfferCustomProducts} from '@/offer/offers/offer_custom_products'
import {OfferUpsert} from '@/offer/offers/offer_details'
import {OfferRelations} from '@/offer/offers/offer_relations'
import {OfferSelectProducts} from '@/offer/offers/offer_select_products'
import {OfferRelationDashboard} from '@/offer/relation_dashboard/relation_dashboard'
// Module: voucher
import {VoucherList} from '@/vouchers/voucher_list'
import {VoucherUpsert} from '@/vouchers/voucher_upsert'
import {VoucherRelationSelection} from '@/vouchers/voucher_relation_selection'
// Module: pricelist
import PricelistList from '@/pricelist/pricelist'
import PricelistApproval from '@/pricelist/approval'
import {PricelistOfferitemList} from '@/pricelist/offer_item_list'
import PricelistProductsOfInterest from '@/pricelist/products_of_interest/list'
import {OfferSilentStock as PricelistSilentStock} from '@/pricelist/silent_stock'
import {OfferSpotlight as PricelistSpotlight} from '@/pricelist/spotlight'
import PricelistWork from '@/pricelist/tbo_to_approve_list'
import ComparePriceListPrices from '@/pricelist/compare_prices'
// Module: purchase_orders
import PurchaseOrdersInstockLoendersloot from '@/purchase_orders/instock_loendersloot'
import PurchaseOrdersItemsList from '@/purchase_orders/items/list'
import PurchaseOrdersManageList from '@/purchase_orders/list'
import PurchaseOrdersManageArrivalNotice from '@/purchase_orders/arrival_notice'
import PurchaseOrdersManageCancel from '@/purchase_orders/cancel'
import PurchaseOrdersManageConfirm from '@/purchase_orders/confirm'
import PurchaseOrdersManageFinancials from '@/purchase_orders/financials'
import PurchaseOrdersManageInStock from '@/purchase_orders/instock'
import PurchaseOrdersManageRFP from '@/purchase_orders/request_for_proposal'
import CreateOrEditPurchaseOrder from '@/purchase_orders/edit'
import PurchaseOrdersDetail from '@/purchase_orders/view_purchase_order'
import MovePurchaseOrderItems from '@/purchase_orders/view_purchase_order/move_items'
import PurchaseOrdersTBO from '@/purchase_orders/tbo/tbo'
import PurchaseOrdersUpIsUp from '@/purchase_orders/up_is_up'
// Module: sales_orders
import SalesOrdersList from '@/sales_orders/list'
import SalesOrdersUpsert from '@/sales_orders/edit'
import SalesOrdersInvoice from '@/sales_orders/invoice'
import SalesOrdersMoveItems from '@/sales_orders/move_sales_order_items'
import SalesOrdersOuttakeInstruction from '@/sales_orders/outtake_instruction'
import SalesOrdersProforma from '@/sales_orders/proforma'
import SalesOrdersExact from '@/sales_orders/exact'
import SalesOrdersDetails from '@/sales_orders/view'
import SalesOrdersLowMarginApproval from '@/sales_orders/low_margin_approval'
import SalesOrdersTargets from '@/sales_orders/targets'
// Module: stock
import {StockMoveToWarehouse} from '@/stock/move_to_warehouse'
import StockImageReview from '@/stock/image_review_list'
import {StockList} from '@/stock/stock_list'
import {StockItemDetails} from '@/stock/item_view'
import StockItemEdit from '@/stock/item_edit'
import StockItemCasesUpdate from '@/stock/mutation_create_update_cases'
import StockItemProductUpsert from '@/stock/mutation_create_update_product'
import StockMutationList from '@/stock/mutation_list'
import StockMutationDetails from '@/stock/mutation_view'
import {StockMutationInStock} from '@/stock/mutation_instock'
import StockMutationOuttakeInstruction from '@/stock/outtake_instruction'
import StockRecon from '@/stock/loendersloot_stock_recon'
import StockReconDetails from '@/stock/loendersloot_stock_recon_results'
// Module: market
import {MarketStatistics} from '@/market/statistics'
import {MarketListPricelistItems} from '@/market/pricelists/list_pricelist_items'
import {MarketListPricelists} from '@/market/pricelists/list_pricelists'
import {MarketDiscover} from '@/market/discover'
import {MarketExplore} from '@/market/explore'
import {MarketBestBuys} from '@/market/bestbuys'
// Module: portal
import {PortalUserActivity} from '@/portal/portal_users/activity'
import {PortalUserUpsert} from '@/portal/portal_users/portal_user_edit'
import {PortalUserList} from '@/portal/portal_users/portal_user_list'
import {PortalTipsUpsert} from '@/portal/portal_tips/portal_tips_edit'
import {PortalTipsList} from '@/portal/portal_tips/portal_tips_list'
import {PortalQuotesUpsert} from '@/portal/portal_quotes/portal_quotes_edit'
import {PortalQuotesList} from '@/portal/portal_quotes/portal_quotes_list'
import {PortalDemoUsers} from '@/portal/portal_demo_users/portal_demo_users'
import {PortalNewsUpsert} from '@/portal/portal_news/portal_news_edit'
import {PortalNewsList} from '@/portal/portal_news/portal_news_list'
import {PortalSearchLog} from '@/portal/portal_search_log'
import {SalesOrdersRevenue} from '@/sales_orders/revenue'
import {SalesOrdersInstock} from '@/sales_orders/instock'
import {SalesOrdersGreenShipment} from '@/sales_orders/create_green_shipment'
import {SalesOrdersAssignedStock} from '@/sales_orders/assigned_stock'
import {ProductCategoryList} from '@/data/product_categories/list'
// Module: data
import LoenderslootTagsUpsert from '@/data/loendersloot_tags/loendersloot_tag_edit'
import LoenderslootTags from '@/data/loendersloot_tags/loendersloot_tag_list'
import CrmRelationsList from '@/data/suppliers/supplier_list/index'
import {WarehouseUpsert} from '@/data/warehouses/warehouse_edit'
import {WarehouseDeactivate} from '@/data/warehouses/warehouse_deactivate'
import {WarehouseList} from '@/data/warehouses/warehouse_list'
import {VatTypeUpsert} from '@/data/vattypes/vattype_edit'
import {VatTypeList} from '@/data/vattypes/vattype_list'
import {VatRateUpsert} from '@/data/vatrates/edit'
import {VatRateList} from '@/data/vatrates/list'
import VatCheck from '@/data/vatcheck/vatcheck'
import UsersUpsert from '@/data/user_manage/edit'
import UsersList from '@/data/user_manage/list'
import SystemSettings from '@/data/system_parameters/system_parameters'
import {ProductMerge} from '@/data/products/product_merge'
import {ProductEdit} from '@/data/products/product_edit'
import {ProductListSuppressed} from '@/data/products/suppressed_product_keys'
import ProductsList from '@/data/products/list'
import {PaymentTermUpsert} from '@/data/paymentterms/paymentterm_edit'
import {PaymentTermList} from '@/data/paymentterms/paymentterm_list'
import MarginThresholdsEdit from '@/data/margin_threshold/edit'
import MarginThresholdsList from '@/data/margin_threshold/list'
import {LedgerUpsert} from '@/data/ledgers/ledger_edit'
import {LedgerList} from '@/data/ledgers/ledger_list'
import ItemTagEdit from '@/data/item_tags/edit'
import ItemTagList from '@/data/item_tags/list'
import {InsuranceTypesUpsert} from '@/data/insurancetypes/insurancetype_edit'
import {InsuranceTypesList} from '@/data/insurancetypes/insurancetype_list'
import BrandHoldersUpsert from '@/data/brand_holders/edit'
import BrandHoldersList from '@/data/brand_holders/list'
import AttachmentCreate from '@/data/attachment_categories/edit'
import AttachmentEdit from '@/data/attachment_categories/edit'
import AttachmentList from '@/data/attachment_categories/list'
import AfvalfondsOverview from '@/data/afvalfonds/afvalfonds'
import DashboardReports from '@/data/reports/reports'
import {DashboardDiana} from '@/data/diana/topic_listeners'
import BrandsUpsert from '@/data/brands/edit'
import BrandsList from '@/data/brands/list'
import CBS from '@/data/cbs/cbs_export'
import {ExciseCategoriesUpsert} from '@/data/excise_categories/excise_category_edit'
import {ExciseCategoriesList} from '@/data/excise_categories/excise_category_list'
import {EmailPlaceholdersUpsert} from '@/data/email_placeholders/email_placeholder_edit'
import {EmailPlaceholdersList} from '@/data/email_placeholders/email_placeholder_list'
import {CommentTemplatesUpsert} from '@/data/commenttemplates/commenttemplate_edit'
import {CommentTemplatesList} from '@/data/commenttemplates/commenttemplate_list'
import {CrmRelationsWarehousesSelect} from '@/data/suppliers/warehouse_select'
import {CrmRelationsContactPersonUpsert} from '@/data/suppliers/contact_person_edit'
import {CrmRelationsUpsert} from '@/data/suppliers/supplier_edit'
import {CrmRelationsDeactivate} from '@/data/suppliers/supplier_deactivate'

const without_auth = make_route(require_no_auth, LayoutLanding)
const with_auth = make_route(require_auth, LayoutDiscover)

export const DEFAULT_ROUTE = '/dashboard/figures'

// Object keys are used in order; first match wins.
export const routes = {
    '/login': without_auth(Login),
    '/user/forgot-password': without_auth(AuthForgotPassword),
    '/user/reset-password': without_auth(AuthResetPassword),

    '/dashboard/figures': with_auth(DashboardFigures),
    '/dashboard/portal': with_auth(DashboardPortal),
    '/dashboard/search-log': with_auth(PortalSearchLog),
    '/dashboard/diana': with_auth(DashboardDiana),
    '/dashboard/reports': with_auth(DashboardReports),

    '/data/afvalfonds': with_auth(AfvalfondsOverview),
    '/data/attachment-categories': with_auth(AttachmentList),
    '/data/attachment-categories/:artkey/edit': with_auth(AttachmentEdit),
    '/data/attachment-categories/create': with_auth(AttachmentCreate),
    '/data/brand-holders': with_auth(BrandHoldersList),
    '/data/brand-holders/:artkey/edit': with_auth(BrandHoldersUpsert),
    '/data/brand-holders/create': with_auth(BrandHoldersUpsert),
    '/data/brands': with_auth(BrandsList),
    '/data/brands/:artkey/edit': with_auth(BrandsUpsert),
    '/data/brands/create': with_auth(BrandsUpsert),
    '/data/cbs': with_auth(CBS),
    '/data/commenttemplates': with_auth(CommentTemplatesList),
    '/data/commenttemplates/:artkey/edit': with_auth(CommentTemplatesUpsert),
    '/data/commenttemplates/create': with_auth(CommentTemplatesUpsert),
    '/data/email-placeholders': with_auth(EmailPlaceholdersList),
    '/data/email-placeholders/:artkey/edit': with_auth(EmailPlaceholdersUpsert),
    '/data/email-placeholders/create': with_auth(EmailPlaceholdersUpsert),
    '/data/excise-categories': with_auth(ExciseCategoriesList),
    '/data/excise-categories/:artkey/edit': with_auth(ExciseCategoriesUpsert),
    '/data/excise-categories/create': with_auth(ExciseCategoriesUpsert),
    '/data/insurancetypes': with_auth(InsuranceTypesList),
    '/data/insurancetypes/:artkey/edit': with_auth(InsuranceTypesUpsert),
    '/data/insurancetypes/create': with_auth(InsuranceTypesUpsert),
    '/data/item-tags': with_auth(ItemTagList),
    '/data/item-tags/:artkey/edit': with_auth(ItemTagEdit),
    '/data/item-tags/create': with_auth(ItemTagEdit),
    '/data/loendersloot-tags': with_auth(LoenderslootTags),
    '/data/loendersloot-tags/:artkey/edit': with_auth(LoenderslootTagsUpsert),
    '/data/loendersloot-tags/create': with_auth(LoenderslootTagsUpsert),

    '/data/ledgers': with_auth(LedgerList),
    '/data/ledgers/:artkey/edit': with_auth(LedgerUpsert),
    '/data/ledgers/create': with_auth(LedgerUpsert),
    '/data/loendersloot-messages': with_auth(LoenderslootMessages),
    '/data/margin-thresholds': with_auth(MarginThresholdsList),
    '/data/margin-thresholds/edit': with_auth(MarginThresholdsEdit),
    '/data/paymentterms': with_auth(PaymentTermList),
    '/data/paymentterms/:artkey/edit': with_auth(PaymentTermUpsert),
    '/data/paymentterms/create': with_auth(PaymentTermUpsert),
    '/data/product-categories': with_auth(ProductCategoryList),
    '/data/products': with_auth(ProductsList),
    '/data/products/:artkey/merged': with_auth(ProductEdit),
    '/data/suppressed-product-keys': with_auth(ProductListSuppressed),
    '/data/products/merge': with_auth(ProductMerge),
    '/data/products/:artkey': with_auth(ProductEdit),
    '/data/system-parameters': with_auth(SystemSettings),
    '/data': redirect_to('/data/products'),

    '/crm/email/:artkey/edit': with_auth(EmailUpsert),
    '/crm/email/:artkey/recipient-selection': with_auth(EmailRecipientSelection),
    '/crm/email/create': with_auth(EmailUpsert),
    '/crm/email/:artkey': with_auth(CrmEmailBatch),
    '/crm/email': with_auth(CrmEmailBatchList),
    '/crm/leads': with_auth(CrmLeadsList),
    '/crm/leads/:artkey/edit': with_auth(CrmLeadsEdit),
    '/crm/leads/:artkey': with_auth(CrmLeadsView),
    '/crm/uploads/upload': with_auth(CrmUploadsUpload),
    '/crm/relations': with_auth(CrmRelationsList),
    '/crm/relations/:artkey/deactivate': with_auth(CrmRelationsDeactivate),
    '/crm/relations/:artkey/edit': with_auth(CrmRelationsUpsert),
    '/crm/relations/:supplier_artkey/contact-persons/:artkey/edit': with_auth(CrmRelationsContactPersonUpsert),
    '/crm/relations/:supplier_artkey/contact-persons/create': with_auth(CrmRelationsContactPersonUpsert),
    '/crm/relations/:supplier_artkey/edit/warehouses/:artkey/deactivate': with_auth(WarehouseDeactivate),
    '/crm/relations/:supplier_artkey/edit/warehouses/:artkey/edit': with_auth(WarehouseUpsert),
    '/crm/relations/:supplier_artkey/edit/warehouses/create': with_auth(WarehouseUpsert),
    '/crm/relations/:supplier_artkey/edit/warehouses/select': with_auth(CrmRelationsWarehousesSelect),
    '/crm/relations/create': with_auth(CrmRelationsUpsert),
    '/crm/relations/create/:lead_artkey': with_auth(CrmRelationsUpsert),
    '/crm': redirect_to('/crm/relations'),

    '/data/user-manage': with_auth(UsersList),
    '/data/user-manage/:artkey/edit': with_auth(UsersUpsert),
    '/data/user-manage/create': with_auth(UsersUpsert),

    '/data/vatcheck': with_auth(VatCheck),
    '/data/vatrates': with_auth(VatRateList),
    '/data/vatrates/:artkey/edit': with_auth(VatRateUpsert),
    '/data/vatrates/create': with_auth(VatRateUpsert),

    '/data/vattypes': with_auth(VatTypeList),
    '/data/vattypes/:artkey/edit': with_auth(VatTypeUpsert),
    '/data/vattypes/create': with_auth(VatTypeUpsert),

    '/data/warehouses': with_auth(WarehouseList),
    '/data/warehouses/:artkey/deactivate': with_auth(WarehouseDeactivate),
    '/data/warehouses/:artkey/edit': with_auth(WarehouseUpsert),
    '/data/warehouses/create': with_auth(WarehouseUpsert),

    '/my-discover/dashboard': with_auth(MyDiscoverDashboard),
    '/my-discover/profile': with_auth(MyDiscoverProfile),
    '/my-discover/downloads': with_auth(MyDiscoverDownloads),
    '/my-discover/colleagues': with_auth(MyDiscoverColleagues),

    '/market/best-buys': with_auth(MarketBestBuys),
    '/market/discover': with_auth(MarketDiscover),
    '/market/explore': with_auth(MarketExplore),
    '/market/pricelists': with_auth(MarketListPricelists),
    '/market/pricelists/upsert': with_auth(MarketListPricelists),
    '/market/pricelists/upsert/:artkey': with_auth(MarketListPricelists),
    '/market/pricelists/setup': with_auth(MarketListPricelists),
    '/market/pricelists/:artkey': with_auth(MarketListPricelistItems),
    '/market/statistics': with_auth(MarketStatistics),
    '/market': redirect_to('/market/discover'),

    '/offer/email/create': with_auth(EmailUpsert),
    '/offer/email/:artkey/edit': with_auth(EmailUpsert),
    '/offer/email/:artkey/recipient-selection': with_auth(EmailRecipientSelection),
    '/offer/offers': with_auth(OfferList),
    '/offer/offers/:artkey/custom_products': with_auth(OfferCustomProducts),
    '/offer/offers/:artkey/details': with_auth(OfferUpsert),
    '/offer/offers/:artkey/relations': with_auth(OfferRelations),
    '/offer/offers/:artkey/select_products': with_auth(OfferSelectProducts),
    '/offer/offers/:offer/email/:artkey/edit': with_auth(EmailUpsert),
    '/offer/offers/:offer/email/:artkey/recipient-selection': with_auth(EmailRecipientSelection),
    '/offer/offers/:offer/email/:artkey/email-send': with_auth(CrmEmailBatch),
    '/offer/offers/:offer/email/create': with_auth(EmailUpsert),

    '/offer/offers/create': with_auth(OfferUpsert),
    '/offer/relation-dashboard': with_auth(OfferRelationDashboard),
    '/offer/vouchers': with_auth(VoucherList),
    '/offer/vouchers/create': with_auth(VoucherUpsert),
    '/offer/vouchers/:artkey/edit': with_auth(VoucherUpsert),
    '/offer/vouchers/:artkey/relation-selection': with_auth(VoucherRelationSelection),
    '/offer': redirect_to('/offer/relation-dashboard'),

    '/portal/portal-demo-users': with_auth(PortalDemoUsers),
    '/portal/portal-news': with_auth(PortalNewsList),
    '/portal/portal-news/:artkey/edit': with_auth(PortalNewsUpsert),
    '/portal/portal-news/create': with_auth(PortalNewsUpsert),
    '/portal/portal-quotes': with_auth(PortalQuotesList),
    '/portal/portal-quotes/:artkey/edit': with_auth(PortalQuotesUpsert),
    '/portal/portal-quotes/create': with_auth(PortalQuotesUpsert),
    '/portal/portal-tips': with_auth(PortalTipsList),
    '/portal/portal-tips/:artkey/edit': with_auth(PortalTipsUpsert),
    '/portal/portal-tips/create': with_auth(PortalTipsUpsert),
    '/portal/portal-users': with_auth(PortalUserList),
    '/portal/portal-users/:artkey/activity': with_auth(PortalUserActivity),
    '/portal/portal-users/:artkey/edit': with_auth(PortalUserUpsert),
    '/portal/portal-users/create': with_auth(PortalUserUpsert),
    '/portal/portal-users/create/:contact_person_artkey': with_auth(PortalUserUpsert),
    '/portal': redirect_to('/portal/portal-users'),

    '/pricelist': with_auth(PricelistList),
    '/pricelist/approval': with_auth(PricelistApproval),
    '/pricelist/offer-item-list': with_auth(PricelistOfferitemList),
    '/pricelist/products-of-interest': with_auth(PricelistProductsOfInterest),
    '/pricelist/silent-stock': with_auth(PricelistSilentStock),
    '/pricelist/spotlight': with_auth(PricelistSpotlight),
    '/pricelist/work': with_auth(PricelistWork),
    '/pricelist/compare-prices': with_auth(ComparePriceListPrices),

    '/purchase-orders/manage/:artkey/instock_loendersloot': with_auth(PurchaseOrdersInstockLoendersloot),
    '/purchase-orders/items/list': with_auth(PurchaseOrdersItemsList),
    '/purchase-orders/manage': with_auth(PurchaseOrdersManageList),
    '/purchase-orders/manage/:artkey/arrival-notice': with_auth(PurchaseOrdersManageArrivalNotice),
    '/purchase-orders/manage/:artkey/cancel': with_auth(PurchaseOrdersManageCancel),
    '/purchase-orders/manage/:artkey/confirm': with_auth(PurchaseOrdersManageConfirm),
    '/purchase-orders/manage/:artkey/edit': with_auth(CreateOrEditPurchaseOrder),
    '/purchase-orders/manage/:artkey/financials': with_auth(PurchaseOrdersManageFinancials),
    '/purchase-orders/manage/:artkey/instock': with_auth(PurchaseOrdersManageInStock),
    '/purchase-orders/manage/:artkey/move-items': with_auth(MovePurchaseOrderItems),
    '/purchase-orders/manage/:artkey/request-for-proposal': with_auth(PurchaseOrdersManageRFP),
    '/purchase-orders/manage/create': with_auth(CreateOrEditPurchaseOrder),
    '/purchase-orders/manage/:artkey': with_auth(PurchaseOrdersDetail),
    '/purchase-orders/tbo': with_auth(PurchaseOrdersTBO),
    '/purchase-orders/up-is-up': with_auth(PurchaseOrdersUpIsUp),
    '/purchase-orders': redirect_to('/purchase-orders/manage'),

    '/sales-orders/assigned-stock': with_auth(SalesOrdersAssignedStock),
    '/sales-orders/manage': with_auth(SalesOrdersList),

    '/sales-orders/manage/:artkey/create-green-shipment': with_auth(SalesOrdersGreenShipment),
    '/sales-orders/manage/:artkey/edit': with_auth(SalesOrdersUpsert),
    '/sales-orders/manage/:artkey/instock': with_auth(SalesOrdersInstock),
    '/sales-orders/manage/:artkey/invoice': with_auth(SalesOrdersInvoice),
    '/sales-orders/manage/:artkey/move-items': with_auth(SalesOrdersMoveItems),
    '/sales-orders/manage/:artkey/outtake-instruction': with_auth(SalesOrdersOuttakeInstruction),
    '/sales-orders/manage/:artkey/proforma': with_auth(SalesOrdersProforma),
    '/sales-orders/manage/create': with_auth(SalesOrdersUpsert),
    '/sales-orders/manage/exact': with_auth(SalesOrdersExact),
    '/sales-orders/manage/:artkey': with_auth(SalesOrdersDetails),
    '/sales-orders/margin-approval': with_auth(SalesOrdersLowMarginApproval),
    '/sales-orders/revenue': with_auth(SalesOrdersRevenue),
    '/sales-orders/targets': with_auth(SalesOrdersTargets),
    '/sales-orders': redirect_to('/sales-orders/manage'),

    '/stock/mutations/move-to-warehouse': with_auth(StockMoveToWarehouse),
    '/stock/image-review': with_auth(StockImageReview),
    '/stock/manage': with_auth(StockList),
    '/stock/manage/:reference': with_auth(StockItemDetails),
    '/stock/manage/:artkey/edit': with_auth(StockItemEdit),
    '/stock/manage/:reference/update-cases': with_auth(StockItemCasesUpdate),
    '/stock/manage/:reference/update-product': with_auth(StockItemProductUpsert),
    '/stock/manage/:reference/update-product/:update_type': with_auth(StockItemProductUpsert),
    '/stock/mutations/manage': with_auth(StockMutationList),
    '/stock/mutations/manage/:reference': with_auth(StockMutationDetails),
    '/stock/mutations/manage/:reference/edit': with_auth(StockMoveToWarehouse),
    '/stock/mutations/manage/:reference/instock': with_auth(StockMutationInStock),
    '/stock/mutations/manage/:reference/outtake-instruction': with_auth(StockMutationOuttakeInstruction),
    '/stock/recon': with_auth(StockRecon),
    '/stock/recon/:artkey': with_auth(StockReconDetails),
    '/stock': redirect_to('/stock/manage'),

    '/user/exact_auth/:from_view...': with_auth(UserExactAuth),
}
