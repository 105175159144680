import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {CURRENCY_SYMBOLS, format_money_responsive} from '@bitstillery/common/lib/format'
import {$t} from '@bitstillery/common/app'
import {days_left} from '@bitstillery/common/lib/format'

interface ChangedAttrs {
    current: number
    currency?: string
    hint?: boolean
    previous: number
    type?: string
    validity?: string
}

export class Changed extends MithrilTsxComponent<ChangedAttrs> {

    view(vnode: m.Vnode<ChangedAttrs>) {
        return (
            <div className={classes('c-changed', `type-${vnode.attrs.type ? vnode.attrs.type : 'brand'}`)}>
                {(vnode.attrs.previous !== vnode.attrs.current) &&
                <span className="previous">
                    {!!vnode.attrs.currency && `${CURRENCY_SYMBOLS[vnode.attrs.currency]} `}
                    {format_money_responsive(vnode.attrs.previous)}
                </span>}
                <span className="current">
                    {!!vnode.attrs.currency && `${CURRENCY_SYMBOLS[vnode.attrs.currency]} `}
                    {format_money_responsive(vnode.attrs.current)}{vnode.attrs.hint && <span className="price-hint">*</span>}
                    {(() => {
                        if (vnode.attrs.validity) {
                            return <div className="validity">
                                {$t('price.offer_until', {count: days_left(vnode.attrs.validity)})}
                            </div>
                        }
                    })()}
                </span>

            </div>
        )
    }
}
