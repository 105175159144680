import m from 'mithril'
import {Button, PanelMenu} from '@bitstillery/common/components'
import {get_route} from '@bitstillery/common/lib/utils'
import {$t} from '@bitstillery/common/app'

import {UserScheduleStatus} from './user_schedule_status'

import {$s} from '@/app'

export class PanelMenuDiscover extends PanelMenu {

    slot_menu(): m.Children {
        return <div className="menu">
            {$s.navigation.menu.map((menu_item, i) => {
                if (!menu_item.url) return <hr/>
                return <Button
                    active={m.route.get().startsWith(`/${menu_item.id}`)}
                    icon={typeof menu_item.icon === 'function' ? menu_item.icon() : menu_item.icon}
                    link={get_route(menu_item.url)}
                    onclick={() => {
                        $s.page.icon = menu_item.icon
                        $s.page.title = $t(`${menu_item.i18n}.text`)
                        $s.navigation.selected[0] = i
                    }}
                    text={$t(`${menu_item.i18n}.text`)}
                    tip={$t(`${menu_item.i18n}.tip`)}
                    tip_config={{placement: 'right'}}
                    type="info"
                    variant="menu"
                />
            })}

            <div className="profile-options">
                <UserScheduleStatus />
            </div>
        </div>
    }

    slot_toggles() {
        const common_toggles = super.slot_toggles()
        return common_toggles
    }
}
