import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Country} from '@bitstillery/common/components'
import {format_iso_to_date} from '@bitstillery/common/lib/format'

import {RequeryListener} from './reports'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {ReportArguments} from '@/factserver_api/reporting_api'

interface RelationsLogistics {
    supplier_name: string
    supplier_street_address: string
    supplier_zip_code: string
    supplier_city: string
    supplier_country_code: string

    last_sales_order_reference: string
    last_sales_order_date: string
    incoterm: string
    incoterm_location: string

    warehouse_name: string
    warehouse_street_address: string
    warehouse_zip_code: string
    warehouse_city: string
    warehouse_country_code: string
}

export interface RelationsLogisticsAttrs {
    report_arguments: ReportArguments
    add_requery_listener: (component: RequeryListener) => void
    remove_requery_listener: (component: RequeryListener) => void
}

export class RelationsLogisticsReport extends MithrilTsxComponent<RelationsLogisticsAttrs> implements RequeryListener {
    reporting_fetcher = new PagedCollectionFetcher<RelationsLogistics>(
        'reporting.relations_logistics_report.get_all',
        'supplier_name',
    )

    constructor(vnode: m.Vnode<RelationsLogisticsAttrs>) {
        super()

        vnode.attrs.add_requery_listener(this)
    }

    fire(report_arguments: ReportArguments): void {
        this.reporting_fetcher.filters['start_date'] = report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = report_arguments.end_date?.toISODate() || ''
        this.reporting_fetcher.reset_and_query()
    }

    onremove(vnode: m.Vnode<RelationsLogisticsAttrs>): void {
        vnode.attrs.remove_requery_listener(this)
    }

    view(): m.Children {
        return (
            <CollectionTable<RelationsLogistics, void> collection_fetcher={this.reporting_fetcher}>
                <CollectionTableColumn<RelationsLogistics>
                    header_title={() => 'Relation'}
                    sort_name={'supplier_name'}
                    data_field={(row: RelationsLogistics) => row.supplier_name}
                />
                <CollectionTableColumn<RelationsLogistics>
                    header_title={() => 'Relation address'}
                    data_field={(row: RelationsLogistics) =>
                        `${row.supplier_street_address} ${row.supplier_zip_code} ${row.supplier_city}`
                    }
                />
                <CollectionTableColumn<RelationsLogistics>
                    header_title={() => 'Relation country'}
                    sort_name={'supplier_country_code'}
                    data_field={(row: RelationsLogistics) => <Country country_code={row.supplier_country_code} type={'flag_with_country'}/>}
                />
                <CollectionTableColumn<RelationsLogistics>
                    header_title={() => 'Last sales order'}
                    data_field={(row: RelationsLogistics) =>
                        `${row.last_sales_order_reference} - ${format_iso_to_date(row.last_sales_order_date)}`
                    }
                />
                <CollectionTableColumn<RelationsLogistics>
                    header_title={() => 'Incoterm'}
                    sort_name={'incoterm'}
                    data_field={(row: RelationsLogistics) => row.incoterm}
                />
                <CollectionTableColumn<RelationsLogistics>
                    header_title={() => 'Incoterm location'}
                    data_field={(row: RelationsLogistics) => row.incoterm_location}
                />
                <CollectionTableColumn<RelationsLogistics>
                    header_title={() => 'Warehouse address'}
                    data_field={(row: RelationsLogistics) =>
                        row.warehouse_street_address
                            ? `${row.warehouse_street_address} ${row.warehouse_zip_code} ${row.warehouse_city}`
                            : '-'
                    }
                />
                <CollectionTableColumn<RelationsLogistics>
                    header_title={() => 'Warehouse country'}
                    sort_name={'warehouse_country_code'}
                    data_field={(row: RelationsLogistics) => <Country country_code={row.warehouse_country_code} type={'flag_with_country'}/>}
                />
            </CollectionTable>
        )
    }
}
