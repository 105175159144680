import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {Amount} from '@bitstillery/common/components'

import {InsuranceSalesOrderReportAttrs} from './insurance_sales_order_report'
import {RequeryListener} from './reports'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {Link} from '@/components/discover'
import {ReportArguments} from '@/factserver_api/reporting_api'

interface ItemHistory {
    artkey: number
    history_date: string
    account_slug: string
    account_name: string

    purchase_order_reference: string
    reference: string
    entry_date: string
    warehouse_name: string
    product_name: string
    lot: string

    number_of_bottles_per_case: number
    volume: string
    alcohol_percentage: string
    refill_status: string
    gift_box_type: string
    customs_status: string
    tax_label: string

    sales_price_per_case: string
    euro_stock_value: string
    euro_was_bought_for: string
    euro_was_bought_for_plus_costs: string
    purchase_order_currency: string
    number_of_cases_in_stock: number
    number_of_shipped_cases: number
    number_of_cases: number
}

export class StockHistoryReport extends MithrilTsxComponent<InsuranceSalesOrderReportAttrs> implements RequeryListener {
    reporting_fetcher = new PagedCollectionFetcher<ItemHistory>('reporting.stock_history.get_all', 'artkey')

    constructor(vnode: m.Vnode<InsuranceSalesOrderReportAttrs>) {
        super()
        this.reporting_fetcher.filters['start_date'] = vnode.attrs.report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = vnode.attrs.report_arguments.end_date?.toISODate() || ''

        vnode.attrs.add_requery_listener(this)
    }

    fire(report_arguments: ReportArguments): void {
        this.reporting_fetcher.filters['start_date'] = report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = report_arguments.end_date?.toISODate() || ''
        this.reporting_fetcher.reset_and_query()
    }

    onremove(vnode: m.Vnode<InsuranceSalesOrderReportAttrs>): void {
        vnode.attrs.remove_requery_listener(this)
    }

    view(): m.Children {
        return (
            <CollectionTable<ItemHistory, void> collection_fetcher={this.reporting_fetcher}>
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Date'}
                    sort_name={'history_date'}
                    data_field={(row: ItemHistory) => format_iso_to_date(row.history_date)}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Purchase order'}
                    data_field={(row: ItemHistory) => (
                        <span>
                            <Link href={`/purchase-orders/manage/${row.purchase_order_reference}`}>
                                {row.purchase_order_reference}
                            </Link>
                            {' - '}
                            <Link href={`/stock/manage/${row.reference}`}>{row.reference}</Link>
                        </span>
                    )}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Entry date'}
                    sort_name={'entry_date'}
                    data_field={(row: ItemHistory) => format_iso_to_date(row.entry_date)}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Warehouse'}
                    data_field={(row: ItemHistory) => row.warehouse_name}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Lot'}
                    data_field={(row: ItemHistory) => row.lot}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Product'}
                    sort_name={'product_name'}
                    data_field={(row: ItemHistory) => row.product_name}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Btls/cs'}
                    data_field={(row: ItemHistory) => row.number_of_bottles_per_case}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Volume'}
                    data_field={(row: ItemHistory) => row.volume}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Alcohol'}
                    data_field={(row: ItemHistory) => row.alcohol_percentage}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Refill'}
                    data_field={(row: ItemHistory) => row.refill_status}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Gift box'}
                    data_field={(row: ItemHistory) => row.gift_box_type}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Tax label'}
                    data_field={(row: ItemHistory) => row.tax_label}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Customs status'}
                    data_field={(row: ItemHistory) => row.customs_status}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Stock value'}
                    data_field={(row: ItemHistory) => <Amount amount={row.euro_stock_value} currency={'EUR'} />}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Sales price'}
                    data_field={(row: ItemHistory) => <Amount amount={row.sales_price_per_case} currency={'EUR'} />}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Was bought for'}
                    data_field={(row: ItemHistory) => <Amount amount={row.euro_was_bought_for} currency={'EUR'} />}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Was bought for with costs'}
                    data_field={(row: ItemHistory) => (
                        <Amount amount={row.euro_was_bought_for_plus_costs} currency={'EUR'} />
                    )}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Number of cases'}
                    data_field={(row: ItemHistory) => row.number_of_cases}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Number of shipped cases'}
                    data_field={(row: ItemHistory) => row.number_of_shipped_cases}
                />
                <CollectionTableColumn<ItemHistory>
                    header_title={() => 'Number of cases in stock'}
                    data_field={(row: ItemHistory) => row.number_of_cases_in_stock}
                />
            </CollectionTable>
        )
    }
}
