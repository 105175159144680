import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {displayable_float, format_iso_to_date, format_iso_to_date_time, titleize} from '@bitstillery/common/lib/format'
import {FieldSelect} from '@bitstillery/common/components'

import {$s} from '@/app'
import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {GetMutationListResponse} from '@/factserver_api/stock_api'
import {ProductManagementApi} from '@/factserver_api/product_management_api'

enum MutationReason {
    STOCK_DIFFERENCE = 'Stock Difference',
    MOVE_TO_WAREHOUSE = 'Move to Warehouse',
    DAMAGES = 'Damages',
    CORRECT_BOTTLES_PER_CASE = 'Correct bottles per case',
    CHANGE_LOT_NUMBER = 'Change lot number',
    OTHER = 'Other',
}

enum MutationStatus {
    INTRANSIT = 'In transit',
    INSTOCK = 'In stock',
}

export default class MutationList extends MithrilTsxComponent<unknown> {
    product_management_api = new ProductManagementApi()
    mutations_fetcher = new PagedCollectionFetcher<GetMutationListResponse>(
        'stock.get_item_mutations',
        'created_on',
        undefined,
        30,
        false,
    )

    search_bar_controller: SearchBarControl | null = null

    constructor() {
        super()
        this.mutations_fetcher.filters['mutation_reason'] = ''
        this.mutations_fetcher.filters['mutation_status'] = ''
    }

    search_for_search_text(search_text: string): void {
        this.mutations_fetcher.set_search_terms(search_text)
    }

    update_mutation_reason_filter(reason: MutationReason): void {
        this.mutations_fetcher.filters['mutation_reason'] = reason
        this.mutations_fetcher.reset_and_query()
    }

    update_mutation_status_filter(status: MutationStatus): void {
        this.mutations_fetcher.filters['mutation_status'] = status
        this.mutations_fetcher.reset_and_query()
    }

    view(): m.Children {
        return (
            <div className="c-stock-mutations view">
                <div className="c-filter-group">
                    <div className="filter-field vertical">
                        <label>Search</label>
                        <SearchBar
                            placeholder={'Search for product, warehouse, lot, description, reason and/or created by...'}
                            on_submit={(text: string) => this.search_for_search_text(text)}
                            on_get_suggestions$={(filter_text: string) =>
                                this.product_management_api.get_simple_product_names(filter_text)
                            }
                            default_search_text={this.mutations_fetcher.search_text()}
                            search_bar_controller={(controller: SearchBarControl) =>
                                (this.search_bar_controller = controller)
                            }
                        />
                    </div>
                    <div className="filter-field vertical">
                        <FieldSelect
                            label="Reason"
                            model={[this.mutations_fetcher.filters, 'mutation_reason']}
                            onchange={(value: string) => {
                                this.update_mutation_reason_filter(value as MutationReason)
                            }}
                            options={Object.values(MutationReason).map((i) => ({label: i, value: i}))}
                            placeholder="Select reason..."
                        />
                    </div>
                    <div className="filter-field vertical">
                        <FieldSelect
                            label="Status"
                            model={[this.mutations_fetcher.filters, 'mutation_status']}
                            onchange={(value: string) => {
                                this.update_mutation_status_filter(value as MutationStatus)
                            }}
                            options={Object.values(MutationStatus).map((i) => ({label: i, value: i}))}
                            placeholder="Select status..."
                        />
                    </div>
                </div>

                <CollectionTable<GetMutationListResponse, void>
                    collection_fetcher={this.mutations_fetcher}
                    on_row_click={(row: GetMutationListResponse) =>
                        m.route.set(`/stock/mutations/manage/${row.reference}?artkey=${row.artkey}`)
                    }
                >
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => '#'}
                        sort_name={'reference'}
                        data_field={(row: GetMutationListResponse) => row.reference}
                    />
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => 'W#'}
                        data_field={(row: GetMutationListResponse) => row.warehouse_reference}
                    />
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => 'Date'}
                        sort_name={'created_on'}
                        data_field={(row: GetMutationListResponse) => format_iso_to_date_time(row.created_on)}
                    />
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => 'Reason'}
                        sort_name={'item_mutation_reason'}
                        data_field={(row: GetMutationListResponse) => row.item_mutation_reason}
                    />
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => 'Description'}
                        data_field={(row: GetMutationListResponse) => row.description}
                    />
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => 'Status'}
                        data_field={(row: GetMutationListResponse) => row.status}
                    />
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => 'Loe Status'}
                        data_field={(row: GetMutationListResponse) => titleize(row.warehouse_status)}
                    />
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => 'Stock value difference'}
                        data_field={(row: GetMutationListResponse) => (
                            <span
                                data-value={
                                    displayable_float(row.stock_value_difference, 2, $s.identity.user.decimal_locale) + ' EUR'
                                }
                                class="colored-number"
                            />
                        )}
                    />
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => 'Created by'}
                        data_field={(row: GetMutationListResponse) => row.user_profile_name}
                    />
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => 'Move to warehouse'}
                        data_field={(row: GetMutationListResponse) => row.target_warehouse_name}
                    />
                    <CollectionTableColumn<GetMutationListResponse>
                        header_title={() => 'Expected delivery date'}
                        td_class_name={'col-sm-2'}
                        sort_name={'expected_delivery_date'}
                        data_field={(row: GetMutationListResponse) => format_iso_to_date(row.expected_delivery_date)}
                    />
                </CollectionTable>
            </div>
        )
    }
}
