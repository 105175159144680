import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/lib/format'

import {RequeryListener} from './reports'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {ReportArguments} from '@/factserver_api/reporting_api'

interface ModifiedProduct {
    artkey: string
    name: string
    was_last_updated_on: string
    modified_by: string
    product_category_name: string
}

export interface ModifiedProductsReportAttrs {
    report_arguments: ReportArguments
    add_requery_listener: (component: RequeryListener) => void
    remove_requery_listener: (component: RequeryListener) => void
}

export class ModifiedProductsReport
    extends MithrilTsxComponent<ModifiedProductsReportAttrs>
    implements RequeryListener
{
    reporting_fetcher = new PagedCollectionFetcher<ModifiedProduct>(
        'reporting.modified_products_report.get_all',
        'name',
    )

    constructor(vnode: m.Vnode<ModifiedProductsReportAttrs>) {
        super()
        this.reporting_fetcher.filters['start_date'] = vnode.attrs.report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = vnode.attrs.report_arguments.end_date?.toISODate() || ''

        vnode.attrs.add_requery_listener(this)
    }

    onremove(vnode: m.Vnode<ModifiedProductsReportAttrs>): void {
        vnode.attrs.remove_requery_listener(this)
    }

    fire(report_arguments: ReportArguments): void {
        this.reporting_fetcher.filters['start_date'] = report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = report_arguments.end_date?.toISODate() || ''
        this.reporting_fetcher.reset_and_query()
    }

    view(): m.Children {
        return (
            <CollectionTable<ModifiedProduct, void> collection_fetcher={this.reporting_fetcher}>
                <CollectionTableColumn<ModifiedProduct>
                    header_title={() => 'Product'}
                    sort_name={'name'}
                    data_field={(row: ModifiedProduct) => (
                        <a target="_blank" href={`#!/data/products/${row.artkey}`}>
                            {row.name}
                        </a>
                    )}
                />
                <CollectionTableColumn<ModifiedProduct>
                    header_title={() => 'Modified by'}
                    data_field={(row: ModifiedProduct) => row.modified_by}
                />
                <CollectionTableColumn<ModifiedProduct>
                    header_title={() => 'Updated on'}
                    sort_name={'was_last_updated_on'}
                    data_field={(row: ModifiedProduct) => format_iso_to_date(row.was_last_updated_on)}
                />
                <CollectionTableColumn<ModifiedProduct>
                    header_title={() => 'Category name'}
                    sort_name={'product_category_name'}
                    data_field={(row: ModifiedProduct) => row.product_category_name}
                />
            </CollectionTable>
        )
    }
}
