import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {format_money_with_symbol, format_percentage} from '@bitstillery/common/lib/format'
import {classes} from '@bitstillery/common/lib/utils'
import {logger} from '@bitstillery/common/app'
import {watch} from '@bitstillery/common/lib/store'

import {$s} from '@/app'
import {PieChart} from '@/components/adminlte/pie_chart'
import {FigureWidget} from '@/components/box'
import {DashboardApi, SalesFiguresResponse} from '@/factserver_api/dashboard_api'
import {UserStatsModel} from '@/dashboard/figures_dashboard'

/**
 * Display widgets regarding the sales of the sales-manager in this period (this month).
 */
export class ThisMonthSalesStatusWidget extends MithrilTsxComponent<UserStatsModel> {
    watchers = []

    empty_sales_figure = {
        count: 0,
        turnover_in_euro: 0,
        total_margin: 0,
        average_margin_percentage: 0,
        number_of_cases: 0,
    }

    sales_figures: SalesFiguresResponse = {
        confirmed: this.empty_sales_figure,
        invoiced: this.empty_sales_figure,
        latest_sales_orders: [],
        pending: this.empty_sales_figure,
        saved: this.empty_sales_figure,
        top_sales_orders: [],
        waiting_on_tbo: this.empty_sales_figure,
    }

    quantity_pie_chart: PieChart
    now = DateTime.local()
    start_of_month = DateTime.local(this.now.year, this.now.month, 1, 0, 0, 0)
    period_until = DateTime.local().plus({day: 1})
    turnover_pie_chart: PieChart

    dashboard_api = new DashboardApi()

    oninit(vnode: m.Vnode<UserStatsModel>) {
        this.watchers.push(watch(vnode.attrs.model, 'user_artkey', (user_artkey) => {
            this.fetch_data(user_artkey)
        }))
    }

    ondestroy(): void {
        this.watchers.forEach((unwatch: any) => unwatch())
    }

    oncreate(): void {
        this.quantity_pie_chart = new PieChart('monthly-quantity-portal-sales-ratio') as PieChart
        this.quantity_pie_chart.title(['New orders - amount'])
        this.quantity_pie_chart.legend_position('bottom')

        this.turnover_pie_chart = new PieChart('monthly-turonver-portal-sales-ratio', true) as PieChart
        this.turnover_pie_chart.title(['New orders - value'])
        this.turnover_pie_chart.legend_position('bottom')
    }

    fetch_data(user_artkey: number) {
        logger.info(`[ThisMonthSalesStatusWidget] fetch data for user: ${user_artkey}`)

        this.dashboard_api
            .portal_sales_figures({
                period_from: this.start_of_month.toISODate(),
                period_till: this.period_until.toISODate(),
                sales_manager_artkey: user_artkey ? user_artkey : undefined,
            })
            .subscribe({
                next: (response) => {
                    this.quantity_pie_chart.labels(['Portal', 'Discover'])
                    this.turnover_pie_chart.labels(['Portal', 'Discover'])
                    this.turnover_pie_chart.data([
                        response.portal_sales.turnover_in_euro,
                        response.non_portal_sales.turnover_in_euro,
                    ])
                    this.quantity_pie_chart.data([response.portal_sales.count, response.non_portal_sales.count])

                },
                complete: () => {
                    m.redraw()
                },
            })

        this.dashboard_api
            .sales_figures({
                period_from: this.start_of_month.toISODate(),
                period_till: this.period_until.toISODate(),
                sales_manager_artkey: user_artkey ? user_artkey : undefined,
            })
            .subscribe({
                next: (response) => {
                    this.sales_figures = response
                },
                complete: () => {
                    m.redraw()
                },
            })
    }

    view(vnode: m.Vnode<UserStatsModel>) {
        return (
            <div className={classes('c-widget-individual-sales dashboard-widget')}>
                <div className="widget-title">
                    <span className="text">Sales this month</span>
                </div>
                <div className="widget-body">
                    <div className="figures-wrapper">
                        <FigureWidget
                            color="sales"
                            figure={format_money_with_symbol(this.sales_figures.pending.turnover_in_euro, 'EUR', $s.identity.user.decimal_locale)}
                            link={`/sales-orders/manage?sales_order_status=Saved or confirmed&user_artkey=${vnode.attrs.model.user_artkey || ''}`}
                            icon={'glyphicon glyphicon-backward'}
                            sub_title={`${this.sales_figures.pending.count.toLocaleString()} orders & ${this.sales_figures.pending.number_of_cases.toLocaleString()} cases`}
                            title="Pending - portal"
                        />

                        <FigureWidget
                            color="sales"
                            figure={format_money_with_symbol(this.sales_figures.saved.turnover_in_euro, 'EUR', $s.identity.user.decimal_locale)}
                            icon={'glyphicon glyphicon-screenshot'}
                            link={`/sales-orders/manage?sales_order_status=Saved&user_artkey=${vnode.attrs.model.user_artkey || ''}`}
                            sub_title={`${this.sales_figures.saved.count} orders & ${this.sales_figures.saved.number_of_cases.toLocaleString()} cases`}
                            title="Saved"
                        />

                        <FigureWidget
                            color="sales"
                            icon={'glyphicon glyphicon-ok-sign'}
                            link={`/sales-orders/manage?sales_order_status=Confirmed&user_artkey=${vnode.attrs.model.user_artkey || ''}&order_by=is_confirmed_on`}
                            figure={format_money_with_symbol(this.sales_figures.confirmed.turnover_in_euro, 'EUR', $s.identity.user.decimal_locale)}
                            sub_title={`${this.sales_figures.confirmed.count} orders & ${this.sales_figures.confirmed.number_of_cases.toLocaleString()} cases`}
                            title="Confirmed"
                        />
                    </div>

                    <div className="portal-charts">
                        <div className="chart-wrapper">
                            <canvas id='monthly-quantity-portal-sales-ratio' />
                        </div>
                        <div className="chart-wrapper">
                            <canvas id='monthly-turonver-portal-sales-ratio' />
                        </div>
                    </div>

                    <div className="figures-wrapper">
                        <FigureWidget
                            color="sales"
                            figure={format_money_with_symbol(this.sales_figures.invoiced.turnover_in_euro, 'EUR', $s.identity.user.decimal_locale)}
                            icon={'glyphicon glyphicon-file'}
                            link={`/sales-orders/manage?sales_order_status=Invoiced&user_artkey=${vnode.attrs.model.user_artkey || ''}&order_by=is_invoiced_on`}
                            sub_title={`${this.sales_figures.invoiced.count} orders & ${this.sales_figures.invoiced.number_of_cases.toLocaleString()} cases`}
                            title="Invoiced"
                        />

                        <FigureWidget
                            color="sales"
                            figure={format_money_with_symbol(this.sales_figures.invoiced.total_margin, 'EUR', $s.identity.user.decimal_locale)}
                            icon={'fa fa fa-chart-line'}
                            link={`/sales-orders/manage?sales_order_status=Invoiced&user_artkey=${vnode.attrs.model.user_artkey || ''}`}
                            sub_title={`Average ${format_percentage(
                                this.sales_figures.invoiced.average_margin_percentage / 100,
                            )}`}
                            title="Invoiced margin"
                        />

                        <FigureWidget
                            color="sales"
                            icon={'glyphicon glyphicon-flag'}
                            link={'/purchase-orders/tbo?only_mine=true&show=Open'}
                            figure={format_money_with_symbol(
                                this.sales_figures.waiting_on_tbo.turnover_in_euro,
                                'EUR',
                                $s.identity.user.decimal_locale,
                            )}
                            sub_title={`${this.sales_figures.waiting_on_tbo.count} orders awaiting TBO`}
                            title="Waiting on TBO"
                        />
                    </div>

                </div>
            </div>
        )
    }
}
