/** llm:tested */
import m from 'mithril'
import {deref} from '@bitstillery/common/lib/utils'

import {icon_button} from '@/components/_buttons'

interface BoxAttrs {
    cls: string
    title: string
    body: () => any
    loading: () => boolean
    collapsed: () => boolean
}

interface BoxVnode {
    attrs: BoxAttrs
}

export const Box = (initial_vnode: BoxVnode) => {
    const {cls, title, body, loading, collapsed} = initial_vnode.attrs

    return {
        view: (_vnode: m.Vnode<any>) => {
            const box_cls = collapsed() ? `${cls} collapsed-box` : cls
            return (
                <div class={`box ${box_cls}`}>
                    <div class="box-header with-border">
                        <h3 class="box-title">{title}</h3>
                        <div class="box-tools pull-right">
                            {icon_button('fa-minus', {
                                class:'btn-box-tool',
                                'data-widget': 'collapse',
                            })}
                        </div>
                    </div>
                    <div class="box-body">
                        {deref(body())}
                    </div>
                    {loading() &&
                        <div class="overlay">
                            <i class="fa fa-spinner fa-spin"/>
                        </div>
                    }
                </div>
            )
        },
    }
}
