import {format_money} from '@bitstillery/common/lib/format'

import {$s} from '@/app'
/**
 * Render a tooltip label for a chartjs element.
 *
 * Use as:
 * <code>this.chart.options.tooltips.callbacks.label = (tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) =>
 * tooltip_label(tooltipItem, data, tooltip_as_money)
 * </code>
 *
 * @param item The item to render.
 * @param as_money If true, the value will be rendered with format_money("EUR").
 */
export function tooltip_label(item: any, as_money: boolean): string | string[] {
    const label = item.label

    if (as_money) {
        return `${label}: ${format_money(+(item.raw as string), 'EUR', $s.identity.user.decimal_locale)}`
    }
    return `${label}: ${item.formattedValue}`
}

export interface ValueRenderer {
    value: number
}

/**
 * Render a label as money or as a number, prefixed with the actual label, as "Label: 42" or "Money: E 12,98"
 *
 * Usage:
 * <code>this.chart.options.plugins.labels.render = (args: any) => label_renderer(args, format_value_as_money)</code>
 *
 * @param args Args from the chartjs_plugin_labels callback.
 * @param as_money To format the value as money or just as is.
 * @return The label.
 */
export function label_renderer(args: ValueRenderer, as_money: boolean): string | string[] {
    if (as_money) {
        return format_money(args.value, 'EUR', $s.identity.user.decimal_locale)
    }
    return `${args.value}`
}

export const background_colors = [
    'rgba(243, 156, 18, 0.4)',
    'rgba(0, 166, 90, 0.4)',
    'rgba(221, 75, 57, 0.4)',
    'rgba(0, 192, 239, 0.4)',
    'rgba(255, 99, 132, 0.4)',
    'rgba(54, 162, 235, 0.4)',
    'rgba(255, 206, 86, 0.4)',
    'rgba(75, 192, 192, 0.4)',
    'rgba(153, 102, 255, 0.4)',
    'rgba(255, 159, 64, 0.4)',
]

export const border_colors = [
    'rgba(243, 156, 18, 1)',
    'rgba(0, 166, 90, 1)',
    'rgba(221, 75, 57, 1)',
    'rgba(0, 192, 239, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
]

/**
 * Generate a chart options struct with sensible Discover defaults.
 *
 * @param type sofar pie, horizontalBar
 */
export function default_chart_options_for(type: any): any {
    return {
        type: type,
        data: {
            labels: [],
            datasets: [
                {
                    label: '',
                    data: [],
                    backgroundColor: background_colors,
                    borderColor: border_colors,
                    borderWidth: 1,
                },
            ],
        },
    }
}
