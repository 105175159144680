/** llm:production */
import m from 'mithril'
import {head} from 'prelude-ls'
import {Amount} from '@bitstillery/common/components'
import {Button} from '@bitstillery/common/components'
import {format_date} from '@bitstillery/common/lib/format'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import api from '@/api'
import * as inputs from '@/components/inputs'
import {
    TaxLabelDropDown,
} from '@/components/case_inputs'
import {item_tags_editor} from '@/stock/components/item_tags_editor'
import {AutocompleteInput} from '@/components/collection/autocomplete_input'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {Item, ItemMutationReason} from '@/models/stock'
import {Bottle} from '@/models/bottles'
import {$m} from '@/app'

export class ItemForm extends MithrilTsxComponent<any> {
    item: any
    base_item_json: any
    new_items: any
    reason: any
    update_type: any

    constructor(vnode: any) {
        super()
        const {
            item,
            base_item_json,
            new_items,
            reason,
            update_type,
        } = vnode.attrs

        this.item = item
        this.base_item_json = base_item_json
        this.new_items = new_items
        this.reason = reason
        this.update_type = update_type
    }

    add_item = () => {
        this.new_items().push(window.prop(new Item(this.base_item_json())))
    }

    remove_item = (index: number) => {
        this.new_items().pop(index)
    }

    view(vnode: any) {
        return <div class="panel panel-default">
            <div class="panel-heading"><div class="panel-title">Updated stock</div></div>
            <div class="panel-body">
                <table class="table">
                    <thead class="thead-default">
                        <tr>
                            <th>#</th>
                            <th>Intake</th>
                            <th>Warehouse</th>
                            <th>Lot #</th>
                            <th>Product</th>
                            <th>Btl / cs</th>
                            <th class="number">Size</th>
                            <th class="number">Alc %</th>
                            <th>Ref</th>
                            <th>GB</th>
                            <th class="price">Purchase / cs</th>
                            <th class="price">Cost / cs</th>
                            <th class="number">Stock</th>
                            {vnode.attrs.show_sales && <th class="number">Sales</th>}
                            {vnode.attrs.show_available && <th class="number">Avail.</th>}
                            <th>Customs</th>
                            <th></th>
                        </tr>
                    </thead>
                    {this.item && this.item().artkey() && (
                        <tbody class="table-row">
                            <tr>
                                <td>
                                    <m.route.Link target="_blank" href={`/purchase-orders/manage/${this.item().purchase_order_item().purchase_order().reference()}`}>
                                        {this.item().purchase_order_item().purchase_order().reference()}
                                    </m.route.Link>
                                </td>
                                <td>{format_date(this.item().entry_date()) || '-'}</td>
                                <td class="ellipsis"><span>{this.item().purchase_order_item().purchase_order().target_warehouse().name()}</span></td>
                                <td>{this.item().lot()}</td>
                                <td>{this.item().bottle().product().name()}</td>
                                <td class="number">{this.item().number_of_bottles_per_case()}</td>
                                <td class="number">{this.item().bottle().display_volume()}</td>
                                <td class="number">{this.item().bottle().display_alcohol_percentage()}</td>
                                <td>{this.item().bottle().refill_status()}</td>
                                <td>{this.item().gift_box_type()}</td>
                                <td class="price">
                                    <Amount
                                        amount={this.item().was_bought_for()}
                                        currency={this.item().purchase_order_item().purchase_order().was_bought_in()}
                                    />
                                </td>
                                <td class="price">
                                    <Amount
                                        amount={this.item().was_bought_for_plus_costs}
                                        currency={this.item().purchase_order_item().purchase_order().was_bought_in()}
                                    />
                                </td>
                                <td class="number">{this.item().number_of_cases_in_stock()}</td>
                                {vnode.attrs.show_sales && <td class="number">{this.item().number_of_cases_in_sales()}</td>}
                                {vnode.attrs.show_available && <td class="number">{this.item().number_of_cases_available()}</td>}
                                <td>{this.item().customs_status()}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    )}
                    {this.new_items().map((item: any, index: number) => (
                        <NewItemForm
                            item={item}
                            index={index}
                            remove_item={this.remove_item}
                            fields={vnode.attrs.fields}
                            reason={this.reason}
                            update_type={this.update_type}
                        />
                    ))}
                </table>
                {this.reason() !== ItemMutationReason.CORRECT_BOTTLES_PER_CASE && (
                    <Button
                        icon="plus"
                        onclick={this.add_item}
                        text="Create item"
                        type="info"
                    />
                )}
            </div>
        </div>
    }
}

class NewItemForm extends MithrilTsxComponent<any> {
    item: any
    reason: any
    remove_item: any
    update_type: any
    product: any
    bottles: any
    case: any
    original_number_of_bottles_per_case: any
    original_gift_box_type: any
    original_tax_label: any
    original_bottle_artkey: any
    original_was_bought_for: any
    original_was_bought_for_plus_costs: any
    product_management_api: ProductManagementApi

    constructor(vnode: any) {
        super()
        this.item = vnode.attrs.item
        this.reason = vnode.attrs.reason
        this.remove_item = vnode.attrs.remove_item
        this.update_type = vnode.attrs.update_type

        this.product = window.prop(this.item().bottle().product())
        this.bottles = window.prop([])
        this.case = window.prop([])

        this.original_number_of_bottles_per_case = window.prop(this.item().number_of_bottles_per_case())
        this.original_gift_box_type = window.prop(this.item().gift_box_type())
        this.original_tax_label = window.prop(this.item().tax_label())
        this.original_bottle_artkey = window.prop(this.item().bottle().artkey())
        this.original_was_bought_for = window.prop(this.item().was_bought_for())
        this.original_was_bought_for_plus_costs = window.prop(this.item().was_bought_for_plus_costs())

        this.query_bottles(this.item().bottle().product().artkey())
        this.product_management_api = new ProductManagementApi()
    }

    after_update_product = (selected_product: any) => {
        if (!selected_product) {
            this.item().bottle_artkey('')
            this.item().bottle(new Bottle())
            this.bottles([])
        } else {
            this.product().artkey(selected_product.artkey)
            this.product().name(selected_product.name)
            this.query_bottles(this.product().artkey())
        }
    }

    after_update_bottle = () => {
        this.item().bottle_artkey(this.item().bottle().artkey())
        this.get_case()
    }

    get_case = () => {
        if (!this.item().bottle().artkey()) {
            return
        }
        const data = {
            bottle_artkey: this.item().bottle().artkey(),
            search_exact_case: true,
            number_of_bottles: this.item().number_of_bottles_per_case(),
            gift_box_type: this.item().gift_box_type() || null,
            tax_label: this.item().tax_label() || null,
        }

        api.call2('product_management.get_cases_for_bottle', data, (resp: any) => {
            this.case(resp.result.length > 0 ? head(resp.result) : null)
            this.update_prices()
        })
    }

    update_prices = () => {
        if (this.reason() === ItemMutationReason.CORRECT_BOTTLES_PER_CASE) {
            this.item().was_bought_for(this.original_was_bought_for())
            this.item().was_bought_for_plus_costs(this.original_was_bought_for_plus_costs())
        } else {
            this.item().was_bought_for(this.original_was_bought_for() *
                                       this.item().number_of_bottles_per_case() /
                                       this.original_number_of_bottles_per_case())
            this.item().was_bought_for_plus_costs(this.item().was_bought_for() +
                                                  this.original_was_bought_for_plus_costs() -
                                                  this.original_was_bought_for())
        }
    }

    query_bottles = (product_artkey: string) => {
        const data = {product_artkey: product_artkey}
        api.call2('product_management.get_product_and_bottles', data, (resp: any) => {
            this.bottles($m.bottles.create_bottles(resp.product.bottles))
        })
    }

    view(vnode: any) {
        return <tbody class="table-row">
            <tr>
                <td>
                    <m.route.Link target="_blank" href={`/purchase-orders/manage/${this.item().purchase_order_item().purchase_order().reference()}`}>
                        {this.item().purchase_order_item().purchase_order().reference()}
                    </m.route.Link>
                </td>
                <td>{format_date(this.item().entry_date()) || '-'}</td>
                <td class="ellipsis"><span>{this.item().purchase_order_item().purchase_order().target_warehouse().name()}</span></td>
                <td>
                    {vnode.attrs.fields.includes('lot')
                        ? inputs.text(this.item().lot, {required: true})
                        : this.item().lot()}
                </td>
                {vnode.attrs.fields.includes('product')
                    ? <td class="col-sm-2">
                        <AutocompleteInput
                            placeholder="Select a product"
                            default_text={this.product().name()}
                            on_get_suggestions$={(filter_text: string) => this.product_management_api.get_simple_products(filter_text)}
                            suggestion_as_label={(simple_product: any) => simple_product.name}
                            on_selected={(simple_product: any) => this.after_update_product(simple_product)}
                        />
                    </td>
                    : <td>{this.item().bottle().product().name()}</td>
                }
                <td style="width: 90px">
                    {vnode.attrs.fields.includes('number_of_bottles_per_case')
                        ? inputs.number(this.item().number_of_bottles_per_case, {
                            min: 1,
                            required: true,
                            disabled: !this.item().bottle().artkey(),
                            after_update: this.get_case,
                        })
                        : this.item().number_of_bottles_per_case()}
                </td>
                {vnode.attrs.fields.includes('product')
                    ? <td colspan={3}>
                        {inputs.specs(this.bottles, this.item().bottle, {
                            after_update: this.after_update_bottle,
                            required: true,
                        })}
                    </td>
                    : [
                        <td class="number">{this.item().bottle().display_volume()}</td>,
                        <td class="number">{this.item().bottle().display_alcohol_percentage()}</td>,
                        <td>{this.item().bottle().refill_status()}</td>,
                    ]
                }
                <td>
                    {vnode.attrs.fields.includes('gift_box_type')
                        ? inputs.gift_box_type(this.item().gift_box_type, {
                            disabled: !this.item().bottle().artkey(),
                            after_update: this.get_case,
                        })
                        : this.item().gift_box_type()}
                </td>
                <td class="price">
                    <Amount
                        amount={this.item().was_bought_for()}
                        currency={this.item().purchase_order_item().purchase_order().was_bought_in()}
                    />
                </td>
                <td class="price">
                    <Amount
                        amount={this.item().was_bought_for_plus_costs()}
                        currency={this.item().purchase_order_item().purchase_order().was_bought_in()}
                    />
                </td>
                <td style="width: 100px">
                    {vnode.attrs.fields.includes('stock')
                        ? inputs.number(this.item().number_of_cases_in_stock, {
                            required: true,
                            disabled: !this.item().bottle().artkey(),
                        })
                        : this.item().number_of_cases_in_stock()}
                </td>
                {vnode.attrs.show_available && <td class="number">-</td>}
                {vnode.attrs.show_sales && <td class="number">-</td>}
                <td>
                    {vnode.attrs.fields.includes('customs_status')
                        ? inputs.customs_status(this.item().customs_status, {
                            disabled: !this.item().bottle().artkey(),
                            required: true,
                        })
                        : this.item().customs_status()}
                </td>
                <td class="clickable">
                    {this.reason() !== ItemMutationReason.CORRECT_BOTTLES_PER_CASE && (
                        <span class="glyphicon glyphicon-remove-sign" onclick={() => this.remove_item(vnode.attrs.index)}></span>
                    )}
                </td>
            </tr>
            {this.update_type !== 'correct-bottles' && (
                <tr>
                    <td colspan="100%">
                        <div class="panel panel-default">
                            <div class="panel-body">
                                <form class="flex-form">
                                    <div class="fieldset">
                                        <TaxLabelDropDown
                                            label="Tax label"
                                            model={[this.item(), 'tax_label']}
                                            onchange={() => {
                                                this.get_case()
                                            }}
                                        />
                                        {item_tags_editor(this.item)}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </tbody>
    }
}
