import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import tippy, {Content, Placement} from 'tippy.js'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {random_string} from '@bitstillery/common/lib/utils'
import {Argument} from 'classnames'

export interface TippyAttrs {
    allowHTML?: boolean
    animation?: string | boolean
    arrow?: boolean | string | SVGElement | DocumentFragment
    content: Content
    inertia?: boolean
    placement?: Placement
    maxWidth?: number | string
    role?: string
    theme?: string
    zIndex?: number

    onclick: (event) => unknown
    className?: Argument
}

/**
 * Wraps an element into a Tippy tooltip with allowHTML true.
 *
 * Usage:
 *   <TippyWrapper content={`<div>Number of cases in stock</div>`}>
 *      ...
 *   </TippyWrapper>
 *
 * See https://atomiks.github.io/tippyjs/ and https://atomiks.github.io/tippyjs/v6/all-props/.
 */
export class Tippy extends MithrilTsxComponent<TippyAttrs> {
    id = random_string(8, false)

    oncreate(vn: m.Vnode<TippyAttrs>): void {
        const tippy_attrs = {
            allowHTML: vn.attrs.allowHTML ? vn.attrs.allowHTML : true,
            content: vn.attrs.content,
            delay: vn.attrs.delay ? vn.attrs.delay : 0,
            interactive: vn.attrs.interactive ? vn.attrs.interactive : false,
            placement: vn.attrs.placement,
            zIndex: vn.attrs.zIndex ? vn.attrs.zIndex : 100000,
        }
        tippy(`#${this.id}`, tippy_attrs)
    }

    view(vnode: m.Vnode<TippyAttrs>): m.Children {
        return (
            <span
                className={classes(vnode.attrs.className)}
                id={this.id}
                onclick={vnode.attrs.onclick}
            >
                {vnode.children}
            </span>
        )
    }
}

export const tip = tippy
