import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {format_money} from '@bitstillery/common/lib/format'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {$s} from '@bitstillery/common/app'

function convert_from_source_to_target(
    amount_to_convert: number,
    source_currency: string,
    target_currency: string,
    original_rate?: number | null,
    target_rate?: number | null,
): number {
    if (source_currency === target_currency) {
        return amount_to_convert
    }

    const original = original_rate || $s.currencies.exchange_rates[source_currency].rate
    const target = target_rate || $s.currencies.exchange_rates[target_currency].rate
    return amount_to_convert * target / original
}

/**
 * Convert the amount_to_convert from source_currency to the default EUR currency. If the source is EUR
 * or the rate is 1, the amount_to_convert is returned.
 */
function convert_from_currency_to_euro(amount_to_convert: number, source_currency: string, use_rate?: number | null): number {
    let converted_amount = amount_to_convert
    if (source_currency === $s.currencies.default || use_rate === 1) {
        return converted_amount
    }
    let rate = use_rate || $s.currencies.exchange_rates[source_currency].rate
    converted_amount = converted_amount / rate
    return converted_amount
}

function convert_from_euro_to_currency(amount_to_convert: number, target_currency: string, use_rate?: number | null): number {
    let converted_amount = amount_to_convert
    if (target_currency === $s.currencies.default || use_rate === 1) {
        return converted_amount
    }
    let rate = use_rate || $s.currencies.exchange_rates[target_currency].rate
    converted_amount = converted_amount * rate
    return converted_amount

}

interface AmountAttrs {
    amount: number | null
    currency: string
    display_currency?: string
    rate?: number
    display_rate?: number
    excise?: number
    force_excise?: boolean
}

function _calculate_display_strings(
    amount: number | null,
    include_excise: boolean,
    currency: string,
    rate_value?: number,
    display_currency_value?: string,
    excise_attr?: number,
    display_rate_attr?: number,
): [string, string | null] {
    if (!amount) {
        return ['-', null]
    }

    const decimal_locale = $s.identity.user.decimal_locale
    let calculated_alternative_amount_str: string | null = null

    const rate = rate_value ? +rate_value : null
    const display_currency = display_currency_value ?? currency

    if (excise_attr && include_excise) {
        // Excise is always in the default currency.
        const display_excise = convert_from_euro_to_currency(excise_attr, currency)
        amount += display_excise
    }

    let display_amount: number
    if (currency !== display_currency) {
        const display_rate_value = display_rate_attr
        const display_rate = display_rate_value ? +display_rate_value : null

        display_amount = convert_from_source_to_target(
            amount, currency, display_currency, rate, display_rate,
        )

        // Show original amount on hover.
        calculated_alternative_amount_str = format_money(amount, currency, decimal_locale)
    } else {
        // No conversion necessary for the input.
        display_amount = amount
        if (display_currency !== $s.currencies.default) {
            // Always show EUR currency on hover.
            const alternative_amount = convert_from_currency_to_euro(amount, display_currency, rate)
            calculated_alternative_amount_str = format_money(alternative_amount, $s.currencies.default, decimal_locale)
        }
    }

    return [format_money(display_amount, display_currency, decimal_locale), calculated_alternative_amount_str]
}

/**
 * ----------------------------------------------------------------------------
 * Mithril component to show amounts with currencies and excise
 *
 * Parameters:
 * - amount: The amount to show.
 * - currency: The currency that the amount is in.
 * - display_currency: The currency that the amount should be displayed in.
 *   Defaults to currency.
 * - rate: The exchange rate to be used. Defaults to current exchange rates.
 * - excise: Optional excise amount.
 * - force_excise: Include the optional excise amount, regardless of the Excise Mode toggle value.
 *
 */
export class Amount extends MithrilTsxComponent<AmountAttrs> {
    alternative_str: string | null
    amount_str: string
    amount: number | null
    include_excise: boolean

    constructor(vnode: m.Vnode<AmountAttrs>) {
        super()
        this.amount = vnode.attrs.amount ? +vnode.attrs.amount : null
        this.include_excise = $s.include_excise || (vnode.attrs.force_excise ? vnode.attrs.force_excise : false)
        ;[this.amount_str, this.alternative_str] = _calculate_display_strings(
            this.amount,
            this.include_excise,
            vnode.attrs.currency,
            vnode.attrs.rate,
            vnode.attrs.display_currency,
            vnode.attrs.excise ? +vnode.attrs.excise : undefined,
            vnode.attrs.display_rate,
        )
    }

    onupdate(vnode: m.VnodeDOM<AmountAttrs>): void {
        const new_amount = vnode.attrs.amount ? +vnode.attrs.amount : null
        const new_include_excise = $s.include_excise || (vnode.attrs.force_excise ? vnode.attrs.force_excise : false)

        // If either amount or include_excise changed: update the Amount component.
        if (this.amount !== new_amount || this.include_excise !== new_include_excise) {
            this.amount = new_amount
            this.include_excise = new_include_excise
            ;[this.amount_str, this.alternative_str] = _calculate_display_strings(
                this.amount,
                this.include_excise,
                vnode.attrs.currency,
                vnode.attrs.rate,
                vnode.attrs.display_currency,
                vnode.attrs.excise ? +vnode.attrs.excise : undefined,
                vnode.attrs.display_rate,
            )

            m.redraw()
        }
    }

    view(vn): m.Children {
        if (this.alternative_str) {
            return <abbr title={this.alternative_str}>{this.amount_str}</abbr>
        }
        return <span className={classes('c-amount', vn.attrs.className)}>{this.amount_str}</span>
    }
}

interface SubAmountAttrs extends AmountAttrs {
    label: string
}

/**
 * Shows an amount in a help block.
 */
export class SubAmount extends MithrilTsxComponent<SubAmountAttrs> {
    view(vnode: m.Vnode<SubAmountAttrs>): m.Children {
        return (
            <span class={'help-block'}>
                {`${vnode.attrs.label} `}
                <Amount {...vnode.attrs} />
            </span>
        )
    }
}
