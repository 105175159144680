import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {api, notifier} from '@bitstillery/common/app'
import {Amount, Button, FieldMoney, FieldDate, Spinner, FieldText} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy.ts'

import {UserDropDown} from '@/components/users'
import {GetSalesTargetsResponse, PostSalesTargetsRequest} from '@/factserver_api/fact2server_api'

interface SalesTargetData {
    is_loading: boolean
    sales_manager_artkey: number | null
    targets: GetSalesTargetsResponse[]
}

export default class SalesTarget extends MithrilTsxComponent<unknown> {
    selected_date = new Date()

    data: SalesTargetData = proxy({
        is_loading: false,
        sales_manager_artkey: null,
        targets: [],
    })

    async oncreate() {
        this.init_targets()
        await this.fetch_targets_for_user()
    }

    date_picker_options() {
        const max_date = new Date()
        max_date.setFullYear(max_date.getFullYear() + 1)
        return {
            minDate: '2019-01-01',
            maxDate: max_date,
            onSelect: ({date}) => this.select_date(date),
            toggleSelected: false,
        }
    }

    async select_date(date: Date) {
        this.selected_date = date
        await this.fetch_targets_for_user()
    }

    async fetch_targets_for_user() {
        this.data.is_loading = true

        // The targets are initialized with empty values and then fill them with the fetched values
        this.init_targets()
        let url = `discover/sales-targets?year=${this.selected_date.getFullYear()}`
        if (this.data.sales_manager_artkey) {
            url += `&sales_manager_artkey=${this.data.sales_manager_artkey}`
        }
        const {result} = await api.get<GetSalesTargetsResponse[]>(url)

        this.data.targets.forEach((target) => {
            const target_for_month = result.find((t) => t.month === target.month)
            if (target_for_month) {
                target.turnover = target_for_month.turnover
                target.gross_margin_percentage = target_for_month.gross_margin_percentage
            }
        })
        this.data.is_loading = false
    }

    init_targets() {
        // Initialize empty targets for all months
        this.data.targets = Array.from(
            {length: 12}, (_, i) => ({
                month: i + 1,
                turnover: '0',
                gross_margin_percentage: '0',
            }),
        )
    }

    async save() {
        const request_body: PostSalesTargetsRequest = {
            targets: this.data.targets, sales_manager_artkey: this.data.sales_manager_artkey,
        }
        await api.post(`discover/sales-targets/${this.selected_date.getFullYear()}`, request_body, true)
        notifier.notify(`Sales targets saved for ${this.selected_date.getFullYear()}`, 'success')
    }

    month_year_to_datetime(month: number, year: number): DateTime {
        return DateTime.fromFormat(`${year}-${month}-01`, 'yyyy-M-dd')
    }

    view(): m.Children | void | null {
        return (
            <div className="c-sales-targets view">
                <div className="c-filter-group">
                    <FieldDate
                        preset="years"
                        placeholder="Select year"
                        ref={[this, 'selected_date']}
                        date_picker_options={this.date_picker_options()}
                    />

                    <UserDropDown
                        model={[this, 'sales_manager_artkey']}
                        onchange={async(sales_manager_artkey: string | null) => {
                            this.data.sales_manager_artkey = sales_manager_artkey ? +sales_manager_artkey : null
                            await this.fetch_targets_for_user()
                        }}
                        placeholder="Global for account"
                    />
                </div>

                <div className="c-targets-forecasts fieldset">
                    {this.data.is_loading && <Spinner />}
                    {!this.data.is_loading && (
                        <div>
                            {this.data.targets
                                .map((target) => (
                                    <div className="field-group" key={`target-${target.month}`}>
                                        <div className="field center">
                                            <label>{this.month_year_to_datetime(target.month, this.selected_date.getFullYear()).toFormat('MMMM')}</label>
                                        </div>
                                        <FieldMoney
                                            min={0}
                                            model={[target, 'turnover']}
                                            required={true}
                                            show_addon={false}
                                        />
                                        <div className='field'>
                                            <div className='control'>
                                                <FieldText
                                                    type="number"
                                                    min={0}
                                                    model={[target, 'gross_margin_percentage']}
                                                    on_value={(value: string) => (target.gross_margin_percentage = value)}
                                                />
                                                <span class="control-addon">%</span>
                                            </div>
                                        </div>
                                        <Amount
                                            className="field center"
                                            currency={'EUR'}
                                            amount={(+target.gross_margin_percentage / 100) * +target.turnover}
                                        />
                                    </div>
                                ))
                            }
                            <Button type="success" text="Save" icon='save' onclick={() => this.save()} />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
