import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {format_iso_to_date_time} from '@bitstillery/common/lib/format'
import {Country} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/models/item'
import {Spinner} from '@bitstillery/common/components'
import {Amount} from '@bitstillery/common/components'

import {$s} from '@/app'
import {Link} from '@/components/discover'
import {GetItemMutationByReferenceResponse, Item} from '@/factserver_api/stock_api'

export interface ItemMutationTargetAndSourceAttrs {
    item_mutation: GetItemMutationByReferenceResponse | null
}

export class ItemMutationTargetAndSource extends MithrilTsxComponent<ItemMutationTargetAndSourceAttrs> {
    item_to_table_row(item: Item): m.Children {
        return <tr>
            <td>{item.purchase_order_reference}</td>
            <td>{format_iso_to_date_time(item.entry_date)}</td>
            <td>{item.warehouse.name}</td>
            <td>{item.lot}</td>
            <td>{item.case.bottle.product.name}</td>
            <td><Country country_code={item.country_of_origin} type={'flag_with_country'}/></td>
            <td>{item.case.number_of_bottles}</td>
            <td>
                {to_specs(
                    {
                        alcohol_percentage: item.case.bottle.alcohol_percentage,
                        refill_status: item.case.bottle.refill_status,
                        volume: item.case.bottle.volume,
                    },
                    $s.identity.user.decimal_locale,
                )}
            </td>
            <td>
                <Amount amount={item.was_bought_for} currency={item.was_bought_in} />
            </td>
            <td>
                <Amount amount={item.was_bought_for_plus_costs} currency={item.was_bought_in} />
            </td>
            <td>{item.number_of_cases}</td>
            <td>{item.number_of_cases_in_stock}</td>
            <td>{item.number_of_cases_in_purchase}</td>
            <td>{item.number_of_cases_in_sales}</td>
            <td>{item.number_of_cases_available}</td>
            <td>{item.case.customs_status}</td>
            <td>
                <Link href={`/stock/manage/${item.reference}`}>{item.reference}</Link>
            </td>
        </tr>
    }

    source_and_target_table_head(): m.Children {
        return <thead className={'thead-default'}>
            <tr>
                <th>#</th>
                <th>Intake</th>
                <th>Warehouse</th>
                <th>Lot #</th>
                <th>Product</th>
                <th>Country of origin</th>
                <th>Btl / cs</th>
                <th>Spec</th>
                <th>Purchase / cs</th>
                <th>Cost / cs</th>
                <th>Cases</th>
                <th>Stock</th>
                <th>In purchase</th>
                <th>In sales</th>
                <th>Avail</th>
                <th>Customs status</th>
                <th></th>
            </tr>
        </thead>
    }

    view(vnode: m.Vnode<ItemMutationTargetAndSourceAttrs>): m.Children {
        return (
            <div className={'details'}>
                {!vnode.attrs.item_mutation && <Spinner />}
                {vnode.attrs.item_mutation && [
                    <div className={'panel panel-default'}>
                        <div className={'panel-heading'}>After mutation</div>
                        <div className={'panel-body'}>
                            <table className={'table'}>
                                {this.source_and_target_table_head()}
                                <tbody className={'table-row'}>
                                    {vnode.attrs.item_mutation?.targets.map((target) =>
                                        this.item_to_table_row(target),
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>,
                    <div className={'panel panel-default'}>
                        <div className={'panel-heading'}>Before mutation</div>
                        <div className={'panel-body'}>
                            <table className={'table'}>
                                {this.source_and_target_table_head()}
                                <tbody className={'table-row'}>
                                    {vnode.attrs.item_mutation?.sources.map((item) =>
                                        this.item_to_table_row(item),
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>,
                ]}
            </div>
        )
    }
}
