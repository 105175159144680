import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, ButtonGroup, FieldDate, FieldSelect, FieldText} from '@bitstillery/common/components'
import {proxy} from '@bitstillery/common/lib/proxy'
import {merge_deep} from '@bitstillery/common/lib/utils'
import {api} from '@bitstillery/common/app'
import {Amount, FieldMoney} from '@bitstillery/common/components'

import {$m, $s} from '@/app'
import {item_tags_editor} from '@/stock/components/item_tags_editor'
import {OfferItem} from '@/models/pricelists'
import {GetBottleCasesResponse} from '@/factserver_api/fact2server_api'

export class AddToOffer extends MithrilTsxComponent<any> {
    done: Function
    mode = 'legacy'
    spli: Function

    data = (() => {
        const $f = {
            case: {
                artkey: null,
                best_before_date: null,
                bottle_artkey: null,
                customs_status: null,
                gift_box_type: null,
                item_tags: [],
                cases_per_pallet: null,
                cases_per_pallet_layer: null,
                // Only number_of_bottles is actually used in the form; but
                // item-tags-editor uses the whole case object.
                number_of_bottles: 0,
                tax_label: '',
            },
            delivery_period: 0,
            maximum_quantity: 0,
            minimum_quantity: 0,
            offers: [],
            offer_artkey: null,
            sales_price_per_case: 0,
        }
        return proxy({
            $f,
        })
    })()

    async oninit(vnode:m.Vnode<any>) {
        this.done = vnode.attrs.done

        vnode.attrs.mode && (this.mode = vnode.attrs.mode)
        vnode.attrs.offer_artkey && (this.data.$f.offer_artkey = vnode.attrs.offer_artkey)
        this.spli = vnode.attrs.spli

        this.custom_offer = vnode.attrs.custom_offer
        if (!this.custom_offer) {
            throw Error('Can only invoke this popup for custom offer items.')
        }

        if (this.custom_offer) {
            const {result} = await api.post('offer.get_offer_records')
            this.data.$f.offers = result
        }

        merge_deep(this.data.$f, {
            case: {
                best_before_date: null,
                bottle_artkey: this.spli().bottle().artkey(),
                customs_status: this.spli().customs_status(),
                gift_box_type: this.spli().case_gift_box_type ? this.spli().case_gift_box_type() : this.spli().gift_box_type(),
                cases_per_pallet: this.spli().cases_per_pallet(),
                cases_per_pallet_layer: this.spli().cases_per_pallet_layer(),
                item_tags: [],
                number_of_bottles: this.spli().number_of_bottles_per_case(),
                tax_label: '',
            },
        })

        this.lookup_case()

        // (!) Only the item-tags-editor uses the OfferItem model; remove when possible.
        this.offer_item = window.prop(new OfferItem({case: this.data.$f.case}))
    }

    is_valid() {
        const all_valid = [
            this.data.$f.case.number_of_bottles > 0,
            this.data.$f.sales_price_per_case > 0,
            this.data.$f.maximum_quantity > 0,
            this.data.$f.delivery_period > 0,
        ].every((i) => i)
        if (this.custom_offer) {
            return all_valid && this.data.$f.offer_artkey
        }
        return all_valid
    }

    async lookup_case() {
        const search_data = Object.assign(JSON.parse(JSON.stringify(this.data.$f.case)), {
            search_exact_case: true,
        })
        search_data.tax_label = search_data.tax_label ? search_data.tax_label : null
        search_data.gift_box_type = search_data.gift_box_type ? search_data.tax_label : null
        let filter = JSON.stringify(search_data)

        const {result, status_code} = await api.get<GetBottleCasesResponse[]>(
            `discover/bottles/${this.data.$f.case.bottle_artkey}/cases?filters=${encodeURI(filter)}`) 
        if (status_code > 299) {
            return
        }
        let old_artkey = this.offer_item().case().artkey()
        let new_artkey = null
        if (result.length) {
            new_artkey = result[0].artkey
        }

        if (old_artkey !== new_artkey) {
            merge_deep(this.data.$f, {
                case: {
                    best_before_date: result[0].best_before_date,
                    gift_box_type: result[0].gift_box_type,
                    item_tags: result[0].item_tags || [],
                    tax_label: result[0].tax_label,
                },
            })

            if (new_artkey) {
                this.lookup_offer_item(new_artkey)
            } else {
                this.data.$f.case.artkey = null
            }
        }
    }

    async lookup_offer_item(case_artkey) {
        const {result} : {result: any} = await api.post('pricelist.get_offer_item', {case_artkey})
        if (!result) return

        merge_deep(this.data.$f, {
            delivery_period: result.delivery_period,
            maximum_quantity: result.maximum_quantity,
            minimum_quantity: result.minimum_quantity,
        })

        // Only prefill the sales price if the offer item is currently offered, which
        // is the case if the list quantity > 0.
        if (result.list_quantity > 0) {
            this.data.$f.sales_price_per_case = result.price_per_case
        }
    }

    async add_offer_item() {
        if (!this.is_valid) return

        await api.post('offer.create_custom_offer_item_from_market', {
            case: this.data.$f.case,
            delivery_period: this.data.$f.delivery_period,
            minimum_quantity: this.data.$f.minimum_quantity,
            offer_artkey: this.data.$f.offer_artkey,
            price_per_case: this.data.$f.sales_price_per_case,
            quantity: this.data.$f.maximum_quantity,
            spli_artkey: this.spli().artkey(),
        })
        this.done()

        // Return false to prevent any further action from happening, as the
        // api call handling has taken care of everything already.
        return false
    }

    price_or_dash(value) {
        if (!value) return '-'
        return <Amount
            amount={value}
            currency={this.spli().currency()}
            display_currency={$s.currencies.default}
        />
    }

    view(vn:m.Vnode<any>) {
        return <div className="c-add-to-offer">
            {this.mode !== 'panel' && <dl className="spli dl-horizontal">
                <dt>Supplier</dt>
                <dd>{this.spli().supplier_price_list().supplier().name()}</dd>
                <dt>Product</dt>
                <dd>{this.spli().bottle().product().name()}</dd>
                <dt>Specs</dt>
                <dd>{this.spli().bottle().to_specs()}</dd>
                <dt>Bottles per case</dt>
                <dd>{this.spli().number_of_bottles_per_case()}</dd>
                <dt>Giftbox</dt>
                <dd>{this.spli().case_gift_box_type ? this.spli().case_gift_box_type() : this.spli().gift_box_type()}</dd>
                <dt>Quantity cases</dt>
                <dd>{this.spli().number_of_cases()}</dd>
                <dt>Quantity bottles</dt>
                <dd>{this.spli().number_of_bottles()}</dd>
                <dt>Price per case</dt>
                <dd>{this.price_or_dash(this.spli().price_per_case())}</dd>
                <dt>Price per bottle</dt>
                <dd>{this.price_or_dash(this.spli().price_per_bottle())}</dd>
                <dt>Customs status</dt>
                <dd>{this.spli().customs_status()}</dd>
                <dt>Aux info</dt>
                <dd>{this.spli().aux_info()}</dd>
                <dt>Pricelist incoterm</dt>
                <dd>{this.spli().incoterm()}</dd>
                <dt>Availability status</dt>
                <dd>{this.spli().availability_status()}</dd>
            </dl>}

            <div className="fieldset">
                <div className="field-group">
                    <FieldSelect
                        label="Offer"
                        model={[this.data.$f, 'offer_artkey']}
                        options={this.data.$f.offers.map(((i:any) => ({
                            label: i.title,
                            value: i.artkey,
                        })))}
                        placeholder="Select an Offer..."
                    />
                </div>
                <div className="field-group">
                    <FieldMoney
                        currency={[$s.currencies, 'default']}
                        label="Sales Price"
                        model={[this.data.$f, 'sales_price_per_case']}
                        required={true}
                    />
                </div>
                <div className="field-group">
                    <FieldText
                        label="Bottles per case"
                        min={1}
                        model={[this.data.$f.case, 'number_of_bottles']}
                        onafterupdate={() => this.lookup_case()}
                        type="number"
                    />
                    <FieldText
                        label="Maximum Quantity"
                        min={1}
                        model={[this.data.$f, 'maximum_quantity']}
                        type="number"
                    />
                </div>
                <div className="field-group">
                    <FieldText
                        help="Amount of weeks it takes to deliver"
                        label="Delivery Period"
                        min={1}
                        model={[this.data.$f, 'delivery_period']}
                        type="number"
                    />

                    <FieldText
                        help="Minimum quantity that can be ordered of this product"
                        label="Minimum Quantity"
                        min={1}
                        model={[this.data.$f, 'minimum_quantity']}
                        type="number"
                    />
                </div>

                <div className="field-group">
                    <FieldSelect
                        label="Tax Label"
                        model={[this.data.$f.case, 'tax_label']}
                        options={(() => {
                            const category = $m.data.item_tag_category.tax_label_category()
                            return $m.data.item_tag.get_all_from_category(category).map((i) => ({
                                label: i.name(),
                                value: i.name(),
                            }))
                        })()}
                        placeholder="Select Tax label..."
                    />

                    <FieldDate
                        label="Best Before Date"
                        date_picker_options={{
                            onSelect: ({date}) => {
                                this.data.$f.case.best_before_date = date.toISOString().split('T')[0]
                            },
                        }}
                    />
                </div>

                {this.offer_item && item_tags_editor(this.offer_item().case, {only_tags: true}) }
                <div className="btn-toolbar">
                    <ButtonGroup>
                        <Button
                            icon="stop"
                            onclick={vn.attrs.oncancel}
                            text="Cancel"
                        />
                        <Button
                            disabled={!this.is_valid()}
                            icon="plus"
                            onclick={() => this.add_offer_item()}
                            text="Add to Custom Offer"
                            type="success"
                        />
                    </ButtonGroup>
                </div>
            </div>
        </div>
    }
}
