import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {Amount, CollectionItems, CollectionView, Link} from '@bitstillery/common/components'
import {
    CollectionTransforms,
    CollectionProxy,
} from '@bitstillery/common/lib/collection'

interface RelationPurchaseOrdersAttrs {
    relation_artkey: number
    on_refresh_price_list: () => any
}

const transforms:CollectionTransforms = {
    filters_to_query: () => {
        const query = {
            filters: {
                purchase_order_statuses: ['Saved', 'Confirmed'],
            },
            page_size: 30,
            sort_ascending: collection.state.sort.order === 'asc' ? 'ASC' : 'DESC',
            sort_by: collection.state.sort.by,
        }
        return query
    },
}

const collection = new CollectionProxy()

const columns = [
    {
        name: 'Name',
        render: (row) => {
            return <div className="td-group">
                <span className={'header'}>
                    <Link href={`/purchase-orders/manage/${row.reference}`} target="_blank">
                        {row.reference}
                    </Link>
                </span>
            </div>
        },
    },
    {name: 'Date', render: (row) => format_iso_to_date(row.created_on)},
    {name: 'Incoterm location', render: (row) => row.incoterm_location},
    {
        name: 'Amount',
        render: (row) => <Amount
            amount={row.was_bought_for}
            currency={row.was_bought_in}
        />,
    },
    {name: 'Status', render: (row) => row.purchase_order_status},
]

export class RelationPurchaseOrders extends MithrilTsxComponent<RelationPurchaseOrdersAttrs> {

    async oninit(vnode) {
        collection.init({
            endpoint: {
                method: 'get',
                path: `discover/relations/${vnode.attrs.relation_artkey}/purchase-orders/collection-view`,
            },
            sort: {
                by: 'name',
                order: 'asc',
                options: [
                    ['created_on', 'Created on'],
                ],
            },
        }, null, transforms)
    }

    view(): m.Children {
        return <CollectionView mode="table" className="c-relation-purchase-orders">
            <CollectionItems
                collection={collection}
                columns={columns}
                scroll_container={'.c-relation-purchase-orders'}
            />
        </CollectionView>
    }
}
