import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {displayable_float, format_iso_to_date, format_iso_to_date_time, titleize} from '@bitstillery/common/lib/format'
import {DataCard} from '@bitstillery/common/components'
import {DateTime} from 'luxon'

import {$s} from '@/app'
import {GetItemMutationByReferenceResponse} from '@/factserver_api/stock_api'

export interface ItemMutationDetailsAttrs {
    item_mutation: GetItemMutationByReferenceResponse | null
}

export class ItemMutationDetails extends MithrilTsxComponent<ItemMutationDetailsAttrs> {
    view(vnode: m.Vnode<ItemMutationDetailsAttrs>): m.Children {
        const planned_unloading_time = vnode.attrs.item_mutation?.planned_unloading_date
            ? DateTime.fromISO(vnode.attrs.item_mutation.planned_unloading_date).toISOTime()
            : null
        if (!vnode.attrs.item_mutation) return null
        return <div className="content">
            <DataCard
                model={{data: [
                    {label: 'Created on', value: format_iso_to_date_time(vnode.attrs.item_mutation?.created_on)},
                    {label: 'Created by', value: vnode.attrs.item_mutation?.user.profile.name},
                    {label: 'Reason', value: vnode.attrs.item_mutation?.item_mutation_reason},
                    {label: 'Stock value difference', value: <span
                        data-value={
                            displayable_float(
                                vnode.attrs.item_mutation?.stock_value_difference,
                                2,
                                $s.identity.user.decimal_locale,
                            ) + ' EUR'
                        }
                        class="colored-number"
                    />},
                    {label: 'Description', value: vnode.attrs.item_mutation?.description},
                    {label: 'Sent to Loendersloot', value: format_iso_to_date_time(vnode.attrs.item_mutation?.sent_to_loendersloot_on)},
                    {label: 'Expected delivery', value: format_iso_to_date(vnode.attrs.item_mutation.expected_delivery_date)},
                    {
                        label: 'Planned unloading date',
                        value: (() => {
                            if (planned_unloading_time) {
                                if (planned_unloading_time.startsWith('00:00:00')) {
                                    return format_iso_to_date(vnode.attrs.item_mutation.planned_unloading_date)
                                }
                                return format_iso_to_date_time(vnode.attrs.item_mutation.planned_unloading_date)
                            }
                            return '-'
                        })(),
                    },
                    {label: 'Warehouse status', value: (() => {
                        if (!vnode.attrs.item_mutation?.warehouse_status) return '-'
                        return [
                            `${titleize(vnode.attrs.item_mutation?.warehouse_status)} - `,
                            format_iso_to_date_time(vnode.attrs.item_mutation?.last_warehouse_message),
                        ]
                    })()},
                    {label: 'Status', value: vnode.attrs.item_mutation?.status},
                    {label: 'Target warehouse', value: vnode.attrs.item_mutation?.target_warehouse?.name},
                    {label: 'Incoterms', value: <span>{vnode.attrs.item_mutation?.incoterm} {vnode.attrs.item_mutation?.incoterm_location}</span>},
                    {label: 'Number of euro pallets', value: vnode.attrs.item_mutation?.number_of_euro_pallets},
                    {label: 'Number of block pallets', value: vnode.attrs.item_mutation?.number_of_euro_pallets},
                ]}}
            />
        </div>
    }
}
