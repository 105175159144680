import {MithrilTsxComponent} from 'mithril-tsx-component'
import {next_tick, proxy} from '@bitstillery/common/lib/proxy'
import m from 'mithril'
import {to_specs} from '@bitstillery/common/models/item'
import {
    Button,
    ButtonGroup,
    FieldMoney,
    FieldSelect,
    FieldText,
    Icon,
    Spinner,
} from '@bitstillery/common/components'
import {countries} from '@bitstillery/common/lib/countries'
import {
    bottle_gtin,
    conditional,
    invalid_fields,
    invalid_fields_format,
    required,
    reset_validation,
    validation,
} from '@bitstillery/common/lib/validation'
import {CollectionProxy} from '@bitstillery/common/lib/collection'
import {api, events, logger, notifier} from '@bitstillery/common/app'
import {classes, object_to_query_string} from '@bitstillery/common/lib/utils'
import {watch} from '@bitstillery/common/lib/store'
import {merge_deep} from '@bitstillery/common/lib/utils'

import {model} from '@/market/pricelists/list_pricelist_items'
import {$m, $s} from '@/app'
import {GiftBoxTypeDropDown} from '@/components/case_inputs'
import {IncotermsDropDown} from '@/components/incoterms'
import {IncotermsDropDownData} from '@/factserver_api/incoterms_api'
import {SpliData} from '@/models/pricelists'
import {
    BottleByGTINResp,
    GetProductsResponse,
    PalletLayoutResponse,
    SupplierPricelistItemRequest,
} from '@/factserver_api/fact2server_api'

interface UpsertSpliAttrs {
    collection: CollectionProxy
    /** Either create a spli from a sourceline or update an existing spli */
    source: 'spli' | 'sourceline'
}

export class UpsertSpli extends MithrilTsxComponent<UpsertSpliAttrs> {

    $v: any = {}
    data = (() => {
        return proxy({
            bottles: [],
            linked: {
                gtin: false,
                product: false,
                specs: false,
            },
            previous_number_of_bottles_per_case: 6,
            per_case: true,
        })
    })()

    watchers = [] as any

    async context_update(vnode) {
        logger.info('[upsert_spli] updating panel context')
        const data = $s.context.data as any

        // The incoterm (e.g. DAP) and location are one field and must be splitted for editing and rejoined when saving.
        if (data && 'incoterm' in data) {
            const [incoterm, ...location] = data.incoterm.split('-').map((i) => i.trim())
            data.incoterm = incoterm
            data.incoterm_location = location.join(' ')
        }

        reset_validation(this.$v)
        Object.assign(this.$v, {
            alcohol_percentage: validation([data, 'alcohol_percentage'], conditional(() => !this.data.linked.specs, required())), // specs
            bottle_gtin_code: validation([data, 'bottle_gtin_code'], bottle_gtin()),
            country_of_origin: validation([data, 'country_of_origin'], required()),
            currency: validation([data, 'currency'], required()),
            customs_status: validation([data, 'customs_status'], required()),
            number_of_bottles_per_case: validation([data, 'number_of_bottles_per_case'], required()),
            price_per_bottle: validation([data, 'price_per_bottle'], conditional(() => !this.data.per_case, required())),
            price_per_case: validation([data, 'price_per_case'], conditional(() => this.data.per_case, required())),
            product_name: validation([data, 'product_name'], required()),
            product_category_artkey: validation([data, 'product_category_artkey'], required()),
            refill_status: validation([data, 'refill_status'], conditional(() => !this.data.linked.specs, required())), // specs
            volume: validation([data, 'volume'], conditional(() => !this.data.linked.specs, required())), // specs
        })

        if (data.number_of_bottles) {
            this.data.per_case = false
            data.price_per_case = null
            data.number_of_cases = null
        } else {
            this.data.per_case = true
            data.price_per_bottle = null
            data.number_of_bottles = null
        }

        this.data.previous_number_of_bottles_per_case = data.number_of_bottles_per_case

        if (vnode.attrs.source === 'spli' && data.bottle_gtin_code) {
            // First try to retrieve the form context from GTIN
            await this.context_from_bottle_gtin(data)
        } else {
            this.data.linked.gtin = false
            this.data.linked.product = false

            if (data.product_name?.length >= 3) {
                logger.debug('[upsert_spli] try to match product from name')
                $s.context.loading = true
                const {result:products}: {result:Array<GetProductsResponse>} = await api.get(`discover/products?search_terms=${data.product_name}`) as any
                const matched_product: GetProductsResponse | undefined = products.find((i) => {
                    if (data.product_artkey) {
                        return data.product_artkey === i.artkey
                    }
                    return i.name === data.product_name
                })
                // Try to retrieve the form context from product name
                if (matched_product) {
                    await this.context_from_product({...data, ...matched_product})
                }
                $s.context.loading = false
            }

            if (!this.data.linked.product || !this.data.linked.specs) {
                logger.debug('[upsert_spli] context for unknown product/specs')
                // Fill in blanks from pricelist defaults
                if (!data.currency) data.currency = model.supplier_pricelist.default_currency
                if (!data.customs_status) data.customs_status = model.supplier_pricelist.default_customs_status
                if (!data.incoterm) data.incoterm = model.supplier_pricelist.default_incoterm
                if (!data.incoterm_location) data.incoterm_location = model.supplier_pricelist.default_incoterm_location
                if (!data.number_of_bottles_per_case) data.number_of_bottles_per_case = 6
            }

        }

        // These references to the variables change every time the context changes, so we need to rebind them in every context update.
        this.watchers.push(
            watch($s.context.data, 'bottle_artkey', () => {this.get_pallet_layout()}),
            watch($s.context.data, 'number_of_bottles_per_case', () => {this.get_pallet_layout()}),
            watch($s.context.data, 'gift_box_type', () => {this.get_pallet_layout()}),
            watch($s.context.data, 'customs_status', () => {this.get_pallet_layout()}),
        )
    }

    async oninit(vnode) {
        this.watchers.push(watch($s.context, 'data', (new_data) => {
            if (new_data) {
                this.context_update(vnode)
            }
        }))

        await this.context_update(vnode)
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    async context_from_bottle_gtin(data:SpliData) {
        $s.context.loading = true
        const {result: bottle, status_code}: {result: BottleByGTINResp; status_code: number} = await api.get(`discover/bottle-gtin/${data.bottle_gtin_code}/bottle`)
        if (status_code === 404) {
            notifier.notify('Sorry, we don\'t know about this bottle GTIN yet', 'warning')
            $s.context.loading = false
            return
        }

        this.data.bottles.splice(0, this.data.bottles.length, bottle)
        // Specs set...
        data.bottle_artkey = bottle.artkey
        data.product_name = bottle.product_name
        logger.debug(`[upsert_spli] context from bottle gtin "${data.bottle_gtin_code}"`)

        // Mix with existing context, because we don't have all fields here.
        await this.context_from_product({
            artkey: bottle.product_artkey,
            country_of_origin: data.country_of_origin,
            default_country_code: bottle.default_country_code,
            name: bottle.product_name,
            product_category: {
                artkey: bottle.product_category_artkey,
                name: bottle.product_category_name,
            },
        })

        merge_deep(this.data.linked, {product: true, gtin: true, specs: true})
        $s.context.loading = false
    }

    async context_from_product(product: any) {
        logger.debug(`[upsert_spli] context from product suggestion: "${product.name}"`)
        merge_deep($s.context.data, {
            product_artkey: product.artkey,
            product_category_artkey: product.product_category.artkey,
            product_category: product.product_category.name,
            product_name: product.name,
            country_of_origin: product.country_of_origin ? product.country_of_origin : product.default_country_code,
        })

        if (product.bottles) {
            this.data.bottles.splice(0, this.data.bottles.length, ...product.bottles)
            this.data.bottles.sort((a, b) => { return +b.volume - +a.volume })
        }

        merge_deep(this.data.linked, {
            gtin: false,
            product: true,
            specs: !!$s.context.data.bottle_artkey,
        })
    }

    async get_pallet_layout() {
        const data = $s.context.data as SpliData
        if (!data.bottle_artkey || !data.number_of_bottles_per_case) return

        const request = {
            bottle_artkey: data.bottle_artkey,
            number_of_bottles: data.number_of_bottles_per_case,
        }
        if (data.gift_box_type) {
            request['gift_box_type'] = data.gift_box_type
        }

        if (data.customs_status && data.customs_status !== '') {
            request['customs_status'] = data.customs_status
        }

        const {result, success} = await api.get<PalletLayoutResponse>(
            `discover/cases/find-pallet-layout?${object_to_query_string(request)}`,
        )

        if (success) {
            merge_deep(data, {
                cases_per_pallet: result.cases_per_pallet,
                cases_per_pallet_layer: result.cases_per_pallet_layer,
            })
        }
    }

    async upsert_item(vnode: m.Vnode<UpsertSpliAttrs>) {
        const data = $s.context.data as any
        if (model.supplier_pricelist === null) {
            notifier.notify('Sorry, the supplier pricelist could not be loaded', 'warning')
            return
        }
        const spl_artkey = model.supplier_pricelist.artkey
        const endpoint_prefix = `discover/supplier-price-lists/${spl_artkey}/supplier-price-list-item`
        const spli_data: SupplierPricelistItemRequest = {
            source_line_artkey: vnode.attrs.source === 'sourceline' ? data.artkey : null,
            aux_info: data.aux_info,
            availability_status: data.availability_status,
            bottle: {
                alcohol_percentage: data.alcohol_percentage,
                artkey: data.bottle_artkey || null,
                product: {
                    artkey: data.product_artkey,
                    category: {
                        artkey: data.product_category_artkey,
                    },
                    name: data.product_name,
                    default_country_code: data.default_country_code,
                },
                refill_status: data.refill_status || null,
                volume: data.volume,
            },
            bottle_gtin_code: data.bottle_gtin_code || null,
            country_of_origin: data.country_of_origin,
            case_gtin_code: data.case_gtin_code,
            currency: data.currency,
            customs_status: data.customs_status,
            gift_box_type: data.gift_box_type || '',
            incoterm: data.incoterm_location ? `${data.incoterm} - ${data.incoterm_location}` : data.incoterm,
            number_of_bottles: data.number_of_bottles || null,
            number_of_bottles_per_case: data.number_of_bottles_per_case,
            number_of_cases: data.number_of_cases || null,
            price_per_bottle: data.price_per_bottle,
            price_per_case: data.price_per_case,
            supplier_price_list_artkey: spl_artkey,
        }

        let success, result
        if (vnode.attrs.source === 'sourceline') {
            // Manually resolving a sourceline.
            try {
                ({result, success} = await api.post(endpoint_prefix, spli_data, true))

                if (success) {
                    notifier.notify(`Successfully resolved sourceline: ${data.product_name}`, 'success')
                } else {
                    notifier.notify(`Something went wrong while resolving: ${result}`, 'danger')
                }

                vnode.attrs.collection.select_next($s.context.id)
            } catch (err) {
                notifier.notify(`Something went wrong while resolving: ${result.detail}`, 'danger')
            }
        } else if (vnode.attrs.source === 'spli') {
            try {
                if (data.artkey) {
                    // Update an existing spli item.
                    spli_data.artkey = data.artkey;
                    ({result, success} = await api.put<SupplierPricelistItemRequest>(`${endpoint_prefix}/${data.artkey}`, spli_data) as any)
                    if (success) {
                        notifier.notify(`Successfully updated pricelist item: ${data.product_name}`, 'success')
                    }
                } else {
                    // Create a new spli item.
                    ({result, success} = await api.post(endpoint_prefix, spli_data, true))

                    if (success) {
                        notifier.notify(`Successfully created pricelist item: ${data.product_name}`, 'success')
                        $s.context.data = $m.pricelists.spli_row_model() // Clear the form.
                        await this.context_update(vnode)
                    }
                }
            } catch (err) {
                notifier.notify(`Something went wrong while resolving: ${err}`, 'danger')
            }

            if (success) {
                await vnode.attrs.collection.update_context()
            } else {
                notifier.notify(`Sorry, the pricelist item could not be applied: ${result.detail}`, 'warning')
                return
            }
        }

        // Refetch the pricelist to update the UI
        this.context_update(vnode)
        events.emit('spl:refetch')
    }

    view(vnode: m.Vnode<UpsertSpliAttrs, {}>): m.Children {
        const data = $s.context.data ? $s.context.data : {} as any
        let invalid = invalid_fields(this.$v)

        const product_status = {
            icon: this.data.linked.product ? 'link' : 'linkOff',
            text: (() => {
                const linked = [] as any
                if ($s.context.loading) return 'Loading...'
                if (this.data.linked.product) linked.push('Product')
                if (this.data.linked.specs) linked.push('Specs')
                if (this.data.linked.gtin) linked.push('GTIN')

                if (linked.length) return `Linked: ${linked.join(', ')}`
                return 'Unlinked Product'
            })(),
            type: (() => {
                if ($s.context.loading) return 'default'
                if (this.data.linked.product && this.data.linked.specs && this.data.linked.gtin) return 'success'
                if (this.data.linked.product && this.data.linked.specs) return 'success'
                if (this.data.linked.product) return 'info'
                return 'default'
            })(),
        }

        return <div className="c-upsert-spli">

            <div className={classes('spli-status', product_status.type)}>
                {$s.context.loading ? <Spinner/> : <Icon
                    name={product_status.icon}
                    type={product_status.type}
                />}
                <span>{product_status.text}</span>
            </div>

            <div className="context-well">

                <div className="field-group">
                    <FieldText
                        disabled={this.data.linked.gtin || $s.context.loading}
                        help={(() => {
                            if (this.data.linked.product || this.data.linked.gtin) {
                                return 'Good, we know this product already!'
                            }

                            return 'Do you REALLY need to create a new product?'
                        })()}
                        label="Product name"
                        placeholder="Product name"
                        search={{
                            linked: !!$s.context.data.product_artkey,
                            onreset: () => {
                                // Clear the reference to an existing product; we're about to create a new one.
                                merge_deep($s.context.data, {
                                    product_artkey: null,
                                    product_category_artkey: null,
                                })
                                merge_deep(this.data.linked, {product: false, gtin: false, specs: false})
                            },
                            onsuggestion: async(suggestion) => {
                                this.data.bottles.length = 0
                                // Clear the reference to existing specs; a newly selected product comes with its own specs.
                                merge_deep($s.context.data, {alcohol_percentage: null, bottle_artkey: null, refill_status: null, volume: null})
                                reset_validation(this.$v)
                                await this.context_from_product(suggestion)
                            },
                            provider: async(filter_text) => {
                                if (filter_text.length <= 3) return []
                                const {result} = await api.get(`discover/products?search_terms=${filter_text}`) as any
                                return result.map((i) => ({value: i.name, label: i.name, ...i}))
                            },
                        }}
                        model={[data, ['product_name']]}
                        validation={this.$v.product_name}
                    />

                    <div className="field gtin-field">
                        <FieldText
                            disabled={this.data.linked.gtin || $s.context.loading}
                            help="Product & specs from GTIN"
                            label="GTIN (Bottle)"
                            model={[data, 'bottle_gtin_code']}
                            validation={this.$v.bottle_gtin_code}
                        >
                            <Button
                                disabled={!this.data.linked.gtin && !data.bottle_gtin_code || this.$v.bottle_gtin_code._invalid}
                                icon={this.data.linked.gtin ? 'linkOff' : 'link'}
                                onclick={() => {
                                    if (this.data.linked.gtin) {
                                        data.bottle_gtin_code = null
                                        this.data.linked.gtin = false
                                    } else if (data.bottle_gtin_code) {
                                        this.context_from_bottle_gtin(data)
                                    }
                                }}
                                tip={() => this.data.linked.gtin ? 'Clear bottle GTIN' : 'Match product & specs from bottle GTIN'}
                                type={this.data.linked.gtin ? 'danger' : 'info'}
                            />
                        </FieldText>
                    </div>
                </div>

                <div className="field-group">
                    <FieldSelect
                        disabled={this.data.linked.product || $s.context.loading}
                        help={this.data.linked.product ? 'Category from product' : 'Select a product category'}
                        label="Category"
                        model={[data, 'product_category_artkey']}
                        options={model.product_categories.map((category: any) => ({
                            label: category.name,
                            value: category.artkey,
                        }))}
                        placeholder={'Product category...'}
                        validation={this.$v.product_category_artkey}
                    />
                    <FieldSelect
                        disabled={this.data.linked.gtin || $s.context.loading}
                        help={this.data.linked.specs ? 'Good! Use existing specs if possible' : 'Do you REALLY need new specs?'}
                        label="Specs"
                        model={[data, 'bottle_artkey']}
                        onchange={(bottle_artkey) => {
                            // Make sure specs validation isn't triggered yet; no need for validation warnings to begin with.
                            reset_validation(this.$v)
                            const bottle = this.data.bottles.find((i) => i.artkey === bottle_artkey)
                            if (bottle) {
                                merge_deep(data, {
                                    alcohol_percentage: bottle.alcohol_percentage,
                                    refill_status: bottle.refill_status,
                                    volume: bottle.volume,
                                })
                                this.data.linked.specs = true
                            } else {
                                this.data.linked.specs = false
                                merge_deep(data, {alcohol_percentage: null, refill_status: null, volume: null})
                            }
                        }}
                        options={this.data.bottles.map((bottle: any) => ({
                            label: to_specs(bottle, $s.identity.user.decimal_locale),
                            value: bottle.artkey,
                        }))}
                        placeholder="New Specs..."
                    />
                </div>

                <div className="field-group">
                    <FieldText
                        disabled={this.data.linked.specs || this.data.linked.gtin || $s.context.loading}
                        label="Size cl"
                        min={0}
                        model={[data, 'volume']}
                        step="0.1"
                        type="number"
                        validation={this.$v.volume}
                    />

                    <FieldText
                        disabled={this.data.linked.specs || this.data.linked.gtin || $s.context.loading}
                        label="Alcohol %"
                        max={100}
                        min={0}
                        model={[data, 'alcohol_percentage']}
                        step="0.1"
                        type="number"
                        validation={this.$v.alcohol_percentage}
                    />

                    <FieldSelect
                        disabled={this.data.linked.specs || this.data.linked.gtin || $s.context.loading}
                        label="Refill status"
                        model={[data, 'refill_status']}
                        options={$m.data.bottle_refill_statusses.map((i: string) => ({
                            value: i,
                            label: i,
                        }))}
                        placeholder="select..."
                        validation={this.$v.refill_status}
                    />
                </div>
            </div>

            <div className="fieldset">
                <div className="field-group">

                    <div className="field-merge">
                        <Button
                            className="mt-3"
                            icon={this.data.per_case ? 'case' : 'bottle'}
                            onclick={async() => {
                                this.data.per_case = !this.data.per_case

                                if (this.data.per_case) {
                                    if (data.number_of_bottles) {
                                        data.number_of_cases = Math.ceil(data.number_of_bottles / data.number_of_bottles_per_case)
                                    }
                                    data.number_of_bottles = undefined
                                    if (data.price_per_bottle) {
                                        data.price_per_case = Number(data.price_per_bottle) * data.number_of_bottles_per_case
                                    }
                                    data.price_per_bottle = null
                                } else {
                                    if (data.number_of_cases) {
                                        data.number_of_bottles = data.number_of_cases * data.number_of_bottles_per_case
                                    }
                                    data.number_of_cases = undefined
                                    if (data.price_per_case) {
                                        data.price_per_bottle = Number(data.price_per_case) / data.number_of_bottles_per_case
                                    }
                                    data.price_per_case = null
                                }

                                await next_tick()
                                await next_tick()
                                m.redraw()
                            }}
                            tip={() => {
                                return `Use ${this.data.per_case ? 'cases' : 'bottles'} for the unit price`
                            }}
                            type="info"
                        />
                        <FieldText
                            className="mw-120"
                            disabled={$s.context.loading}
                            label={`Quantity (${this.data.per_case ? 'case' : 'bottle'})`}
                            min={0}
                            model={this.data.per_case ? [data, 'number_of_cases'] : [data, 'number_of_bottles']}
                            placeholder="..."
                            type="number"
                        />
                    </div>

                    <FieldText
                        disabled={$s.context.loading}
                        label="Bottles per case"
                        min={1}
                        model={[data, 'number_of_bottles_per_case']}
                        onafterupdate={(value: number) => {
                            if (value) {
                                // Adjust the case price when the number of bottles per case changes.
                                if (this.data.per_case && data.price_per_case) {
                                    data.price_per_case = +(
                                        +data.price_per_case / this.data.previous_number_of_bottles_per_case * value
                                    ).toFixed(2)
                                }
                                this.data.previous_number_of_bottles_per_case = value
                            }
                        }}
                        type="number"
                        validation={this.$v.number_of_bottles_per_case}
                    />

                    <FieldMoney
                        change_currency={true}
                        currency={[data, 'currency']}
                        disabled={$s.context.loading}
                        label={`Price (${data.currency})`}
                        min={0}
                        model={this.data.per_case ? [data, 'price_per_case'] : [data, 'price_per_bottle']}
                        validation={this.data.per_case ? this.$v.price_per_case : this.$v.price_per_bottle}
                    />
                </div>

                <div className="field-group">
                    <FieldSelect
                        disabled={$s.context.loading}
                        label="Country of Origin"
                        model={[data, 'country_of_origin']}
                        options={Object.entries(countries).map(([value, label]) => ({label, value}))}
                        placeholder="Select..."
                        validation={this.$v.country_of_origin}
                    />

                    <FieldSelect
                        disabled={$s.context.loading}
                        label="Customs status"
                        model={[data, 'customs_status']}
                        options={[
                            {label: 'T1', value: 'T1'},
                            {label: 'T2', value: 'T2'},
                        ]}
                        placeholder={'Select...'}
                        validation={this.$v.customs_status}
                    />

                </div>

                <div className="field-group">
                    <GiftBoxTypeDropDown
                        disabled={$s.context.loading}
                        model={[data, 'gift_box_type']}
                    />
                    <FieldText
                        disabled={$s.context.loading}
                        label="GTIN (Case)"
                        model={[data, 'case_gtin_code']}
                    />
                </div>

                <div className="field-group">
                    <IncotermsDropDown
                        disabled={$s.context.loading}
                        get_all_for_drop_down_response$={IncotermsDropDownData.incoterms()}
                        label="Incoterm"
                        model={[data, 'incoterm']}
                    />
                    <FieldText
                        disabled={$s.context.loading}
                        label="Location"
                        model={[data, 'incoterm_location']}
                    />
                </div>

                <div className="field-group">
                    <FieldText
                        disabled={true}
                        label="Cases per pallet"
                        model={[data, 'cases_per_pallet']}
                    />
                    <FieldText
                        disabled={true}
                        label="Cases per pallet layer"
                        model={[data, 'cases_per_pallet_layer']}
                    />
                </div>

                <div className="field-group">
                    <FieldText
                        disabled={$s.context.loading}
                        label="Availability status"
                        model={[data, 'availability_status']}
                    />
                    <FieldText
                        disabled={$s.context.loading}
                        label="Aux info"
                        model={[data, 'aux_info']}
                        oninput={(value: string | null) => data.aux_info = value}
                    />
                </div>

                <ButtonGroup>
                    <Button
                        disabled={invalid.length}
                        icon="save"
                        onclick={() => this.upsert_item(vnode)}
                        text={(() => {
                            if (vnode.attrs.source === 'sourceline') {
                                return 'Resolve Sourceline'
                            } else {
                                if (data.artkey) {
                                    return 'Update Pricelist Item'
                                }
                                return 'Add Pricelist Item'
                            }
                        })()}
                        tip={() => invalid_fields_format(invalid_fields(this.$v), 'tip')}
                        type="success"
                    />
                </ButtonGroup>
            </div>
        </div>
    }
}
