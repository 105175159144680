import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {
    displayable_float,
    format_iso_to_date,
    format_iso_to_date_time,
    format_money,
    titleize,
} from '@bitstillery/common/lib/format'
import {to_specs} from '@bitstillery/common/models/item'
import {
    Amount,
    CellMargin,
    Country,
    FieldMoney,
    FieldSelect,
    FieldText,
    Spinner,
    Tippy,
} from '@bitstillery/common/components'
import {api, notifier} from '@bitstillery/common/app'

import {accountIcon} from '@/accounts'
import {AddItemToSalesOrder, SalesOrderAttr} from '@/sales_orders/components/add_item_to_sales_order'
import {AddItemToSalesOrderClassic} from '@/sales_orders/components/add_item_to_sales_order_classic'
import {$s} from '@/app'
import {SelectionEnabledComponent} from '@/components/selection_controller'
import {NumberInput} from '@/components/input_numbers'
import {CancelButton, DangerButton, DefaultButton, SuccessButton} from '@/components/buttons'
import {Link} from '@/components/discover'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {CaseInfoPanel} from '@/components/market_info/case_info_panel'
import {OfferHistory} from '@/components/market_info/offer_history'
import {CaseLabels} from '@/components/labels'
import {ClipboardCopy} from '@/components/clipboard'
import {
    calculate_margin_percentage,
    calculate_number_of_bottles,
    calculate_total_ex_duty_soi,
    calculate_total_margin_soi,
    calculate_total_value_soi,
    GetFastSalesOrderWithItemsResponse,
    SalesApi,
    SalesOrderItem,
    SalesOrderStatus,
    SellableBottlesItem,
    SellableBottlesWithItems,
} from '@/factserver_api/sales_api'
import {GetSellableBottlesResponse, SellableItemsByBottleResponse} from '@/factserver_api/fact2server_api'

export interface SalesOrderItemsAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    sales_order_changed: () => void
    register_selection_enabled_component: (component: SelectionEnabledComponent) => void
}

export interface SalesOrderItemAttrs {
    sales_order: GetFastSalesOrderWithItemsResponse
    sales_order_item: SalesOrderItem
    sales_order_changed: () => void
    register_selection_enabled_component: (component: SelectionEnabledComponent) => void
    selected_soi_artkeys: number[]
}

/**
 * Display a sales order item.
 */
export class SalesOrderItemView extends MithrilTsxComponent<SalesOrderItemAttrs> {
    sales_api = new SalesApi()
    sales_order_item_changed: () => void
    is_performing_api_call = false
    is_editing = false
    is_showing_details = false

    sellable_bottles: SellableBottlesWithItems[] = []
    sellable_items_for_product: SellableBottlesItem[] = []

    soi: SalesOrderItem

    // The item artkey currently saved on the sales order item.
    initial_item_artkey: number

    constructor(vnode: m.Vnode<SalesOrderItemAttrs>) {
        super()
        this.sales_order_item_changed = vnode.attrs.sales_order_changed
        this.soi = vnode.attrs.sales_order_item
    }

    cancel_edit(): void {
        this.is_editing = false
        this.sales_order_item_changed()
    }

    delete_sales_order_item(sales_order_item_artkey: number): void {
        this.is_performing_api_call = true
        this.sales_api.delete_sales_order_item(sales_order_item_artkey).subscribe({
            next: () => {
                this.is_performing_api_call = false
                this.sales_order_item_changed()
            },
            error: () => {
                this.is_performing_api_call = false
            },
        })
    }

    format_items_for_drop_down(vnode: m.Vnode<SalesOrderItemAttrs>) {
        return this.sellable_items_for_product.map((item: SellableBottlesItem) => {
            const parts = [
                item.lot ? `${item.lot}` : '',
                `Costs: EUR ${item.euro_was_bought_for}`,
                item.relation_sales_price
                    ? `Target: ${format_money(
                        +item.relation_sales_price,
                        vnode.attrs.sales_order.was_sold_in,
                        $s.identity.user.decimal_locale,
                    )}`
                    : '',
                item.case.number_of_bottles ? `${item.case.number_of_bottles} btl/cs` : '',
                `${item.number_of_cases_available || 0} cs available`,
            ]

            if (item.case.no_eu_address) {
                parts.push('No EU address')
            }

            return {
                label: parts.filter((part) => part).join(' - '),
                value: item.artkey,
            }
        })
    }

    save_edit(soi: SalesOrderItem): void {
        this.is_performing_api_call = true
        this.sales_api
            .update_sales_order_item(
                soi.artkey,
                soi.item.artkey,
                soi.number_of_cases,
                +soi.price_per_case,
                soi.description,
                soi.portal_comment,
            )
            .subscribe({
                next: () => {
                    this.is_performing_api_call = false
                    this.is_editing = false
                    this.sales_order_item_changed()
                },
                error: () => {
                    this.is_performing_api_call = false
                },
            })
    }

    async select_other_bottle(soi: SalesOrderItem, sales_order: GetFastSalesOrderWithItemsResponse, bottle_artkey: number) {
        const sellable_bottle = this.sellable_bottles.find(
            (bottle: SellableBottlesWithItems) => bottle.artkey === bottle_artkey,
        )
        if (!sellable_bottle) {
            return
        }
        const items = await this._fetch_sellable_items_for_bottle(bottle_artkey, sales_order)
        sellable_bottle.items = items
        this.sellable_items_for_product = items
        // select bottle and first item in model.
        soi.item.case.bottle.artkey = bottle_artkey
        if (sellable_bottle.items.length > 0) {
            this.select_other_item(soi, sellable_bottle.items[0].artkey)
        }
        m.redraw()
    }

    select_other_item(soi: SalesOrderItem, item_artkey: number): void {
        const selected_item = this.sellable_items_for_product.find((item) => item.artkey === item_artkey)
        soi.item.artkey = item_artkey
        /*
         Updating the underlying response to reflect changes in margin. The item-model becomes inconsistent here.
         After a cancel or a confirm it is reloaded, and all will be fine.
         */
        if (selected_item) {
            soi.item.case.number_of_bottles = selected_item.case.number_of_bottles
            soi.item.euro_was_bought_for = +selected_item.euro_was_bought_for
            if (
                selected_item.relation_sales_price &&
                +selected_item.relation_sales_price > +soi.price_per_case
            ) {
                notifier.notify(
                    `Recommended price for this item is ${displayable_float(
                        selected_item.relation_sales_price,
                        2,
                        $s.identity.user.decimal_locale,
                    )}`,
                    'info',
                )
            }
        }
    }

    async start_edit(sales_order: GetFastSalesOrderWithItemsResponse, soi: SalesOrderItem) {
        this.is_editing = true
        const product_artkey = soi.item.case.bottle.product.artkey
        const bottle_artkey = soi.item.case.bottle.artkey
        this.initial_item_artkey = soi.item.artkey

        let bottles: GetSellableBottlesResponse[]
        let items: SellableItemsByBottleResponse[]

        await Promise.all([
            api.get<GetSellableBottlesResponse[]>(
                `discover/products/${product_artkey}/sellable-bottles?relation_artkey=${sales_order.supplier.artkey}&item_artkey=${soi.item.artkey}`,
            ),
            this._fetch_sellable_items_for_bottle(bottle_artkey, sales_order),
        ]).then(
            ([bottles_response, items_response]) => {
                [bottles, items] = [bottles_response.result, items_response]
                this.sellable_bottles = bottles.map((bottle) => {
                    return {
                        ...bottle,
                        items: [],
                    }
                })

                this.sellable_bottles.find((bottle) => {
                    if (bottle.artkey === bottle_artkey) {
                        bottle.items = items
                        this.sellable_items_for_product = items
                    }
                })

                m.redraw()
            },
        )
    }

    async _fetch_sellable_items_for_bottle(bottle_artkey: number, sales_order: GetFastSalesOrderWithItemsResponse): Promise<SellableItemsByBottleResponse[]> {
        let request_url = `discover/bottles/${bottle_artkey}/sellable-items/${sales_order.supplier.artkey}`
        request_url += `?sales_order_currency=${sales_order.was_sold_in}&item_artkey=${this.initial_item_artkey}`

        const {result: items} = await api.get<SellableItemsByBottleResponse[]>(request_url)
        return items
    }

    toggle_details(event: Event): void {
        if (event.target) {
            const closest = $(event.target).closest('.no-click')
            if (closest.length === 0) {
                this.is_showing_details = !this.is_showing_details
            }
        }
    }

    view(vnode: m.Vnode<SalesOrderItemAttrs>): m.Children {
        const soi = vnode.attrs.sales_order_item
        const sales_order = vnode.attrs.sales_order
        const _case = vnode.attrs.sales_order_item.item.case
        const eu_address_alert = _case.no_eu_address && _case.customs_status === 'T2' && sales_order.supplier.requires_eu_address
        let tr_class_name = calculate_total_margin_soi(soi, sales_order) < 0 ? 'danger' : eu_address_alert ? 'warning' : ''
        tr_class_name = this.is_editing ? `${tr_class_name} no-click` : tr_class_name
        return (
            <tbody className={'table-row'} key={soi.artkey}>
                <tr className={tr_class_name} onclick={(event: Event) => this.toggle_details(event)}>
                    <td>
                        {_case.bottle.product.name} <ClipboardCopy text={_case.bottle.product.name}/>
                        <span className={'help-block'}>{_case.bottle.product.category}</span>
                    </td>
                    <td className={'no-click'}>{soi.item.case.number_of_bottles}</td>
                    {!this.is_editing && <td>
                        <span key={soi.artkey}>
                            <div>
                                {to_specs(_case.bottle, $s.identity.user.decimal_locale)}
                                {' '}
                                {_case.gift_box_type}
                                {' '}
                                {_case.customs_status}
                            </div>
                            <CaseLabels
                                case={
                                    new Object({
                                        ...soi.item,
                                        ..._case,
                                    })
                                }
                                show_as_icons={true}
                            />
                        </span></td>}
                    {this.is_editing && (
                        <td colspan={4}>
                            <FieldSelect
                                model={[soi.item.case.bottle, 'artkey']}
                                onchange={(value: string) => this.select_other_bottle(soi, sales_order, +value)}
                                options={this.sellable_bottles.map((sellable_bottle) => ({
                                    label: to_specs(sellable_bottle, $s.identity.user.decimal_locale),
                                    value: sellable_bottle.artkey,
                                }))}
                            />
                        </td>
                    )}

                    {this.is_editing && (
                        <td colspan={3}>
                            <FieldSelect
                                model={[soi.item, 'artkey']}
                                onchange={(value: string) => this.select_other_item(soi, +value)}
                                options={this.format_items_for_drop_down(vnode)}
                            />
                        </td>
                    )}

                    {!this.is_editing && <td>
                        <Country country_code={soi.item.country_of_origin} type={'flag_with_country'}/>
                    </td>}
                    {!this.is_editing && (
                        <td>
                            <Link
                                href={`/purchase-orders/manage/${soi.item.purchase_order_artkey}?account=${soi.item.purchase_order_account_slug}`}
                            >
                                {soi.item.purchase_order_reference}{' '}
                                {accountIcon({
                                    slug: soi.item.purchase_order_account_slug,
                                    name: soi.item.purchase_order_account_name,
                                })}
                            </Link>
                        </td>
                    )}
                    {!this.is_editing && (
                        <td>
                            <Link href={`/stock/manage?search=${soi.item.lot}`}>
                                {soi.item.lot}{' '}
                                {soi.item.lot &&
                                accountIcon({
                                    slug: soi.item.account.slug,
                                    name: soi.item.account.name,
                                })}
                            </Link>
                            {soi.item.bottle_gtin_code && (
                                <small className={'help-block'}>GTIN: {soi.item.bottle_gtin_code}</small>
                            )}
                        </td>
                    )}
                    <td className={'price'}>
                        <Amount
                            amount={soi.item.was_bought_for}
                            currency={soi.item.was_bought_in}
                            rate={soi.item.bought_against_rate}
                            excise={soi.excise_per_case}
                        />
                        <span className={'help-block'}>
                            <div>
                                Costs:{' '}
                                <Amount
                                    amount={soi.item.euro_was_bought_for_plus_costs}
                                    currency={'EUR'}
                                    excise={soi.excise_per_case}
                                />
                            </div>
                        </span>
                    </td>

                    {!this.is_editing && <td className={'number'}>{soi.number_of_cases}</td>}
                    {this.is_editing && (
                        <td>
                            <NumberInput
                                value={soi.number_of_cases}
                                minimum={1}
                                oninput={(value: number) => (soi.number_of_cases = value)}
                            />
                        </td>
                    )}

                    <td className={'number'}>{calculate_number_of_bottles(soi)}</td>

                    {!this.is_editing && (
                        <td className={'price'}>
                            <Amount
                                amount={soi.price_per_case}
                                currency={sales_order.was_sold_in}
                                rate={sales_order.sold_against_rate}
                            />
                            {sales_order.includes_excise && (
                                <span className={'help-block'}>
                                        Duty:{' '}
                                    <Amount
                                        amount={soi.excise_per_case}
                                        currency={sales_order.was_sold_in}
                                        rate={sales_order.sold_against_rate}
                                    />
                                </span>
                            )}
                            {soi.was_first_sold_for && (soi.was_first_sold_for !== soi.price_per_case) && (
                                <span className={'help-block'}>
                                        First sold for:{' '}
                                    <Amount
                                        amount={soi.was_first_sold_for}
                                        currency={sales_order.was_sold_in}
                                        rate={sales_order.sold_against_rate}
                                    />
                                </span>
                            )}
                        </td>
                    )}
                    {this.is_editing && (
                        <td>
                            <FieldMoney
                                currency={[sales_order, 'was_sold_in']}
                                model={[soi, 'price_per_case']}
                                required={true}
                            />
                        </td>
                    )}
                    <td className={'price'}>
                        <Amount
                            amount={calculate_total_value_soi(soi)}
                            currency={sales_order.was_sold_in}
                            rate={sales_order.sold_against_rate}
                        />
                        {sales_order.includes_excise && (
                            <span className={'help-block'}>
                                Ex Duty:{' '}
                                <Amount
                                    amount={calculate_total_ex_duty_soi(soi)}
                                    currency={sales_order.was_sold_in}
                                    rate={sales_order.sold_against_rate}
                                />
                            </span>
                        )}
                    </td>
                    <td className={'price'}>
                        <Amount amount={calculate_total_margin_soi(soi, sales_order)} currency={'EUR'}/>
                    </td>
                    <td>
                        <CellMargin value={calculate_margin_percentage(soi, sales_order)}/>
                    </td>
                    <td>
                        {/* No status yet */}
                        {!sales_order.warehouse_status && <span className={'fas fa-spin'}/>}

                        {/* Loendersloot status and soi is matched. */}
                        {sales_order.warehouse_status && soi.loendersloot_outtake_item.artkey &&
                        <Tippy
                            content={`<strong>Match with "${soi.loendersloot_outtake_item.description}"</strong>
                                        <ul>
                                            <li>Loendersloot lot: ${soi.loendersloot_outtake_item.lot}</li>
                                            <li>Loendersloot article: ${soi.loendersloot_outtake_item.article_lo}</li>
                                            <li>Loendersloot quantity: ${soi.loendersloot_outtake_item.quantity}</li>
                                        </ul>`}
                            allowHTML={true}
                        >
                            {' '}
                            <span className={'fas fa-check'}/>
                        </Tippy>

                        }

                        {/* Loendersloot status and soi is not matched. */}
                        {sales_order.warehouse_status && !soi.loendersloot_outtake_item.artkey &&
                        <Tippy
                            content={'Sales order item is not matched with loendersloot outtake. Check the outtake.'}
                            allowHTML={true}
                        >
                            {' '}
                            <span className={'fas fa-not-equal bad-color'}>
                            </span>
                        </Tippy>
                        }
                    </td>
                    <td>
                        {format_iso_to_date(soi.created_from_portal_timestamp || '')}
                        <Tippy
                            content={`<strong>History</strong>
                                    <ul>
                                        <li>Created on: ${format_iso_to_date_time(soi.created_on)}</li>
                                        <li>Created in: ${
            soi.created_from_portal_timestamp ? 'Portal' : 'Discover'
            }</li>
                                        <li>Last updated on: ${format_iso_to_date_time(soi.was_last_updated_on)}</li>
                                        <li>Last updated by: ${titleize(
                soi.was_last_updated_by_discover_user.name || '-',
            )}</li>
                                        <li>Added from portal: ${format_iso_to_date_time(
                soi.created_from_portal_timestamp || '',
            )}</li>
                                        <li>Last updated in portal by: ${
            soi.was_last_updated_by_portal_user.name || '-'
            }</li>
                                    </ul>`}
                            allowHTML={true}
                        >
                            {' '}
                            <i class="far fa-clipboard"/>
                        </Tippy>
                    </td>
                    {!this.is_performing_api_call && (
                        <td>
                            <span>
                                {!this.is_editing &&
                                    !sales_order.is_part_of_buy_from_account &&
                                    (!sales_order.sent_pre_advice_to_loendersloot_on || sales_order.pre_advice_cancelled_on) &&
                                    sales_order.combined_status !== SalesOrderStatus.INVOICED && (
                                    <div className={'pull-right'}>
                                        <DefaultButton
                                            icon_class={'glyphicon glyphicon-pencil'}
                                            onclick={() => this.start_edit(sales_order, soi)}
                                        />
                                        <DangerButton
                                            icon_class={'glyphicon glyphicon-remove'}
                                            onclick={() => this.delete_sales_order_item(soi.artkey)}
                                        />
                                    </div>
                                )}
                                {this.is_editing && (
                                    <div className={'pull-right'}>
                                        <CancelButton onclick={() => this.cancel_edit()}/>
                                        <SuccessButton
                                            icon_class={'glyphicon glyphicon-ok'}
                                            title={' '}
                                            onclick={() => this.save_edit(soi)}
                                        />
                                    </div>
                                )}
                            </span>
                        </td>
                    )}
                    {this.is_performing_api_call && (
                        <td>
                            <div style={'width: 81px'}>
                                <Spinner/>
                            </div>
                        </td>
                    )}
                </tr>
                {!this.is_editing && soi.description && (
                    <tr>
                        <td colspan={'100%'}>
                            <label className={'col-sm-2'}>Description</label>
                            <div className={'col-sm-4'}>{soi.description}</div>
                        </td>
                    </tr>
                )}
                {this.is_editing && (
                    <tr>
                        <td colspan={'100%'}>
                            <label className={'col-sm-2'}>Description</label>
                            <div className={'col-sm-4'}>
                                <FieldText
                                    model={[soi, 'description']}
                                />
                            </div>
                        </td>
                    </tr>
                )}
                {soi.portal_comment && (
                    <tr>
                        <td colspan={'100%'}>
                            <label className={'col-sm-2'}>
                            Portal comment
                                {!this.is_editing && <span className={'fa fa-comments'}/>}
                                {this.is_editing && (
                                    <DangerButton
                                        icon_class={'glyphicon glyphicon-remove'}
                                        onclick={() => (soi.portal_comment = '')}
                                    />
                                )}
                            </label>
                            <div className={'col-sm-4'}>{soi.portal_comment}</div>
                        </td>
                    </tr>
                )}
                {this.is_showing_details && (
                    <tr className={'well'}>
                        <td colspan={'100%'}>
                            <div className="well-container">
                                <CaseInfoPanel
                                    bottle_artkey={soi.item.case.bottle.artkey}
                                    customs_status={soi.item.case.customs_status}
                                    with_excise={sales_order.includes_excise}
                                    bottle_mode={sales_order.supplier.price_preference === 'bottle'}
                                />
                                <OfferHistory
                                    bottle_artkey={soi.item.case.bottle.artkey}
                                    customs_status={soi.item.case.customs_status}
                                />
                                <BottleSalesOrders
                                    bottle_artkey={soi.item.case.bottle.artkey}
                                    case_artkey={soi.item.case.artkey}
                                    current_client_artkey={sales_order.supplier.artkey}
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        )
    }
}

/**
 * Shows the sales order items of a sales order
 */
export class SalesOrderItems extends MithrilTsxComponent<SalesOrderItemsAttrs> {

    sales_order_as_function_view(vnode: m.Vnode<SalesOrderItemsAttrs>): () => SalesOrderAttr {
        const sales_order = vnode.attrs.sales_order
        return () => {
            return {
                artkey: () => sales_order.artkey,
                includes_excise: () => sales_order.includes_excise,
                was_sold_in: () => sales_order.was_sold_in,
                supplier: () => {
                    return {
                        artkey: () => sales_order.supplier.artkey,
                        name: () => sales_order.supplier.name,
                        currency: () => sales_order.supplier.currency,
                        portal_customs_visibility: () => sales_order.supplier.portal_customs_visibility,
                    }
                },
            }
        }
    }

    view(vnode: m.Vnode<SalesOrderItemsAttrs>): m.Children {
        return (
            <span>
                {!vnode.attrs.sales_order.is_part_of_buy_from_account && !vnode.attrs.sales_order.pre_advice_reference && ![SalesOrderStatus.CANCELLED, SalesOrderStatus.INVOICED].includes(vnode.attrs.sales_order.combined_status) && (
                    <AddItemToSalesOrder
                        sales_order_item_added={vnode.attrs.sales_order_changed}
                        sales_order={this.sales_order_as_function_view(vnode)}
                    />
                )}
                {!vnode.attrs.sales_order.is_part_of_buy_from_account && !vnode.attrs.sales_order.pre_advice_reference && ![SalesOrderStatus.CANCELLED, SalesOrderStatus.INVOICED].includes(vnode.attrs.sales_order.combined_status) && (
                    <AddItemToSalesOrderClassic
                        sales_order_item_added={vnode.attrs.sales_order_changed}
                        sales_order={this.sales_order_as_function_view(vnode)}
                    />
                )}
                {vnode.attrs.sales_order.sales_order_items.length > 0 && (
                    <table className={'table search-table clickable'}>
                        <thead className={'thead-default'}>
                            <tr>
                                <th>Product</th>
                                <th>Btl / cs</th>
                                <th>Specs</th>
                                <th>Country of Origin</th>
                                <th>P.O. #</th>
                                <th>Lot #</th>
                                <th className={'price'}>Purchase / cs</th>
                                <th className={'number'}>Cases</th>
                                <th className={'number'}>Bottles</th>
                                <th className={'price'}>Price / cs</th>
                                <th className={'price'}>Total value</th>
                                <th className={'price'}>Margin (€)</th>
                                <th>Margin %</th>
                                <th>Outtake status</th>
                                <th>Added from Portal</th>
                                <th></th>
                            </tr>
                        </thead>
                        {/* Display the sales_order items.*/}
                        {vnode.attrs.sales_order.sales_order_items.map((soi) => (
                            <SalesOrderItemView
                                sales_order={vnode.attrs.sales_order}
                                sales_order_item={soi}
                                sales_order_changed={vnode.attrs.sales_order_changed}
                                selected_soi_artkeys={[]}
                                register_selection_enabled_component={
                                    vnode.attrs.register_selection_enabled_component
                                }
                            />
                        ))}
                    </table>
                )}
            </span>
        )
    }
}
