import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {from, fromEvent, Observable} from 'rxjs'
import {Icon} from '@bitstillery/common/components'
import {classes, random_string} from '@bitstillery/common/lib/utils'
import {catchError, debounceTime, filter, map, mergeAll, switchMap} from 'rxjs/operators'

export interface SearchBarControl {
    set_and_submit_search_text: (new_search_text: string, do_not_submit_search?: boolean) => void
    clear_search_text: () => void
    submit_search_text(): void
}

export interface SearchBarProps {

    placeholder: string
    default_search_text?: string
    disabled?: boolean
    help?: string
    model?: [Object, string]
    on_submit: (search_text: string) => unknown
    required?: boolean
    search_bar_controller?: (search_bar_control: SearchBarControl) => unknown
    suggestions?: string[]
    validation?: any

    on_get_suggestions$?: (filter_text: string) => Observable<string>
    on_get_suggestions: (filter_text: string) => string[] | [][]
}

export class SearchBar extends MithrilTsxComponent<SearchBarProps> implements SearchBarControl {
    search_text = ''
    last_submitted_search_text = ''
    search_component_id = random_string(8)
    matched_suggestions: string[] = []
    highlighted_index = -1
    do_show_suggestions = true
    on_get_suggestions$: (filter_text: string) => Observable<string> = (_) => from([])

    constructor(vnode: m.Vnode<SearchBarProps>) {
        super()

        this.on_submit = vnode.attrs.on_submit

        this.search_text = vnode.attrs.default_search_text || ''
        this.on_get_suggestions$ = vnode.attrs.on_get_suggestions$ || (() => from([]))
    }

    oncreate(vnode: m.Vnode<SearchBarProps>): void {
        if (vnode.attrs.search_bar_controller) {
            vnode.attrs.search_bar_controller(this)
        }

        const input_element = document.getElementById(this.search_component_id) as HTMLInputElement

        // the main auto-suggestion loop. Fetch chars from input_element, debounce, etc.
        fromEvent<InputEvent>(input_element, 'input')
            .pipe(
                filter((input_event) => input_event !== null),
                map((input_event) => (input_event.target as HTMLInputElement).value),
                /* Only show suggestions if length input text > 2. */
                filter((e) => e.length > 2),
                debounceTime(250),
                /* Only show suggestions if user did not press enter (If enter pressed, search immediately). */
                filter(() => {
                    const do_show_suggestions = this.do_show_suggestions // this might be false, if enter is pressed.
                    this.do_show_suggestions = true
                    this.matched_suggestions = []
                    return do_show_suggestions
                }),
                /* Create a map with values that match input. */
                switchMap(
                    (value) => {
                        if (vnode.attrs.on_get_suggestions$) {
                            return this.on_get_suggestions$(value).pipe(catchError(() => from([]))) // whenever error use [] as default.
                        }
                        else if (vnode.attrs.on_get_suggestions) {
                            return from(vnode.attrs.on_get_suggestions(value)).pipe(mergeAll())
                        }
                        else {
                            return from([])
                        }
                    },
                ),
            )
            .subscribe({
                next: (value: string | []) => {
                    this.matched_suggestions.push(value)
                    m.redraw()
                },
            })

        // register several listeners on the keydown event, this way we make escape, enter, up and down work.
        const keydown$ = fromEvent<KeyboardEvent>(input_element, 'keydown')

        keydown$
            .pipe(
                map((event) => event.key),
                filter((key) => key === 'ArrowDown'),
            )
            .subscribe(() => {
                this.highlighted_index = Math.min(this.highlighted_index + 1, this.matched_suggestions.length)
                m.redraw()
            })

        keydown$
            .pipe(
                map((event) => event.key),
                filter((key) => key === 'ArrowUp'),
            )
            .subscribe(() => {
                this.highlighted_index = Math.max(this.highlighted_index - 1, -1)
                m.redraw()
            })

        /* If enter of Tab is pressed and a suggestion is selected, select this suggestion. */
        keydown$
            .pipe(
                map((event) => event.key),
                filter((key) => key === 'Enter' || key === 'Tab'),
                filter(() => this.highlighted_index >= 0 && this.highlighted_index < this.matched_suggestions.length),
            )
            .subscribe(() => {
                this.select_suggestion(this.highlighted_index)
            })

        /** Stop propagation on enter and prevent defaults for inclusion of this object in Forms. */
        keydown$
            .pipe(
                filter((event) => event.key === 'Enter'),
            )
            .subscribe((enter_event) => {
                enter_event.stopPropagation()
                enter_event.preventDefault()
            })

        /* If any key is pressed that is not special, clear the do_show_suggestions flag. */
        keydown$
            .pipe(
                map((event) => event.key),
                filter((key) => key !== 'Enter'),
                filter((key) => key !== 'Tab'),
            )
            .subscribe(() => {
                this.do_show_suggestions = true
            })

        /* If suggestions are not visible and Enter/Tab is pressed, search for it, clear suggestions and make sure suggestions do not pop up. */
        keydown$
            .pipe(
                map((event) => event.key),
                filter((key) => key === 'Enter' || key === 'Tab'),
                filter(() => this.highlighted_index < 0),
            )
            .subscribe(() => {
                this.do_show_suggestions = false
                this.submit_search_text()
                this.clear_suggestions()
            })

        keydown$
            .pipe(
                map((event) => event.key),
                filter((key) => key === 'Escape'),
            )
            .subscribe(() => {
                this.clear_suggestions()
            })
    }

    set_and_submit_search_text(search_text: string, do_not_submit_search?: boolean): void {
        this.search_text = search_text
        if (!do_not_submit_search) {
            this.submit_search_text()
        }
    }

    clear_search_text(): void {
        this.search_text = ''
    }

    submit_search_text(): void {
        if (typeof this.on_submit === 'function') {
            this.on_submit(this.search_text)
        }
    }

    clear_suggestions(): void {
        this.matched_suggestions = []
        this.highlighted_index = -1
        m.redraw()
    }

    select_suggestion(index: number): void {
        if (index >= 0 && index < this.matched_suggestions.length) {
            this.search_text = this.matched_suggestions[index]
            this.clear_suggestions()
        }
    }

    over_suggestion = (event: MouseEvent): void => {
        const target_id = (event.target as HTMLInputElement).id
        this.highlighted_index = +target_id.split('-')[1]
    }

    update_search_text = (input_event: InputEvent): void => {
        if (input_event.target) this.search_text = (input_event.target as HTMLInputElement).value
    }

    view(vnode: m.Vnode<SearchBarProps>): m.Children {
        const validation = vnode.attrs.validation
        if (validation && vnode.attrs.label) {validation.description = vnode.attrs.label}
        const invalid = validation ? validation._invalid : false
        this.on_submit = vnode.attrs.on_submit

        if (vnode.attrs.matched_suggestions) {
            if (vnode.attrs.show_suggestions) {
                this.matched_suggestions = vnode.attrs.matched_suggestions
            } else {
                this.matched_suggestions = []
            }
        }
        return (
            <div className={classes('c-search-bar', 'field', vnode.attrs.className, {
                disabled: vnode.attrs.disabled,
                valid: validation && !invalid,
                invalid: validation && invalid && validation?.dirty,
            })}>
                {vnode.attrs.label && <label>{vnode.attrs.label}</label>}
                <div className="control">
                    <input
                        autocomplete="off"
                        id={this.search_component_id}
                        type="text"
                        disabled={vnode.attrs.disabled}
                        value={typeof vnode.attrs.model !== 'undefined' ? vnode.attrs.model[0][vnode.attrs.model[1]] : this.search_text}
                        oninput={(e) => {
                            if (vnode.attrs.validation) {
                                vnode.attrs.validation.dirty = true
                            }
                            if (typeof vnode.attrs.model !== 'undefined') {
                                vnode.attrs.model[0][vnode.attrs.model[1]] = e.target.value
                                if (vnode.attrs.oninput) {
                                    vnode.attrs.oninput(e.target.value)
                                }
                            } else {
                                this.update_search_text(e)
                            }
                        }}
                        required={vnode.attrs.required}
                        placeholder={vnode.attrs.placeholder}
                    />

                    {this.matched_suggestions.length > 0 && (
                        <div className={'autocomplete-suggestions'}>
                            {this.matched_suggestions.map((suggestion, index) => (
                                <div
                                    id={`suggestion-${index}`}
                                    onclick={(e) => {
                                        const target_id = e.target.id
                                        const index = +target_id.split('-')[1]
                                        this.search_text = this.matched_suggestions[index]

                                        this.submit_search_text()
                                        this.clear_suggestions()
                                    }}
                                    onmouseover={this.over_suggestion}
                                    className={
                                        index === this.highlighted_index
                                            ? 'autocomplete-suggestion highlighted'
                                            : 'autocomplete-suggestion'
                                    }
                                >
                                    {suggestion}
                                </div>
                            ))}
                        </div>
                    )}

                    <button
                        tabindex="-1"
                        type="button"
                        disabled={vnode.attrs.disabled}
                        className={'btn btn-default'}
                        onclick={() => {
                            this.search_text = ''
                            if (typeof vnode.attrs.model !== 'undefined') {
                                vnode.attrs.model[0][vnode.attrs.model[1]] = ''
                                if (vnode.attrs.oninput) {
                                    vnode.attrs.oninput('')
                                }
                            }
                            this.submit_search_text()
                            if (vnode.attrs.onclear) vnode.attrs.onclear()
                        }}
                    >
                        <Icon name="close" size="s" />

                    </button>
                    {vnode.children}
                </div>
                {(() => {
                    if (invalid && validation?.dirty) {
                        return <div className="help validation">{typeof invalid.message === 'function' ? invalid.message() : invalid.message}</div>
                    } else if (vnode.attrs.help) {
                        return <div className="help">{vnode.attrs.help}</div>
                    }
                })()}
            </div>
        )
    }
}
