import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date, format_money} from '@bitstillery/common/lib/format'
import {classes} from '@bitstillery/common/lib/utils'
import {Button, Spinner} from '@bitstillery/common/components'
import {to_specs} from '@bitstillery/common/lib/specs'
import {api, notifier} from '@bitstillery/common/app'

import {EditAssist} from '../components/form'
import {Link} from '../components/discover'

import {ItemEditor} from './components/item_editor'

import {
    HiddenInformation,
    HiddenStockItemHelper,
} from '@/purchase_orders/view_purchase_order/hidden_information'
import {GetItemResponse} from '@/factserver_api/fact2server_api'
import {CaseLabels} from '@/components/labels'

type SelectableMainTab = 'item-editor' | 'hidden-information'

interface ItemEditUrlParams {
    selected_tab?: SelectableMainTab
}

export default class ItemEdit extends MithrilTsxComponent<unknown> {
    item: GetItemResponse | null = null
    edit_assist: EditAssist
    is_loading = false
    selected_tab: SelectableMainTab = 'item-editor'

    constructor() {
        super()
        this.edit_assist = new EditAssist(m.route)
    }

    async oncreate() {
        if (!this.edit_assist.artkey) {
            notifier.notify('Invalid route supplied. Cannot find the stock item', 'danger')
            m.route.set('/stock')
        }

        const params = m.route.param() as ItemEditUrlParams
        if (params.selected_tab) {
            this.selected_tab = params.selected_tab
        }
    }

    async oninit() {
        const {result} = await api.get<GetItemResponse>(`discover/items/${this.edit_assist.artkey}`)
        this.item = result
        this.edit_assist.reference = result.reference
        m.redraw()
    }

    update_url_params() {
        let route = m.route.get()
        if (route.includes('?')) {
            route = route.split('?')[0]
        }

        const params: ItemEditUrlParams = {
            selected_tab: this.selected_tab,
        }
        m.route.set(route, params)
    }

    update_selected_tab(selected_tab: SelectableMainTab): void {
        this.selected_tab = selected_tab
        this.update_url_params()
    }

    view() {
        if (!this.item) {
            return <div className="view"><Spinner/></div>
        }
        return (
            <div className="c-stock-item-edit view">
                <div className="btn-toolbar">
                    <Button
                        active={false}
                        icon="back"
                        link={`/stock/manage/${this.edit_assist.reference}`}
                        variant="toggle"
                    />
                </div>

                <div className="c-tabs">
                    <ul className="nav nav-tabs">
                        <li
                            className={classes('nav-link', {
                                active: this.selected_tab === 'item-editor',
                            })}
                            onclick={() => this.update_selected_tab('item-editor')}
                        >
                            <a>Item editor</a>
                        </li>
                        <li
                            className={classes('nav-link', {
                                active: this.selected_tab === 'hidden-information',
                            })}
                            onclick={() => this.update_selected_tab('hidden-information')}
                        >
                            <a>Hidden information</a>
                        </li>
                    </ul>
                    {this.selected_tab === 'hidden-information' &&
                        <HiddenInformation hidden_information_helper={new HiddenStockItemHelper(this.item.artkey)}/>
                    }
                    {this.selected_tab === 'item-editor' &&
                        <div className="c-stock-item-editor">

                            <table className={'table'}>
                                <thead className={'thead-default'}>
                                    <tr>
                                        <th>#</th>
                                        <th>Intake</th>
                                        <th>Warehouse</th>
                                        <th>Lot #</th>
                                        <th>Product</th>
                                        <th>Features</th>
                                        <th className={'price'}>Purchase / cs</th>
                                        <th className={'price'}>Cost / cs</th>
                                        <th className={'number'}>Stock</th>
                                        <th>Customs</th>
                                    </tr>
                                </thead>
                                <tbody className={'table-row'}>
                                    <tr>
                                        <td>
                                            <Link
                                                target="_blank"
                                                href={`/purchase-orders/manage/${this.item.purchase_order_item.purchase_order.artkey}`}
                                            >
                                                {this.item.purchase_order_item.purchase_order.reference}
                                            </Link>
                                        </td>
                                        <td>{format_iso_to_date(this.item.entry_date)}</td>
                                        <td className={'ellipsis'}>
                                            <span>{this.item.warehouse?.name}</span>
                                        </td>
                                        <td>{this.item.lot}</td>
                                        <td className={'td-group'}>
                                            <span className="header">
                                                {this.item.case.bottle.product.name}
                                            </span>
                                            <span>{to_specs({
                                                bottle_alcohol_percentage: this.item.case.bottle.alcohol_percentage,
                                                bottle_refill_status: this.item.case.bottle.refill_status,
                                                bottle_volume: this.item.case.bottle.volume,
                                                case_number_of_bottles: this.item.case.number_of_bottles,
                                                case_gift_box_type: this.item.case.gift_box_type,
                                                case_customs_status: this.item.case.customs_status,
                                                case_tax_label: this.item.case.tax_label,
                                            })}</span>
                                        </td>
                                        <td>
                                            <CaseLabels
                                                show_as_icons={true}
                                                case={{
                                                    ...this.item.case,
                                                    remark: this.item.remark,
                                                    cases_per_pallet: this.item.cases_per_pallet,
                                                    serialized_item_tags: this.item.case.item_tag_artkeys.join(','),
                                                }}/>
                                        </td>
                                        <td className={'price'}>
                                            {format_money(this.item.purchase_order_item.was_bought_for, this.item.purchase_order_item.purchase_order.was_bought_in)}
                                        </td>
                                        <td className={'price'}>
                                            {format_money(this.item.purchase_order_item.was_bought_for_plus_costs, this.item.purchase_order_item.purchase_order.was_bought_in)}
                                        </td>
                                        <td style={'width: 100px'}>{this.item.number_of_cases_in_stock}</td>
                                        <td>{this.item.case.customs_status}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className={'panel panel-default'}>
                                <div className={'panel-body'}>
                                    <ItemEditor
                                        item={this.item}
                                        edit_assist={this.edit_assist}
                                    />
                                </div>
                            </div>
                        </div>}
                </div>
            </div>

        )
    }
}
