import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy, type_remove_watch_function, watch} from '@bitstillery/common/lib/proxy'
import {clear_url_params, get_url_params, set_url_params} from '@bitstillery/common/url_utils'
import {FieldSelect} from '@bitstillery/common/components'
import {DateTime} from 'luxon'
import {current_account_slug} from '@bitstillery/common/account/account'
import {notifier} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'
import {SelectOption} from '@bitstillery/common/types/field'

import {DefaultButton, PrimaryButton} from '../components/buttons'
import {EditableRelationList, RelationsSearch} from '../components/relation'

import {StatisticsTable} from './statistics/statistics_table'

import {SearchBarControl} from '@/components/collection/search_bar'
import {GetRelationResponse} from '@/factserver_api/fact2server_api'
import {UserDropDown} from '@/components/users'
import {DateRangePicker} from '@/market/statistics/statistics_date_range'
import {StatisticsApi, StatisticsRequest} from '@/market/statistics/statistics_api'

export enum RelationType {
    PURCHASE = 'Purchase',
    SALES = 'Sales',
}

export enum DateRangePickerSize {
    YEAR = 'Years',
    MONTH = 'Months',
    QUARTER = 'Quarter',
    CUSTOM = 'Custom',
}

export interface DateRange {
    date: string | null
    to_date: string | null
}

export interface StatisticsOptions {
    type: RelationType
    date_picker_size: DateRangePickerSize
    date_ranges: DateRange[]
    relation_artkey?: number
    relation_name?: string
    account_manager_artkey?: string
    selected_relations: GetRelationResponse[]
}

interface StatisticsUrlParams {
    type?: RelationType
    date_picker_size?: DateRangePickerSize
    relation_artkey?: number
    relation_name?: string
    account_manager_artkey?: string
    date_ranges?: string
}

export interface DifferenceKeys {
    left: string
    right: string
}

interface StatisticsData {
    options: StatisticsOptions
    request_params: StatisticsOptions | null
    totals: object | null
    statistics: object[] | null
    loading_statistics: boolean
    exporting_statistics: boolean
    difference_keys: DifferenceKeys
}

export class MarketStatistics extends MithrilTsxComponent<unknown> {
    search_bar_controller: SearchBarControl | null = null
    api: StatisticsApi = new StatisticsApi()
    data: StatisticsData

    watches: type_remove_watch_function[] = []

    constructor() {
        super()
        this.init(get_url_params<StatisticsUrlParams>())
    }

    init(params: StatisticsUrlParams = {}): void {
        this.data = proxy({
            options: {
                type: params.type ?? RelationType.SALES,
                date_picker_size: params.date_picker_size ?? DateRangePickerSize.YEAR,
                date_ranges: [],
                relation_name: params.relation_name,
                relation_artkey: params.relation_artkey,
                account_manager_artkey: params.account_manager_artkey,
                selected_relations: [],
            },
            difference_keys: {
                left: '',
                right: '',
            },
            request_params: null,
            totals: null,
            statistics: null,
            loading_statistics: false,
            exporting_statistics: false,
        })
        this.watches.push(watch(this.data.options.selected_relations, () => {
            this.data.options.relation_artkey = this.data.options.selected_relations[0]?.artkey
            this.data.options.relation_name = this.data.options.selected_relations[0]?.name
        }))

        if (this.search_bar_controller) {
            this.search_bar_controller.clear_search_text()
        }

        if (params.date_ranges) {
            params.date_ranges.split(',').forEach((date_range) => {
                const [date, to_date] = date_range.split('_')
                this.data.options.date_ranges.push({date: date, to_date: to_date})
            })

            this.search_statistics() // When date ranges are in url, search statistics
        } else {
            const current_year = DateTime.now()
            const last_year = current_year.minus({years: 1})

            this.data.options.date_ranges = [
                {date: last_year.startOf('year').toISODate(), to_date: last_year.endOf('year').toISODate()},
                {date: current_year.startOf('year').toISODate(), to_date: current_year.endOf('year').toISODate()},
            ]
        }
    }

    remove() {
        this.watches.forEach((watch) => watch())
    }

    _get_statistics_request(): StatisticsRequest {
        return JSON.parse(JSON.stringify({
            type: this.data.options.type,
            date_ranges: this.date_ranges_to_string(),
            account_slug: current_account_slug(),
            account_manager_artkey: this.data.options.account_manager_artkey,
            relation_artkey: this.data.options.relation_artkey,
        }))
    }

    search_statistics(): void {
        this.data.loading_statistics = true
        this.data.statistics = null
        this.save_request_params(this.data.options)

        this.api.get_statistics(this._get_statistics_request()).subscribe({
            next:(statistics_data) => {
                this.data.totals = statistics_data.totals
                this.data.statistics = statistics_data.rows
                this._set_diff_keys()
                this.data.loading_statistics = false
            },
            error: () => {
                this.data.loading_statistics = false
                notifier.notify('Something went wrong fetching the statistics', 'danger')
            },
        })
    }

    export_statistics(): void {
        this.data.exporting_statistics = true

        const filename = `Statistics ${this.data.options.type.toLowerCase()} export ${current_account_slug()}.xlsx`

        this.api.export_sales_statistics(this._get_statistics_request(), filename).subscribe({
            next: () => {this.data.exporting_statistics = false},
            error: () => {
                notifier.notify('Something went wrong downloading the export of the statistics', 'danger')
                this.data.exporting_statistics = false
            },
        })
    }

    save_request_params(options: StatisticsOptions): void {
        this.data.request_params = JSON.parse(JSON.stringify(options))

        // @ts-ignore
        this.update_url_params(this.data.request_params)
    }

    update_url_params(options: StatisticsOptions): void {
        const date_ranges = this.date_ranges_to_string()
        let params: StatisticsUrlParams = {
            type: options.type,
            date_picker_size: options.date_picker_size,
            relation_artkey: options.relation_artkey,
            relation_name: options.relation_name,
            account_manager_artkey: options.account_manager_artkey,
            date_ranges: date_ranges.join(','),
        }
        set_url_params(params)
    }

    date_ranges_to_string(): string[] {
        return this.data.options.date_ranges.filter((date_range) => {
            return date_range.date !== null && date_range.to_date !== null
        }).map((date_range: DateRange) => {
            return `${date_range.date}_${date_range.to_date}`
        })
    }

    _set_diff_keys() {
        if (this.data.request_params && this.data.request_params.date_ranges.length >= 2) {
            // Pre-select date range keys for difference columns
            const first_date_range = this.data.request_params.date_ranges[0]
            const second_date_range = this.data.request_params.date_ranges[1]
            this.data.difference_keys.left = `${first_date_range.date}_${first_date_range.to_date}`
            this.data.difference_keys.right = `${second_date_range.date}_${second_date_range.to_date}`
        }
    }

    view(): m.Children {
        let diff_options:SelectOption[] = []
        if (this.data.request_params && this.data.request_params.date_ranges.length > 2) {
            diff_options = this.data.request_params.date_ranges.map(
                (date_range) => {
                    return {
                        value: `${date_range.date}_${date_range.to_date}`,
                        label: formatted_date_string_for_size(date_range, this.data.request_params?.date_picker_size),
                    }
                },
            )
        }

        const loading = this.data.loading_statistics || this.data.exporting_statistics
        const any_range_filled: boolean = this.data.options.date_ranges.every((date_range) => {
            return date_range.date !== null && date_range.to_date !== null
        })

        return <div className={'c-statistics-body view'}>
            <div className={'c-filters'}>
                <div className={'filter-option'}>
                    <label>Type</label>
                    <div>
                        <DefaultButton
                            title={'Purchase'}
                            onclick={(): void => {this.data.options.type = RelationType.PURCHASE}}
                            additional_class={this.data.options.type === RelationType.PURCHASE ? 'btn-primary' : ''}
                            disabled={loading}
                        />
                        <DefaultButton
                            title={'Sales'}
                            onclick={(): void => {this.data.options.type = RelationType.SALES}}
                            additional_class={this.data.options.type === RelationType.SALES ? 'btn-primary' : ''}
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className={'filter-option'}>
                    <RelationsSearch
                        label={'Relation'}
                        disabled={this.data.options.account_manager_artkey || loading}
                        is_single_select={true}
                        selected_relations={this.data.options.selected_relations}
                        selected_relation_name={this.data.options.selected_relations[0]?.name}
                        search_bar_controller={(controller: SearchBarControl) =>
                            this.search_bar_controller = controller
                        }
                    />
                    <EditableRelationList selected_relations={this.data.options.selected_relations} />
                </div>

                <div className={'filter-option'}>
                    <label>Account Manager</label>
                    <div>
                        <UserDropDown
                            disabled={this.data.options.relation_artkey || loading}
                            model={[this.data.options, 'account_manager_artkey']}
                        />
                    </div>
                </div>
                <div className={'filter-option'}>
                    {this.data.options.date_picker_size && (
                        <DateRangePicker
                            options={this.data.options}
                            date_ranges={this.data.options.date_ranges}
                            label={'Date Ranges'}
                            allow_additional_ranges={true}
                            max_date_ranges={4}
                            disabled={loading}
                        />
                    )}
                </div>
            </div>
            <div className={'c-buttons'}>
                <DefaultButton
                    title={'Reset filters'}
                    onclick={(): void => {
                        this.init()
                        clear_url_params()
                    }}
                    disabled={loading}
                />
                <PrimaryButton
                    title={'Find statistics'}
                    onclick={(): void => {this.search_statistics()}}
                    disabled={this.data.loading_statistics || !any_range_filled}
                />
                <DefaultButton
                    title={'Export'}
                    icon_class={'fas fa-file-excel'}
                    disabled={this.data.exporting_statistics || !any_range_filled}
                    onclick={(): void => {this.export_statistics()}}
                />
            </div>
            {diff_options.length > 0 && [
                <label>Difference between</label>,
                <div className={'c-diff-select'}>
                    <FieldSelect
                        model={[this.data.difference_keys, 'left']}
                        options={diff_options}
                    />
                    <FieldSelect
                        model={[this.data.difference_keys, 'right']}
                        options={diff_options}
                    />
                </div>,
            ]}
            <p>
                The sales and purchasing data on this page also includes data from the previous accounts:
                <ul>
                    <li>MSI Purchase B.V. contains data from Moving Spirits International BV</li>
                    <li>E-Travel Retail International BV contains data from A2BC Travel Retail BV</li>
                </ul>
            </p>
            <div className={'c-statistics'}>
                {this.data.loading_statistics && (
                    <Spinner />
                )}
                {this.data.statistics && !this.data.loading_statistics && (
                    <StatisticsTable
                        loading={this.data.loading_statistics}
                        totals={this.data.totals}
                        statistics_data={this.data.statistics}
                        options={this.data.request_params}
                        difference_keys={this.data.difference_keys}
                    />
                )}
            </div>
        </div>
    }
}

export function formatted_date_string_for_size(date_range: DateRange, date_picker_size?: DateRangePickerSize) {
    if (!date_range.date) {
        return ''
    }

    switch (date_picker_size) {
    case DateRangePickerSize.YEAR:
        return new Date(date_range.date).getFullYear().toString()
    case DateRangePickerSize.MONTH:
        return new Date(date_range.date).toLocaleDateString('en-us', {year: 'numeric', month: 'long'})
    default: // date range
        return `${date_range.date} - ${date_range.to_date}`
    }
}
