import {
    persistent as persistent_common,
    StateCommon,
    session as session_common,
    volatile as volatile_common,
} from '@bitstillery/common/lib/state'
import {copy_object, merge_deep} from '@bitstillery/common/lib/utils'

import {menu, MenuItem} from './menu'

export type supported_currencies = 'EUR' | 'GBP' | 'USD' | 'JPY'

export interface StateDiscover extends StateCommon {
    account: {
        slug: string
    }
    data_card: {
        collapsed: false
    }
    exact: {
        access_token: string
        refresh_token: string
        access_expiry: number
        frontend_host: string
    }
    navigation: {
        items: []
        selected: [number, number | null]
        menu: MenuItem[]
    }
    schedule: {
        confirmed_today: boolean
        status: null | string
    }
    user: Object
    supplier_price_list: {
        add_to_order: {
            last_selected: {
                artkey: number | null
            }
        }
    }
    currencies: {
        last_api_call_on: string | null
        default: 'EUR'
        all: ['EUR', 'GBP', 'USD', 'JPY']
        exchange_rates: {
            'EUR': {
                refreshed_on: string | null
                rate: string
                portal_rate: string
            }
            'USD': {
                    refreshed_on: string | null
                    rate: string
                    portal_rate: string
            }
            'JPY': {
                    refreshed_on: string | null
                    rate: string
                    portal_rate: string
            }
            'GBP': {
                refreshed_on: string | null
                rate: string
                portal_rate: string
            }
        }
    }
}

export const volatile = merge_deep({
    navigation: {
        menu,
        selected: [null, null],
    },
    schedule: {
        confirmed_today: true,
        status: null,
    },
    supplier_price_list: {
        add_to_order: {
            last_selected: {
                artkey: null,
            },
        },
    },

}, copy_object(volatile_common))

export const session = merge_deep({
    account: {
        slug: process.env.MSI_THEME,
    },
}, copy_object(session_common))

export const persistent = merge_deep({
    currencies: {
        last_api_call_on: null as string | null,
        default: 'EUR',
        all: ['EUR', 'GBP', 'USD', 'JPY'],
        exchange_rates: {
            EUR: {
                refreshed_on: null,
                rate: 0.0,
                portal_rate: 0.0,
            },
            USD: {
                refreshed_on: null,
                rate: 0.0,
                portal_rate: 0.0,
            },
            GBP: {
                refreshed_on: null,
                rate: 0.0,
                portal_rate: 0.0,
            },
            JPY: {
                refreshed_on: null,
                rate: 0.0,
                portal_rate: 0.0,
            },
        },
    },
    exact: {
        access_token: '',
        refresh_token: '',
        access_expiry: '',
        frontend_host: '',
    },
    session: copy_object(session),
}, copy_object(persistent_common))
