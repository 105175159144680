import m from 'mithril'
import {proxy} from '@bitstillery/common/lib/proxy'
import {BarMain, Button, Dropdown, FieldSelect, FieldSwitch, Icon} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'
import {set_account_styles} from '@bitstillery/common/account/account'
import {$t, store} from '@bitstillery/common/app'

import {onSet} from '@/_utils'
import {$m, $s} from '@/app'
import {Search} from '@/components/search/search'

export class BarMainDiscover extends BarMain {
    data = proxy({
        profile: {
            toggle: false,
        },
    })

    include_excise = (function(property) {
        return onSet(property, function() {
            let include = sessionStorage.getItem('include_excise') === 'true'
            return sessionStorage.setItem('include_excise', !include)
        })
    }.call(this, window.prop(sessionStorage.getItem('include_excise') === 'true')))

    slot_actions() {
        return [
            <Dropdown
                className='dropdown-my-portal right'
                model={this.data.profile}
                trigger='click'
            >
                <div className={classes('btn-dropdown')}>
                    <Icon
                        active={m.route.get().startsWith('/my-portal') || this.data.profile.toggle}
                        name={$m.data.exact_keys.is_authenticated() ? 'accountFinance' : 'profile'}
                        size="l"
                        tip={$t('my_portal.tip')}
                        className={classes({finance: $m.data.exact_keys.is_authenticated()}, `signed-${$s.exact.access_token ? 'in' : 'out'}`)}
                    />
                </div>

                <div className='dropdown-body'>
                    <header>{$t('my_discover.text')}</header>
                    <Button
                        icon="profile"
                        link="/my-discover/profile"
                        text={$t('my_discover.text')}
                        variant="menu"
                    />
                    {!$m.data.exact_keys.is_authenticated() &&
                    <Button
                        icon="exact"
                        onclick={() => {
                            m.route.set('/user/exact_auth/user')
                        }}
                        text={$t('profile.actions.exact_login')}
                        variant="menu"
                    />}
                    <Button
                        icon="logout"
                        onclick={() => {
                            if ($m.data.exact_keys.is_authenticated()) {
                                $m.data.exact_keys.logout()
                            }

                            this.data.profile.toggle = false
                            $m.identity.logout()
                        }}
                        text={$t('my_portal.logout')}
                        variant="menu"
                    />
                </div>
            </Dropdown>,
        ]
    }

    slot_search() {
        return [
            <FieldSelect
                className="vendor-select"
                model={[$s.session.account, 'slug']}
                options={[
                    {label: 'MSP', value: 'msp'},
                    {label: 'ETR', value: 'etr'},
                    {label: 'MSI', value: 'msi'},
                    {label: 'A2BC', value: 'a2bc'},
                ]}
                onchange={(slug) => {
                    $s.session.account.slug = slug
                    set_account_styles(slug)
                    store.save()
                    $s.env.key = Date.now()
                }}
            />,
            <Search />,
            <FieldSwitch
                label="Incl. Excise (NL)"
                model={[$s, 'include_excise']}
                onchange={() => {
                    store.save()
                }}
            />,
        ]
    }
}
