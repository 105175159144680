import {MithrilTsxComponent} from 'mithril-tsx-component'
import {titleize} from '@bitstillery/common/utils'
import m from 'mithril'

export class CellDestination extends MithrilTsxComponent<any> {

    view(vnode:m.Vnode<any>) {
        return <div className="c-cell-destination td-group">

            {vnode.attrs.row.warehouse_status && <div className="header">
                {`${titleize(vnode.attrs.row.warehouse_status)} ${vnode.attrs.row.warehouse_reference ? `: ${vnode.attrs.row.warehouse_reference}` : ''}`}
            </div>}

            <div className="header-subtle">
                {(() => {
                    if (vnode.attrs.row.destination_name) {
                        return vnode.attrs.row.destination_name
                    }
                    else if (vnode.attrs.row.destination_location) {
                        return vnode.attrs.row.destination_location
                    }
                    return '-'
                })()}
            </div>

            <span className="sub-details">
                {vnode.attrs.row.incoterm_and_location}
            </span>
        </div>
    }
}
