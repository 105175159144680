import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {proxy} from '@bitstillery/common/lib/proxy'
import {required, validation} from '@bitstillery/common/lib/validation'
import {classes} from '@bitstillery/common/lib/utils'
import {FieldSelect, Icon} from '@bitstillery/common/components'
import {countries} from '@bitstillery/common/lib/countries'
import {notifier} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'

import {AddLogEntry, LogEntries} from '@/components/logbook/entries'
import {DefaultButton, DangerButton, SuccessButton, PrimaryButton} from '@/components/buttons'
import {AttachmentList, AttachmentHelper, UploadAttachment} from '@/components/attachment_list'
import {AttachmentType} from '@/factserver_api/attachment_api'
import {
    CrmApi,
    GetLeadResponse, LeadContactStatus, LeadContactStatusChoices, LeadSource,
    LeadStatus,
} from '@/factserver_api/crm_api'

export interface FieldAttrs {
    label: string
    value: string | null
}

class FieldReadonly extends MithrilTsxComponent<FieldAttrs> {
    view(vnode: m.Vnode<FieldAttrs>): m.Children {
        return <div className={'field-readonly'}>
            <div className={'key'}>{vnode.attrs.label}</div>
            <div className={'value'}>{vnode.attrs.value ? vnode.attrs.value : '...'}</div>
        </div>
    }
}

export default class LeadView extends MithrilTsxComponent<unknown> {
    crm_api = new CrmApi()
    loading = true
    lead: GetLeadResponse | null = null
    artkey: number
    attachment_helper: AttachmentHelper
    data: {tab: string}
    loading_redeem_code_operation = false

    constructor() {
        super()
        this.artkey = +m.route.param('artkey')

        this.attachment_helper = new AttachmentHelper(this.artkey, AttachmentType.LEAD)
        this.query_and_set_lead(this.artkey)

        const pathname = m.parsePathname(m.route.get())
        // @ts-ignore
        const params = Object.fromEntries(new URLSearchParams(pathname.params))

        this.data = proxy({

            tab: params.tab ? params.tab : 'lead',
        })

        this.$v = {
            contact_status: validation([this.lead, 'contact_status'], required()),
        }
    }

    set_status(status) {
        if (this.lead === null) {
            return
        }
        this.crm_api.set_lead_status(this.lead.artkey, status).subscribe({
            next: () => {
                notifier.notify(`Successfully set status to: ${status}`, 'success')
                m.route.set('/crm/leads')
            },
        })

        this.crm_api.set_lead_status(this.lead.artkey, status).subscribe({
            next: () => {
                notifier.notify(`Successfully set status to: ${status}`, 'success')
                m.route.set('/crm/leads')
            },
        })
    }

    save_contact_status(new_contact_status?: string) {
        if (!new_contact_status) {
            return
        }
        if (this.lead === null || !this.lead.manager?.artkey) {
            return
        }

        this.crm_api.quick_update_manager(this.lead.artkey, this.lead.manager.artkey, this.lead.memo, new_contact_status).subscribe({
            next: () => {
                if (this.lead !== null) {
                    this.lead.contact_status = new_contact_status
                }
                notifier.notify('Lead updated successfully.', 'success')
                m.redraw()
            },
        })
    }

    set_tab(tabName) {
        if (this.lead === null) {
            return
        }
        // @ts-ignore
        this.data.tab = tabName
        m.route.set(`/crm/leads/${this.lead.artkey}?tab=${tabName}`)
    }

    delete() {
        if (confirm('Are you sure you want to delete this lead?')) {
            this.crm_api.delete_lead(this.artkey).subscribe({
                next: () => {
                    notifier.notify('Lead updated successfully.', 'success')
                    m.route.set('/crm/leads')
                },
            })
        }
    }

    query_and_set_lead(
        artkey: number,
    ) {
        this.loading = true

        this.crm_api.get_lead(artkey).subscribe({
            next: (lead) => {
                this.lead = lead
                this.loading = false
                m.redraw()
            },
        })
    }

    readable_lead_source(lead: GetLeadResponse) {
        const lead_data_file = lead.lead_data_file

        if (lead.source === LeadSource.REGISTRATION_FORM) {
            return 'Registration form'
        } else if (lead_data_file) {
            return `Lead data file: ${lead_data_file.file_name} (${lead_data_file.description})`
        }
    }

    generate_redeem_code() {
        this.loading_redeem_code_operation = true
        this.crm_api.generate_redeem_code_by_artkey(this.artkey).subscribe({
            next: () => {
                this.loading_redeem_code_operation = false
                this.query_and_set_lead(this.artkey)
            },
        })
    }

    remove_redeem_code() {
        this.loading_redeem_code_operation = true
        this.crm_api.remove_redeem_code(this.artkey).subscribe({
            next: () => {
                this.loading_redeem_code_operation = false
                this.query_and_set_lead(this.artkey)
            },
        })
    }

    view() {
        if (this.loading || this.lead === null) {
            return <Spinner/>
        }
        const contact_person = this.lead.contact_persons?.[0]
        const warehouse = this.lead.warehouses?.[0]
        return (
            <div className="c-lead-edit view">
                <div className="btn-toolbar">
                    <DefaultButton
                        icon="arrow-left"
                        onclick={() => m.route.set('/crm/leads')}
                        icon_class={'glyphicon glyphicon-arrow-left'}
                    >Back to list
                    </DefaultButton>
                    <PrimaryButton
                        icon="arrow-left"
                        onclick={() => m.route.set(`/crm/leads/${this.lead?.artkey}/edit`)}
                        icon_class={'glyphicon glyphicon-edit'}
                    >Edit Lead
                    </PrimaryButton>

                    {!this.lead.redeem_code &&
                    <DefaultButton
                        icon_class={'fas fa-plus'}
                        title={'Redeem code'}
                        disabled={this.loading_redeem_code_operation}
                        onclick={() => this.generate_redeem_code()}
                    />}
                    {this.lead.redeem_code &&
                    <DefaultButton
                        icon_class={'fas fa-minus'}
                        title={'Redeem code'}
                        disabled={this.loading_redeem_code_operation}
                        onclick={() => this.remove_redeem_code()}
                    />}

                    <DangerButton
                        onclick={() => this.delete()}
                        icon_class={'glyphicon glyphicon-trash'}
                    >
                        Delete Lead
                    </DangerButton>

                    {this.lead.status !== LeadStatus.CONVERTED &&
                        this.lead.status !== LeadStatus.ARCHIVED && (
                        <div>
                            <DefaultButton
                                icon_class="fa fa-eye"
                                onclick={() => this.set_status('archived')}
                            >
                                Archive Lead
                            </DefaultButton>
                            <SuccessButton
                                icon_class="fa fa-folder-plus"
                                onclick={() =>
                                    m.route.set(`/crm/relations/create/${this.lead?.artkey}`)
                                }
                            >
                                Lead to relation
                            </SuccessButton>
                        </div>
                    )}
                    {this.lead.status === LeadStatus.ARCHIVED && (
                        <DefaultButton
                            label="Unarchive"
                            icon_class={'fa fa-eye-slash'}
                            onclick={() => this.set_status('registered')}
                        />
                    )}
                </div>

                <div className="c-tabs">
                    <ul className="nav nav-tabs">
                        <li
                            className={classes('nav-link', {
                                active: this.data.tab === 'lead',
                            })}
                            onclick={() => this.set_tab('lead')}
                        >
                            <a>Lead</a>
                        </li>
                        <li
                            className={classes('nav-link', {
                                active: this.data.tab === 'logbook',
                            })}
                            onclick={() => this.set_tab('logbook')}
                        >
                            <a>Logbook</a>
                        </li>
                        <li
                            className={classes('nav-link', {
                                active: this.data.tab === 'attachments',
                            })}
                            onclick={() => this.set_tab('attachments')}
                        >
                            <a>Attachments</a>
                        </li>
                    </ul>
                    <div className={classes({active: this.data.tab === 'lead', 'c-tab': true})}>
                        <div className="fieldset-group">
                            <div className="fieldset">
                                <div className="fieldset-label">General information</div>
                                <FieldReadonly label="Company name" value={this.lead.name} />
                                <FieldReadonly label="Manager" value={this.lead.manager?.profile.name} />
                                <FieldReadonly label="Company type" value={this.lead.company_type} />
                                <FieldReadonly label="VAT ID" value={this.lead.vat_id} />
                                <FieldReadonly label="Company registration number" value={this.lead.company_registration_number} />
                                <FieldReadonly label="Non mail indicator" value={this.lead.non_mail_indicator} />
                                <FieldReadonly label="Number of employees" value={this.lead.nr_of_employees} />
                                <FieldReadonly label="Year founded" value={this.lead.year_founded} />
                                <FieldReadonly label="Turnover group" value={this.lead.turnover_group} />
                                <FieldReadonly label="Lead source" value={this.readable_lead_source(this.lead)} />
                                <FieldReadonly label="Lead message" value={this.lead.message} />
                                <FieldReadonly label="Redeem code" value={this.lead.redeem_code ? `${this.lead.redeem_code} valid until ${format_iso_to_date(this.lead.redeem_code_valid_until)}` : ''} />
                            </div>

                            <div class="fieldset">
                                <div class="fieldset-label">Address &amp; media</div>
                                <div class="field-group">
                                    <FieldReadonly label="Address" value={this.lead.street_address}/>
                                    <FieldReadonly label="Zip code" value={this.lead.zip_code}/>
                                </div>
                                <div class="field-group">
                                    <FieldReadonly label="City" value={this.lead.city}/>
                                    <FieldReadonly label="Country"
                                        value={this.lead.country_code ? countries[this.lead.country_code.toUpperCase()] : '-'}/>
                                    <FieldReadonly label="Language"
                                        value={this.lead.language ? countries[this.lead.language.toUpperCase()] : '-'}/>
                                </div>
                                <FieldReadonly label="Email Address" value={this.lead.emailaddress}/>
                                <FieldReadonly label="Phone number" value={this.lead.telephone_number}/>
                                <FieldReadonly label="Website" value={this.lead.url}/>
                                <div class="field-group">
                                    <FieldReadonly label={['LinkedIn ', <Icon className='fab fa-linkedin'/>]}
                                        value={this.lead.linkedin}/>
                                    <FieldReadonly label={['Facebook ', <Icon className='fab fa-facebook'/>]}
                                        value={this.lead.facebook}/>
                                </div>
                                <div class="field-group">
                                    <FieldReadonly label={['Instagram ', <Icon className='fab fa-instagram'/>]}
                                        value={this.lead.instagram}/>
                                    <FieldReadonly label={['Twitter ', <Icon className='fab fa-twitter'/>]}
                                        value={this.lead.twitter}/>
                                </div>
                                <FieldReadonly label="Operates online" value={this.lead.operates_online ?
                                    <Icon className='fa fa-check'/> : !this.lead.operates_online ?
                                        <Icon className='fa fa-times'/> : null}/>
                            </div>
                        </div>

                        <div className="fieldset-group">
                            <div className="fieldset">
                                <div className="fieldset-label">Contact info</div>
                                {
                                    contact_person && (
                                        <div>
                                            <FieldReadonly label="First Name" value={contact_person.first_name}/>
                                            <FieldReadonly label="Name" value={contact_person.family_name}/>
                                            <FieldReadonly label="Position" value={contact_person.position}/>
                                            <FieldReadonly label="Email Address" value={contact_person.emailaddress}/>
                                            <FieldReadonly label="Phone number"
                                                value={contact_person.telephone_number}/>
                                        </div>
                                    )}
                            </div>
                            <div className="fieldset">
                                <div className="fieldset-label">Transport</div>
                                {warehouse && (
                                    <FieldReadonly
                                        label="Warehouse"
                                        value={
                                            warehouse.name
                                        }
                                    />
                                )}
                                {warehouse && (
                                    <div>
                                        <FieldReadonly label="Excise ID" value={warehouse.excise_id}/>
                                        <FieldReadonly label="Excise Location ID"
                                            value={warehouse.excise_location_id}/>
                                        <FieldReadonly label="Address" value={warehouse.street_address}/>
                                        <FieldReadonly label="Zip code" value={warehouse.zip_code}/>
                                        <FieldReadonly
                                            label="City/town and/or Country"
                                            value={`${warehouse.city ? warehouse.city + ', ' : ''}${
                                                warehouse.country_code
                                                    ? countries[warehouse.country_code.toUpperCase()]
                                                    : ''
                                            }`}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={classes({active: this.data.tab === 'logbook', 'c-tab': true})}>
                        <div className={'fieldset'}>
                            <div className={'field-group'}>
                                <FieldSelect
                                    disabled={!this.lead.manager?.artkey}
                                    label="Contact status"
                                    model={[this.lead, 'contact_status']}
                                    onchange={(value: string) => {
                                        this.save_contact_status(value)
                                    }}
                                    options={Object.keys(LeadContactStatus).map((it) => ({
                                        label: LeadContactStatusChoices[it],
                                        value: it,
                                    }))}
                                />
                            </div>
                        </div>
                        <div className={'fieldset'}>
                            <AddLogEntry
                                artkey={this.lead.artkey}
                                process="crm.leads.add_log_entry"
                                on_add_callback={() => {
                                    if (this.lead !== null) {
                                        this.query_and_set_lead(this.lead.artkey)
                                    }
                                }}
                            />
                            <LogEntries log_entries={this.lead.log_entries}/>
                        </div>
                    </div>

                    <div className={classes({active: this.data.tab === 'attachments', 'c-tab': true})}>
                        <AttachmentList attachment_helper={this.attachment_helper}/>
                        <UploadAttachment attachment_helper={this.attachment_helper}/>
                    </div>
                </div>
            </div>
        )
    }
}
