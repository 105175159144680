/** llm:production */
import m from 'mithril'
import {compact, concat, empty, map, sort, unique} from 'prelude-ls'
import {Amount, Link, Spinner} from '@bitstillery/common/components'

import {formatDate, toString} from '@/_utils'
import panel from '@/components/_panel'
import table from '@/components/table'
import {labels} from '@/components/_labels'
import {Popover} from '@/components/popover/popover'
import {$m, $s} from '@/app'

const get_item_labels = (item: any) => {
    return concat(compact([
        map((tag: any) => ({
            category: tag.category().name(),
            name: tag.name(),
        }), compact(map($m.data.item_tag.get_item_tag, (item.item_tags || [])))),

        item.case.tax_label !== '' ? {
            category: 'Tax Label',
            name: item.case.tax_label,
        } : null,

        item.best_before_date ? {
            category: 'BBD',
            name: formatDate(item.best_before_date),
        } : null,

        item.cases_per_pallet ? {
            category: 'CPP',
            name: toString(item.case.cases_per_pallet),
        } : null,
    ]))
}

export class PurchaseOrderItems {
    view(vnode: any) {
        return panel(vnode.attrs.title,
            vnode.attrs.loading() ?
                <Spinner/> :
                table.table(vnode.attrs.data, [
                    {
                        width: 3,
                        field: 'purchase_order.reference',
                        name: '#',
                        function: (purchase_order_item: any) =>
                            <m.route.Link
                                href={`/purchase-orders/manage/${purchase_order_item.purchase_order.reference}`}>
                                {purchase_order_item.purchase_order.reference}
                            </m.route.Link>,
                    },
                    {
                        width: 5,
                        field: 'purchase_order.created_on',
                        name: 'Date',
                        function: (purchase_order_item: any) =>
                            formatDate(purchase_order_item.purchase_order.created_on),
                    },
                    {
                        width: 8,
                        field: 'purchase_order.supplier.name',
                        name: 'Relation',
                        ellipsis: true,
                    },
                    {
                        width: 8,
                        field: 'case.bottle.product.name',
                        name: 'Product',
                        ellipsis: true,
                    },
                    {
                        width: 3,
                        field: 'case.number_of_bottles',
                        name: 'Btl / cs',
                        classes: 'number',
                    },
                    {
                        width: 4,
                        field: 'case.bottle.volume',
                        name: 'Size',
                        classes: 'number',
                        function: (purchase_order_item: any) =>
                            (+purchase_order_item.case.bottle.volume).toFixed(1) + ' cl',
                    },
                    {
                        width: 4,
                        field: 'case.bottle.alcohol_percentage',
                        name: 'Alc %',
                        classes: 'number',
                        function: (purchase_order_item: any) =>
                            (+purchase_order_item.case.bottle.alcohol_percentage).toFixed(1) + '%',
                    },
                    {
                        width: 3,
                        field: 'case.bottle.refill_status',
                        name: 'Ref',
                    },
                    {
                        width: 5,
                        field: 'case.gift_box_type',
                        name: 'GB',
                    },
                    {
                        width: 3,
                        field: 'customs_status',
                        name: 'Cus.',
                    },
                    {
                        width: 3,
                        field: 'number_of_cases',
                        name: 'Cases',
                        classes: 'number',
                    },
                    {
                        width: 6,
                        field: 'was_bought_for',
                        name: 'Price / cs',
                        classes: 'price',
                        function: (purchase_order_item: any) =>
                            <Amount
                                amount={purchase_order_item.was_bought_for}
                                currency={purchase_order_item.purchase_order.was_bought_in}
                                rate={purchase_order_item.purchase_order.bought_against_rate}
                            />,
                    },
                    {
                        width: 6,
                        field: 'euro_was_bought_for',
                        name: '€ / cs',
                        classes: 'price',
                        function: (purchase_order_item: any) =>
                            <Amount
                                amount={purchase_order_item.was_bought_for}
                                currency={purchase_order_item.purchase_order.was_bought_in}
                                rate={purchase_order_item.purchase_order.bought_against_rate}
                                display_currency={$s.currencies.default}
                            />,
                    },
                    {
                        width: 7,
                        field: 'total_price',
                        name: 'Total price',
                        classes: 'price',
                        function: (purchase_order_item: any) =>
                            <Amount
                                amount={purchase_order_item.purchase_order.was_bought_for}
                                currency={purchase_order_item.purchase_order.was_bought_in}
                                rate={purchase_order_item.purchase_order.bought_against_rate}
                            />,
                    },
                    {
                        width: 5,
                        field: 'purchase_order.status',
                        name: 'Status',
                    },
                    {
                        width: 8,
                        field: 'purchase_order.supplier.purchase_manager.profile.name',
                        name: 'Purchase manager',
                        function: (purchase_order_item: any) =>
                            purchase_order_item.purchase_order.supplier.purchase_manager.profile.name !== purchase_order_item.purchase_order.was_handled_by.profile.name ?
                                <Popover
                                    title="Created by"
                                    content={purchase_order_item.purchase_order.was_handled_by.profile.name}
                                >
                                    {purchase_order_item.purchase_order.supplier.purchase_manager.profile.name} <span
                                        class="glyphicon glyphicon-user"></span>
                                </Popover> :
                                purchase_order_item.purchase_order.supplier.purchase_manager.profile.name,
                    },
                    {
                        width: 6,
                        field: 'purchase_order.target_warehouse.name',
                        name: 'Warehouse',
                        ellipsis: true,
                    },
                    {
                        width: 9,
                        field: 'lots',
                        name: 'Lot(s)',
                        function: (purchase_order_item: any) =>
                            sort(unique(purchase_order_item.lots)).map((lot: string) =>
                                <div>
                                    <Link
                                        target="_blank"
                                        href={`/stock/manage?search=${lot}`.replace(/ /g, '+')}
                                    >
                                        {lot}
                                    </Link>
                                </div>,
                            ),
                    },
                ], {
                    search_table_style: false,
                    sticky_header: false,
                    details: (item: any) =>
                        compact([
                            (() => {
                                const item_labels = get_item_labels(item)
                                return !empty(item_labels) ? labels(item_labels) : null
                            })(),
                            item.remark ?
                                <div>
                                    <strong>Remark: </strong>
                                    <span>{item.remark}</span>
                                </div> : null,
                        ]),
                }),
        )
    }
}
