import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {product_photo_image_location} from '@bitstillery/common/lib/utils'
import {format_iso_to_date_time} from '@bitstillery/common/lib/format'
import Sortable from 'sortablejs'
import {Observable} from 'rxjs'
import {Button, ButtonGroup, Icon, DataCard, Spinner} from '@bitstillery/common/components'
import {config, notifier} from '@bitstillery/common/app'

import {CheckBox} from '../html_components'
import {FileDropArea} from '../file_drop_area'

import {ProductPhotoHelper} from './product_photo_helper'

import {ProductPhotoApi} from '@/factserver_api/product_photos'
import {
    CreateProductPhotoRequest,
    CreateProductPhotoResponse,
    GetProductPhotoResponse,
    RotateDirection,
} from '@/factserver_api/fact2server_api'

interface ProductPhotoAttrs {
    product_photo: GetProductPhotoResponse
    on_product_photos_changed: () => unknown
    photo_list: any
}

class ProductPhotoItem extends MithrilTsxComponent<ProductPhotoAttrs> {
    image_location: string
    product_photo_helper: ProductPhotoHelper = new ProductPhotoHelper()

    constructor(vnode: m.Vnode<ProductPhotoAttrs>) {
        super()
        this.image_location = product_photo_image_location(config.product_photo_host, vnode.attrs.product_photo)
    }

    get is_loading() {
        return this.product_photo_helper.is_loading
    }

    view(vnode: m.Vnode<ProductPhotoAttrs>): m.Children {
        const product_photo = vnode.attrs.product_photo
        return (
            <div className="c-product-photo-item">
                <div className="photo-container">
                    <img
                        src={this.image_location}
                        alt={`A photo for ${product_photo.file_name}`}
                    />
                    <div className="controls">
                        <ButtonGroup>
                            <Icon
                                className="move-target"
                                name="arrowUpDown"
                                size="s"
                                type="info"
                                tip="Drag to change order"
                                variant="toggle"
                            />
                            <CheckBox
                                checked={product_photo.is_internal}
                                disabled={this.is_loading}
                                id={`flip_is_internal-${product_photo.artkey}`}
                                label="Internal"
                                onchange={() => this.product_photo_helper.flip_is_internal(product_photo)}
                            />
                        </ButtonGroup>
                        <ButtonGroup>
                            <Button
                                disabled={this.is_loading}
                                icon="download"
                                onclick={() => this.product_photo_helper.download_photo(product_photo)}
                                size="s"
                                tip="Download photo"
                            />
                            <Button
                                disabled={this.is_loading}
                                icon="rotateLeft"
                                onclick={() => this.product_photo_helper.rotate(product_photo, RotateDirection.Left, vnode.attrs.on_product_photos_changed)}
                                size="s"
                                tip="Rotate left"
                            />
                            <Button
                                disabled={this.is_loading}
                                icon="rotateRight"
                                onclick={() => this.product_photo_helper.rotate(product_photo, RotateDirection.Right, vnode.attrs.on_product_photos_changed)}
                                size="s"
                                tip="Rotate right"
                            />

                            {!product_photo.verified_by_name && <Button
                                disabled={this.is_loading}
                                icon_class="thumbUp"
                                onclick={() => this.product_photo_helper.approve(product_photo, vnode.attrs.on_product_photos_changed)}
                                size="s"
                            />}
                            {product_photo.rank !== 1 && <Button
                                icon="image"
                                onclick={() => vnode.attrs.photo_list.make_primary(product_photo.artkey)}
                                size="s"
                                tip="Make image primary"
                            />}
                            <Button
                                disabled={this.is_loading}
                                icon="trash"
                                onclick={() => this.product_photo_helper.delete(product_photo, vnode.attrs.on_product_photos_changed)}
                                size="s"
                                tip="Delete photo"
                                type="danger"
                            />
                        </ButtonGroup>
                    </div>

                </div>
                <DataCard model={{
                    data: [
                        {label: 'File', value: product_photo.file_name},
                        {label: 'Rank', value: product_photo.rank},
                        {label: 'Created on', value: format_iso_to_date_time(product_photo.created_on)},
                        {label: 'Updated on', value: format_iso_to_date_time(product_photo.was_last_updated_on)},
                        {label: 'Updated by', value: product_photo.was_last_updated_by_name},
                        {label: 'Approved by', value: product_photo.verified_by_name},
                    ],
                }}/>
            </div>
        )
    }
}

interface ProductPhotoListAttrs {
    product_photos: GetProductPhotoResponse[]
    offer_item_artkey?: number
    /** Either pass a offer_item_artkey or a item_artkey. */
    item_artkey?: number
    on_product_photos_changed: () => unknown
    on_sort_product_photo_items: (artkeys: number[]) => unknown
    download_all_photos: (include_internal: boolean) => unknown
}

export class ProductPhotoList extends MithrilTsxComponent<ProductPhotoListAttrs> {
    sortable: Sortable | null = null
    product_photo_api = new ProductPhotoApi()
    is_loading = false
    on_sort_product_photo_items: (artkeys: number[]) => unknown

    constructor(vnode: m.Vnode<ProductPhotoListAttrs>) {
        super()
        if (vnode.attrs.item_artkey && vnode.attrs.offer_item_artkey) {
            throw Error('Product photo list not correctly initialised. Please the IT-Team.')
        }

        this.on_sort_product_photo_items = vnode.attrs.on_sort_product_photo_items
    }

    oncreate(): void {
        // Initialise the sortable list
        const photo_list_element = document.getElementById('photo-list')
        if (!photo_list_element) {
            throw new Error('Programmer error, cannot locate element photo-list')
        }
        this.sortable = Sortable.create(photo_list_element, {
            handle: '.move-target',
            animation: 150,
            onEnd: () => this.resort(),
        })
    }

    call_resort_api(artkeys: number[]): void {
        this.is_loading = true
        this.product_photo_api.sort(artkeys).subscribe({
            next: () => {
                this.on_sort_product_photo_items(artkeys)
                this.is_loading = false
                m.redraw()
            },
            error: () => {
                this.is_loading = false
                m.redraw()
            },
        })
    }

    resort(): void {
        if (!this.sortable) {
            return
        }
        const artkeys = this.sortable.toArray().map((artkey) => +artkey)
        this.call_resort_api(artkeys)
    }

    make_primary(product_photo_artkey: number): void {
        if (!this.sortable) {
            return
        }
        const current_artkeys = this.sortable
            .toArray()
            .map((artkey) => +artkey)
            .filter((artkey) => artkey !== product_photo_artkey)
        const new_order = [product_photo_artkey].concat(current_artkeys)
        this.call_resort_api(new_order)
        this.sortable.sort(new_order.map((artkey) => `${artkey}`))
    }

    files_to_upload: { file: File; contents: string }[] = []
    is_uploading = false

    do_upload(vnode: m.Vnode<ProductPhotoListAttrs>): void {
        if (this.files_to_upload.length === 0 || this.is_uploading) {
            return
        }

        this.is_uploading = true

        const to_upload = this.files_to_upload.pop()
        if (!to_upload) {
            return
        }
        const contents = to_upload.contents
        const file = to_upload.file

        const request: CreateProductPhotoRequest = {
            contents_base64_encoded: contents,
            is_primary: file.name.includes('primary'),
            is_internal: file.name.includes('internal'),
        }
        if (!vnode.attrs.item_artkey && !vnode.attrs.offer_item_artkey) {
            return
        }
        let dd: Observable<CreateProductPhotoResponse>
        if (vnode.attrs.item_artkey) {
            dd = this.product_photo_api.upload_photo_for_item(vnode.attrs.item_artkey, request)
        } else if (vnode.attrs.offer_item_artkey) {
            dd = this.product_photo_api.upload_photo_for_offer_item(vnode.attrs.offer_item_artkey, request)
        } else {
            throw new Error('No support for no item_artkey of offer_item_artkey')
        }

        dd.subscribe({
            next: () => {
                this.is_uploading = false
                this.do_upload(vnode)
                vnode.attrs.on_product_photos_changed()
                if (this.files_to_upload.length === 0) {
                    notifier.notify('Photos were uploaded', 'success')
                }
            },
            error: () => {
                this.is_uploading = false
                notifier.notify(`${file.name} failed to upload`, 'warning')
                if (this.files_to_upload.length === 0) {
                    vnode.attrs.on_product_photos_changed()
                }
            },
        })
    }

    /**
     *  Note: Cannot upload photos all at once, this causes a congestion on the server, so store it in an array and
     *  call do_upload, this method will read the array empty.
     */
    upload_photos(vnode: m.Vnode<ProductPhotoListAttrs>, file: File, contents: string): void {
        this.files_to_upload.push({
            file: file,
            contents: contents,
        })
        this.do_upload(vnode)
    }

    view(vnode: m.Vnode<ProductPhotoListAttrs>): m.Children {
        const can_upload = vnode.attrs.item_artkey || vnode.attrs.offer_item_artkey

        return (
            <div className="c-product-photo-list">
                <ButtonGroup>
                    <Button
                        icon="download"
                        onclick={() => vnode.attrs.download_all_photos(false)}
                        text="Download"
                        type="info"
                    />
                    <Button
                        icon="download"
                        onclick={() => vnode.attrs.download_all_photos(true)}
                        text="With internal photos"
                    />
                </ButtonGroup>

                {can_upload && <ButtonGroup>
                    <FileDropArea
                        onupload={(file: File, contents: string) => this.upload_photos(vnode, file, contents)}
                    />
                    {this.files_to_upload.length > 0 && (
                        <span>
                            <Spinner />
                            <span> {this.files_to_upload.length} to go ...</span>
                        </span>
                    )}
                </ButtonGroup>}

                <div id="photo-list">
                    {vnode.attrs.product_photos.map((product_photo) => (
                        <div className="product-photo" key={product_photo.artkey} data-id={product_photo.artkey}>
                            {!this.is_loading &&
                                <ProductPhotoItem
                                    photo_list={this}
                                    product_photo={product_photo} {...vnode.attrs}
                                />
                            }
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}
