import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Amount, Button, Icon, Spinner} from '@bitstillery/common/components'
import {api} from '@bitstillery/common/app'
import {classes} from '@bitstillery/common/lib/utils'
import {to_specs} from '@bitstillery/common/lib/specs'

import {Link} from '../discover'
import {CheckBox} from '../html_components'

import {CaseInfoPanelAttrs} from './case_info_panel'
import {BottleThroughput} from './bottle_throughput'

import {accountIconBySlug} from '@/accounts'
import {$s} from '@/app'
import {
    GetPurchaseOrderItemByBottleResponse,
    GetPurchaseOrderItemsByBottleRequest,
    PurchaseApi,
    PurchaseOrderItemByBottle,
} from '@/factserver_api/purchase_api'
import {BottleThroughputResponse} from '@/factserver_api/fact2server_api'

type BottlePurchaseOrdersAttrs = Pick<
    CaseInfoPanelAttrs,
    'bottle_artkey' | 'ignore_ref' | 'case_artkey' | 'up_is_up' | 'case_customs_status' | 'bottle_mode' | 'current_supplier_artkey'
>

export class BottlePurchaseOrders extends MithrilTsxComponent<BottlePurchaseOrdersAttrs> {
    purchase_api = new PurchaseApi()
    is_loading = true
    purchase_order_items: PurchaseOrderItemByBottle[] = []
    total = 0
    limit = 5
    offset = 0
    attrs: BottlePurchaseOrdersAttrs
    bottle_artkey: number
    current_supplier_only = false
    throughput: object | null = null

    constructor(vnode: m.Vnode<BottlePurchaseOrdersAttrs>) {
        super()
        this.attrs = vnode.attrs
        this.attrs.up_is_up = true
        this.bottle_artkey = this.attrs.bottle_artkey
    }

    async oncreate() {
        this.total = 0
        this.offset = 0
        this.purchase_order_items = []
        await this.show_more()
    }

    get_title(): string {
        if (this.attrs.current_supplier_artkey) {
            return 'this supplier'
        }
        if (this.attrs.case_artkey) {
            return 'this case'
        }
        if (this.attrs.bottle_artkey) {
            if (this.attrs.ignore_ref) {
                return 'these specs (ignoring ref/nonref)'
            }
            return 'these specs (size, alc %, ref)'
        }
        return 'this offer item'
    }

    async show_more() {
        await this._query_purchase_order_items()
        this.offset += 5
    }

    async toggle_current_supplier_only() {
        this.current_supplier_only = !this.current_supplier_only
        await this._query_purchase_order_items(true)
    }

    async _query_purchase_order_items(reset = false) {
        this.is_loading = true
        const bottle_artkey = this.attrs.bottle_artkey
        const case_artkey = this.attrs.case_artkey
        if (reset) {
            this.total = 0
            this.offset = 0
        }
        const data: GetPurchaseOrderItemsByBottleRequest = {
            bottle_artkey: bottle_artkey,
            ignore_ref: this.attrs.ignore_ref,
            case_artkey: case_artkey,
            limit: this.limit,
            offset: this.offset,
        }
        if (this.current_supplier_only) {
            data['supplier_artkey'] = this.attrs.current_supplier_artkey
        }

        const quantity_bought_url = `discover/bottles/quantity-bought?bottle_artkey=${bottle_artkey}&ignore_ref=${this.attrs.ignore_ref ?? false}`
        const response = await Promise.all([
            this.purchase_api.get_purchase_order_items_by_bottle(data).toPromise(),
            api.get(`${quantity_bought_url}${case_artkey ? `&case_artkey=${case_artkey}` : ''}`),
        ])

        const poi_response: GetPurchaseOrderItemByBottleResponse = response[0]
        const quantity_bought = response[1].result as BottleThroughputResponse

        if (reset) { // use this reset, otherwise the screen flickers (does a lot layout).
            this.purchase_order_items.splice(0, this.purchase_order_items.length)
        }

        this.total = poi_response.total
        this.purchase_order_items = this.purchase_order_items.concat(poi_response.purchase_order_items)
        this.throughput = quantity_bought
        this.is_loading = false
        m.redraw()
    }

    view(vnode: m.Vnode<BottlePurchaseOrdersAttrs>): m.Children {
        const title = this.get_title()
        return (
            <div className="c-bottle-purchase-orders collection-widget">
                <div className="header">
                    <div className="title">
                        <div className={'header'}>
                            <Icon name="cart" type="info" />
                        Latest purchase orders for {title}
                        </div>
                        {this.throughput && (<BottleThroughput prefix="Purchased" type="Long" throughput={this.throughput} />)}
                    </div>
                    {vnode.attrs.current_supplier_artkey && <div className="options">
                        <CheckBox
                            id={`bottle-purchase-${vnode.attrs.bottle_artkey}`}
                            checked={this.current_supplier_only}
                            onchange={async() => await this.toggle_current_supplier_only()}
                        >
                            Current supplier only
                        </CheckBox>
                    </div>}
                </div>
                <div className="content">
                    {this.purchase_order_items.length === 0 ? (
                        <div class="c-empty-message">{this.is_loading ? '' : 'Not in any purchase order.'}</div>
                    ) : (
                        <div>
                            <table className={'table table-condensed'}>
                                <thead className="thead-default">
                                    <tr>
                                        <th>#</th>
                                        <th>Ref</th>
                                        <th>GB</th>
                                        <th className={'price'}>€ / cs</th>
                                        {this.attrs.bottle_mode && <th className={'price'}>€ / btl</th>}
                                        <th>Status</th>
                                        <th>Last update</th>
                                        <th>Handled by</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.purchase_order_items.map((purchase_order_item) => (
                                        <tr>
                                            <td>
                                                <div className={classes('td-group')}>
                                                    <span className={'header'}>
                                                        {accountIconBySlug(purchase_order_item.account_slug, purchase_order_item.account_name)}
                                                        <div>
                                                            {purchase_order_item.supplier_name}
                                                        </div>
                                                    </span>
                                                    <span className={'details'}>
                                                        <Link
                                                            target="_blank"
                                                            href={`/purchase-orders/manage/${purchase_order_item.purchase_order_artkey}?account=${purchase_order_item.account_slug}`}
                                                        >
                                                            {purchase_order_item.purchase_order_reference}
                                                        </Link>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className={classes('td-group')}>
                                                    <span className={'header'}>
                                                        {purchase_order_item.number_of_cases} cs
                                                    </span>
                                                    <span className={'details'}>
                                                        {to_specs({
                                                            case_number_of_bottles: purchase_order_item.case_number_of_bottles,
                                                            bottle_volume: purchase_order_item.bottle_volume,
                                                            bottle_alcohol_percentage: purchase_order_item.bottle_alcohol_percentage,
                                                            case_gift_box_type: purchase_order_item.case_gift_box_type,
                                                            bottle_refill_status: purchase_order_item.bottle_refill_status,
                                                            case_customs_status: purchase_order_item.case_customs_status,
                                                            case_tax_label: purchase_order_item.case_tax_label,
                                                        })}
                                                    </span>
                                                </div>
                                            </td>
                                            <td className="ellipsis">{purchase_order_item.case_gift_box_type}</td>
                                            <td className={'price'}>
                                                <Amount
                                                    amount={+purchase_order_item.was_bought_for}
                                                    currency={purchase_order_item.purchase_order_was_bought_in}
                                                    rate={+purchase_order_item.purchase_order_bought_against_rate}
                                                    display_currency={$s.currencies.default}
                                                />
                                            </td>
                                            {this.attrs.bottle_mode && (
                                                <td className={'price'}>
                                                    <Amount
                                                        amount={+purchase_order_item.price_per_bottle}
                                                        currency={purchase_order_item.purchase_order_was_bought_in}
                                                        rate={+purchase_order_item.purchase_order_bought_against_rate}
                                                        display_currency={$s.currencies.default}
                                                    />
                                                </td>
                                            )}
                                            <td>{purchase_order_item.purchase_order_status}</td>
                                            <td>{format_iso_to_date(purchase_order_item.purchase_order_latest_status_update)}</td>
                                            <td>{purchase_order_item.profile_name}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {this.purchase_order_items.length < this.total && <Button
                                className="btn-show-more"
                                icon="chevronDown"
                                size="s"
                                onclick={async() => await this.show_more()}
                                text="Show more results"
                            />}
                        </div>
                    )}
                    {this.is_loading && <Spinner />}
                </div>
            </div>
        )
    }
}
