import m from 'mithril'
import {$s, events} from '@bitstillery/common/app'

(function() {
    var push_state = history.pushState
    var replace_state = history.replaceState

    history.pushState = function() {
        push_state.apply(history, arguments)
        window.dispatchEvent(new Event('pushstate'))
        window.dispatchEvent(new Event('locationchange'))
    }

    history.replaceState = function() {
        replace_state.apply(history, arguments)
        window.dispatchEvent(new Event('replacestate'))
        window.dispatchEvent(new Event('locationchange'))
    }

    window.addEventListener('popstate', function() {
        window.dispatchEvent(new Event('locationchange'))
    })
})()

export const make_route = function(auth, layout) {
    return function(component) {
        return {
            onmatch: () => {
                events.emit('router.onmatch')
                return auth(component)
            },
            render: (vnode) => {
                return m(layout, vnode)
            },
        }
    }
}

export function optional_auth(component) {
    return component
}

export function redirect_to(url) {
    return {
        oninit: () => m.route.set(url),
        view: () => null,
    }
}

export function require_auth(component) {

    if (!$s.identity.token && !$s.identity.user.artkey) {
        const redirect_to = encodeURIComponent(window.location.href)
        if (!redirect_to.includes('dashboard')) {
            m.route.set(`/login?redirect=${redirect_to}`)
        } else {
            m.route.set('/login')
        }
    } else {
        return component
    }
}

export function require_no_auth(component) {

    if ($s.identity.token) {
        m.route.set('/')
    } else {
        return component
    }
}
