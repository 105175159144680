import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {
    Amount,
    CellAvailability,
    CellMargin,
    CellProduct,
    CollectionHeader,
    CollectionItems,
    CollectionView,
    FieldMoney,
    Icon,
    Link,
    PanelFilters,
    RowActionEdit,
} from '@bitstillery/common/components'
import {
    FiltersDescription,
    FilterSelectMultipleState,
    FilterSelectSingleState,
    FilterTextState,
    FilterSelectRangeState,
} from '@bitstillery/common/types'
import {generate_filters} from '@bitstillery/common/lib/filters'
import {classes} from '@bitstillery/common/lib/utils'
import {watch} from '@bitstillery/common/lib/store'
import {CollectionTransforms, CollectionProxy} from '@bitstillery/common/lib/collection'
import {proxy} from '@bitstillery/common/lib/proxy'
import {api, notifier} from '@bitstillery/common/app'
import {required} from '@bitstillery/common/lib/validation'
import {is_filter_active} from '@bitstillery/common/lib/filters'
import {$t} from '@bitstillery/common/app'

import {$s} from '@/app'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {CaseLabels} from '@/components/labels'
import {EditableRelationList, RelationsSearch} from '@/components/relation'
import {
    GetBulkOfferForOfferItemsResponse,
    GetOfferItemCompareWithMarketResponse,
    GetRelationResponse,
} from '@/factserver_api/fact2server_api'
import {BottleMarket} from '@/components/market_info/bottle_market'
import {BottleStock} from '@/components/market_info/bottle_stock'
import {OfferHistory} from '@/components/market_info/offer_history'
import {BottlePurchaseOrders} from '@/components/market_info/bottle_purchase_orders'
import {BottleSalesOrders} from '@/components/market_info/bottle_sales_orders'
import {ProductManagementApi} from '@/factserver_api/product_management_api'

interface GetOfferItemCompareWithMarketResponseExt extends GetOfferItemCompareWithMarketResponse {
    is_showing_details?: boolean
    edit?: boolean
    new_price_per_case?: string
    bulk_offer?: GetBulkOfferForOfferItemsResponse
}

interface ComparePricesFilters extends FiltersDescription {
    cases_available: Partial<FilterSelectRangeState>
    category: Partial<FilterSelectMultipleState>
    price_age: Partial<FilterSelectRangeState>
    product_search: Partial<FilterTextState>
    relation: Partial<FilterSelectMultipleState>
    relation_type: Partial<FilterSelectSingleState>
    stock_age: Partial<FilterSelectRangeState>
}

const save_offeritem = async function({
    artkey,
    delivery_period_in_weeks,
    is_hidden,
    maximum_order_quantity,
    minimum_order_quantity,
    price_per_case,
    silent_price_change,
}) {

    return await api.put(`discover/offer-items/${artkey}`, {
        price_per_case: `${price_per_case}`,
        delivery_period_in_weeks,
        maximum_order_quantity,
        minimum_order_quantity,
        silent_price_change,
        is_hidden: is_hidden,
        needs_approval: false,
    })
}

const filters = generate_filters<ComparePricesFilters>({
    cases_available: {
        default: [0, 1000],
        icon: 'case',
        infinity: false,
        placement: {order: 3},
        scale: [0, 1000],
        serialize: ['number'],
        unit: 'day',
        type: 'SELECT_RANGE',
    },
    category: {
        icon: 'bottle',
        placement: {order: 4},
        serialize: ['number'],
        type: 'SELECT_MULTIPLE',
    },
    price_age: {
        default: [0, 1000],
        icon: 'money',
        infinity: false,
        placement: {order: 2},
        scale: [0, 1000],
        serialize: ['number'],
        unit: 'day',
        type: 'SELECT_RANGE',
    },
    product_search: {
        icon: 'search',
        serialize: 'string',
        type: 'TEXT',
    },
    relation: {
        icon: 'profile',
        serialize: ['number'],
        type: 'SELECT_MULTIPLE',
    },
    relation_type: {
        icon: 'brandHolders',
        options: [
            ['competitors', 'Competitors'],
        ],
        placement: {order: 0},
        serialize: 'string',
        type: 'SELECT_SINGLE',
    },
    stock_age: {
        default: [0, 1000],
        icon: 'stock',
        infinity: false,
        placement: {order: 1},
        scale: [0, 1000],
        serialize: ['number'],
        unit: 'day',
        type: 'SELECT_RANGE',
    },
})

const transforms:CollectionTransforms = {
    filters_to_query: (filters) => {
        const query = {
            filters: {
                number_of_cases_available_from: is_filter_active(filters.cases_available) ? filters.cases_available.selection[0] : undefined,
                number_of_cases_available_to: is_filter_active(filters.cases_available) ? filters.cases_available.selection[1] : undefined,
                only_competitors: filters.relation_type.selection === 'competitors',
                only_relation_artkeys: is_filter_active(filters.relation) ? filters.relation.selection : undefined,
                price_age_in_days_from: is_filter_active(filters.price_age) ? String(filters.price_age.selection[0]) : undefined,
                price_age_in_days_to: is_filter_active(filters.price_age) ? String(filters.price_age.selection[1]) : undefined,
                show_category_artkeys: is_filter_active(filters.category) ? filters.category.selection : undefined,
                stock_age_from: is_filter_active(filters.stock_age) ? String(filters.stock_age.selection[0]) : undefined,
                stock_age_to: is_filter_active(filters.stock_age) ? String(filters.stock_age.selection[1]) : undefined,
            },
            page_size: 50,
            search_terms: is_filter_active(filters.product_search) ? filters.product_search.selection : undefined,
            sort_by: collection.state.sort.by,
            sort_ascending: collection.state.sort.order === 'asc' ? 'ASC' : 'DESC',
        }
        return query
    },
    items_queried: async(api_result) => {
        const artkeys_fetched = api_result.result.map((item) => item.artkey)
        if (artkeys_fetched.length) {
            const {result} = await api.get<GetBulkOfferForOfferItemsResponse[]>(`discover/offer-items/compare-with-market/bulk-offer-prices?offer_item_artkeys=${artkeys_fetched.join(',')}`)
            result.forEach((coi) => {
                api_result.result
                    .filter((item) => item.artkey === coi.offer_item_artkey)
                    .forEach((item) => item.bulk_offer = coi)
            })
        }

        return api_result.result
    },
    filter_metadata: {
        transform: async(filters) => {
            const relations = await Promise.all(filters.relation.selection.map(async(artkey) => {
                const {result} = await api.get<GetRelationResponse>(`discover/relations/${artkey}`)
                return [result.artkey, result.name]
            })) as any
            filters.relation.options.splice(0, filters.relation.options.length, ...relations)

            const {result: {categories}} = await api.get('discover/product-categories') as any
            filters.category.options = categories.map((category) => {
                return [
                    category.artkey,
                    category.name,
                    category.children.length,
                    category.children.map((child) => [child.artkey, child.name, 0, []]),
                ]},
            )
        },
    },
}

const collection = new CollectionProxy()

const columns = [
    {
        name: 'Product',
        width: '2fr',
        render: (row: GetOfferItemCompareWithMarketResponseExt) => <CellProduct
            {...row}
            additional_details={
                <CaseLabels
                    show_as_icons={true}
                    case={{
                        best_before_date: row.best_before_date,
                        serialized_item_tags: row.case_serialized_item_tags,
                        remark: row.item_remark,
                        tax_label: row.case_tax_label,
                    }}
                />
            }
        />,
    }, {
        name: 'Purchase price',
        render: (row: GetOfferItemCompareWithMarketResponseExt) => {
            return <Amount
                amount={row.avg_purchase_price}
                currency={'EUR'}
            />
        },
    }, {
        id: 'price_per_case',
        name: 'Price per case',
        render: (row: GetOfferItemCompareWithMarketResponseExt) => {
            if (row.bulk_offer) {
                return <div className={classes('td-group')}>
                    <div className={'header'}>
                        <Amount
                            amount={row.bulk_offer.bulk_price}
                            currency={'EUR'}
                        />

                    </div>
                    <div className="details">from: {row.price_per_case}</div>
                    <div className="details">
                        <Link href={`/offer/offers/${row.bulk_offer.offer_artkey}/custom_products`}>
                                in offer {row.bulk_offer.offer_title}
                        </Link>
                    </div>
                </div>
            }
            return <Amount
                amount={row.price_per_case}
                currency={'EUR'}
            />
        },
    }, {
        name: 'Margin',
        width: '0.5fr',
        render: (row: GetOfferItemCompareWithMarketResponseExt) => {
            let margin_percentage = ((+(row?.price_per_case || 0) / +row.avg_purchase_price) - 1)
            return <CellMargin value={margin_percentage} />
        },
    }, {
        name: 'Lowest competitor price',
        render: (row: GetOfferItemCompareWithMarketResponseExt) => {
            if (!row.lowest_market_price_competitor) {
                return '-'
            } else {
                const diff_class = +row.lowest_market_price_competitor.delta > 0 ? 'red' : 'green'
                return <div className="td-group">
                    <span className="header">
                        <Link href={`/market/pricelists/${row.lowest_market_price_competitor.supplier_price_list_artkey}?page=1&search_terms=${row.product_name}`}>
                            {row.lowest_market_price_competitor.relation_name}
                        </Link>
                    </span>
                    <span className={'detail'}>
                        <Amount amount={row.lowest_market_price_competitor.price_per_case} currency={'EUR'} />
                    </span>
                    <span className={classes('detail', diff_class)}>
                        <Amount amount={Math.abs(+row.lowest_market_price_competitor.delta)} currency={'EUR'} />
                    </span>
                </div>
            }
        },
    }, {
        name: 'Stock age',
        width: '0.5fr',
        render: (row: GetOfferItemCompareWithMarketResponseExt) => {
            return row.item_stock_age
        },
    }, {
        name: 'Price age (days)',
        width: '0.5fr',
        render: (row: GetOfferItemCompareWithMarketResponseExt) => {
            return row.price_age_in_days
        },
    }, {
        name: 'Stock',
        render: (row: GetOfferItemCompareWithMarketResponseExt) => {
            return <div className="td-group">
                <CellAvailability key={row.artkey} row={{
                    number_of_cases: row.item_number_of_cases,
                    number_of_cases_in_stock: row.item_number_of_cases_in_stock,
                    number_of_cases_in_sales: row.item_number_of_cases_in_sales,
                    number_of_cases_in_purchase: row.item_number_of_cases_in_purchase,
                    number_of_cases_available: row.item_number_of_cases_available,
                }}/>
            </div>
        },
    },
]

export default class ComparePriceListPrices extends MithrilTsxComponent<unknown> {
    // Controls for legacy filters
    currency = 'EUR'
    filter_product_search_controller: SearchBarControl | null = null
    filter_relation_controller: SearchBarControl | null = null

    product_management_api = new ProductManagementApi()
    watchers = [] as any
    data = proxy({
        selected_relations: [] as GetRelationResponse[],
    })

    oninit() {
        collection.init({
            bulk: {
                action_alt: {
                    icon: 'incognito',
                },
                fields: {
                    price_per_case: {
                        render: (item, field_name, validation) => <FieldMoney
                            currency={[this, 'currency']}
                            label={`Price per case (${$s.currencies.default})`}
                            min={0}
                            model={[item, field_name]}
                            validation={validation}
                            value={item[field_name]}
                        />,
                        validation: required(),
                    },
                },
                async persist(items, mode) {
                    const promises:any[] = []
                    for (const item of items) {
                        promises.push(save_offeritem({
                            artkey: item.artkey,
                            price_per_case: item.price_per_case,
                            delivery_period_in_weeks: item.delivery_period_in_weeks,
                            maximum_order_quantity: item.maximum_order_quantity,
                            minimum_order_quantity: item.minimum_order_quantity,
                            is_hidden: item.is_hidden,
                            silent_price_change: mode === 'alt',
                        }))
                    }

                    await Promise.all(promises)
                    if (mode === 'alt') {
                        notifier.notify($t('notifications.saved_offer_items_alt', {count: items.length}), 'info')
                    } else {
                        notifier.notify($t('notifications.saved_offer_items', {count: items.length}), 'success')
                    }
                },

            },
            endpoint: {
                method: 'get',
                path: 'discover/offer-items/compare-with-market/collection-view',
            },
            sort: {
                by: 'product_name',
                order: 'asc',
                options: [
                    ['product_name', 'Product name'],
                    ['item_stock_age', 'Stock age'],
                    ['price_age_in_days', 'Price age in days'],
                    ['lowest_price_competitors', 'Lowest competitor price'],
                ],
            },
        }, filters, transforms)
    }

    oncreate() {
        // Search filter needs to be wired manually, as long
        // its not integrated in the common filters.
        this.watchers.push(watch(filters.product_search, 'selection', () => {
            if (filters.product_search.selection === '') {
                this.filter_product_search_controller.clear_search_text()
            }
        }))

        this.watchers.push(watch(this.data.selected_relations, () => {
            filters.relation.options.splice(0, filters.relation.options.length)
            filters.relation.selection.splice(0, filters.relation.selection.length)
            this.data.selected_relations.forEach((relation) => {
                filters.relation.options.push([relation.artkey, relation.name])
                filters.relation.selection.push(relation.artkey)
            })
        }))
    }

    onremove() {
        this.watchers.forEach((unwatch) => unwatch())
    }

    view(_vnode:m.Vnode<any>): m.Children {
        return <div className="c-pricelist-compare-prices view-container">
            <PanelFilters collection={collection}>
                {/* Legacy filters using new filter state model; port these ASAP to common filters */}
                <SearchBar
                    disabled={filters.product_search.disabled}
                    on_get_suggestions$={(filter_text) => {
                        return this.product_management_api.get_simple_product_names(filter_text)}
                    }
                    on_submit={(term) => {
                        filters.product_search.selection = term
                    }}
                    placeholder="Filter productname..."
                    search_bar_controller={(controller) => {
                        this.filter_product_search_controller = controller
                    }}
                />
                <div className="filter-group">
                    <RelationsSearch
                        disabled={filters.relation.disabled}
                        placeholder="Filter pricelists from suppliers..."
                        selected_relations={this.data.selected_relations}
                        search_bar_controller={(controller) => {
                            this.filter_relation_controller = controller
                        }}
                    />
                    <EditableRelationList selected_relations={this.data.selected_relations} />
                </div>
            </PanelFilters>
            <CollectionView mode="table">
                <CollectionHeader
                    collection={collection}
                    columns={columns}
                />
                <CollectionItems
                    collection={collection}
                    columns={columns}
                    row_actions={(row) => {
                        return [
                            <RowActionEdit
                                collection={collection}
                                context={{name: 'edit_price', title: 'Edit Price'}}
                                mode="inline"
                                row={row}
                            />,
                        ]
                    }}

                    row_detail={(row) => {
                        return <ApprovalInfoPanel row={row} />
                    }}
                    row_status={(row) => {
                        return {
                            render: [
                                <Icon
                                    name="eye"
                                    tip={row.is_hidden ? 'This product is hidden.' : 'This product is visible.'}
                                    type={row.is_hidden ? 'warning' : 'default'}
                                />,
                            ],
                            type: row.is_hidden ? 'warning' : 'default',
                        }
                    }}
                />
            </CollectionView>
        </div>
    }
}

class ApprovalInfoPanel extends MithrilTsxComponent<any> {
    view(vnode: m.Vnode<any>): m.Children {
        const row = vnode.attrs.row
        const info_panel_attrs = {
            bottle_artkey: row.bottle_artkey,
            case_customs_status: row.case_customs_status,
            ignore_ref: true,
        }
        return [
            <div className="columns">
                <div className="column is-12">
                    <BottleMarket {...info_panel_attrs} />
                </div>
            </div>,
            <div className="columns">
                <div className="column is-12">
                    <BottleStock {...info_panel_attrs} />
                </div>
            </div>,
            <div className="columns">
                <div className="column is-12">
                    <OfferHistory {...info_panel_attrs}/>
                </div>
            </div>,
            <div className="columns">
                <div className="column is-6">
                    <BottlePurchaseOrders {...info_panel_attrs} />
                </div>
                <div className="column is-6">
                    <BottleSalesOrders {...info_panel_attrs} />
                </div>
            </div>,
        ]
    }
}
