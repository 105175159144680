import {Observable} from 'rxjs'
import {DateTime} from 'luxon'

import {Api} from './api'

export enum Report {
    LEDGER_SALES_REPORT = 'Ledger sales report',
    LEDGER_PURCHASE_REPORT = 'Ledger purchase report',
    INSURANCE_PURCHASE_ORDERS_REPORT = 'Insurance purchase orders report',
    INSURANCE_SALES_ORDERS_REPORT = 'Insurance sales orders report',
    MODIFIED_PRODUCTS_REPORT = 'Modified products report',
    STOCK_AGE_REPORT = 'Stock age report',
    LIS_WMS_ARTICLES = 'Lis wms articles',
    DUPLICATE_STOCK_ARTICLES = 'Duplicate stock articles',
    STOCK_HISTORY_REPORT = 'Stock history report',
    BRAND_TRANSACTIONS_REPORT = 'Brand transactions report',
    COUNTRIES_TRADE_REPORT = 'Countries trade report',
    RELATIONS_LOGISTICS_REPORT = 'Relations logistics report',
    STOCK_RECONCILIATION_REPORT = 'Stock reconciliation report'
}

export interface ReportingMetaElement {
    label: string
    value: string | number
    type: string
    unit?: string
}

export interface ReportingMetaInformationResponse {
    meta: ReportingMetaElement[]
}

export interface ReportArguments {
    report: string | null
    start_date: DateTime | null
    end_date: DateTime | null
    additional_args: Record<string, string | number | boolean>
}

export interface DownloadExcelResponse {
    base64_encoded_file: string
}

export class ReportingApi {
    api = new Api()

    meta_information_for(report_request: ReportArguments): Observable<ReportingMetaInformationResponse> {
        const payload = {
            ...{
                start_date: report_request.start_date?.toISODate() || '',
                end_date: report_request.end_date?.toISODate() || '',
                report: report_request.report,
            },
            ...report_request.additional_args,
        }
        return this.api.post_request('reporting.reporting.meta_information_for', payload)
    }

    download_excel_for(report_request: ReportArguments): Observable<DownloadExcelResponse> {
        const payload = {
            ...{
                start_date: report_request.start_date?.toISODate() || '',
                end_date: report_request.end_date?.toISODate() || '',
                report: report_request.report,
            },
            ...report_request.additional_args,
        }
        return this.api.post_request('reporting.reporting.download_excel_for', payload)
    }
}
