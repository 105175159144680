import {events} from '@bitstillery/common/app'
import {DateTime} from 'luxon'
import {api, store} from '@bitstillery/common/app'

import {GetAllCurrenciesResponse} from '@/factserver_api/fact2server_api'
import {$s} from '@/app'

function _needs_update_from_api() {
    const now = DateTime.now()
    const refresh_moment = now.minus({hours: 4})
    const latest = $s.currencies.last_api_call_on
    return !latest || DateTime.fromISO(latest) < refresh_moment
}

export function bind() {
    events.on('identity.login', async() => {

        if (!_needs_update_from_api()) return
        const {status_code, result: currency_exchange_rates} = await api.get<GetAllCurrenciesResponse[]>('discover/data/currencies/latest')
        if (status_code > 299) {
            return
        }
        $s.currencies.last_api_call_on = new Date().toISOString()
        $s.currencies.all.forEach((currency) => {
            const cer_eur = currency_exchange_rates.find((cer) => cer.currency === currency)
            if (cer_eur) {
                $s.currencies.exchange_rates[currency].rate = cer_eur.exchange_rate
                $s.currencies.exchange_rates[currency].portal_rate = cer_eur.portal_exchange_rate
                $s.currencies.exchange_rates[currency].refreshed_on = cer_eur.date
            }
        })

        store.save()
    })
}
