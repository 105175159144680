import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {DateTime} from 'luxon'
import {notifier} from '@bitstillery/common/app'
import {Tippy} from '@bitstillery/common/components'

import {CaseInfoPanel} from '../components/market_info/case_info_panel'

import {accountIconBySlug} from '@/accounts'
import {Modal} from '@/components/modal/modal'
import {AddToOrder} from '@/purchase_orders/components/add_to_order'
import {
    CollectionTable,
    CollectionTableColumn,
    CollectionTableRowComponentProps,
    PagedCollectionFetcher,
    SearchFilterValue,
} from '@/components/collection/collection_table'
import {MarkUpIsUpEntryAsSeenRequest, PurchaseApi} from '@/factserver_api/purchase_api'
import {SupplierPriceListItem} from '@/factserver_api/marketanalysis_api'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {
    ApplyFilterButton,
    ArrayFilterHelper,
    CheckboxFilter,
    DateFilter,
    FilterHelper,
    FilterRadioButtonGroup,
    RangedFilter,
    RangedMinMaxFilter,
    SeenUnseenFilterValues,
    SidebarLocalStorage,
    SingleValueFilterHelper,
} from '@/components/collection/side_bar'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {DefaultButton} from '@/components/buttons'

interface UpIsUpEntry {
    bottle_artkey: number
    bottle_alcohol_percentage: number
    bottle_volume: number
    bottle_refill_status: string
    account_slug: string
    account_name: string
    product_name: string
    product_category_name: string
    number_of_cases: number
    number_of_cases_available: number
    number_of_cases_in_purchase: number
    number_of_cases_in_stock: number
    number_of_cases_in_sales: number
    availability_percentage: number
    throughput: number
    days_until_sold: number
    projected_days_sold_out: number
    snooze_until_availability: number
    snooze_until_date: string
    is_snoozed: boolean

    // Fields added for the view.
    is_showing_details: boolean
}

interface UpIsUpSidebarAttrs {
    fetcher: PagedCollectionFetcher<UpIsUpEntry>
}

class UpIsUpSidebar extends MithrilTsxComponent<UpIsUpSidebarAttrs> {
    product_management_api = new ProductManagementApi()
    fetcher: PagedCollectionFetcher<UpIsUpEntry>
    all_filter_helpers: FilterHelper[]
    side_bar_storage: SidebarLocalStorage

    seen_unseen_filter_helper: SingleValueFilterHelper
    throughput_filter_helper: ArrayFilterHelper
    available_percentage_limit_filter_helper: SingleValueFilterHelper
    last_sale_after_filter_helper: SingleValueFilterHelper
    only_sold_out_filter_helper: SingleValueFilterHelper

    search_bar_controller: SearchBarControl | null = null

    programmatically_generated_change = false // keep track if a change in the ui-data is user or javascript based.

    constructor(vnode: m.Vnode<UpIsUpSidebarAttrs>) {
        super()

        this.fetcher = vnode.attrs.fetcher

        // Default is beginning of previous week.
        const last_week = DateTime.now().minus({day: 7 + DateTime.now().weekday})
        this.last_sale_after_filter_helper = new SingleValueFilterHelper(
            this.fetcher,
            'last_sale_after',
            last_week.toISODate(),
        )
        this.seen_unseen_filter_helper = new SingleValueFilterHelper(this.fetcher, 'seen_unseen', 'unseen')
        this.throughput_filter_helper = new ArrayFilterHelper(this.fetcher, 'throughput_range', [], ['0', '100'])
        this.available_percentage_limit_filter_helper = new SingleValueFilterHelper(
            this.fetcher,
            'available_percentage_limit',
            '0',
        )
        this.only_sold_out_filter_helper = new SingleValueFilterHelper(this.fetcher, 'only_sold_out', 'yes')

        // Monitor changes on available percentage <-> only sold out stock
        this.available_percentage_limit_filter_helper.onchange = (value: SearchFilterValue) => {
            if (this.programmatically_generated_change) {
                this.programmatically_generated_change = false
                return
            }
            if (value !== '') {
                this.programmatically_generated_change = true
                this.only_sold_out_filter_helper.set_fetcher_filter_value('no')
            }
        }
        this.only_sold_out_filter_helper.onchange = (value: SearchFilterValue) => {
            if (this.programmatically_generated_change) {
                this.programmatically_generated_change = false
                return
            }
            if (value === 'yes') {
                this.programmatically_generated_change = true
                this.available_percentage_limit_filter_helper.set_fetcher_filter_value(0)
            }
        }

        this.all_filter_helpers = [
            this.seen_unseen_filter_helper,
            this.throughput_filter_helper,
            this.available_percentage_limit_filter_helper,
            this.last_sale_after_filter_helper,
            this.only_sold_out_filter_helper,
        ]
        this.side_bar_storage = new SidebarLocalStorage('purchase-up-is-up-sidebar', this.all_filter_helpers)
    }

    oncreate(): void {
        this.side_bar_storage.load()
    }

    view(vnode: m.Vnode<UpIsUpSidebarAttrs>): m.Children {
        return [
            <ApplyFilterButton
                fetcher={vnode.attrs.fetcher}
                search_bar_control={null}
                filter_helpers={this.all_filter_helpers}
                side_bar_storage={this.side_bar_storage}
            />,
            <div className={'mb-1'}>
                <SearchBar
                    placeholder="Search for products..."
                    on_submit={(search_text: string) => this.fetcher.set_search_terms(search_text)}
                    on_get_suggestions$={(filter_text: string) =>
                        this.product_management_api.get_simple_product_names(filter_text)
                    }
                    search_bar_controller={(controller: SearchBarControl) =>
                        (this.search_bar_controller = controller)
                    }
                />
            </div>,

            <FilterRadioButtonGroup
                title={'Seen or unseen'}
                helper={this.seen_unseen_filter_helper}
                filter_values={SeenUnseenFilterValues}
            />,

            <DateFilter
                title={'Sales order confirmed after'}
                helper={this.last_sale_after_filter_helper}
            />,

            <RangedMinMaxFilter
                title={'Throughput'}
                open_end={false}
                min={0}
                max={100}
                step={5}
                helper={this.throughput_filter_helper}
            />,
            <RangedFilter
                title={'Max availability percentage'}
                open_end={false}
                min={0}
                max={100}
                step={5}
                helper={this.available_percentage_limit_filter_helper}
            />,
            <CheckboxFilter title={'Only sold out stock'} helper={this.only_sold_out_filter_helper} />,
        ]
    }
}

export default class UpIsUpView extends MithrilTsxComponent<unknown> {
    is_showing_seen = false
    up_is_up_fetcher = new PagedCollectionFetcher<UpIsUpEntry>(
        'purchase.up_is_up.get_up_is_up_list',
        'product_name',
        () => {
            this.is_showing_seen = this.up_is_up_fetcher.filters['seen_unseen'] !== 'unseen'
        },
    )

    purchase_api = new PurchaseApi()

    mark_seen(row: UpIsUpEntry, snooze_until_availability?: number): void {
        const request: MarkUpIsUpEntryAsSeenRequest = {
            bottle_artkey: row.bottle_artkey,
            snooze_until_availability: snooze_until_availability,
        }
        if (row.projected_days_sold_out) {
            const snooze_until_date = DateTime.now().plus({days: row.projected_days_sold_out})
            request['snooze_until_date'] = snooze_until_date.toISODate()
        }

        this.purchase_api.mark_up_is_up_entry_seen(request).subscribe({
            next: (response) => {
                let message = 'Marked entry as seen.'
                let snooze_until_date: DateTime | null = null
                if (response.snooze_until_date) {
                    snooze_until_date = DateTime.fromISO(response.snooze_until_date).toLocaleString()
                }
                if (response.snooze_until_availability && snooze_until_date) {
                    message += ` The item will return when availability goes below ${response.snooze_until_availability}% or on ${snooze_until_date}, whichever comes first.`
                } else if (response.snooze_until_availability) {
                    message += ` The item will return when availability goes below ${response.snooze_until_availability}%.`
                } else if (snooze_until_date) {
                    message += ` The item will return on ${snooze_until_date}.`
                }
                notifier.notify(message, 'success')
                row.is_snoozed = true
                if (this.up_is_up_fetcher.filters['seen_unseen']) {
                    this.remove_row(row)
                }
                m.redraw()
            },
            error: () => null,
        })
    }

    mark_unseen(row: UpIsUpEntry): void {
        this.purchase_api
            .mark_up_is_up_entry_unseen({
                bottle_artkey: row.bottle_artkey,
            })
            .subscribe({
                next: () => {
                    notifier.notify('Marked entry as unseen', 'success')
                    row.is_snoozed = false
                    if (this.up_is_up_fetcher.filters['seen_unseen']) {
                        this.remove_row(row)
                    }
                    m.redraw()
                },
                error: () => null,
            })
    }

    remove_row(row: UpIsUpEntry): void {
        this.up_is_up_fetcher.fetched_rows = this.up_is_up_fetcher.fetched_rows.filter(function(value) {
            return row !== value
        })
        if (this.up_is_up_fetcher.fetched_rows.length === 0) {
            this.up_is_up_fetcher.reset_and_query()
        }
    }

    view(): m.Children {
        return (
            <div className="c-purchase-op-is-op view filter-sidebar-wrapper">
                <div className="c-filter-sidebar">
                    <UpIsUpSidebar fetcher={this.up_is_up_fetcher} />
                </div>
                <div className={'filter-result'}>
                    <CollectionTable<UpIsUpEntry, void>
                        collection_fetcher={this.up_is_up_fetcher}
                        on_row_click_component={UpIsUpDetailView}
                        on_row_click={(row: UpIsUpEntry) => (row.is_showing_details = !row.is_showing_details)}
                    >
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => 'Product'}
                            sort_name={'product_name'}
                            data_field={(row: UpIsUpEntry) => (
                                <span data-bottle-artkey={row.bottle_artkey}>{row.product_name}</span>
                            )}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => 'Account'}
                            data_field={(row: UpIsUpEntry) => accountIconBySlug(row.account_slug, row.account_name)}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => 'Category'}
                            data_field={(row: UpIsUpEntry) => row.product_category_name}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => 'Size'}
                            data_field={(row: UpIsUpEntry) => row.bottle_volume}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => 'Alc %'}
                            data_field={(row: UpIsUpEntry) => row.bottle_alcohol_percentage}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => 'Ref'}
                            data_field={(row: UpIsUpEntry) => row.bottle_refill_status}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => (
                                <Tippy
                                    content={
                                        'Total number of cases purchased on lots that are not yet sold out, or that were sold out in the last 14 days'
                                    }
                                >
                                    <span className={'fas fa-boxes'} />
                                </Tippy>
                            )}
                            data_field={(row: UpIsUpEntry) => row.number_of_cases}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => (
                                <Tippy content={'Number of cases in stock'}>
                                    <span className={'glyphicon glyphicon-home'} />
                                </Tippy>
                            )}
                            data_field={(row: UpIsUpEntry) => row.number_of_cases_in_stock}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => (
                                <Tippy content={'Number of cases in purchase'}>
                                    <span className={'glyphicon glyphicon-shopping-cart'} />
                                </Tippy>
                            )}
                            data_field={(row: UpIsUpEntry) => row.number_of_cases_in_purchase}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => (
                                <Tippy content={'Number of cases in sales'}>
                                    <span className={'glyphicon glyphicon-screenshot'} />
                                </Tippy>
                            )}
                            data_field={(row: UpIsUpEntry) => row.number_of_cases_in_sales}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => (
                                <Tippy content={'Number of cases available'}>
                                    <span className={'fas fa-shield-alt'} />
                                </Tippy>
                            )}
                            data_field={(row: UpIsUpEntry) => {
                                return `${row.number_of_cases_available} (${row.availability_percentage.toFixed(1)}%)`
                            }}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => (
                                <Tippy content={'Throughput'}>
                                    <span className={'glyphicon glyphicon-flash'} />
                                </Tippy>
                            )}
                            sort_name={'throughput'}
                            data_field={(row: UpIsUpEntry) => `${row.throughput.toFixed(1)}%`}
                        />
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => (
                                <Tippy
                                    content={
                                        'The date the system expects this bottle to sell out, based on sales since purchase'
                                    }
                                >
                                    Projected sold-out
                                </Tippy>
                            )}
                            data_field={(row: UpIsUpEntry) => {
                                return row.projected_days_sold_out !== null && row.projected_days_sold_out !== undefined
                                    ? row.projected_days_sold_out.toString() +
                                          ' day' +
                                          (row.projected_days_sold_out === 1 ? '' : 's')
                                    : '-'
                            }}
                        />
                        {this.is_showing_seen && (
                            <CollectionTableColumn<UpIsUpEntry>
                                header_title={() => 'Snoozed until'}
                                data_field={(row: UpIsUpEntry) => {
                                    const snooze_list = []
                                    if (row.snooze_until_date) {
                                        snooze_list.push(DateTime.fromISO(row.snooze_until_date).toLocaleString())
                                    }
                                    if (row.snooze_until_availability !== null) {
                                        snooze_list.push(`${row.snooze_until_availability}%`)
                                    }
                                    return snooze_list.join(' / ')
                                }}
                            />
                        )}
                        <CollectionTableColumn<UpIsUpEntry>
                            header_title={() => ''}
                            sort_name={'number_of_cases_in_sales'}
                            data_field={(row: UpIsUpEntry) => {
                                if (row.is_snoozed) {
                                    return (
                                        <div className="row-actions">
                                            <Tippy content={'Mark as unseen'}>
                                                <DefaultButton
                                                    icon_class={'glyphicon glyphicon-eye-open'}
                                                    onclick={() => this.mark_unseen(row)}
                                                />
                                            </Tippy>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="row-actions">
                                            <Tippy content={'Mark as seen'}>
                                                <DefaultButton
                                                    icon_class={'glyphicon glyphicon-eye-close'}
                                                    onclick={() => this.mark_seen(row)}
                                                />
                                            </Tippy>
                                            {row.availability_percentage > 10 && (
                                                <Tippy content={'Mark as seen until below 10% availability'}>
                                                    <DefaultButton
                                                        icon_class={'glyphicon glyphicon-eye-close'}
                                                        title={' <10%'}
                                                        onclick={() => this.mark_seen(row, 10)}
                                                    />
                                                </Tippy>
                                            )}
                                            {row.availability_percentage > 20 && (
                                                <Tippy content={'Mark as seen until below 20% availability'}>
                                                    <DefaultButton
                                                        icon_class={'glyphicon glyphicon-eye-close'}
                                                        title={' <20%'}
                                                        onclick={() => this.mark_seen(row, 20)}
                                                    />
                                                </Tippy>
                                            )}
                                            {row.availability_percentage > 30 && (
                                                <Tippy content={'Mark as seen until below 30% availability'}>
                                                    <DefaultButton
                                                        icon_class={'glyphicon glyphicon-eye-close'}
                                                        title={' <30%'}
                                                        onclick={() => this.mark_seen(row, 30)}
                                                    />
                                                </Tippy>
                                            )}
                                        </div>
                                    )
                                }
                            }}
                        />
                    </CollectionTable>
                </div>
            </div>
        )
    }
}

class UpIsUpDetailView extends MithrilTsxComponent<CollectionTableRowComponentProps<UpIsUpEntry, unknown>> {
    selected_spli: SupplierPriceListItem | null = null

    close_add_to_order(): void {
        this.selected_spli = null
    }

    view(vnode: m.Vnode<CollectionTableRowComponentProps<UpIsUpEntry, unknown>>): m.Children | null {
        if (!vnode.attrs.row.is_showing_details) {
            return <span />
        }
        const entry = vnode.attrs.row
        return (
            <tr className={'well'}>
                <td colspan={'100%'}>
                    {this.selected_spli !== null && (
                        <Modal title={'Add market item to Purchase Order'} onclose={() => this.close_add_to_order()}>
                            <AddToOrder
                                selected_spli={this.selected_spli}
                                supplier_artkey={this.selected_spli.supplier.artkey}
                                on_added_item={() => this.close_add_to_order()}
                                oncancel={() => this.close_add_to_order()}
                            />
                        </Modal>
                    )}
                    <div className="well-container">

                        <CaseInfoPanel
                            bottle_artkey={entry.bottle_artkey}
                            ignore_ref={true}
                            add_to_order={(spli: SupplierPriceListItem) => (this.selected_spli = spli)}
                        />
                    </div>
                </td>
            </tr>
        )
    }
}
