import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Icon, Spinner} from '@bitstillery/common/components'
import {is_filter_active, clear_filter} from '@bitstillery/common/lib/filters'
import {proxy} from '@bitstillery/common/lib/proxy'
import {watch} from '@bitstillery/common/lib/store'
import {$t} from '@bitstillery/common/app'

export class FiltersActive extends MithrilTsxComponent<any> {

    data = proxy({
        filters_loading: -1,
        loading: true,
    })

    watchers:any = []

    oncreate(vnode) {
        const filters = Object.values(vnode.attrs.collection.filters)
        const loading_filters = filters.filter((i:any) => i.loading)
        this.data.filters_loading = loading_filters.length
        // This component is becollectioning used at the same place where filters are being
        // initialized and loaded. We wait until all loading filters have at least been
        // done loading once, so that active filter titles can be retrieved from
        // filter options from the backend.
        if (!loading_filters.length) {
            this.data.loading = false
        } else {
            for (const filter of loading_filters) {
                this.watchers.push(watch(filter, 'loading', () => {
                    this.data.filters_loading -= 1
                }))
            }
        }

        this.watchers.push(watch(this.data, 'filters_loading', (modelvalue) => {
            if (modelvalue === 0) {
                // All filters are initialized; get rid of the watchers.
                this.data.loading = false
                this.watchers.map((unwatch) => unwatch())
            }
        }))
    }

    onremove() {
        this.watchers.map((watcher) => watcher())
    }

    matching_option(id, options) {
        for (const option of options) {
            if (option[0] === id) {
                return option
            }

            if (option.length >= 4 && option[3].length) {
                const match = this.matching_option(id, option[3])
                if (match) return match
            }
        }
    }

    render_button(filter, title, item_id) {
        return <Button
            className="term"
            onclick={() => {
                if (filter.type === 'TEXT' && Array.isArray(filter.selection)) {
                    const filtered = filter.selection.filter((i) => i !== title)
                    filter.selection.splice(0, filter.selection.length, ...filtered)
                    return
                }

                // Clicking an active filter means deselecting the whole filter
                // in case of a single value filter.
                if (filter.type !== 'SELECT_MULTIPLE') {
                    clear_filter(filter)
                    return
                }

                // A multi filter will remove the clicked value from its selected options.
                // Prevents redraws while manipulating the selection.
                const selection = [...filter.selection]
                let matched_option

                for (const option of filter.options) {
                    // Clicked item_id has a direct match
                    if (option[0] === item_id) {
                        matched_option = option
                    } else if (option.length === 4) {
                        // Clicked item_id matches one of the children in options
                        matched_option = option[3].find((i) => i[0] === item_id)
                    }

                    if (matched_option) {
                        break
                    }
                }

                if (matched_option && matched_option.length === 4) {
                    // The matched option has children; remove those from the selection as well
                    for (const option of matched_option[3]) {
                        const sub_option_index = selection.indexOf(option[0])
                        if (sub_option_index >= 0) {
                            selection.splice(sub_option_index, 1)
                        }
                    }
                }

                selection.splice(selection.findIndex((i) => i === item_id), 1)
                filter.selection.splice(0, filter.selection.length, ...selection)
            }}
            tip={() => {
                const name = $t(filter.title)
                let selection = title
                return $t('filters.actions.clear_filter_selection', {name, selection})
            }}
            type="info"
            variant="unset"
        >
            <Icon
                className="mr-05"
                name={(() => {
                    if (filter.icon) {
                        return filter.icon
                    }
                    return 'filter'
                })()}
                size="s"
                type="unset"
            />
            <span>
                {filter.options_i18n ? $t(`${filter.options_i18n}${title}`) : title}
                <Icon className="icon-close" name="filterRemove"/>
            </span>
        </Button>
    }

    render_filter(filter) {
        if (!is_filter_active(filter)) {
            return null
        }

        // See if we can match the filter title from an active option
        let matched_option_index

        if (filter.type === 'SELECT_MULTIPLE') {
            let item_ids
            const has_children = filter.options.some((i) => i.length >= 4)
            if (has_children) {
                // Remove selected children, if a parent is selected.
                item_ids = filter.options.map((i) => {
                    const ids = []
                    if (filter.selection.includes(i[0])) {
                        // Parent is selected; no need to add the children.
                        ids.push(i[0])
                    } else if (i.length >= 4) {
                        // Parent is not selected; add the children as filter buttons.
                        const subids = i[3].filter((_i) => filter.selection.includes(_i[0])).map(((_i) => _i[0]))
                        ids.push.apply(ids, subids)
                    }
                    return ids
                }).flat()
            } else {
                item_ids = [...filter.selection]
            }

            return item_ids.map((item_id) => {
                let title = item_id
                if (filter.options.length) {
                    const matched_option = this.matching_option(item_id, filter.options)
                    if (matched_option) {
                        title = matched_option[1]
                    }
                }
                return this.render_button(filter, title, item_id)
            })
        } else if (filter.type === 'TEXT') {
            if (Array.isArray(filter.selection)) {
                const unique_selections = [...new Set(filter.selection)]
                return unique_selections.map((title: string) => {
                    return this.render_button(filter, title, null)
                })
            }

            return this.render_button(filter, filter.selection, null)
        } else {
            let title
            // Range filter
            if (filter.type === 'SELECT_RANGE') {
                const from = filter.selection[0]
                let to = filter.selection[1] === Infinity ? '∞' : filter.selection[1]

                let i18n_unit = $t(`filters.units.${filter.unit}`, {count: filter.selection[1]})
                if (filter.selection[1] === Infinity) {
                    i18n_unit = $t(`filters.units.${filter.unit}`, {count: 2})
                }

                title = `${from} - ${to} ${i18n_unit}`
            } else if (filter.type === 'SELECT_SINGLE') {
                // Try to match the selected id with a name in options
                if (Array.isArray(filter.selection)) {
                    // Summary of a multiple selection when using single mode
                    title = `${filter.title}: ${filter.selection.length}`
                } else {
                    matched_option_index = filter.options.findIndex((i) => i[0] === filter.selection)
                    if (matched_option_index >= 0) {
                        title = filter.options[matched_option_index][1]
                    }
                }
            } else if (filter.type === 'TEXT') {
                title = filter.selection

            }

            return this.render_button(filter, title, null)
        }
    }

    view(vnode:m.Vnode<any>) {
        const active_filters = Object.values(vnode.attrs.collection.filters).filter((filter) => is_filter_active(filter))

        return <div className="c-filters-active">
            {(() => {
                if (!active_filters.length || this.data.loading || !vnode.attrs.collection.state.ready) {
                    return <div class="inactive">
                        {this.data.loading ? <Spinner size="l" /> : <Icon name="filterOff" />}
                    </div>
                }

                return <div class="filters">
                    {Object.values(vnode.attrs.collection.filters)
                        .filter((filter:any) => !filter.disabled)
                        .map((filter:any) => {
                            return this.render_filter(filter)
                        })}
                </div>
            })()}
        </div>
    }
}
