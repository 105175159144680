import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {jsPDF} from 'jspdf'
import {countries} from '@bitstillery/common/lib/countries'
import {DateTime} from 'luxon'
import {to_specs_with_features} from '@bitstillery/common/models/item'
import {PDF} from '@bitstillery/common/pdf/pdf'
import {Spinner} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'

import {PDFHelper, PDFComponent, PDFCommentTemplate, PDFUpdateAndDownload} from '../components/pdf_helper'

import {$s} from '@/app'
import {PurchaseApi, PurchaseOrderWithItemsResponse} from '@/factserver_api/purchase_api'
import {DefaultButton} from '@/components/buttons'

export interface ArrivalNoticeRendererAttrs {
    purchase_order: PurchaseOrderWithItemsResponse
}

/**
 * Maps the ArrivalNoticeRendererAttrs to base64 encoded string containing the Arrival Notice.
 *
 * @param attrs Needed data for the pdf (purchase order)
 * @returns Base64 encoded string for the created PDF.
 */
function render_to_base64(attrs: ArrivalNoticeRendererAttrs): jsPDF {
    const pdf = new PDF(attrs.purchase_order.account)
    const doc: jsPDF = pdf.document

    doc.setProperties({
        title: `Arrival Notice ${attrs.purchase_order.supplier.name} ${attrs.purchase_order.reference}`,
        author: attrs.purchase_order.account.name,
        keywords: 'Arrival Notice, Purchase',
        creator: attrs.purchase_order.account.name,
        subject: `Arrival Notice ${attrs.purchase_order.reference}`,
    })

    pdf.title('Arrival Notice')
    pdf.relation_address([
        attrs.purchase_order.supplier.name,
        attrs.purchase_order.supplier.street_address,
        attrs.purchase_order.supplier.zip_code
            ? `${attrs.purchase_order.supplier.zip_code} ${attrs.purchase_order.supplier.city}`
            : attrs.purchase_order.supplier.city,
        countries[attrs.purchase_order.supplier.country_code],
    ])

    pdf.incoterm(`${attrs.purchase_order.incoterm} - ${attrs.purchase_order.incoterm_location}`)

    const destination_address = [
        attrs.purchase_order.target_warehouse.name,
        attrs.purchase_order.target_warehouse.street_address,
        `${attrs.purchase_order.target_warehouse.zip_code} ${attrs.purchase_order.target_warehouse.city}`,
        countries[attrs.purchase_order.target_warehouse.country_code],
    ]
    destination_address.push(`Location ID: ${attrs.purchase_order.target_warehouse.warehouse_id}`)
    if (attrs.purchase_order.target_warehouse.excise_id) {
        destination_address.push(`Excise ID: ${attrs.purchase_order.target_warehouse.excise_id}`)
    }
    destination_address.push('')
    pdf.destination_address(destination_address)
    pdf.details(
        'Details:',
        ['Reference:', 'Date:', 'ETA:'],
        [
            attrs.purchase_order.reference,
            format_iso_to_date(DateTime.now().toISODate()),
            format_iso_to_date(attrs.purchase_order.expected_delivery_date),
        ],
    )

    pdf.arrival_notice_footer(attrs.purchase_order.account)

    const items = attrs.purchase_order.purchase_order_items
        /* Show as new items first, if all new sort on product name. */
        .sort((poi_a, poi_b) => {
            return poi_a.case.bottle.product.name.localeCompare(poi_b.case.bottle.product.name)
        })
        .map((poi) => {
            return [
                pdf.text_cell(`${poi.number_of_cases} cs.`),
                pdf.text_cell(`${poi.case.bottle.product.name}`),
                pdf.text_cell(to_specs_with_features(poi.case, poi.case.bottle, $s.identity.user.decimal_locale)),
                pdf.text_cell(poi.case.customs_status),
                pdf.text_cell(poi.case.bottle.loendersloot_id),
            ]
        })

    const main_table = pdf.auto_table(
        attrs.purchase_order.account,
        'en',
        ['Quantity', 'Description', '', 'Customs', 'Prod. Id'],
        items,
    )

    /* Total quantity */
    const total_quantity_in_cases = attrs.purchase_order.purchase_order_items.reduce(
        (previous, current) => current.number_of_cases + previous,
        0,
    )
    const total_quantity = `${total_quantity_in_cases} cs.`
    const quantity_table = pdf.total_quantity(pdf.text_cell(`${total_quantity}`), main_table)

    /* Render the comments, if any. */
    if (attrs.purchase_order.arrival_notice_comment) {
        pdf.additional_string_table(
            quantity_table,
            ['Comments:'],
            attrs.purchase_order.arrival_notice_comment.split('\n'),
        )
    }

    return doc
}

export default class ArrivalNotice extends MithrilTsxComponent<unknown> {
    purchase_order_artkey: number
    purchase_api = new PurchaseApi()
    purchase_order: PurchaseOrderWithItemsResponse | null = null
    pdf_helper = new PDFHelper<ArrivalNoticeRendererAttrs>(render_to_base64)
    selected_comment_artkey: string | null = null

    constructor() {
        super()

        if (!m.route.param('artkey')) {
            m.route.set('/purchase-orders/manage')
            throw Error('No purchase order artkey in route.')
        } else {
            this.purchase_order_artkey = +m.route.param('artkey')
            this.fetch_purchase_order()
        }
    }

    fetch_purchase_order(): void {
        this.purchase_api.purchase_order_with_items(this.purchase_order_artkey).subscribe({
            next: (response: PurchaseOrderWithItemsResponse[]) => {
                if (response.length !== 1) {
                    notifier.notify(`Cannot load purchase order with artkey ${this.purchase_order_artkey}`, 'warning')
                    m.route.set('/purchase-orders/manage')
                }
                this.purchase_order = response[0]

                this.rerender_pdf(false)
                m.redraw()
            },
            error: () => {
                notifier.notify(`Cannot load purchase order with artkey ${this.purchase_order_artkey}`, 'warning')
                m.route.set('/purchase-orders/manage')
            },
        })
    }

    save_arrival_notice(): void {
        if (!this.purchase_order) {
            return
        }
        this.purchase_api
            .update_arrival_notice(this.purchase_order.artkey, this.purchase_order.arrival_notice_comment)
            .subscribe({
                next: () => notifier.notify('Settings for RFP saved', 'success'),
                error: () => notifier.notify('Could not save the settings for the RFP', 'warning'),
            })
    }

    /** Rerender the pdf for this purchase order. Any pending changes concerning the pdf will be saved. */
    rerender_pdf(save_settings = true): void {
        if (!this.purchase_order) {
            return
        }
        if (save_settings) {
            this.save_arrival_notice()
        }
        this.pdf_helper.render_base64_encoded({
            purchase_order: this.purchase_order,
        })
    }

    view(): m.Children {
        if (!this.purchase_order) return <Spinner/>
        return (
            <div className="c-arrival-notice view pdf-view">
                <div className="btn-toolbar">
                    <DefaultButton
                        title=" Back to list"
                        icon_class="glyphicon glyphicon-arrow-left"
                        additional_class="btn-default"
                        onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}`)}
                    />
                </div>
                <h2>{this.purchase_order.reference}</h2>
                <div className="pdf-wrapper">
                    <form className="flex-form">
                        <div className="fieldset">
                            <PDFCommentTemplate
                                value={this.purchase_order.arrival_notice_comment}
                                onchange={(value: string) => {
                                    if (this.purchase_order) {
                                        this.purchase_order.arrival_notice_comment = value
                                        this.rerender_pdf(true)
                                    }
                                }}
                            />
                        </div>

                        <PDFUpdateAndDownload
                            rerender_pdf={() => this.rerender_pdf()}
                            pdf_file_name={`Arrival Notice - ${this.purchase_order?.reference || ''}.pdf`}
                            pdf_helper={this.pdf_helper}
                        />
                    </form>
                    <PDFComponent pdf_helper={this.pdf_helper} />
                </div>
            </div>
        )
    }
}
