import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {$s} from '@bitstillery/common/app'

export interface LinkAttrs {
    account?: 'a2bc' | 'msi' | 'msp'
    href: string
    onclick?: Function
    target?: string
    className?: string
    disabled?: boolean
    type?: string
}

export class Link extends MithrilTsxComponent<LinkAttrs> {
    get_link_target(link_attrs: LinkAttrs, is_external: boolean): string {
        if (link_attrs.target) {
            return link_attrs.target
        }

        if (is_external) {
            return '_blank'
        }

        if (process.env.MSI_PACKAGE === 'discover' && link_attrs.account) {
            return ($s.session.account.slug === link_attrs.account) ? '_self' : '_blank'
        }

        return '_self'
    }

    add_account_to_href(href: string, account: string): string {
        return `${href}${href.includes('?') ? '&' : '?'}account=${account}`
    }

    view(vnode: m.Vnode<LinkAttrs>): m.Children {
        let href = encodeURI(vnode.attrs.href)
        const is_external_link = href.startsWith('http') || href.startsWith('mailto:') || href.startsWith('tel:')
        const target = this.get_link_target(vnode.attrs, is_external_link)

        if (process.env.MSI_PACKAGE === 'discover' && !is_external_link && !href.includes('account=')) {
            href = this.add_account_to_href(href, vnode.attrs.account ?? $s.session.account.slug)
        }

        if (is_external_link) {
            // External links cannot be handled by m.route.Link
            return <a
                href={href}
                className={classes('c-link', 'no-click', `type-${vnode.attrs.type ? vnode.attrs.type : 'info'}`, vnode.attrs.className)}
                rel={'noreferrer noopener'}
                disabled={vnode.attrs.disabled}
                target={target}
            >{vnode.children}</a>
        }

        return m(m.route.Link, {
            onclick: (e) => {
                e.stopPropagation()
                $s.env.key = Date.now() // force rebuild of the filters.
                if (vnode.attrs.onclick) {
                    vnode.attrs.onclick(e)
                }
            },
            href: href,
            className: classes('c-link', 'no-click', `type-${vnode.attrs.type ? vnode.attrs.type : 'info'}`, vnode.attrs.className),
            rel: 'noreferrer noopener',
            disabled: vnode.attrs.disabled,
            target: target,
            tabindex: vnode.attrs.tabindex ? vnode.attrs.tabindex : -1,
        }, vnode.children)
    }
}
