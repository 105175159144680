import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, NotificationGroup} from '@bitstillery/common/components'
import {supported_languages} from '@bitstillery/common/lib/i18n'
import {get_support} from '@bitstillery/common/lib/support'
import {set_language} from '@bitstillery/common/lib/i18n'
import {$s, $t, logger} from '@bitstillery/common/app'

const language_flags = {de: 'de', 'en-GB': 'gb', es: 'es', fr: 'fr', it: 'it', nl: 'nl'}

export class LayoutLanding extends MithrilTsxComponent<any> {

    data = proxy({
        buttons: {
            nav: [
                {icon: 'sales', tip: $t._('page.title.registration'), link: '/registration'},
                {icon: 'login', tip: $t._('page.title.login_slug'), link: '/login'},
            ],
            support: [] as any,
        },
        language: {
            tld: '',
            fallback: 'en-GB',
        },
    })

    support: any

    oninit() {
        const language_tld = window.location.hostname.split('.').pop() as string
        if (supported_languages.map((i) => i[0]).includes(language_tld)) {
            // Do not set the language if the tld doesn't match with one of the i18n languages;
            // consequently, there will be no language picker.
            this.data.language.tld = language_tld
        }

        const language_override = m.route.param('language') ? m.route.param('language').replace('/', '') : null
        if (language_override) {
            if (language_override !== $s.language && supported_languages.map((i) => i[0]).includes(language_override)) {
                this.data.language.tld = language_override
                logger.debug(`language override from querystring: ${language_override}`)
                set_language(language_override)
            }
        }

        if (![this.data.language.fallback, this.data.language.tld].includes($s.language)) {
            // User may have a store with a different language set than the current.
            // Use this language as fallback, instead of the default 'en-GB'.
            this.data.language.fallback = $s.language
        }

        this.support = get_support()
        this.data.buttons.support = [
            {icon: 'phone', tip: $t._('support.contact.phone', {contact: this.support.phone}), link: `tel:${this.support.phone}`},
            {icon: 'email', tip: $t._('support.contact.email', {contact: this.support.email}), link: `mailto:${this.support.email}`},
        ]
    }

    view(vn:m.Vnode) {
        // This is a bare minimum layout for embedding views like register
        // in other sites; e.g. movingspirits.eu, a2bc.com
        // The iframe embedding depends on postMessage to dynamically
        // adjust its height based on the content. This is a bit
        // sensitive to styling changes. Checkout the common styleguide
        // for a test version of the iframe.
        if (m.route.param('mode') === 'embedded') {
            return (
                <div className="c-layout-landing">
                    <NotificationGroup notifications={$s.notifications}/>
                    {vn.children}
                </div>
            )
        }

        const register_urls = [
            m.route.get().startsWith('/registration'),
            m.route.get().startsWith('/user/reset-password'),
            m.route.get().startsWith('/user/forgot-password'),
        ]

        return (
            <div className={classes('c-layout-landing', vn.attrs.className)}>
                <div className={classes('landing-image landing-login', {
                    active: m.route.get().startsWith('/login') || m.route.get().startsWith('/privacy'),
                })}/>
                <div className={classes('landing-image landing-register', {
                    active: register_urls.find((match) => match),
                })}/>
                {process.env.MSI_PACKAGE === 'portal' && <div className={classes('landing-image landing-redeem', {
                    active: m.route.get().startsWith('/redeem'),
                })}>
                </div>}

                <div className="l-panel">
                    <div className="header">
                        <div className="logo">
                            <img src="/theme/img/logo.png"/>
                        </div>
                        <div className="sub-header">
                            <h3>{$s.page.title}</h3>
                            <p>{$s.page.subtitle}</p>
                            {(this.data.language.tld) &&
                            <div className="language-selection">
                                {this.data.language.tld && <div
                                    class={classes('option-tld', 'fi', `fi-${language_flags[this.data.language.tld]}`, {
                                        active: $s.language === this.data.language.tld,
                                    })}
                                    onclick={() => {set_language(this.data.language.tld)}}
                                />}

                                <div
                                    class={classes('option-fallback', 'fi', `fi-${language_flags[this.data.language.fallback]}`, {
                                        active: $s.language === this.data.language.fallback,
                                    })}
                                    onclick={() => {set_language(this.data.language.fallback)}}
                                />
                            </div>}
                        </div>
                        <div
                            className="support-manager"
                            style={{'background-image': `url(${this.support.img})`}}
                        >
                            <div className="name">
                                <span>{this.support.name}</span>
                                <span>{$t(this.support.role)}</span>
                            </div>
                            <NotificationGroup notifications={$s.notifications}/>
                            {(() => {
                                let buttons = this.data.buttons.support
                                // Navigation is hidden on hidden pages.
                                if (!m.route.get().startsWith('/redeem') && !m.route.get().startsWith('/user/reset-password')) {
                                    buttons = buttons.concat(this.data.buttons.nav)
                                }

                                return buttons.map(button => {
                                    const is_active = m.route.get() === button.link
                                    if (button.link === '/registration' && process.env.MSI_PACKAGE === 'discover') return null
                                    return <Button
                                        active={is_active}
                                        link={button.link}
                                        icon={button.icon}
                                        tip={button.tip}
                                        type={is_active ? 'info' : 'default'}
                                        variant="toggle"
                                    />
                                })
                            })()}
                        </div>
                    </div>

                    {vn.children}
                </div>
            </div>
        )
    }
}
