import m from 'mithril'
import {Country} from '@bitstillery/common/components'
import {
    format_iso_to_date,
    format_iso_to_date_time,
    format_money_with_symbol,
    titleize,
} from '@bitstillery/common/lib/format'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {DateTime} from 'luxon'
import {Tippy} from '@bitstillery/common/components'
import {Amount} from '@bitstillery/common/components'

import {accountIconBySlug} from '@/accounts'
import {$s} from '@/app'
import {DetailRow} from '@/components/details/detail_row'
import {InlineEditableText} from '@/components/html_components'
import {Link} from '@/components/discover'
import {PurchaseOrderStatus} from '@/models/purchase_orders'
import {PurchaseApi} from '@/factserver_api/purchase_api'
import {LOENDERSLOOT_WAREHOUSE_ARTKEY} from '@/factserver_api/factserver_generic'
import {GetPurchaseOrderResponse} from '@/factserver_api/fact2server_api'

interface PurchaseOrderDetailsAttrs {
    purchase_order: GetPurchaseOrderResponse
}

export class PurchaseOrderDetails extends MithrilTsxComponent<PurchaseOrderDetailsAttrs> {
    purchase_api = new PurchaseApi()

    update_remark(vnode: m.Vnode<PurchaseOrderDetailsAttrs>) {
        this.purchase_api.update_remark(vnode.attrs.purchase_order.artkey, vnode.attrs.purchase_order.remark || '').subscribe({
            next: () => {
                m.redraw()
            },
            error: () => {
            },
        })
    }

    view(vnode: m.Vnode<PurchaseOrderDetailsAttrs>): m.Children {
        const purchase_order = vnode.attrs.purchase_order
        const is_target_loendersloot = purchase_order.target_warehouse.artkey === LOENDERSLOOT_WAREHOUSE_ARTKEY
        const has_stock_item = [PurchaseOrderStatus.IN_STOCK, PurchaseOrderStatus.CONFIRMED].includes(
            purchase_order.status,
        )
        const planned_loading_time = purchase_order.planned_unloading_date
            ? DateTime.fromISO(purchase_order.planned_unloading_date).toISOTime()
            : null
        const has_loading_time = planned_loading_time && !planned_loading_time.startsWith('00:00:00')
        return (
            <div class="c-details columns">
                <div class="column is-half">
                    <DetailRow label={'Reference'} value={purchase_order.reference} />
                    <DetailRow label={'Supplier reference'} value={purchase_order.supplier_reference} />
                    <DetailRow
                        label={'For account'}
                        value={<span><span class="mr-05">
                            {accountIconBySlug(purchase_order.account.slug, purchase_order.account.slug)}
                        </span>{purchase_order.account.name}</span>}
                    />
                    <DetailRow label={'Created on'} value={format_iso_to_date_time(purchase_order.created_on)} />
                    <DetailRow label={'Created by'} value={purchase_order.was_handled_by.profile.name} />
                    <DetailRow label={'Purchase manager'} value={purchase_order.supplier.purchase_manager.profile.name} />
                    <DetailRow
                        label={'Status'}
                        value={<a target="_blank" href={`/stock/manage/?search=${vnode.attrs.purchase_order.reference}`}>
                            {purchase_order.status}
                        </a>}
                        visible={purchase_order.status === PurchaseOrderStatus.IN_STOCK}/>
                    <DetailRow
                        label={'Status'}
                        value={purchase_order.status}
                        visible={purchase_order.status !== PurchaseOrderStatus.IN_STOCK}/>
                    <DetailRow label={'Confirmation date'} value={format_iso_to_date(purchase_order.confirmation_date)} />
                    <DetailRow
                        label={'Intake date'}
                        hide_on_no_value={true}
                        value={format_iso_to_date(purchase_order.intake_date)} />
                    <DetailRow
                        label={'Sent to FIS date'}
                        hide_on_no_value={true}
                        value={format_iso_to_date(purchase_order.was_sent_to_financial_information_system_on)} />
                    <DetailRow label={'Communicated'} value={purchase_order.is_communicated_on
                        ? `Yes, on ${format_iso_to_date(purchase_order.is_communicated_on)}`
                        : 'Not yet'}/>
                    <DetailRow
                        label={'Relation'}
                        value={<div style={'display: flex; align-items: center'}>
                            <Country country_code={purchase_order.supplier.country_code} type={'flag_only'}/>
                            <Link href={`/crm/relations/${purchase_order.supplier.artkey}/edit`} >
                                {purchase_order.supplier.name}</Link>

                        </div>} />
                    <DetailRow label={'Currency'} value={purchase_order.was_bought_in} />
                    <DetailRow
                        label={'Exchange rate'}
                        value={Number(purchase_order.bought_against_rate).toFixed(4)}
                        visible={purchase_order.was_bought_in !== $s.currencies.default}
                    />
                    <DetailRow
                        label={'Remarks'}
                        value_class={'remark'}
                        value={
                            <InlineEditableText
                                model={[vnode.attrs.purchase_order, 'remark']}
                                onsave={() => this.update_remark(vnode)}
                                oncancel={(original_text: string) => vnode.attrs.purchase_order.remark = original_text}
                            />
                        }
                    />
                </div>
                <div class="column is-half">
                    <DetailRow label={'Insurance'} value={purchase_order.insurance_type.name} />
                    <DetailRow label={'Incoterm'} value={`${purchase_order.incoterm} - ${purchase_order.incoterm_location}`} />
                    <DetailRow label={'Origin warehouse'} value={purchase_order.origin_warehouse?.name} />
                    <DetailRow label={'Target warehouse'} value={purchase_order.target_warehouse.name} />
                    <DetailRow label={'Warehouse reference'} value={purchase_order.warehouse_reference} />
                    <DetailRow
                        label={'Costs per case'}
                        value={<Tippy content={'' +
                            `Warehouse costs: ${format_money_with_symbol(purchase_order.warehouse_base_costs || 0, 'EUR')} <br/>` +
                            `Warehouse costs per case: ${format_money_with_symbol(purchase_order.warehouse_costs_per_case || 0, 'EUR')}  <br/>` +
                            `Transport costs: ${format_money_with_symbol(purchase_order.transport_costs || 0, 'EUR')} <br/>` +
                            `Pallet costs: ${format_money_with_symbol(purchase_order.pallet_costs || 0, 'EUR')} <br/>` +
                            `Other costs: ${format_money_with_symbol(purchase_order.other_costs || 0, 'EUR')} <br/>` +
                            `Number of cases: ${purchase_order.number_of_cases} <br/>` +
                            ''}><Amount
                                amount={purchase_order.euro_costs_per_case}
                                currency={'EUR'}
                            /></Tippy>}
                        visible={has_stock_item}
                    />
                    <DetailRow
                        label={'Waste fund costs'}
                        value={<Amount
                            amount={purchase_order.waste_fund_costs}
                            currency={purchase_order.was_bought_in}
                        />}
                        visible={has_stock_item && purchase_order.supplier.country_code === 'NL'}
                    />
                    <DetailRow label={'Number of items'} value={purchase_order.number_of_order_lines} />
                    <DetailRow label={'Number of cases'} value={purchase_order.number_of_cases} />
                    <DetailRow label={'Total value'} value={ <Amount
                        amount={purchase_order.was_bought_for}
                        currency={purchase_order.was_bought_in}
                        rate={purchase_order.bought_against_rate}
                    /> } />
                    <DetailRow label={'Number of euro pallets'} value={purchase_order.number_of_euro_pallets} />
                    <DetailRow label={'Number of block pallets'} value={purchase_order.number_of_block_pallets} />
                    <DetailRow
                        label={'Sent to loendersloot on'}
                        visible={is_target_loendersloot}
                        value={format_iso_to_date_time(purchase_order.sent_to_loendersloot_on)} />
                    <DetailRow
                        label={'Expected delivery date'}
                        value={format_iso_to_date_time(purchase_order.expected_delivery_date)}
                        hide_on_no_value={true} />
                    <DetailRow
                        label={'Planned unloading date'}
                        visible={is_target_loendersloot}
                        hide_on_no_value={true}
                        value={has_loading_time ? format_iso_to_date_time(purchase_order.planned_unloading_date) : format_iso_to_date(purchase_order.planned_unloading_date)} />
                    <DetailRow
                        label={'Warehouse status'}
                        visible={is_target_loendersloot}
                        hide_on_no_value={true}
                        value={<span>
                            {titleize(purchase_order.warehouse_status || 'new')} - {' '}
                            {format_iso_to_date_time(purchase_order.last_warehouse_message)}
                        </span>} />
                    <DetailRow
                        label={'Loading info'}
                        visible={purchase_order.delivery}
                        hide_on_no_value={true}
                        value={<span>
                            {purchase_order.delivery?.weight_in_kilos && (
                                <div>{`${purchase_order.delivery.weight_in_kilos} kg`}</div>
                            )}
                            {purchase_order.delivery?.number_of_euro_pallets && (
                                <div>{`${purchase_order.delivery.number_of_euro_pallets} euro pallet(s)`}</div>
                            )}
                            {purchase_order.delivery?.number_of_block_pallets && (
                                <div>{`${purchase_order.delivery.number_of_block_pallets} block pallet(s)`}</div>
                            )}
                        </span>} />
                </div>
            </div>
        )
    }
}
