import {get_route} from '@bitstillery/common/lib/utils'

export interface SubMenuItem {
    icon: string
    url: string
    id?: string
    i18n: string
    children?: Array<Partial<SubMenuItem>>
}

export interface MenuItem {
    icon: string
    id: string
    url: string
    children: Array<Partial<SubMenuItem>>
    i18n: string
}

export const menu: Partial<MenuItem>[] = [
    {
        children: [
            {
                icon: 'compass',
                url: '/market/discover',
            },
            {
                icon: 'pricelist',
                url: get_route('/market/pricelists', {meta: true}),
            },
            {
                icon: 'thumbUp',
                url: '/market/best-buys',
            },
            {
                icon: 'history',
                url: '/market/explore',
            },
            {
                icon: 'chartBox',
                url: '/market/statistics',
            },
        ],
        icon: 'market',
        id: 'market',
        url: '/market/discover',
    },
    {
        children: [
            {
                icon: 'purchaseOrder',
                url: '/purchase-orders/manage',
            },
            {
                icon: 'purchaseOrderItems',
                url: '/purchase-orders/items/list',
            },
            {
                icon: 'tbo',
                url: '/purchase-orders/tbo',
            },
            {
                icon: 'warning',
                url: '/purchase-orders/up-is-up',
            },
        ],
        icon: 'cart',
        id: 'purchase-orders',
        url: '/purchase-orders/manage',
    },
    {
        icon: 'stock',
        id: 'stock',
        children: [
            {
                icon: 'stock',
                url: get_route('/stock/manage', {meta: true}),
            },
            {
                children: [
                    {
                        icon: 'stock',
                        url: '/stock/mutations/manage',
                    },
                    {
                        icon: 'transfer',
                        url: '/stock/mutations/move-to-warehouse',
                    },
                ],
                icon: 'list',
                id: 'mutations',

            },
            {
                icon: 'image',
                url: '/stock/image-review',
            },
            {
                icon: 'magnifyScan',
                url: '/stock/recon',
            },
        ],
        url: get_route('/stock/manage', {meta: true}),
    },
    {
        children: [
            {
                icon: 'pricelist',
                url: '/pricelist/offer-item-list',
            },
            {
                icon: 'ribbon',
                url: '/pricelist/spotlight',
            },
            {
                icon: 'compare',
                url: '/pricelist/compare-prices',
            },
            {
                icon: 'thumbUp',
                url: '/pricelist/approval',
            },
            {
                icon: 'thumbUp',
                url: '/pricelist/work',
            },
            {
                icon: 'handWave',
                url: '/pricelist/silent-stock',
            },
            {
                icon: 'magnifyScan',
                url: '/pricelist/products-of-interest',
            },
        ],
        icon: 'pricelist',
        id: 'pricelist',
        url: '/pricelist/offer-item-list',
    },
    {
        children: [
            {
                icon: 'relation',
                url: '/offer/relation-dashboard',
            },
            {
                icon: 'offers',
                url: '/offer/offers',
            },
            {
                icon: 'voucher',
                url: '/offer/vouchers',
            },
        ],
        icon: 'specialOffers',
        id: 'offer',
        url: '/offer/relation-dashboard',
    },
    {
        children: [
            {
                icon: 'list',
                url: get_route('/sales-orders/manage', {meta: true}),
            },
            {
                icon: 'handCoin',
                url: '/sales-orders/revenue',
            },
            {
                icon: 'runFast',
                url: '/sales-orders/targets',
            },
            {
                icon: 'checkboxMarked',
                url: '/sales-orders/margin-approval',
            },
            {
                icon: 'stock',
                url: '/sales-orders/assigned-stock',
            },
        ],
        icon: 'sales',
        id: 'sales-orders',
        url: get_route('/sales-orders/manage', {meta: true}),
    },
    {},
    {
        children: [
            {
                icon: 'chartBox',
                url: '/dashboard/figures',
            },
            {
                icon: 'chartPie',
                url: '/dashboard/reports',
            },
            {
                icon: 'chartPie',
                url: '/dashboard/diana',
            },
            {
                icon: 'chartGraph',
                url: '/dashboard/portal',
            },
            {
                icon: 'list',
                url: '/dashboard/search-log',
            },
        ],
        icon: 'dashboard',
        id: 'dashboard',
        url: '/dashboard/figures',
    },
    {
        children: [
            {
                icon: 'relation',
                url: '/crm/relations',
            },
            {
                icon: 'lead',
                url: '/crm/leads',
            },
            {
                icon: 'email',
                url: '/crm/email',
            },
        ],
        icon: 'crm',
        id: 'crm',
        url: '/crm/relations',
    },
    {
        children: [
            {
                icon: 'people',
                url: '/portal/portal-users',
            },
            {
                icon: 'people',
                url: '/portal/portal-demo-users',
            },
            {
                icon: 'news',
                url: '/portal/portal-news',
            },
            {
                icon: 'lightBulb',
                url: '/portal/portal-tips',
            },
            {
                icon: 'lightBulb',
                url: '/portal/portal-quotes',
            },
        ],
        icon: 'portal',
        id: 'portal',
        url: '/portal/portal-users',
    },
    {},
    {
        children: [
            {
                icon: 'cog',
                id: 'settings',
                children: [
                    {
                        icon: 'people',
                        url: '/data/user-manage',
                    },
                    {
                        icon: 'cog',
                        url: '/data/system-parameters',
                    },
                ],
            },
            {
                icon: 'product',
                id: 'products',
                children: [
                    {
                        icon: 'productCategories',
                        url: '/data/products',
                    },
                    {
                        icon: 'productCategories',
                        url: '/data/product-categories',
                    },
                    {
                        icon: 'shieldHalfFull',
                        url: '/data/suppressed-product-keys',
                    },
                    {
                        icon: 'itemTags',
                        url: '/data/item-tags',
                    },
                    {
                        icon: 'brandHolders',
                        url: '/data/brand-holders',
                    },
                    {
                        icon: 'brands',
                        url: '/data/brands',
                    },
                ],
            },
            {
                icon: 'finance',
                children: [
                    {
                        icon: 'paymentSettings',
                        url: '/data/paymentterms',
                    },
                    {
                        icon: 'ledgers',
                        url: '/data/ledgers',
                    },
                    {
                        icon: 'customs',
                        url: '/data/excise-categories',
                    },
                    {
                        icon: 'margins',
                        url: '/data/margin-thresholds',
                    },
                    {
                        icon: 'vatTypes',
                        url: '/data/vattypes',
                    },
                    {
                        icon: 'vatRates',
                        url: '/data/vatrates',
                    },
                    {
                        icon: 'vatRates',
                        url: '/data/vatcheck',
                    },
                ],
                url: '/finance',
            },
            {
                icon: 'logistics',
                children: [
                    {
                        icon: 'warehouse',
                        url: '/data/warehouses',
                    },
                    {
                        icon: 'insurance',
                        url: '/data/insurancetypes',
                    },
                    {
                        icon: 'attachment',
                        url: '/data/attachment-categories',
                    },
                    {
                        icon: 'systemMessage',
                        url: '/data/loendersloot-messages',
                    },
                    {
                        icon: 'systemMessage',
                        url: '/data/loendersloot-tags',
                    },
                ],
                url: '/logistics',
            },
            {
                children: [
                    {
                        icon: 'productCategories',
                        url: '/data/commenttemplates',
                    },
                    {
                        icon: 'productCategories',
                        url: '/data/email-placeholders',
                    },
                ],
                icon: 'communication',
                id: 'templates',
            },
            {
                icon: 'cog',
                children: [
                    {
                        icon: 'export',
                        url: '/data/afvalfonds',
                    },
                    {
                        icon: 'export',
                        url: '/data/cbs',
                    },
                ],
                url: '/misc',
            },

        ],
        icon: 'cog',
        id: 'data',
        url: '/data/user-manage',
    },
    {
        children: [
            {
                icon: 'settings',
                url: '/my-discover/profile',
            },
            {
                icon: 'holiday',
                url: '/my-discover/dashboard',
            },
            {
                icon: 'userSchedule',
                url: '/my-discover/colleagues',
            },
            {
                icon: 'download',
                url: '/my-discover/downloads',
            },

        ],
        id: 'my-discover',
        icon: 'settings',
        url: '/my-discover/profile',
    },
]

// Generate i18n keys, instead of hardcoding them.
function generate_i18n(menu) {
    if (!menu.url && !menu.id) return

    if (menu.id) {
        menu.i18n = menu.id.replaceAll('-', '_')
    } else {
        menu.i18n = menu.url.split('?')[0].replace('/', '').replaceAll('/', '_').replaceAll('-', '_')
    }
    if (menu.children) {
        for (const menu_item of menu.children) {
            generate_i18n(menu_item)
        }
    }
}

for (const menu_item of menu) {
    generate_i18n(menu_item)
}
