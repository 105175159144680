import m from 'mithril'
import {classes, copy_object, data_from_blueprint, merge_deep} from '@bitstillery/common/lib/utils'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {Button, Icon} from '@bitstillery/common/components'
import {$s, api, logger} from '@bitstillery/common/app'
import {watch} from '@bitstillery/common/lib/store'
import {debounce} from '@bitstillery/common/lib/utils'
import {reset_validation} from '@bitstillery/common/lib/validation'

interface PanelContextAttrs {
    className?: string
    collection?: any
    count?: number
    icon?: string
    keep_open?: boolean
    minimizable?: boolean
    title?: string
}

// One-time trigger to try to restore the current step from the url.
let onpageloaded = false

export class PanelContext extends MithrilTsxComponent<PanelContextAttrs> {
    watchers = [] as any

    oninit(vnode) {
        if (vnode.attrs.icon) {
            // Used in the context toggles in panel_menu
            $s.context.icon = vnode.attrs.icon
        }

        if (!vnode.attrs.keep_open) {
            $s.panels.context.collapsed = true
        }

        const update_context = debounce(100, async() => {
            this.oncontextchange(vnode)
        })

        this.watchers.push(watch($s.context, 'name', (new_name) => {
            // Watch the context name to collapse the panel if it's null;
            // the context is mutated from multiple locations; toggling
            // the collapsed state happens here however.
            $s.panels.context.collapsed = !new_name

            if (vnode.attrs.context?.name === new_name) {
                update_context(vnode)
            }
        }))

        // Context new style; a common context object that describes the workflow of a page.
        if (vnode.attrs.context) {
            // Represents the route that fits the current state of the context.
            vnode.attrs.context.model_to_route = (params_override) => this.model_to_route(vnode, params_override)
            // Switch global context state to the currently used context.
            $s.context.data = vnode.attrs.context.data

            this.context_data_reset = copy_object(vnode.attrs.context.data)

            this.watchers.push(watch($s.context, 'id', update_context.bind(this)))
            this.watchers.push(watch($s.context, 'key', () => {
                update_context(vnode)
            }))

            if (vnode.attrs.context.stepper) {
                // Make the stepper accessible for other components; e.g. RowActionEdit.
                $s.context.stepper = vnode.attrs.context.stepper
                this.watchers.push(watch(vnode.attrs.context.stepper, 'selection', () => {
                    const context_route = this.model_to_route(vnode)
                    m.route.set(context_route, undefined, {replace: true})
                }))
            }

            const route = m.parsePathname(m.route.get())
            const params = m.route.param()
            if (route.path.startsWith(vnode.attrs.context.routes.base)) {
                // The base route for context handling matches; setup initial state.
                Object.assign($s.context, {
                    key: $s.context.key ? $s.context.key : Date.now(),
                    id: params.artkey ? Number(params.artkey) : null,
                    name: vnode.attrs.context.name,
                    title: params.artkey ? `Edit ${vnode.attrs.context.entity}` : `Create ${vnode.attrs.context.entity}`,
                })
            } else {
                vnode.attrs.context.stepper.loading = false
            }
        }
    }

    onremove(vnode) {
        this.watchers.map((unwatch) => unwatch())

        if (!vnode.attrs.keep_open) {
            $s.panels.context.collapsed = true
        }
        if (vnode.attrs.context) {
            if (vnode.attrs.context.stepper) {
                vnode.attrs.context.stepper.selection = 0
            }
            this.model_reset(vnode)
        }
    }

    /**
     * Based on the combination of id & name, either a context is:
     *  - refreshed from an endpoint
     *  - the context is reset to its initial new state
     *  - the context is removed and the panel is closed.
     * @param vnode
     */
    async oncontextchange(vnode) {
        const context = vnode.attrs.context
        const params = m.route.param()
        delete params.artkey

        if ($s.context.id && $s.context.name) {
            logger.debug(`[panel_context] loading context from: ${context.routes.context()}`)
            const {result} = await api.get(context.routes.context()) as any
            data_from_blueprint(context.data, copy_object(result))
            if (context.data_transform) {
                context.data_transform(context.data, result)
            }
        } else {
            if ($s.context.name) {
                logger.debug('[panel_context] update context for create')
            }

            this.model_reset(vnode)
        }

        if (context.stepper) {
            if (!onpageloaded) {
                // On initial page-load, we do an attempt to restore the current selected step.
                // Afterwards, changing the context to a different item will reset the step to 0.
                const params = m.route.param()
                if (params.step) {
                    context.stepper.selection = Number(params.step)
                    logger.debug(`[pane_context] restore step ${context.stepper.selection} from url`)
                }
                onpageloaded = true
            }

            context.stepper.loading = false
        }

        const context_route = this.model_to_route(vnode)
        m.route.set(context_route, undefined, {replace: true})
    }

    model_reset(vnode) {
        const context = vnode.attrs.context
        if (context.stepper) {
            context.stepper.selection = 0
        }
        logger.info('[panel_context] reset context model to initial state')
        merge_deep(context.data, copy_object(this.context_data_reset))
        if (context.$v) {
            reset_validation(context.$v)
        }

        if (context.data_transform) {
            context.data_transform(context.data)
        }
    }

    model_to_route(vnode, params_override = {}) {
        const params = {...m.route.param(), ...params_override}
        delete params.artkey

        let path
        if ($s.context.id) {
            path = `/market/pricelists/upsert/${$s.context.id}`
        } else if ($s.context.name) {
            path = '/market/pricelists/upsert'
        } else {
            delete params.step
            path = '/market/pricelists'
        }
        if (!('step' in params_override)) {
            params.step = vnode.attrs.context.stepper.selection
        }
        const route_path = m.buildPathname(path, {...params, key: this.key})
        return route_path
    }

    view(vnode:m.Vnode<PanelContextAttrs>):m.Children {
        // The count is used to show (optional) extra context
        // next to the PanelContext toggle in PanelMenu.
        if (vnode.attrs.count) {
            $s.context.count = vnode.attrs.count()
        } else if ($s.context.count !== null) {
            $s.context.count = null
        }

        let current_index = -1

        if (vnode.attrs.collection && $s.context.data && $s.context.data.artkey) {
            current_index = vnode.attrs.collection.state.items.findIndex((i) => {
                return i.artkey === $s.context.data.artkey
            })
        }

        return <div
            className={classes('c-panel-context', vnode.attrs.className, 'float-panel', {
                collapsed: $s.panels.context.collapsed,
                'float-panel--active': $s.env.layout === 'mobile',
                minimizable: vnode.attrs.minimizable,
            })}
            onkeydown={(e) => {
                // Only navigate context when ctrl is pressed.
                if (!e.ctrlKey) return
                if (e.key === 'ArrowUp' && current_index > 0 && !$s.context.loading) {
                    vnode.attrs.collection.select_previous()
                } else if (e.key === 'ArrowDown' && current_index < vnode.attrs.collection.state.items.length - 1 && !$s.context.loading) {
                    vnode.attrs.collection.select_next()
                }
            }}
            tabindex="0"
        >
            <header onclick={() => {
                if (vnode.attrs.minimizable) {
                    $s.panels.context.collapsed = !$s.panels.context.collapsed
                }
            }}>
                <div className="title">
                    <Icon
                        className="icon-d"
                        name={(() => {
                            if ($s.context.icon) return $s.context.icon
                            if (vnode.attrs.icon) return vnode.attrs.icon
                            return 'card_account_details_outline'
                        })()}
                    />
                    <span>{(() => {
                        if ($s.context.title) return $s.context.title
                        if (vnode.attrs.title) return vnode.attrs.title
                        return 'Context Panel'
                    })()}</span>
                </div>
                <div className="panel-actions">
                    {(() => {
                        // No collection to operate on; skip collection actions
                        if (!vnode.attrs.collection || !$s.context.data) return
                        if (!$s.context.id) return

                        return [
                            <Button
                                disabled={current_index <= 0 || $s.context.loading}
                                icon="arrowUpCircle"
                                onclick={() => vnode.attrs.collection.select_previous()}
                                tip="Previous item in the collection (ctrl + left key)"
                                type="info"
                                variant="toggle"
                            />,
                            <Button
                                disabled={(current_index >= vnode.attrs.collection.state.items.length - 1) || $s.context.loading}
                                icon="arrowDownCircle"
                                onclick={() => vnode.attrs.collection.select_next()}
                                tip="Next item in the collection (ctrl + right key)"
                                type="info"
                                variant="toggle"
                            />,
                        ]
                    })()}

                    {!vnode.attrs.minimizable && <Button
                        icon="close"
                        tip="Close this panel"
                        onclick={() => {
                            Object.assign($s.context, {
                                data: {},
                                icon: null,
                                id: null,
                                name: null,
                            })
                        }}
                        variant="toggle"
                    />}
                </div>
            </header>
            {vnode.children}
        </div>
    }
}
