import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_relative} from '@bitstillery/common/lib/format'
import {DateTime} from 'luxon'
import {notifier} from '@bitstillery/common/app'
import {FieldSelect} from '@bitstillery/common/components'

import {DisplayMode} from './notifications'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {CheckBox} from '@/components/html_components'
import {Notification, NotificationApi} from '@/factserver_api/notification_api'

interface NotificationsTableProps {
    notifications_fetcher: PagedCollectionFetcher<Notification>
    selected_display_mode: DisplayMode
}

export class NotificationsTable extends MithrilTsxComponent<NotificationsTableProps> {
    selected_notifications: number[] = []
    scrollable_element: HTMLElement | null = null
    notifications_fetcher: PagedCollectionFetcher<Notification>

    notification_api = new NotificationApi()
    selected_snoozing_period = ''

    _cached_display_mode: DisplayMode | null = null

    constructor(vnode: m.Vnode<NotificationsTableProps>) {
        super()
        this.notifications_fetcher = vnode.attrs.notifications_fetcher
    }

    oncreate(): void {
        this.scrollable_element = document.getElementById('search-table-holder')
    }

    onupdate(vnode: m.Vnode<NotificationsTableProps>): void {
        if (this._cached_display_mode !== vnode.attrs.selected_display_mode) {
            this._cached_display_mode = vnode.attrs.selected_display_mode
            this.selected_notifications = []
        }
    }

    notification_as_link(notification: Notification): JSX.Element {
        return (
            <a href={`#!${notification.link[0]}`} onclick={() => this.mark_as_seen([notification.artkey])}>
                {notification.link[1]}
            </a>
        )
    }

    mark_as_seen(notification_artkeys: number[]): void {
        this.notification_api
            .mark_as_seen({
                notification_artkeys: notification_artkeys,
            })
            .subscribe(() => {
                this.notifications_fetcher.reset_and_query()
                m.redraw()
            })
    }

    mark_selected_as_seen(): void {
        this.mark_as_seen(this.selected_notifications)
        const now = DateTime.now()
        this.notifications_fetcher.fetched_rows
            .filter((notification) => this.selected_notifications.includes(notification.artkey))
            .forEach((notification) => {
                notification.is_seen_at = now.toISO()
                notification.is_read = true
            })
        this.selected_notifications = []
    }

    reload(): void {
        this.selected_notifications = []
        this.notifications_fetcher.reset_and_query()
    }

    block_selected_types(): void {
        const blocked_types = this.notifications_fetcher.fetched_rows
            .filter((notification) => this.selected_notifications.includes(notification.artkey))
            .map((notification) => notification.subject)
        const unique_blocked_types = Array.from(new Set(blocked_types))
        this.notification_api
            .block_subjects({
                subjects: unique_blocked_types,
            })
            .subscribe(() => {
                this.reload()
            })
    }

    unblock_selected_types(): void {
        const blocked_types = this.notifications_fetcher.fetched_rows
            .filter((notification) => this.selected_notifications.includes(notification.artkey))
            .map((notification) => notification.subject)
        const unique_blocked_types = Array.from(new Set(blocked_types))
        this.notification_api
            .unblock_subjects({
                subjects: unique_blocked_types,
            })
            .subscribe(() => {
                this.reload()
            })
    }

    toggle_select_all(): void {
        const fetched_rows = this.notifications_fetcher.count_fetched_rows()
        if (fetched_rows === this.selected_notifications.length) {
            this.selected_notifications = []
        } else {
            this.selected_notifications = this.notifications_fetcher.fetched_rows.map(
                (notification) => notification.artkey,
            )
        }
    }

    toggle_notification_selection(notification: Notification): void {
        if (this.selected_notifications.includes(notification.artkey)) {
            this.selected_notifications = this.selected_notifications.filter((artkey) => String(artkey) !== String(notification.artkey))
        } else {
            this.selected_notifications.push(notification.artkey)
        }
    }

    snooze_notifications_for(days: string): void {
        this.selected_snoozing_period = days
        this.notification_api.snooze_notifications(this.selected_notifications, +days).subscribe({
            next: () => {
                this.reload()
                this.selected_snoozing_period = ''
                notifier.notify(`Notifications will be snoozed for ${days} days`, 'info')
            },
        })
    }

    view(vnode: m.Vnode<NotificationsTableProps>): m.Children {
        return (
            <div className="c-notifications-table">
                <div className="actions">
                    <CheckBox
                        onchange={() => this.toggle_select_all()}
                        title={''}
                        checked={
                            this.selected_notifications.length ===
                                vnode.attrs.notifications_fetcher.count_fetched_rows() &&
                            !vnode.attrs.notifications_fetcher.is_fetching
                        }
                        id={'select-all-notifications'}
                    />
                    <span className={'btn-group'}>
                        <button
                            type="button"
                            class="btn btn-default btn-sm"
                            disabled={
                                this.selected_notifications.length === 0 ||
                                vnode.attrs.selected_display_mode === DisplayMode.SEEN_NOTIFICATIONS
                            }
                            data-toggle="tooltip"
                            data-placement="top"
                            title={`Mark ${this.selected_notifications.length} notifications as seen`}
                            onclick={() => this.mark_selected_as_seen()}
                        >
                            <i class="glyphicon glyphicon-eye-open" />
                        </button>
                        <button
                            type="button"
                            disabled={
                                this.selected_notifications.length === 0 ||
                                vnode.attrs.selected_display_mode === DisplayMode.SEEN_NOTIFICATIONS ||
                                vnode.attrs.selected_display_mode === DisplayMode.BLOCKED_NOTIFICATIONS
                            }
                            class="btn btn-default btn-sm"
                            title={'Block notifications like selected.'}
                            onclick={() => this.block_selected_types()}
                        >
                            <i class="glyphicon glyphicon-volume-off" />
                        </button>
                        <button
                            type="button"
                            disabled={
                                this.selected_notifications.length === 0 ||
                                vnode.attrs.selected_display_mode === DisplayMode.NOTIFICATIONS ||
                                vnode.attrs.selected_display_mode === DisplayMode.SEEN_NOTIFICATIONS
                            }
                            class="btn btn-default btn-sm"
                            title={'Unblock notifications like selected.'}
                            onclick={() => this.unblock_selected_types()}
                        >
                            <i class="glyphicon glyphicon-volume-up" />
                        </button>
                        <button
                            type="button"
                            disabled={vnode.attrs.notifications_fetcher.is_fetching}
                            class="btn btn-default btn-sm"
                            title={'Reload latest notifications.'}
                            onclick={() => this.reload()}
                        >
                            <i class="glyphicon glyphicon-refresh" />
                        </button>
                        <FieldSelect
                            disabled={this.selected_notifications.length === 0}
                            model={[this, 'selected_snoozing_period']}
                            onchange={(value: string) => this.snooze_notifications_for(value)}
                            options={[
                                {value: '1', label: 'Tomorrow'},
                                {value: '2', label: 'In two days'},
                                {value: '7', label: 'Next week'},
                            ]}
                            placeholder="Remind me again..."
                        />
                    </span>
                </div>
                <CollectionTable<Notification, unknown>
                    collection_fetcher={vnode.attrs.notifications_fetcher}
                    is_header_sticky={false}
                    is_header_visible={false}
                    scrollable_dom_element={() => this.scrollable_element}
                    dynamic_tr_classname={(notification: Notification) => (notification.is_seen_at ? 'is-seen' : '')}
                >
                    <CollectionTableColumn<Notification>
                        header_title={() => ''}
                        sort_name={'artkey'}
                        data_field={(notification: Notification) => (
                            <span onclick={(event: Event) => event.stopPropagation()}>
                                <CheckBox
                                    id={`notification-${notification.artkey}`}
                                    onchange={() => this.toggle_notification_selection(notification)}
                                    title={''}
                                    checked={this.selected_notifications.includes(notification.artkey)}
                                />
                            </span>
                        )}
                    />
                    <CollectionTableColumn<Notification>
                        header_title={() => 'Entity'}
                        sort_name={'entity'}
                        data_field={(notification: Notification) => {
                            const is_read_classname = notification.is_read ? 'is-read' : ''
                            return (
                                <div className={`${is_read_classname}`}>{this.notification_as_link(notification)}</div>
                            )
                        }}
                    />
                    <CollectionTableColumn<Notification>
                        header_title={() => 'Message'}
                        sort_name={'message'}
                        data_field={(notification: Notification) => notification.message}
                    />
                    <CollectionTableColumn<Notification>
                        header_title={() => 'Date'}
                        sort_name={'message'}
                        data_field={(notification: Notification) => format_iso_to_relative(notification.date)}
                    />
                </CollectionTable>
            </div>
        )
    }
}
