// eslint-disable-next-line no-unused-vars
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {CURRENCY_SYMBOLS, format_money_responsive} from '@bitstillery/common/lib/format'
import {offer_item_price} from '@bitstillery/common/lib/price'
import {classes} from '@bitstillery/common/lib/utils'

/**
 * This is a regular price. OfferItems with type offer/spotlight
 * use the Changed component.
 */
export class OfferItemAmount extends MithrilTsxComponent<any> {
    view(vnode:m.Vnode<any>) {
        const offer_item = vnode.attrs.offer_item
        let price_info
        if (offer_item) {
            price_info = offer_item_price(offer_item)
        }

        return <span className={classes('c-offer-item-amount', 'c-changed', price_info?.is_down ? 'type-brand' : '')}>
            {!!price_info && price_info.is_down && <span className={'previous'}>{format_money_responsive(price_info.previous)}</span>}
            <span className={'current'}>
                {!!vnode.attrs.currency && `${CURRENCY_SYMBOLS[vnode.attrs.currency]} `}
                {format_money_responsive(Number(vnode.attrs.value ??= 0))}
            </span>
        </span>
    }
}
