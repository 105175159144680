import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {to_specs} from '@bitstillery/common/models/item'
import {Button, FieldSelect, Tippy} from '@bitstillery/common/components'
import {api, notifier} from '@bitstillery/common/app'
import {Spinner} from '@bitstillery/common/components'

import {HorizontalForm} from '../components/form'
import {ProductPhotoHorizontalView} from '../components/product_photos/product_photo_horizontal_view'

import {LoenderslootInspectionItemView} from './components/loendersloot_inspection_item_view'
import {PurchaseOrderDetails} from './view_purchase_order/details'

import {$s} from '@/app'
import {
    GetProductPhotoResponse,
    GetPurchaseOrderItemResponse,
    GetPurchaseOrderResponse,
} from '@/factserver_api/fact2server_api'
import {ProductPhotoApi} from '@/factserver_api/product_photos'
import {
    CollectionTable,
    CollectionTableColumn,
    CollectionTableRowComponentProps,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {
    GetLoenderslootInspectionItemResponse,
    PurchaseApi,
    PurchaseOrderItemResponse,
} from '@/factserver_api/purchase_api'
import {SearchBar, SearchBarControl} from '@/components/collection/search_bar'
import {DangerButton, SaveButton, SuccessButton} from '@/components/buttons'
import {CheckBox} from '@/components/html_components'
import {PurchaseOrderStatus} from '@/models/purchase_orders'
import {gift_box_types} from '@/models/data'

interface LoenderslootInspectionItem extends GetLoenderslootInspectionItemResponse {
    is_showing_details: boolean
}

export default class InStockLoenderslootPurchaseOrder extends MithrilTsxComponent<void> {
    search_text = ''
    purchase_order_artkey: number
    purchase_order: GetPurchaseOrderResponse | null = null
    purchase_order_items: GetPurchaseOrderItemResponse[] | null = null
    search_bar_controller: SearchBarControl | null = null
    suggestions: string[] = []
    in_stockable = false
    on_holdable = false
    refetch_key = Math.random()

    purchase_api = new PurchaseApi()
    loendersloot_inspection_items_fetcher = new PagedCollectionFetcher<LoenderslootInspectionItem>(
        'purchase.loendersloot.get_loendersloot_inspection_items',
        'created_on',
        undefined,
        20,
    )

    search_for_search_text = (search_text: string): void => {
        this.search_text = search_text
        this.loendersloot_inspection_items_fetcher.set_search_terms(search_text)
    }

    constructor() {
        super()
        this.purchase_order_artkey = +m.route.param('artkey')
        this.load_purchase_order(this.purchase_order_artkey)
        this.loendersloot_inspection_items_fetcher.filters['only_in_stockable'] = true
        this.loendersloot_inspection_items_fetcher.filters['purchase_order_artkey'] = this.purchase_order_artkey
    }

    bring_in_stock(): void {
        this.purchase_api.instock_purchase_order(this.purchase_order_artkey).subscribe({
            next: () => {
                notifier.notify('Purchase order brought in stock successfully.', 'success')
                m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}`)
            },
            error: () => null,
        })
    }

    set_on_onhold(): void {
        this.purchase_api.onhold_purchase_order(this.purchase_order_artkey).subscribe({
            next: () => {
                notifier.notify('Purchase order finalized and set to on hold.', 'success')
                m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}`)
            },
            error: () => null,
        })
    }

    determine_if_in_stockable(): boolean {
        return (
            this.purchase_order?.status === PurchaseOrderStatus.CONFIRMED
            && this.purchase_order_items !== null
            && this.purchase_order_items.map((poi) => poi.is_in_stock_loendersloot).every(Boolean)
        )
    }

    /**
     * It is on holdable if any loendersloot inspection item is set to in conflict or in hold.
     */
    determine_if_on_holdable() {
        if (this.purchase_order !== null && this.purchase_order.status !== PurchaseOrderStatus.CONFIRMED) {
            this.on_holdable = false
            return
        }
        this.purchase_api.get_loendersloot_inspection_items(this.purchase_order_artkey, true).subscribe({
            next: (result) => {
                this.on_holdable = result.length !== 0
            },
            error: () => null,
        })
    }

    async load_purchase_order(purchase_order_artkey: number): void {
        const [{result: purchase_order}, {result: purchase_order_items}] = await Promise.all([
            api.get<GetPurchaseOrderResponse>(`discover/purchase-orders/${purchase_order_artkey}`),
            api.get<GetPurchaseOrderItemResponse[]>(`discover/purchase-orders/${purchase_order_artkey}/items`),
        ])

        this.purchase_order = purchase_order
        this.purchase_order_items = purchase_order_items

        if (
            this.purchase_order.status !== PurchaseOrderStatus.CONFIRMED &&
            this.purchase_order.status !== PurchaseOrderStatus.ON_HOLD
        ) {
            notifier.notify(
                'This order does not have the status confirmed. Only confirmed orders can be brought in stock.',
                'danger',
            )
            m.route.set(`/purchase_orders/manage/${this.purchase_order.artkey}`)
        } else {
            this.in_stockable = this.determine_if_in_stockable()
            this.determine_if_on_holdable()
            this.suggestions = this.purchase_order_items.map((item) => item.case.bottle.product.name)
        }
        m.redraw()
    }

    on_item_resolved(): void {
        this.load_purchase_order(this.purchase_order_artkey)
        this.refetch_key = Math.random() // Force a re-render of the table.
        this.loendersloot_inspection_items_fetcher.reset_and_query()
    }

    view(): m.Children {
        return (
            <div className="c-instock-loendersloot view">
                <div className="row" id="button-bar">
                    <div className="col-lg-12 btn-toolbar" role="group">
                        <Button
                            text="Back to order"
                            icon="back"
                            onclick={() => m.route.set(`/purchase-orders/manage/${this.purchase_order_artkey}`)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <h2>Bring purchase order items in stock</h2>{' '}
                    </div>
                </div>

                {this.purchase_order && (
                    <div>
                        <div key='details'>
                            <PurchaseOrderDetails purchase_order={this.purchase_order}/>

                            <p>Please match all items arrived at Loendersloot to purchase order items.</p>

                            <div className='btn-group-vertical'>
                                <Tippy content={'Bring the purchase order in stock and do not notify purchase manager of any issues. Will enable when all items are in stock.'}>
                                    <SaveButton disabled={!this.in_stockable} title="Complete purchase order" onclick={() => this.bring_in_stock()}/>
                                </Tippy>
                                <Tippy content={'If the purchase order had any issues and should be checked by the purchase manager, set it to on hold.'}>
                                    <DangerButton disabled={!this.on_holdable} icon_class={'fas fa-hand-paper'} onclick={() => this.set_on_onhold()}>
                                        {' '}
                                        Set purchase order on hold
                                    </DangerButton>
                                </Tippy>
                            </div>
                        </div>
                        <div className={'row'} key='search_bar'>
                            <div className={'col-sm-8'}>
                                <label>Product name</label>
                                <SearchBar
                                    placeholder={'Search for name and/or category'}
                                    on_submit={this.search_for_search_text}
                                    suggestions={this.suggestions}
                                    default_search_text={this.loendersloot_inspection_items_fetcher.search_text()}
                                    search_bar_controller={(controller: SearchBarControl) =>
                                        (this.search_bar_controller = controller)
                                    }
                                />
                            </div>
                        </div>

                        {this.purchase_order && (
                            <CollectionTable<LoenderslootInspectionItem, InStockLoenderslootInspectionItemRowAttrs>
                                row_component={InStockLoenderslootInspectionItemRow}
                                collection_fetcher={this.loendersloot_inspection_items_fetcher}
                                additional_row_component_args={{
                                    purchase_order_items: this.purchase_order_items,
                                    on_item_resolved: () => this.on_item_resolved(),
                                }}
                                key={this.refetch_key}
                            >
                                <CollectionTableColumn<LoenderslootInspectionItem>
                                    header_title={() => 'Loendersloot inspection item'}
                                />
                            </CollectionTable>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

interface InStockLoenderslootInspectionItemRowAttrs {
    purchase_order_items: GetPurchaseOrderItemResponse[]
    on_item_resolved: () => unknown
}

class InStockLoenderslootInspectionItemRow extends MithrilTsxComponent<CollectionTableRowComponentProps<LoenderslootInspectionItem, InStockLoenderslootInspectionItemRowAttrs>> {
    row: LoenderslootInspectionItem
    additional_args: InStockLoenderslootInspectionItemRowAttrs
    matched_poi_artkey: number | null

    product_photo_api = new ProductPhotoApi()
    product_photos: GetProductPhotoResponse[] = []
    is_fetching_photos: boolean = false

    constructor(
        vnode: m.Vnode<CollectionTableRowComponentProps<LoenderslootInspectionItem, InStockLoenderslootInspectionItemRowAttrs>>,
    ) {
        super()
        if (!vnode.attrs.additional_args) {
            throw new Error('Additional args required')
        }
        this.row = vnode.attrs.row
        this.additional_args = vnode.attrs.additional_args
        this.matched_poi_artkey = vnode.attrs.row.purchase_order_item_artkey
        this.fetch_product_photos(this.row.lot)
    }

    get poi_items() {
        return this.additional_args.purchase_order_items.sort((a, b) => {
            // Sort by product name.
            const name_a = a.case.bottle.product.name
            const name_b = b.case.bottle.product.name
            if (name_a < name_b) {
                return -1
            }
            if (name_a > name_b) {
                return 1
            }
            return 0
        })
    }

    get poi_items_by_artkey() {
        return this.poi_items.reduce<{ [key: number]: GetPurchaseOrderItemResponse }>(
            (acc, curr) => ((acc[curr.artkey] = curr), acc),
            {},
        )
    }

    get_matched_poi(): GetPurchaseOrderItemResponse | null {
        if (this.matched_poi_artkey) {
            return this.poi_items_by_artkey[this.matched_poi_artkey]
        }
        return null
    }

    fetch_product_photos(lot: string): void {
        this.is_fetching_photos = true
        this.product_photo_api.get_all_photos_for_lot(lot).subscribe({
            next: (result) => {
                this.is_fetching_photos = false
                this.product_photos = result
                m.redraw()
            },
            error: () => {
                this.is_fetching_photos = false
            },
        })
    }

    view(
        vnode: m.Vnode<CollectionTableRowComponentProps<LoenderslootInspectionItem, InStockLoenderslootInspectionItemRowAttrs>>,
    ): m.Children | null {
        const row_class_name =
            vnode.attrs.index % 2 === 0
                ? `${vnode.attrs.additional_tr_classname} even`
                : `${vnode.attrs.additional_tr_classname} odd`
        const row = this.row
        const matched_poi = this.get_matched_poi()
        return (
            <tr className={row_class_name} key={vnode.attrs.row.purchase_order_item_artkey}>
                <td colspan="100%">
                    <div className={'row'}>
                        <div className={'col-sm-12'}>
                            <h4>
                                {row.product_name || (
                                    <span>
                                        {row.description}
                                        &nbsp;
                                        <Tippy
                                            content={'Product could not be found. This is the name from Loendersloot.'}
                                        >
                                            <i className={'glyphicon glyphicon-question-sign'}/>
                                        </Tippy>
                                    </span>
                                )}
                            </h4>
                        </div>
                        <div className={'col-sm-6'}>
                            <LoenderslootInspectionItemView loendersloot_inspection_item={row}/>
                        </div>

                        {row.in_stock_on && matched_poi && <div className={'col-sm-6'}>
                            <h5>Matched to purchase order item:</h5>
                            <dl className={'dl-horizontal text-left'}>
                                <dt>Product</dt>
                                <dd>{matched_poi.case.bottle.product.name}</dd>
                                <dt>Specs</dt>
                                <dd>
                                    {to_specs(matched_poi.case.bottle, $s.identity.user.decimal_locale)}
                                </dd>
                                <dt>Gift box</dt>
                                <dd>{matched_poi.case.gift_box_type || '-'}</dd>
                                <dt>Btls / case</dt>
                                <dd>{matched_poi.case.number_of_bottles}</dd>
                                <dt>Number of cases</dt>
                                <dd>{matched_poi.number_of_cases}</dd>
                            </dl>
                        </div>}

                        {!row.in_stock_on && <div className={'col-sm-6'}>
                            <HorizontalForm>
                                <FieldSelect
                                    label="Select purchase order item"
                                    model={[this, 'matched_poi_artkey']}
                                    onchange={(poi_artkey: number) => (this.matched_poi_artkey = poi_artkey)}
                                    options={this.poi_items.filter((i) => !i.is_in_stock_loendersloot).map((i: GetPurchaseOrderItemResponse) => ({
                                        value: i.artkey,
                                        label: `${i.case.bottle.product.name} - ${to_specs(i.case.bottle, $s.identity.user.decimal_locale)} - ${i.case.gift_box_type} - ${i.case.number_of_bottles} / cs - ${i.number_of_cases}cs`,
                                    }))}
                                    placeholder="-"
                                />
                            </HorizontalForm>

                            <div>
                                <MatchDifferences
                                    key={this.matched_poi_artkey}
                                    loendersloot_item={row}
                                    get_purchase_order_item={() => this.get_matched_poi()}
                                    on_item_resolved={() => this.additional_args.on_item_resolved()}
                                    product_photos={this.product_photos}
                                />
                            </div>
                        </div>}
                        {!row.in_stock_on && !this.is_fetching_photos && <div className={'col-sm-12'}>
                            <InstockItemPhotoList product_photos={this.product_photos} fetch_product_photos={this.fetch_product_photos}/>
                        </div>}
                        {this.is_fetching_photos && <Spinner/>}
                    </div>
                </td>
            </tr>
        )
    }
}

interface MatchDifferencesAttrs {
    loendersloot_item: LoenderslootInspectionItem
    get_purchase_order_item: () => PurchaseOrderItemResponse | null
    on_item_resolved: () => unknown
    product_photos: GetProductPhotoResponse[]
}

enum MatchDifferenceType {
    BOTTLES = 'Bottles',
    CASES = 'Cases',
    ALCOHOL = 'Alcohol perc.',
    VOLUME = 'Volume',
    BTL_CS = 'Btl / cs',
    GIFTBOX = 'Giftbox',
    REFILL = 'Refill status',
}

interface MatchDifference {
    loendersloot: string | number
    discover: string | number
    explanation: string | null
}

class MatchDifferences extends MithrilTsxComponent<MatchDifferencesAttrs> {
    attrs: MatchDifferencesAttrs
    issues: Map<MatchDifferenceType, MatchDifference>
    update_poi = false
    missing_eu_address = false
    override_ref_status: string | null = null
    override_gb: string | null = null

    purchase_api = new PurchaseApi()

    constructor(vnode: m.VnodeDOM<MatchDifferencesAttrs>) {
        super()
        this.attrs = vnode.attrs
        this.issues = this.build_issues()
    }

    bring_item_in_stock(purchase_order_item: PurchaseOrderItemResponse | null) {
        if (purchase_order_item === null) {
            // The input should be disabled in this case.
            return
        }
        this.purchase_api
            .instock_loendersloot_inspection_item(
                this.attrs.loendersloot_item.artkey,
                purchase_order_item.artkey,
                this.update_poi,
                this.missing_eu_address,
                this.override_ref_status,
                this.override_gb,
            )
            .subscribe({
                next: (response) => {
                    notifier.notify(response.message, 'success')
                    this.attrs.on_item_resolved()
                },
            })
    }

    build_issues(): Map<MatchDifferenceType, MatchDifference> {
        const issues = new Map<MatchDifferenceType, MatchDifference>()
        const purchase_order_item = this.attrs.get_purchase_order_item()
        if (!purchase_order_item) {
            return issues
        }
        const loendersloot_item = this.attrs.loendersloot_item
        const cs = purchase_order_item.case
        const bottle = cs.bottle
        const loendersloot_number_of_cases = loendersloot_item.number_of_bottles / loendersloot_item.bottles_per_case
        if (loendersloot_number_of_cases !== purchase_order_item.number_of_cases) {
            issues.set(MatchDifferenceType.CASES, {
                loendersloot: loendersloot_number_of_cases,
                discover: purchase_order_item.number_of_cases,
                explanation:
                    'The number of cases in stock will be updated to match the number of bottles from Loendersloot. The purchase order item will not be modified.',
            })
        }
        const purchase_number_of_bottles = purchase_order_item.number_of_cases * +cs.number_of_bottles
        if (loendersloot_item.number_of_bottles !== purchase_number_of_bottles) {
            issues.set(MatchDifferenceType.BOTTLES, {
                loendersloot: loendersloot_item.number_of_bottles,
                discover: purchase_number_of_bottles,
                explanation:
                    'The number of cases in stock will be updated to match the number of bottles from Loendersloot. The purchase order item will not be modified.',
            })
        }
        if (loendersloot_item.bottles_per_case !== +cs.number_of_bottles) {
            issues.set(MatchDifferenceType.BTL_CS, {
                loendersloot: loendersloot_item.bottles_per_case,
                discover: cs.number_of_bottles,
                explanation:
                    'The number of bottles per case in stock will be updated to match Loendersloot.',
            })
        }
        if (loendersloot_item.alcohol_percentage !== +bottle.alcohol_percentage) {
            issues.set(MatchDifferenceType.ALCOHOL, {
                loendersloot: `${loendersloot_item.alcohol_percentage}%`,
                discover: `${bottle.alcohol_percentage}%`,
                explanation: 'Specs in stock will be updated and created if necessary.',
            })
        }
        if (loendersloot_item.bottle_volume && loendersloot_item.bottle_volume !== +bottle.volume) {
            issues.set(MatchDifferenceType.VOLUME, {
                loendersloot: `${loendersloot_item.bottle_volume}cl`,
                discover: `${bottle.volume}cl`,
                explanation: 'Specs in stock will be updated and created if necessary.',
            })
        }
        const other_list = loendersloot_item.other ? loendersloot_item.other.split('/') : []

        const loendersloot_refillable = !other_list.includes('NRF')
        const discover_refillable = purchase_order_item.case.bottle.refill_status === 'ref'
        if (loendersloot_refillable !== discover_refillable) {
            issues.set(MatchDifferenceType.REFILL, {
                loendersloot: loendersloot_refillable ? 'REF' : 'NONREF',
                discover: discover_refillable ? 'REF' : 'NONREF',
                explanation: 'Specs in stock will be updated and created if necessary.',
            })
        }

        const loendersloot_has_gb = Boolean(loendersloot_item.has_gift_box)
        const discover_has_gb = Boolean(cs.gift_box_type)
        if (loendersloot_has_gb !== discover_has_gb) {
            issues.set(MatchDifferenceType.GIFTBOX, {
                loendersloot: loendersloot_has_gb ? 'GB' : 'No GB',
                discover: discover_has_gb ? 'GB' : 'No GB',
                explanation: 'We will overwrite the value in Discover.',
            })
        }
        return issues
    }

    has_issues() {
        return this.issues.size > 0
    }

    set_on_hold() {
        this.purchase_api.onhold_loendersloot_inspection_item(this.attrs.loendersloot_item.artkey).subscribe({
            next: () => {
                notifier.notify('Loendersloot item set to on hold.', 'success')
                this.attrs.on_item_resolved()
            },
        })
    }

    view(): m.Children | null {
        const purchase_order_item = this.attrs.get_purchase_order_item()
        return (
            <div key='puchase_order_views'>
                {purchase_order_item && (
                    <div>
                        {this.has_issues() && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Loendersloot</th>
                                        <th>Discover</th>
                                        <th>Resolution</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from(this.issues).map(([type, issue]) => (
                                        <tr>
                                            <th>{type}</th>
                                            <td>{issue.loendersloot}</td>
                                            <td>{issue.discover}</td>
                                            <td>
                                                {issue.explanation && (
                                                    <Tippy content={issue.explanation}>
                                                        <span className={'glyphicon glyphicon-question-sign'}/>
                                                    </Tippy>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {!this.has_issues() && <strong>All details match!</strong>}
                    </div>
                )}
                <br/>

                <div className={'btn-group-vertical'}>
                    <CheckBox
                        label='Update purchase order item to match Loendersloot'
                        checked={this.update_poi}
                        onchange={() => this.update_poi = !this.update_poi}
                    />

                    <CheckBox
                        label='Missing EU address on label'
                        checked={this.missing_eu_address}
                        onchange={() => this.missing_eu_address = !this.missing_eu_address}
                    />

                    <FieldSelect
                        label={'Override refill status'}
                        model={[this, 'override_ref_status']}
                        options={[
                            {value: 'ref', label: 'Refillable'},
                            {value: 'nonref', label: 'Non refillable'},
                        ]}
                        placeholder="Do not override"
                    />

                    <FieldSelect
                        label="Override gift box"
                        model={[this, 'override_gb']}
                        options={['No GB', ...gift_box_types].map((i) => ({label: i, value: i}))}
                        placeholder="Do not override"
                    />
                    <SuccessButton
                        icon_class={'glyphicon glyphicon-home'}
                        onclick={() => this.bring_item_in_stock(purchase_order_item)}
                        disabled={purchase_order_item === null}
                    >
                        {' '}
                        Bring purchase item in stock
                    </SuccessButton>
                    <DangerButton icon_class={'fas fa-hand-paper'} onclick={() => this.set_on_hold()}>
                        {' '}
                        Set to on hold
                    </DangerButton>
                </div>
            </div>
        )
    }
}

interface InstockItemPhotoListAttrs {
    product_photos: GetProductPhotoResponse[]
    fetch_product_photos: (lot: string) => unknown
    lot: string
}

export class InstockItemPhotoList extends MithrilTsxComponent<InstockItemPhotoListAttrs> {
    product_photo_api = new ProductPhotoApi()

    download_all_files(vnode: m.Vnode<InstockItemPhotoListAttrs>, include_internal: boolean): void {
        this.product_photo_api.download_all_for_lot(vnode.attrs.lot, include_internal,`photos-${vnode.attrs.lot}.zip`)
    }

    view(vnode: m.Vnode<InstockItemPhotoListAttrs, this>): m.Children {
        const product_photos = vnode.attrs.product_photos
        return (
            <div className={'row'} key={''}>
                <div className={'col-xs-12'}>
                    {product_photos.length > 0 && (
                        <span>
                            <ProductPhotoHorizontalView
                                product_photos={product_photos}
                                download_all_photos={(include_internal: boolean) =>
                                    this.download_all_files(vnode, include_internal)
                                }
                                on_product_photos_changed={() => vnode.attrs.fetch_product_photos(vnode.attrs.lot)}
                            />
                        </span>
                    )}
                    {product_photos.length === 0 &&
                        <div><p>No product photos found for this lot.</p></div>}
                </div>
            </div>
        )
    }
}
