import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {Country} from '@bitstillery/common/components'

import {RequeryListener} from './reports'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {ReportArguments} from '@/factserver_api/reporting_api'

interface CountriesTrade {
    country_code: string
    last_purchase_date: string
    last_sales_date: string
    number_of_purchase_orders: number
    number_of_sales_orders: number
}

export interface CountriesTradeReportAttrs {
    report_arguments: ReportArguments
    add_requery_listener: (component: RequeryListener) => void
    remove_requery_listener: (component: RequeryListener) => void
}

export class CountriesTradeReport extends MithrilTsxComponent<CountriesTradeReportAttrs> implements RequeryListener {
    reporting_fetcher = new PagedCollectionFetcher<CountriesTrade>(
        'reporting.countries_trade_report.get_all',
        'country_code',
    )

    constructor(vnode: m.Vnode<CountriesTradeReportAttrs>) {
        super()
        this.reporting_fetcher.filters['start_date'] = vnode.attrs.report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = vnode.attrs.report_arguments.end_date?.toISODate() || ''

        vnode.attrs.add_requery_listener(this)
    }

    fire(report_arguments: ReportArguments): void {
        this.reporting_fetcher.filters['start_date'] = report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = report_arguments.end_date?.toISODate() || ''
        this.reporting_fetcher.reset_and_query()
    }

    onremove(vnode: m.Vnode<CountriesTradeReportAttrs>): void {
        vnode.attrs.remove_requery_listener(this)
    }

    view(): m.Children {
        return (
            <CollectionTable<CountriesTrade, void> collection_fetcher={this.reporting_fetcher}>
                <CollectionTableColumn<CountriesTrade>
                    header_title={() => 'Country'}
                    sort_name={'country_code'}
                    data_field={(row: CountriesTrade) => <Country country_code={row.country_code} type={'flag_with_country'}/>}
                />
                <CollectionTableColumn<CountriesTrade>
                    header_title={() => 'Last purchase (in stock)'}
                    sort_name={'last_purchase_date'}
                    data_field={(row: CountriesTrade) => format_iso_to_date(row.last_purchase_date) || '-'}
                />
                <CollectionTableColumn<CountriesTrade>
                    header_title={() => '# PO'}
                    sort_name={'number_of_purchase_orders'}
                    data_field={(row: CountriesTrade) => row.number_of_purchase_orders}
                />
                <CollectionTableColumn<CountriesTrade>
                    header_title={() => 'Last sales (invoiced)'}
                    sort_name={'last_sales_date'}
                    data_field={(row: CountriesTrade) => format_iso_to_date(row.last_sales_date) || '-'}
                />
                <CollectionTableColumn<CountriesTrade>
                    header_title={() => '# SO'}
                    sort_name={'number_of_sales_orders'}
                    data_field={(row: CountriesTrade) => row.number_of_sales_orders}
                />
            </CollectionTable>
        )
    }
}
