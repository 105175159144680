/** llm:tested */
import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'

import api from '@/api'
import {Collection} from '@/components/collection/collection'
import {CollectionTable} from '@/components/collection_table'
import {EmailPreview} from '@/email/components/email_preview'

interface EmailBatchRecipientsAttrs {
    email_batch: () => EmailBatch
}

interface EmailBatch {
    artkey: string
    offer_artkey: string
    subject: string
    body: string
    batch_type: string
    use_sent_from_user_artkey: string
}

interface Record {
    artkey: string
    preview_body?: () => string
    supplier_name: string
    contact_person_email_addresses: string
    contact_person_names: string
}

export class EmailBatchRecipients extends MithrilTsxComponent<EmailBatchRecipientsAttrs> {
    email_batch: () => EmailBatch
    offer_artkey: string
    recipients: Collection

    oninit(vnode: m.Vnode<EmailBatchRecipientsAttrs>) {
        this.email_batch = vnode.attrs.email_batch
        this.offer_artkey = this.email_batch().offer_artkey
        this.recipients = new Collection({
            api_function_name: 'email.batch.get_recipients',
            additional_params: () => ({email_batch_artkey: this.email_batch().artkey}),
            query_limit: 25,
            default_sort_by: 'artkey',
            default_sort_order: 'asc',
        })
    }

    oncreate() {
        this.recipients.query()
    }

    fetch_preview(record: Record) {
        if (record.preview_body !== undefined) {
            return
        }
        const data = {
            offer_email_batch_artkey: this.email_batch().artkey,
            subject: this.email_batch().subject,
            body: this.email_batch().body,
            pricelist_type: this.email_batch().batch_type,
            offer_artkey: this.offer_artkey,
            supplier_artkey: record.artkey,
            from_email_address_artkey: this.email_batch().use_sent_from_user_artkey,
        }
        record.preview_body = window.prop('')
        api.callAndThen('pricelistbatch.get_rendered_email_for_supplier', data, {
            success: (resp: any) => {
                if (resp.result.message !== undefined) {
                    record.preview_body!('Error while rendering preview: ' + resp.result.message)
                } else {
                    record.preview_body!(resp.result.body)
                }
            },
        })
    }

    view() {
        return <div class="row">
            <div class="col-sm-12">
                <CollectionTable
                    collection={this.recipients}
                    options={{
                        search_table_style: true,
                        sticky_header: true,
                        with_buttons: true,
                        autoscale: true,
                        unique_name: 'email_batch_recipients',
                    }}
                    view_details={(record: Record) => {
                        this.fetch_preview(record)
                        return <div class="col-sm-8 content-preview">
                            <EmailPreview email={record.preview_body} />
                        </div>
                    }}
                    columns={[
                        {
                            width: 2,
                            name: 'Relation',
                            field: 'supplier_name',
                            sort: true,
                        }, {
                            width: 3,
                            name: 'To email addresses',
                            field: 'contact_person_email_addresses',
                            sort: false,
                        }, {
                            width: 3,
                            name: 'Recipient name(s)',
                            field: 'contact_person_names',
                            sort: false,
                        },
                    ]}
                />
            </div>
        </div>
    }
}
