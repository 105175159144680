import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {format_iso_to_date} from '@bitstillery/common/lib/format'
import {Country} from '@bitstillery/common/components'
import {Amount} from '@bitstillery/common/components'

import {RequeryListener} from './reports'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {ReportArguments} from '@/factserver_api/reporting_api'

interface InsurancePerPurchaseOrder {
    artkey: string
    reference: string
    in_stock_on: string
    supplier_name: string
    supplier_country_code: string
    incoterm: string
    incoterm_location: string
    target_warehouse_name: string
    euro_was_bought_for: number
    number_of_cases: number
    number_of_bottles: number
    number_of_order_lines: number
    insurance_type_name: string
    purchase_manager_name: string
}

export interface InsurancePurchaseOrderReportAttrs {
    report_arguments: ReportArguments
    add_requery_listener: (component: RequeryListener) => void
    remove_requery_listener: (component: RequeryListener) => void
}

export class InsurancePurchaseOrderReport
    extends MithrilTsxComponent<InsurancePurchaseOrderReportAttrs>
    implements RequeryListener
{
    reporting_fetcher = new PagedCollectionFetcher<InsurancePerPurchaseOrder>(
        'reporting.insurance_purchase_order_report.get_all',
        'artkey',
    )

    constructor(vnode: m.Vnode<InsurancePurchaseOrderReportAttrs>) {
        super()
        this.reporting_fetcher.filters['start_date'] = vnode.attrs.report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = vnode.attrs.report_arguments.end_date?.toISODate() || ''

        vnode.attrs.add_requery_listener(this)
    }

    onremove(vnode: m.Vnode<InsurancePurchaseOrderReportAttrs>): void {
        vnode.attrs.remove_requery_listener(this)
    }

    fire(report_arguments: ReportArguments): void {
        this.reporting_fetcher.filters['start_date'] = report_arguments.start_date?.toISODate() || ''
        this.reporting_fetcher.filters['end_date'] = report_arguments.end_date?.toISODate() || ''
        this.reporting_fetcher.reset_and_query()
    }

    view(): m.Children {
        return (
            <CollectionTable<InsurancePerPurchaseOrder, void> collection_fetcher={this.reporting_fetcher}>
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Insurance'}
                    sort_name={'insurance_type_name'}
                    data_field={(row: InsurancePerPurchaseOrder) => row.insurance_type_name}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Purchase Order'}
                    sort_name={'artkey'}
                    data_field={(row: InsurancePerPurchaseOrder) => (
                        <a href={`#!/purchase-orders/manage/${row.artkey}`}>{row.reference}</a>
                    )}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Relation'}
                    sort_name={'supplier_name'}
                    data_field={(row: InsurancePerPurchaseOrder) => (
                        <span>
                            {row.supplier_name}
                            <Country country_code={row.supplier_country_code} type={'flag_with_country'}/>
                        </span>
                    )}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Amount'}
                    data_field={(row: InsurancePerPurchaseOrder) => (
                        <Amount amount={row.euro_was_bought_for} currency={'EUR'} />
                    )}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Cases'}
                    data_field={(row: InsurancePerPurchaseOrder) => row.number_of_cases}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Bottles'}
                    data_field={(row: InsurancePerPurchaseOrder) => row.number_of_bottles}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => '# lines'}
                    data_field={(row: InsurancePerPurchaseOrder) => row.number_of_order_lines}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Stocked on'}
                    sort_name={'in_stock_on'}
                    data_field={(row: InsurancePerPurchaseOrder) => format_iso_to_date(row.in_stock_on)}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Incoterm'}
                    data_field={(row: InsurancePerPurchaseOrder) => row.incoterm}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Incoterm location'}
                    data_field={(row: InsurancePerPurchaseOrder) => row.incoterm_location}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Warehouse'}
                    data_field={(row: InsurancePerPurchaseOrder) => row.target_warehouse_name}
                />
                <CollectionTableColumn<InsurancePerPurchaseOrder>
                    header_title={() => 'Purchase manager'}
                    data_field={(row: InsurancePerPurchaseOrder) => row.purchase_manager_name}
                />
            </CollectionTable>
        )
    }
}
