/** llm:tested */
import m from 'mithril'
import {map, orList} from 'prelude-ls'
import {Amount, Link} from '@bitstillery/common/components'
import {current_account_slug} from '@bitstillery/common/account/account'

import {select} from '@/components/inputs'
import {CollectionTable} from '@/components/collection_table'
import {icon, icon_with_popover} from '@/components/icon'
import {label_icons_for_item} from '@/components/_labels'
import {Collection} from '@/components/collection/collection'
import {formatDate, matchTermIn, toLower} from '@/_utils'
import {PurchaseOrderStatus} from '@/models/purchase_orders'
import {SearchBar} from '@/components/collection/search_bar'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {$s} from '@/app'

interface PurchaseOrderItem {
    purchase_order_artkey: number
    purchase_order_reference: string
    purchase_order_supplier_reference: string
    product_name: string
    case_number_of_bottles: number
    bottle_volume: number
    bottle_alcohol_percentage: number
    bottle_refill_status: string
    case_gift_box_type: string
    comment: string
    number_of_cases: number
    refers_to_artkey: number
    original_price_per_case: number
    original_price_per_bottle: number
    original_currency: string
    bought_against_rate: number
    was_bought_for: number
    was_bought_in: string
    was_bought_for_plus_costs: number
    case_customs_status: string
    purchase_order_created_on: string
    supplier_name: string
    purchase_order_status: string
    case_length: number
    case_width: number
    case_height: number
    case_weight: number
    case_item_tags: string[]
    case_tax_label: string
    remark: string
    cases_per_pallet: number
    case_best_before_date: string
}

export default class PurchaseOrderItems {
    search_status: any
    purchase_order_items: any
    search_bar_controller: any
    product_management_api: ProductManagementApi

    constructor() {
        this.search_status = window.prop('Saved or confirmed')

        this.purchase_order_items = new Collection({
            api_function_name: 'purchase.core.search_purchase_order_items',
            additional_params: this.additional_params,
            filter_function: this.is_match,
            sort_order: [
                {name: 'purchase_order_created_on', direction: 'desc'},
                {name: 'purchase_order_artkey', direction: 'desc'},
                {name: 'product_name', direction: 'asc'},
                {name: 'bottle_volume', direction: 'asc'},
                {name: 'bottle_alcohol_percentage', direction: 'asc'},
                {name: 'bottle_refill_status', direction: 'desc'},
            ],
            default_sort_by: 'purchase_order_created_on',
            default_sort_order: 'desc',
            query_limit: 25,
        })

        this.search_bar_controller = null
        this.product_management_api = new ProductManagementApi()
    }

    submit_search = (text: string) => {
        this.purchase_order_items.update_search_term(text)
        this.purchase_order_items.submit_search()
    }

    oncreate() {
        this.purchase_order_items.requery()

        const q = m.route.param('q')
        if (q) {
            this.submit_search(q.replace(/\+/g, ' '))
        }
    }

    is_match = (record: PurchaseOrderItem, term: string) => {
        const status_filter = this.purchase_order_items.additional_params().status
        if (status_filter && !status_filter.includes(record.purchase_order_status)) {
            return false
        }

        return orList([
            matchTermIn(term, map(toLower, [
                record.product_name,
                record.product_category,
                record.bottle_refill_status,
                record.case_gift_box_type || '',
                record.case_tax_label || '',
                record.target_warehouse_name,
                record.supplier_name,
            ])),
            +record.bottle_volume === +term,
            +record.bottle_alcohol_percentage === +term,
            +record.purchase_order_artkey === +term,
            `p${record.purchase_order_artkey}` === term,
        ])
    }

    additional_params = () => {
        const params: any = {}
        if (this.search_status() === 'undefined') {
            // do not add param status
        } else if (this.search_status() === PurchaseOrderStatus.SAVED_OR_CONFIRMED) {
            params['status'] = [PurchaseOrderStatus.SAVED, PurchaseOrderStatus.CONFIRMED]
        } else if (this.search_status() === PurchaseOrderStatus.STOCKED) {
            params['status'] = [PurchaseOrderStatus.READY_FOR_FIS, PurchaseOrderStatus.STOCKED_AND_BOOKED]
        } else {
            params['status'] = [this.search_status()]
        }
        params['account'] = current_account_slug()
        return params
    }

    set_search_status = (search_status: string) => {
        this.search_status(search_status)
        this.purchase_order_items.requery()
    }

    view() {
        return <div class="c-purchase-order-items view">
            <div class="c-filter-group">
                <SearchBar
                    placeholder="Search products..."
                    on_submit={this.submit_search}
                    default_search_text={this.purchase_order_items.search_term()}
                    search_bar_controller={(search_bar_controller: any) => this.search_bar_controller = search_bar_controller}
                    on_get_suggestions$={(filter_text: string) => this.product_management_api.get_simple_product_names(filter_text)}
                />

                {select(this.search_status, PurchaseOrderStatus.choices, {
                    onchange: (e) => this.set_search_status(e),
                })}
            </div>

            <CollectionTable
                collection={this.purchase_order_items}
                options={{
                    search_table_style: true,
                    sticky_header: true,
                    with_buttons: false,
                    autoscale: true,
                    unique_name: 'purchase_order_items',
                }}
                columns={[
                    {
                        width: 4,
                        name: 'Purchase Order reference',
                        header: '#',
                        field: 'purchase_order_reference',
                        sort: true,
                        function: (record: PurchaseOrderItem) =>
                            purchase_order_link({
                                artkey: record.purchase_order_artkey,
                                reference: record.purchase_order_reference,
                            }),
                    },
                    {
                        width: 4,
                        name: 'Supplier reference',
                        header: '##',
                        field: 'purchase_order_supplier_reference',
                        sort: true,
                    },
                    {
                        width: 9,
                        name: 'Product',
                        field: 'product_name',
                        sort: true,
                    },
                    {
                        name: 'Btl / cs',
                        width: 5,
                        field: 'case_number_of_bottles',
                        classes: 'number',
                        sort: true,
                    },
                    {
                        name: 'Specs',
                        width: 10,
                        function: (record: PurchaseOrderItem) => {
                            let specs = ''
                            if (record.bottle_volume) {
                                specs = specs + record.bottle_volume + 'cl'
                            }
                            if (record.bottle_alcohol_percentage) {
                                specs = specs + ` / ${record.bottle_alcohol_percentage}%`
                            }
                            if (record.bottle_refill_status) {
                                specs = specs + ` / ${record.bottle_refill_status}`
                            }
                            if (record.case_gift_box_type) {
                                specs = specs + ` / ${record.case_gift_box_type}`
                            }
                            return specs
                        },
                    },
                    {
                        name: 'Features',
                        width: 10,
                        function: (record: PurchaseOrderItem) => {
                            return <span>
                                {record.comment &&
                                    icon_with_popover({
                                        iconId: 'comment',
                                        title: 'Comment',
                                        content: record.comment,
                                    })
                                }
                                {label_icons_for_item(get_poi_labels(record))}
                            </span>
                        },
                    },
                    {
                        name: 'Cases',
                        width: 6,
                        classes: 'number',
                        field: 'number_of_cases',
                        sort: true,
                    },
                    {
                        name: 'Price / cs',
                        width: 7,
                        classes: 'price',
                        function: (record: PurchaseOrderItem) => {
                            if (!record.refers_to_artkey) return '-'

                            if (record.original_price_per_case) {
                                return <Amount
                                    amount={record.original_price_per_case}
                                    currency={record.original_currency}
                                    rate={record.bought_against_rate}
                                />
                            } else if (record.original_price_per_bottle) {
                                return <Amount
                                    amount={record.original_price_per_bottle * record.case_number_of_bottles}
                                    currency={record.original_currency}
                                    rate={record.bought_against_rate}
                                />
                            }
                            return '-'
                        },
                    },
                    {
                        name: 'Net Price / cs',
                        width: 7,
                        classes: 'price',
                        field: 'was_bought_for',
                        sort: true,
                        function: (record: PurchaseOrderItem) => {
                            const price_per_bottle = record.was_bought_for / record.case_number_of_bottles

                            return <span>
                                {record.refers_to_artkey &&
                                    !((record.original_price_per_case && +record.original_price_per_case === +record.was_bought_for) ||
                                    (record.original_price_per_bottle && +record.original_price_per_bottle === +price_per_bottle)) &&
                                    icon('download', {style: {'margin-right': '5px'}})}

                                <Amount
                                    amount={+record.was_bought_for}
                                    currency={record.was_bought_in}
                                    display_currency={$s.currencies.default}
                                    rate={record.bought_against_rate}
                                />
                            </span>
                        },
                    },
                    {
                        name: 'Cost / cs',
                        width: 7,
                        classes: 'price',
                        field: 'was_bought_for_plus_costs',
                        function: (record: PurchaseOrderItem) =>
                            <Amount
                                amount={record.was_bought_for_plus_costs}
                                currency={record.was_bought_in}
                                rate={record.bought_against_rate}
                            />,
                    },
                    {
                        name: 'Total value',
                        width: 7,
                        classes: 'price',
                        field: 'total_price',
                        function: (record: PurchaseOrderItem) =>
                            <Amount
                                amount={record.number_of_cases * record.was_bought_for}
                                currency={record.was_bought_in}
                                rate={record.bought_against_rate}
                            />,
                    },
                    {
                        name: 'Cus.',
                        width: 3,
                        field: 'case_customs_status',
                        sort: true,
                    },
                    {
                        width: 6,
                        name: 'Order Date',
                        field: 'purchase_order_created_on',
                        transform: formatDate,
                        sort: true,
                    },
                    {
                        width: 17,
                        name: 'Relation',
                        field: 'supplier_name',
                        ellipsis: true,
                        sort: true,
                    },
                    {
                        width: 8,
                        name: 'Status',
                        field: 'purchase_order_status',
                        sort: true,
                    },
                    {
                        header: 'Dimensions (l/w/h)',
                        name: 'Dimensions',
                        sort: false,
                        default_visible: false,
                        function: (record: PurchaseOrderItem) =>
                            record.case_length && record.case_width && record.case_height
                                ? `${record.case_length} x ${record.case_width} x ${record.case_height} cm`
                                : null,
                    },
                    {
                        width: 5,
                        header: 'Weight',
                        name: 'Weight',
                        field: 'case_weight',
                        sort: false,
                        default_visible: false,
                        function: (record: PurchaseOrderItem) =>
                            record.case_weight ? `${record.case_weight} kg` : null,
                    },
                    {
                        width: 1,
                        name: '',
                        function: () => null,
                    },
                ]}
            />
        </div>
    }
}

interface PurchaseOrderLinkProps {
    artkey: number
    reference: string
}

const purchase_order_link = (purchase_order: PurchaseOrderLinkProps) => {
    return <Link
        target="_blank"
        href={`/purchase-orders/manage/${purchase_order.artkey}`}
        class="no-click"
    >
        {purchase_order.reference}
    </Link>
}

const get_poi_labels = (poi: PurchaseOrderItem) => ({
    item_tags: poi.case_item_tags || [],
    tax_label: poi.case_tax_label,
    remark: poi.remark,
    cases_per_pallet: poi.cases_per_pallet,
    best_before_date: poi.case_best_before_date,
})
