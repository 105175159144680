import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {Tippy, Icon} from '@bitstillery/common/components'
import {classes} from '@bitstillery/common/lib/utils'

interface PanelAttrs {
    title?: string
    description?: string
    content_fetcher?: any
    download_callback?: () => void
    collapsible?: boolean
    collapsed?: boolean
    footer?: JSX.Element
    toolbar?: JSX.Element
}

export class Panel extends MithrilTsxComponent<PanelAttrs> {
    description_component: m.Vnode | undefined
    collapsed: boolean = false

    oncreate(vnode: m.Vnode<PanelAttrs>) {
        if (vnode.attrs.description) {
            this.description_component =
                <Tippy content={vnode.attrs.description}>
                    <Icon name={'info'}/>
                </Tippy>
        }

        if (vnode.attrs.collapsible && vnode.attrs.collapsed) {
            this.collapsed = true
        }
    }

    view(vn: m.Vnode<PanelAttrs>) {
        return (
            <div className={classes('c-panel', {collapsible: vn.attrs.collapsible}, {collapsed: this.collapsed})}>
                <div className="panel-heading">
                    {vn.attrs.title && (
                        <div className={'panel-title'} onclick={() => {
                            this.collapsed = !this.collapsed && vn.attrs.collapsible === true
                        }}>
                            {vn.attrs.title}
                        </div>
                    )}
                    {vn.attrs.toolbar}
                    {vn.attrs.download_callback && (
                        <Tippy content={'Download full result'}>
                            {/* @ts-ignore: TS does not recognize that download_callback cannot be undefined here */}
                            <Icon name={'excel'} onclick={() => vn.attrs.download_callback()}/>
                        </Tippy>
                    )}
                    {this.description_component}
                </div>
                <div className="panel-body">
                    {vn.children}
                    {vn.attrs.footer && (<div className="panel-footer">{vn.attrs.footer}</div>)}
                </div>
            </div>
        )
    }
}
