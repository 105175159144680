import m from 'mithril'
import {classes} from '@bitstillery/common/lib/utils'
import {PanelLeft} from '@bitstillery/common/components'
import {Button} from '@bitstillery/common/components'
import {active_filters} from '@bitstillery/common/lib/filters'
import {$s, view} from '@bitstillery/common/app'

export class PanelMenu extends PanelLeft {

    className = 'c-panel-menu'

    slot_toggles(_m:m.Vnode<any>) {
        const filters_active = active_filters($s.filters)
        return [
            $s.env.layout === 'desktop' ? <Button
                className={classes('toggle', 'panel-left-toggle', {
                    [$s.panels.left.collapsed ? 'collapsed' : 'uncollapsed']: true,
                })}
                icon={$s.panels.left.collapsed ? 'menuOpen' : 'chevronLeft'}
                onclick={(e) => {
                    e.stopPropagation()
                    $s.panels.left.collapsed = !$s.panels.left.collapsed
                    // In tablet/mobile mode, the panels and their toggles move to the left.
                    // When toggling this button/panel, the other panels act as an accordion.
                    if (['mobile', 'tablet'].includes($s.env.layout)) {
                        $s.panels.view.filters.collapsed = true
                        $s.panels.view.categories.collapsed = true
                    }

                    if ($s.env.layout === 'mobile') {
                        $s.panels.context.collapsed = true
                    }
                }}
                variant="toggle"
            /> : null,
            ['mobile', 'tablet'].includes($s.env.layout) ? <Button
                className={classes('toggle', 'panel-view-categories', {
                    active: !$s.panels.view.categories.collapsed,
                })}
                icon="categories"
                onclick={(e) => {
                    e.stopPropagation()
                    $s.panels.left.collapsed = true
                    $s.panels.context.collapsed = true
                    $s.panels.view.filters.collapsed = true
                    $s.panels.view.categories.collapsed = !$s.panels.view.categories.collapsed
                }}
                variant="toggle"
            /> : null,
            (['mobile', 'tablet'].includes($s.env.layout) && view.filters && Object.keys(view.filters).length) ? <Button
                className={classes('toggle', 'panel-view-filters', {
                    active: !$s.panels.view.filters.collapsed,
                    selection: filters_active > 0,
                })}
                icon={filters_active ? 'filterRemove' : $s.panels.view.filters.collapsed ? 'filterCollapsed' : 'filterCollapse'}
                onclick={(e) => {
                    e.stopPropagation()
                    $s.panels.view.categories.collapsed = true
                    $s.panels.view.filters.collapsed = !$s.panels.view.filters.collapsed
                    $s.panels.left.collapsed = true
                    $s.panels.context.collapsed = true
                }}
                text={filters_active > 0 ? filters_active : null}
                type={filters_active > 0 ? 'brand' : 'default'}
                variant="toggle"
            >
                {filters_active > 0 && <div className="amount">
                    {filters_active}
                </div>}
            </Button> : null,
            ($s.env.layout === 'mobile' && $s.context.name) ? <Button
                className={classes('toggle', 'panel-context-toggle', {
                    active: !$s.panels.context.collapsed,
                })}
                icon={$s.context.icon ? $s.context.icon : 'card_account_details_outline'}
                onclick={(e) => {
                    e.stopPropagation()
                    $s.panels.left.collapsed = true
                    $s.panels.view.filters.collapsed = true
                    $s.panels.view.categories.collapsed = true
                    $s.panels.context.collapsed = !$s.panels.context.collapsed
                }}
                text="66"
                variant="toggle"
            >
                {typeof $s.context.count === 'number' && (
                    <div className="amount">
                        {$s.context.count}
                    </div>
                )}
            </Button> : null,
        ]
    }
}
