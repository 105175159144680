import m from 'mithril'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {format_iso_to_date, titleize} from '@bitstillery/common/lib/format'
import {notifier} from '@bitstillery/common/app'
import {Tippy} from '@bitstillery/common/components'

import {
    CollectionTable,
    CollectionTableColumn,
    PagedCollectionFetcher,
} from '@/components/collection/collection_table'
import {DangerButton, DefaultButton} from '@/components/buttons'
import {CrmApi, GetCrmDataFileResponse, LeadDataFileStatus} from '@/factserver_api/crm_api'

export class LeadDataFileList extends MithrilTsxComponent<unknown> {
    data_file_fetcher = new PagedCollectionFetcher<GetCrmDataFileResponse[]>('crm.uploads.get_all', 'created_on', undefined)
    crm_api = new CrmApi()

    delete_lead_data_file(row: GetCrmDataFileResponse): void {
        if (confirm('Are you sure you want to delete this data file, and all the uploaded leads? This cannot be undone!')) {
            this.crm_api.delete_data_file(row.artkey).subscribe({
                next: () => {
                    this.data_file_fetcher.reset_and_query()
                    notifier.notify('File deleted successfully', 'success')
                },
            })
        }
    }

    view(): m.Children {
        return [
            <div className="btn-toolbar">
                <DefaultButton
                    title="Upload data file"
                    icon_class={'fa fa-cloud-upload-alt'}
                    onclick={() => {
                        m.route.set('/crm/uploads/upload')
                    }}
                />
            </div>,
            <CollectionTable<GetCrmDataFileResponse, void>
                collection_fetcher={this.data_file_fetcher}
            >
                <CollectionTableColumn<GetCrmDataFileResponse>
                    header_title={() => 'File name'}
                    sort_name={'file_name'}
                    data_field={(row: GetCrmDataFileResponse) => row.file_name}
                />
                <CollectionTableColumn<GetCrmDataFileResponse>
                    header_title={() => 'Description'}
                    sort_name={'description'}
                    data_field={(row: GetCrmDataFileResponse) => row.description}
                />
                <CollectionTableColumn<GetCrmDataFileResponse>
                    header_title={() => 'Uploaded on'}
                    sort_name={'created_on'}
                    data_field={(row: GetCrmDataFileResponse) => format_iso_to_date(row.created_on)}
                />
                <CollectionTableColumn<GetCrmDataFileResponse>
                    header_title={() => 'Status'}
                    sort_name={'status'}
                    data_field={(row: GetCrmDataFileResponse) => titleize(row.status.replace('_', ' ').toLowerCase())}
                />
                <CollectionTableColumn<GetCrmDataFileResponse>
                    header_title={() => ''}
                    data_field={(row: GetCrmDataFileResponse) => {
                        if (row.status === LeadDataFileStatus.PROCESSED || row.status === LeadDataFileStatus.UPLOADING_FAILED) {
                            return <div key={'processed'} className="row-actions">
                                <Tippy content={'<div>Delete file and leads</div>'}>
                                    <DangerButton
                                        icon_class={'fa fa-trash'}
                                        onclick={() => this.delete_lead_data_file(row)}
                                    />
                                </Tippy>
                            </div>
                        }
                    }}
                />
            </CollectionTable>,
        ]
    }
}
