/** llm:tested */
import {events} from '@bitstillery/common/app'

import {$m} from '@/app'
import {Account} from '@/models/accounts'
import {User} from '@/models/users'
import {Ledger, PaymentTerm} from '@/models/data'
import {Bottle} from '@/models/bottles'

export class Supplier {
    artkey = window.prop('')
    name = window.prop('')
    street_address = window.prop('')
    zip_code = window.prop('')
    city = window.prop('')
    country_code = window.prop('')
    region_code = window.prop('')
    emailaddress = window.prop('')
    telephone_number = window.prop('')
    url = window.prop('')
    purchase_manager = window.prop(new User({}))
    sales_manager = window.prop(new User({}))
    price_list_manager = window.prop(new User({}))
    company_type = window.prop('')
    client_status = window.prop('')
    operates_online = window.prop(null)
    price_list_frequency = window.prop('')
    is_supplier = window.prop(false)
    is_buyer = window.prop(false)
    incoterm = window.prop(null)
    purchase_ledger = window.prop(new Ledger({}))
    purchase_ledger_artkey = window.prop('')
    sales_ledger = window.prop(new Ledger({}))
    sales_ledger_artkey = window.prop('')
    low_tariff_sales_ledger = window.prop(new Ledger({}))
    low_tariff_sales_ledger_artkey = window.prop('')
    relation_nr = window.prop('')
    vat_id = window.prop('')
    excise_id = window.prop('')
    memo = window.prop('')
    should_receive_offer_mails = window.prop(false)
    should_receive_purchase_mails = window.prop(false)
    purchase_payment_term = window.prop(new PaymentTerm({}))
    purchase_payment_term_artkey = window.prop('')
    sales_payment_term = window.prop(new PaymentTerm({}))
    sales_payment_term_artkey = window.prop('')
    sales_account = window.prop(new Account({}))
    sales_account_artkey = window.prop('')
    portal_level = window.prop(null)
    portal_customs_visibility = window.prop(null)
    mix_customs_on_pricelist = window.prop(false)
    portal_account_expiry = window.prop(null)
    currency = window.prop('EUR')
    customs_status = window.prop('T1')
    transport_costs_per_case = window.prop(null)
    transport_costs_payment_method = window.prop(null)
    include_ukds_in_price = window.prop(false)
    show_excise_in_portal = window.prop(false)
    include_excise_in_price = window.prop(false)
    minimum_order_amount = window.prop(null)
    price_markup_percentage = window.prop(null)
    pays_transport_costs = window.prop(false)
    has_purchase_orders = window.prop(false)
    has_sales_orders = window.prop(false)
    primary_warehouse_artkey = window.prop(null)
    use_incoterm_on_pricelist = window.prop(false)
    price_preference = window.prop('case')
    active_promotion = window.prop(null)
    promotion_turnover_draft = window.prop(null)
    emailaddress_rfp_documents = window.prop(null)
    emailaddress_financial_documents = window.prop(null)
    exclude_from_pricelist_for_countries = window.prop(null)
    exclude_from_pricelist_for_suppliers = window.prop(null)
    longitude_latitude_checked_on = window.prop(null)
    longitude = window.prop(null)
    latitude = window.prop(null)
    linkedin = window.prop('')
    facebook = window.prop('')
    instagram = window.prop('')
    twitter = window.prop('')
    relation_price_list_generated_on = window.prop('')

    constructor(json: any = {}) {
        this.from_json(json)
    }

    from_json(json: any = {}) {
        for (const prop in json) {
            if (prop === 'purchase_manager') {
                this.purchase_manager($m.users.create_user(json[prop]))
            } else if (prop === 'sales_manager') {
                this.sales_manager($m.users.create_user(json[prop]))
            } else if (prop === 'price_list_manager') {
                this.price_list_manager($m.users.create_user(json[prop]))
            } else if (prop === 'sales_account') {
                this.sales_account_artkey(json[prop].artkey)
                this.sales_account(new Account(json[prop]))
            } else if (prop === 'purchase_ledger') {
                this.purchase_ledger_artkey(json[prop].artkey)
                this.purchase_ledger($m.data.ledgers.create_ledger(json[prop]))
            } else if (prop === 'sales_ledger') {
                this.sales_ledger_artkey(json[prop].artkey)
                this.sales_ledger($m.data.ledgers.create_ledger(json[prop]))
            } else if (prop === 'low_tariff_sales_ledger' && json[prop]) {
                this.low_tariff_sales_ledger_artkey(json[prop].artkey)
                this.low_tariff_sales_ledger($m.data.ledgers.create_ledger(json[prop]))
            } else if (prop === 'sales_payment_term' && json[prop]) {
                this.sales_payment_term_artkey(+json[prop].artkey)
                this.sales_payment_term($m.data.payment_term.create_payment_term(json[prop]))
            } else if (prop === 'purchase_payment_term' && json[prop]) {
                this.purchase_payment_term_artkey(+json[prop].artkey)
                this.purchase_payment_term($m.data.payment_term.create_payment_term(json[prop]))
            } else if (prop === 'exclude_from_pricelist_for_suppliers' && json[prop]) {
                this.exclude_from_pricelist_for_suppliers(json[prop])
            } else if (this[prop]) {
                this[prop](json[prop])
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }
}

export class SupplierPriceList {
    artkey: any
    created_on: any
    start_date: any
    end_date: any
    file_name: any
    is_current: any
    header: any
    header_as_list: any
    number_of_items: any
    number_of_unresolved_source_lines: any
    number_of_unresolved_expert_source_lines: any
    number_of_blacklisted_source_lines: any
    file_hash: any
    description: any
    status: any
    supplier: any
    always_active: any
    source_lines: any

    constructor(artkey: string, supplier: any, created_on: string, start_date: string,
        end_date: string, file_name: string, is_current: boolean, header: string,
        header_as_list: any[], number_of_items: number,
        number_of_unresolved_source_lines: number,
        number_of_unresolved_expert_source_lines_count: number,
        number_of_blacklisted_source_lines: number,
        file_hash: string, description: string, status: string,
        source_lines: any[]) {

        this.artkey = window.prop(artkey)
        this.created_on = window.prop(created_on)
        this.start_date = window.prop(start_date)
        this.end_date = window.prop(end_date)
        this.file_name = window.prop(file_name)
        this.is_current = window.prop(is_current)
        this.header = window.prop(header)
        this.header_as_list = window.prop(header_as_list)
        this.number_of_items = window.prop(number_of_items)
        this.number_of_unresolved_source_lines = window.prop(number_of_unresolved_source_lines)
        this.number_of_unresolved_expert_source_lines = window.prop(number_of_unresolved_expert_source_lines_count)
        this.number_of_blacklisted_source_lines = window.prop(number_of_blacklisted_source_lines)
        this.file_hash = window.prop(file_hash)
        this.description = window.prop(description || '')
        this.status = window.prop(status)
        this.supplier = window.prop(supplier ? new Supplier(supplier) : new Supplier({}))
        this.always_active = window.prop(status === SupplierPriceListStatus.ALWAYS_ACTIVE)
        if (source_lines) {
            this.source_lines = window.prop(source_lines.map(sl => new SupplierPriceListSourceLine(sl)))
        }
    }
}

export class SupplierPriceListsDataModel {
    create(json: any) {
        return new SupplierPriceList(
            json.artkey,
            json.supplier,
            json.created_on,
            json.start_date,
            json.end_date,
            json.file_name,
            json.is_current,
            json.header,
            json.header_as_list,
            json.supplier_price_list_items_count,
            json.unresolved_source_lines_count,
            json.unresolved_expert_source_lines_count,
            json.suppressed_source_line_count,
            json.file_hash,
            json.supplier_price_list_description,
            json.supplier_price_list_status,
            json.supplier_price_list_source_lines,
        )
    }
}

export class SupplierPriceListItem {
    artkey = window.prop('')
    supplier_price_list = window.prop(new SupplierPriceList('', null, '', '', '', '', false, '', [], 0, 0, 0, 0, '', '', '', []))
    supplier_price_list_artkey = window.prop('')
    bottle = window.prop(new Bottle())
    bottle_artkey = window.prop('')
    bottle__product__name = window.prop('')
    bottle__product__default_country_code = window.prop('')
    bottle__product__category = window.prop('')
    bottle__volume = window.prop('')
    bottle__alcohol_percentage = window.prop('')
    bottle__refill_status = window.prop('')
    product__product_bottle_type = window.prop('')
    currency = window.prop('')
    country_of_origin = window.prop('')
    number_of_bottles = window.prop('')
    price_per_case = window.prop('')
    number_of_bottles_per_case = window.prop('')
    aux_info = window.prop('')
    gift_box_type = window.prop('')
    number_of_cases = window.prop('')
    availability_date = window.prop('')
    price_per_bottle = window.prop('')
    availability_status = window.prop('')
    bottle_gtin_code = window.prop('')
    bottle_gtin = window.prop('')
    case_gtin_code = window.prop('')
    incoterm = window.prop('')
    customs_status = window.prop('')
    number_of_matching_tbo_items = window.prop(0)
    suppliers_item_code = window.prop('')
    suppliers_hs_code = window.prop('')
    case_gross_weight_in_kg = window.prop('')
    cases_per_pallet_layer = window.prop('')
    cases_per_pallet = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'supplier_price_list') {
                this.supplier_price_list($m.spls.create(json[prop]))
                this.supplier_price_list_artkey(json[prop].artkey)
            } else if (prop === 'bottle') {
                this.bottle_artkey(json[prop].artkey)
                this.bottle($m.bottles.create_bottle(json[prop], $m.products.create_product(json[prop]['product'])))
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }

    set_bottle(bottle: any) {
        this.bottle_artkey(bottle.artkey())
        this.bottle__product__name(bottle.product().name())
        this.bottle__product__category(bottle.product().category().toLowerCase())
        this.bottle__volume(bottle.volume())
        this.bottle__alcohol_percentage(bottle.alcohol_percentage())
        this.bottle__refill_status(bottle.refill_status())
    }

    reset_values() {
        this.artkey('')
        this.bottle(new Bottle())
        this.bottle_artkey('')
        this.bottle__product__name('')
        this.bottle__product__category('')
        this.bottle__volume('')
        this.bottle__alcohol_percentage('')
        this.bottle__refill_status('')
        this.currency('')
        this.number_of_bottles('')
        this.price_per_case('')
        this.number_of_bottles_per_case('')
        this.aux_info('')
        this.gift_box_type('')
        this.number_of_cases('')
        this.availability_date('')
        this.price_per_bottle('')
        this.availability_status('')
        this.bottle_gtin_code('')
        this.case_gtin_code('')
        this.incoterm('')
        this.customs_status('')
        this.number_of_matching_tbo_items(0)
        this.cases_per_pallet('')
        this.cases_per_pallet_layer('')
    }

    to_json() {
        return {
            artkey: this.artkey(),
            supplier_price_list_artkey: this.supplier_price_list().artkey(),
            currency: this.currency(),
            number_of_bottles: this.number_of_bottles(),
            price_per_case: this.price_per_case(),
            number_of_bottles_per_case: this.number_of_bottles_per_case(),
            aux_info: this.aux_info(),
            gift_box_type: this.gift_box_type(),
            number_of_cases: this.number_of_cases(),
            availability_date: this.availability_date(),
            price_per_bottle: this.price_per_bottle(),
            availability_status: this.availability_status(),
            bottle_gtin: this.bottle_gtin(),
            bottle_gtin_code: this.bottle_gtin_code(),
            case_gtin_code: this.case_gtin_code(),
            incoterm: this.incoterm(),
            customs_status: this.customs_status(),
            bottle: {
                artkey: this.bottle().artkey(),
                volume: this.bottle().volume(),
                alcohol_percentage: this.bottle().alcohol_percentage(),
                refill_status: this.bottle().refill_status(),
                product_artkey: this.bottle().product_artkey(),
                product: {
                    name: this.bottle().product().name(),
                    category: this.bottle().product().category(),
                    artkey: this.bottle().product().artkey(),
                },
            },
        }
    }
}

export class SupplierPriceListStatus {
    static ACTIVE = 'active'
    static ALWAYS_ACTIVE = 'always_active'
    static DELETED = 'deleted'
}

export class SupplierPriceListSourceLine {
    artkey = window.prop('')
    supplier_price_list = window.prop(new SupplierPriceList('', null, '', '', '', '', false, '', [], 0, 0, 0, 0, '', '', '', []))
    supplier_price_list_artkey = window.prop('')
    line_content = window.prop('')
    currency = window.prop('')
    country_of_origin = window.prop('')
    refill_status = window.prop('')
    aux_info = window.prop('')
    product_name = window.prop('')
    availability_date = window.prop('')
    number_of_bottles = window.prop('')
    price_per_case = window.prop('')
    number_of_cases = window.prop('')
    alcohol_percentage = window.prop('')
    volume = window.prop('')
    gift_box_type = window.prop('')
    price_per_bottle = window.prop('')
    availability_status = window.prop('')
    is_matched_as = window.prop(new SupplierPriceListItem({}))
    is_matched_as_artkey = window.prop('')
    number_of_bottles_per_case = window.prop('')
    incoterm = window.prop('')
    customs_status = window.prop('')
    product_map_artkey = window.prop('')
    product_map_key = window.prop('')
    category = window.prop('')
    bottle_gtin_code = window.prop('')

    constructor(json: any = {}) {
        for (const prop in json) {
            if (prop === 'supplier_price_list') {
                this.supplier_price_list = window.prop($m.spls.create(json[prop]))
                this.supplier_price_list_artkey(json[prop].artkey)
            } else if (prop === 'is_matched_as' && json[prop]) {
                this.is_matched_as = new SupplierPriceListItem(json[prop])
                this.is_matched_as_artkey(json[prop].artkey)
            } else {
                this[prop] = window.prop(json[prop])
            }
        }
    }
}

export function bind() {
    events.on('identity.login', async() => {
        $m.spls = new SupplierPriceListsDataModel()
    })
}
