import {MithrilTsxComponent} from 'mithril-tsx-component'
import m from 'mithril'
import {to_specs} from '@bitstillery/common/models/item'
import {proxy} from '@bitstillery/common/lib/proxy'
import {Button, FieldSelect} from '@bitstillery/common/components'
import {notifier} from '@bitstillery/common/app'
import {FieldText, Spinner} from '@bitstillery/common/components'

import {LoenderslootInspectionItemView} from './loendersloot_inspection_item_view'

import {$m, $s} from '@/app'
import {HorizontalForm, VerticalField} from '@/components/form'
import {PrimaryButton} from '@/components/buttons'
import {NumberInput} from '@/components/input_numbers'
import {AutocompleteInput} from '@/components/collection/autocomplete_input'
import {SearchBarControl} from '@/components/collection/search_bar'
import {
    GetLoenderslootInspectionItemResponse,
    PurchaseApi,
    PurchaseOrderItemConflictInStock,
    PurchaseOrderItemResponse,
    PurchaseOrderWithItemsResponse,
} from '@/factserver_api/purchase_api'
import {GetAllProductCategoriesResponse, ProductCategoriesDropDownData} from '@/factserver_api/product_categories'
import {ProductManagementApi} from '@/factserver_api/product_management_api'
import {GetProductPhotoResponse, GetProductsResponse} from '@/factserver_api/fact2server_api'
import {ProductPhotoHorizontalView} from '@/components/product_photos/product_photo_horizontal_view'
import {ProductPhotoApi} from '@/factserver_api/product_photos'

export class LoenderslootConflictHelper {
    purchase_api = new PurchaseApi()
    purchase_order_artkey: number
    loendersloot_inspection_items: GetLoenderslootInspectionItemResponse[] = []

    constructor(artkey: number) {
        this.purchase_order_artkey = artkey

        this.load_conflicts()
    }

    load_conflicts(): void {
        const fetcher = this.purchase_api.get_loendersloot_inspection_items(this.purchase_order_artkey, true)

        fetcher.subscribe({
            next: (result: GetLoenderslootInspectionItemResponse[]) => {
                this.loendersloot_inspection_items = result
                this.loendersloot_inspection_items.forEach((item) => {item.is_showing_photos = false})
                m.redraw()
            },
            error: () => {
                m.redraw()
            },
        })
    }
}

export interface LoenderslootConflictListProps {
    purchase_order_artkey: number
    loendersloot_conflict_helper: LoenderslootConflictHelper
}

/**
 * Show an attachment list for a certain entity.
 */
export class LoenderslootConflictList extends MithrilTsxComponent<LoenderslootConflictListProps> {
    attachment_to_categorize: GetLoenderslootInspectionItemResponse | null = null
    loendersloot_conflict_helper: LoenderslootConflictHelper
    purchase_order: PurchaseOrderWithItemsResponse | null = null
    purchase_api = new PurchaseApi()
    categories: GetAllProductCategoriesResponse = []
    product_photo_api: ProductPhotoApi = new ProductPhotoApi()

    constructor(vnode: m.VnodeDOM<LoenderslootConflictListProps>) {
        super()
        this.purchase_api.purchase_order_with_items(vnode.attrs.purchase_order_artkey).subscribe((response) => {
            if (response) {
                this.purchase_order = response[0]
                m.redraw()
            }
        })
        ProductCategoriesDropDownData.product_categories().subscribe((categories: GetAllProductCategoriesResponse) => {
            this.categories = categories
            m.redraw()
        })

        this.loendersloot_conflict_helper = vnode.attrs.loendersloot_conflict_helper
    }

    on_item_resolved(): void {
        this.loendersloot_conflict_helper.load_conflicts()
    }

    view(): m.Children {
        return (
            <span>
                {!this.purchase_order && <Spinner />}
                {this.purchase_order && this.loendersloot_conflict_helper.loendersloot_inspection_items.length !== 0 && (
                    <table className={'c-table table search-table'}>
                        <thead className={'thead-default'}>
                            <tr>
                                <th>Loendersloot item</th>
                                <th>Purchase order item</th>
                            </tr>
                        </thead>
                        {this.loendersloot_conflict_helper.loendersloot_inspection_items.map((row, index) => {
                            return (
                                <tbody class={'table-row'}>
                                    <tr className={index % 2 === 0 ? 'even' : 'odd'}>
                                        <td>
                                            <LoenderslootInspectionItemView loendersloot_inspection_item={row} />
                                            <Button
                                                text={row.is_showing_photos ? 'Hide photos' : 'Show photos' }
                                                icon={row.is_showing_photos ? 'chevronUp' : 'chevronDown'}
                                                onclick={() => {
                                                    row.is_showing_photos = !row.is_showing_photos
                                                    m.redraw()
                                                }}/>
                                        </td>
                                        <td width={'67%'}>
                                            <LoenderslootInspectionItemResolveForm
                                                loendersloot_inspection_item={row}
                                                purchase_order={this.purchase_order}
                                                categories={this.categories}
                                                on_item_resolved={() => this.on_item_resolved()}
                                            />
                                        </td>
                                    </tr>
                                    <tr className={'well'}>
                                        {row.is_showing_photos && (
                                            <td className={'well-container'} colspan="100%">
                                                <ConflictProductPhotos
                                                    product_photo_api={this.product_photo_api}
                                                    lot={row.lot}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            )})}
                    </table>
                )}
            </span>
        )
    }
}

interface ConflictProductPhotosAttrs {
    product_photo_api: ProductPhotoApi
    lot: string
}

interface ConflictPhotoData {
    product_photos: Array<GetProductPhotoResponse>
    is_fetching_photos: boolean
}

class ConflictProductPhotos extends MithrilTsxComponent<ConflictProductPhotosAttrs> {
    product_photo_api: ProductPhotoApi
    lot: string

    data: ConflictPhotoData = proxy({
        product_photos: [],
        is_fetching_photos: false,
    })

    constructor(vnode: m.VnodeDOM<ConflictProductPhotosAttrs>) {
        super()
        this.product_photo_api = vnode.attrs.product_photo_api
        this.lot = vnode.attrs.lot
        this.fetch_product_photos()
    }

    fetch_product_photos(): void {
        this.data.is_fetching_photos = true
        this.product_photo_api.get_all_photos_for_lot(this.lot).subscribe({
            next: (result) => {
                this.data.is_fetching_photos = false
                this.data.product_photos = result
            },
            error: () => {
                this.data.is_fetching_photos = false
            },
        })
    }

    view(): m.Children {
        const elements: m.Children = []
        if (this.data.is_fetching_photos) {
            elements.push(<Spinner />)
        }
        else if (this.data.product_photos.length !== 0) {
            elements.push(
                <ProductPhotoHorizontalView
                    product_photos={this.data.product_photos}
                    on_product_photos_changed={() => this.fetch_product_photos()}
                    download_all_photos={(include_internal: boolean) => {
                        this.product_photo_api.download_all_for_lot(
                            this.lot, include_internal,`photos-${this.lot}.zip`,
                        )
                    }}
                />,
            )
        }
        else {
            elements.push(<p>No product photos found for this lot.</p>)
        }

        return elements
    }
}

interface LoenderslootInspectionItemResolveFormAttrs {
    loendersloot_inspection_item: GetLoenderslootInspectionItemResponse
    purchase_order: PurchaseOrderWithItemsResponse
    categories: GetAllProductCategoriesResponse
    on_item_resolved: () => unknown
}

class LoenderslootInspectionItemResolveForm extends MithrilTsxComponent<LoenderslootInspectionItemResolveFormAttrs> {
    purchase_order_artkey: number
    poi_items_by_artkey: { [key: number]: PurchaseOrderItemResponse }
    poi_items_in_stockable: Array<PurchaseOrderItemResponse>
    matched_poi_artkey: number | null
    purchase_order_item: PurchaseOrderItemConflictInStock = {}
    loendersloot_inspection_item: GetLoenderslootInspectionItemResponse
    on_item_resolved: () => unknown
    purchase_api = new PurchaseApi()
    product_management_api = new ProductManagementApi()
    current_simple_product: GetProductsResponse | null = null
    search_bar_controller: SearchBarControl | null = null

    constructor(vnode: m.VnodeDOM<LoenderslootInspectionItemResolveFormAttrs>) {
        super()

        this.update_selected_poi(vnode)
    }

    bring_in_stock() {
        this.purchase_api
            .instock_conflict_loendersloot_inspection_item(
                this.purchase_order_artkey,
                this.loendersloot_inspection_item.artkey,
                this.purchase_order_item,
            )
            .subscribe({
                next: () => {
                    notifier.notify('Purchase order item brought in stock.', 'success')
                    this.on_item_resolved()
                },
            })
    }

    onupdate(vnode: m.VnodeDOM<LoenderslootInspectionItemResolveFormAttrs, this>): void {
        if (this.purchase_order_artkey !== vnode.attrs.purchase_order.artkey) {
            this.update_selected_poi(vnode)
        }
        if (this.loendersloot_inspection_item.artkey !== vnode.attrs.loendersloot_inspection_item.artkey) {
            this.update_selected_poi(vnode)
        }
    }

    select_poi(poi_artkey: number | null) {
        this.matched_poi_artkey = poi_artkey
        const loendersloot_inspection_item = this.loendersloot_inspection_item
        if (this.matched_poi_artkey) {
            const matched_poi = this.poi_items_by_artkey[this.matched_poi_artkey]
            this.purchase_order_item.artkey = +matched_poi.artkey
            this.purchase_order_item.product = matched_poi.case.bottle.product.name
            this.purchase_order_item.category_artkey = matched_poi.case.bottle.product.product_category.artkey
            this.purchase_order_item.gift_box_type = matched_poi.case.gift_box_type
            this.purchase_order_item.volume = +matched_poi.case.bottle.volume
            this.purchase_order_item.alcohol_percentage = +matched_poi.case.bottle.alcohol_percentage
            this.purchase_order_item.refill_status = matched_poi.case.bottle.refill_status

            this.purchase_order_item.price_per_case = matched_poi.was_bought_for

            this.purchase_order_item.customs_status = matched_poi.case.customs_status
            this.purchase_order_item.tax_label = matched_poi.case.tax_label
            this.purchase_order_item.remark = matched_poi.remark
            this.purchase_order_item.country_of_origin = matched_poi.country_of_origin
        } else {
            this.purchase_order_item = {}
            this.purchase_order_item.alcohol_percentage = loendersloot_inspection_item.alcohol_percentage
            this.purchase_order_item.volume = loendersloot_inspection_item.bottle_volume
            this.purchase_order_item.refill_status = loendersloot_inspection_item.bottle_refill_status
            this.purchase_order_item.gift_box_type = ''
        }
        this.purchase_order_item.bottles_per_case = loendersloot_inspection_item.bottles_per_case
        this.purchase_order_item.number_of_cases =
            loendersloot_inspection_item.number_of_bottles / loendersloot_inspection_item.bottles_per_case
        if (this.purchase_order_item.product) {
            this.search_bar_controller?.set_and_submit_search_text(this.purchase_order_item.product)
        }
        m.redraw()
    }

    select_product(simple_product: GetProductsResponse | null) {
        this.current_simple_product = simple_product

        if (simple_product) {
            this.purchase_order_item.category_artkey = simple_product.product_category.artkey
        }
    }

    update_selected_poi(vnode: m.VnodeDOM<LoenderslootInspectionItemResolveFormAttrs>) {
        this.purchase_order_artkey = vnode.attrs.purchase_order.artkey
        this.loendersloot_inspection_item = vnode.attrs.loendersloot_inspection_item
        this.poi_items_in_stockable = vnode.attrs.purchase_order.purchase_order_items.filter(
            (poi_item) => !poi_item.is_in_stock_loendersloot,
        )
        this.on_item_resolved = vnode.attrs.on_item_resolved

        this.poi_items_by_artkey = this.poi_items_in_stockable.reduce<{ [key: number]: PurchaseOrderItemResponse }>(
            (acc, curr) => ((acc[curr.artkey] = curr), acc),
            {},
        )
        this.matched_poi_artkey = vnode.attrs.loendersloot_inspection_item.purchase_order_item_artkey || this.poi_items_in_stockable[0]?.artkey
        if (!this.poi_items_by_artkey[this.matched_poi_artkey]) {
            this.matched_poi_artkey = null
        }
        this.select_poi(this.matched_poi_artkey)

    }

    view(vnode: m.Vnode<LoenderslootInspectionItemResolveFormAttrs>): m.Children | null {
        return (
            <HorizontalForm>
                <FieldSelect
                    help="Select purchase order item..."
                    label="Purchase order item"
                    model={[this, 'matched_poi_artkey']}
                    onchange={(poi_artkey: number) => this.select_poi(poi_artkey)}
                    options={this.poi_items_in_stockable.map((i: PurchaseOrderItemResponse) => ({
                        label: `${i.case.bottle.product.name} - ${to_specs(i.case.bottle, $s.identity.user.decimal_locale)} - ${i.case.gift_box_type} - ${i.case.number_of_bottles} / cs - ${i.number_of_cases}cs`,
                        value: i.artkey,
                    }))}
                    placeholder="Select purchase order item..."
                />

                <div className={'panel panel-default'}>
                    <div className={'panel-body'}>
                        <div className={'row'}>
                            <div className={'col-sm-3'}>
                                <VerticalField
                                    label={
                                        <span>
                                            Product
                                            {!this.current_simple_product && (
                                                <span className={'text-danger'}> (new product)</span>
                                            )}
                                        </span>
                                    }
                                >
                                    <AutocompleteInput
                                        placeholder="Select a product"
                                        on_get_suggestions$={(filter_text: string) =>
                                            this.product_management_api.get_simple_products(filter_text)
                                        }
                                        suggestion_as_label={(simple_product: GetProductsResponse) => simple_product.name}
                                        on_selected={(simple_product: GetProductsResponse) =>
                                            this.select_product(simple_product)
                                        }
                                        default_text={this.purchase_order_item.product}
                                        on_new_search_text={(text: string) => {
                                            this.purchase_order_item.product = text
                                            this.select_product(null)
                                        }}
                                        search_bar_controller={(sbc: SearchBarControl) =>
                                            (this.search_bar_controller = sbc)
                                        }
                                    />
                                </VerticalField>
                            </div>
                            <div className={'col-sm-3'}>
                                <FieldSelect
                                    disabled={this.current_simple_product}
                                    label="Category"
                                    model={[this.purchase_order_item, 'category_artkey']}
                                    options={vnode.attrs.categories?.map((i) => ({label: i.name, value: i.artkey}))}
                                    placeholder={'-'}
                                />
                            </div>
                            <div className={'col-sm-1'}>
                                <VerticalField label={'Size cl'}>
                                    <NumberInput
                                        value={this.purchase_order_item.volume}
                                        placeholder={''}
                                        required={false}
                                        oninput={(value: number | null) => {
                                            this.purchase_order_item.volume = value
                                        }}
                                    />
                                </VerticalField>
                            </div>
                            <div className={'col-sm-1'}>
                                <VerticalField label={'Alc %'}>
                                    <NumberInput
                                        value={this.purchase_order_item.alcohol_percentage}
                                        placeholder={''}
                                        required={false}
                                        oninput={(value: number | null) => {
                                            this.purchase_order_item.alcohol_percentage = value
                                        }}
                                    />
                                </VerticalField>
                            </div>
                            <div className={'col-sm-2'}>
                                <FieldSelect
                                    label="Refill status"
                                    model={[this.purchase_order_item, 'refill_status']}
                                    options={$m.data.bottle_refill_statusses.map((i: string) => ({label: i, value: i}))}
                                    placeholder="-"
                                />
                            </div>
                            <div className={'col-sm-2'}>
                                <VerticalField label={'Btl / cs'}>
                                    <NumberInput
                                        value={this.purchase_order_item.bottles_per_case}
                                        placeholder={''}
                                        required={false}
                                        oninput={(value: number | null) => {
                                            this.purchase_order_item.bottles_per_case = value
                                        }}
                                    />
                                </VerticalField>
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-sm-3'}>
                                <FieldSelect
                                    label="Giftbox"
                                    model={[this.purchase_order_item, 'gift_box_type']}
                                    options={$m.data.gift_box_types.map((i: string) => ({label: i, value: i}))}
                                    placeholder="-"
                                />
                            </div>
                            <div className={'col-sm-2'}>
                                <VerticalField label={'Cases'}>
                                    <NumberInput
                                        value={this.purchase_order_item.number_of_cases}
                                        placeholder={''}
                                        required={false}
                                        oninput={(value: number | null) => {
                                            this.purchase_order_item.number_of_cases = value
                                        }}
                                    />
                                </VerticalField>
                            </div>
                            <div className={'col-sm-3'}>
                                <VerticalField label={'Price per case'}>
                                    <NumberInput
                                        value={this.purchase_order_item.price_per_case}
                                        placeholder={''}
                                        required={false}
                                        oninput={(value: number | null) => {
                                            this.purchase_order_item.price_per_case = value
                                        }}
                                    />
                                </VerticalField>
                            </div>
                            <div className={'col-sm-2'}>
                                <FieldSelect
                                    label="Customs"
                                    model={[this.purchase_order_item, 'customs_status']}
                                    options={$m.data.customs_status.map((i) => ({label: i, value: i}))}
                                    placeholder="-"
                                />
                            </div>
                            <div className={'col-sm-2'}>
                                <FieldText
                                    label="Tax label"
                                    model={[this.purchase_order_item, 'tax_label']}
                                    placeholder=""
                                />
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-sm-6'}>
                                <FieldText
                                    label="Remark"
                                    model={[this.purchase_order_item, 'remark']}
                                    placeholder=""
                                />
                            </div>
                        </div>
                        <br />

                        <PrimaryButton
                            icon_class={'fas fa-edit'}
                            onclick={() => this.bring_in_stock()}>
                            Bring item in stock
                        </PrimaryButton>
                    </div>
                </div>
            </HorizontalForm>
        )
    }
}
