/** llm:tested */
import m from 'mithril'
import {Badge} from '@bitstillery/common/badge'
import {countries} from '@bitstillery/common/lib/countries'
import {format_money} from '@bitstillery/common/lib/format'

import api from '@/api'
import {$m} from '@/app'
import {capitalize} from '@/_utils'
import {Percent} from '@/components/html_components'
import {button_with_icon, icon_button} from '@/components/_buttons'
import {Collection} from '@/components/collection/collection'
import {CollectionTable} from '@/components/collection_table'
import {icon} from '@/components/icon'
import {select} from '@/components/inputs'

interface ExciseCategory {
    artkey: string
    name: string
    country_code: string
    duty_rate: number
    duty_application: string
    from_alcohol_percentage: number
    to_alcohol_percentage: number
    product_categories: Array<{
        name: string
    }>
}

export class ExciseCategoriesList {
    selected_country: any
    used_countries: any
    excise_product_categories: any
    unassigned_product_categories_country: any
    expand_details: boolean
    excise_categories: any

    constructor() {
        this.selected_country = window.prop('')
        this.used_countries = window.prop([])
        this.excise_product_categories = window.prop([])
        this.unassigned_product_categories_country = window.prop([])

        this.expand_details = false

        this.excise_categories = new Collection({
            api_function_name: 'excise.get_excise_categories_for_country',
            query_limit: 25,
            additional_params: window.prop({
                country: this.selected_country(),
            }),
        })
    }

    set_country(country_code: string) {
        this.selected_country(country_code)

        this.excise_categories.additional_params({
            ...this.excise_categories.additional_params(),
            country: country_code,
        })
        this.excise_categories.requery()

        api.callAndThen('excise.get_unassigned_product_categories_for_country',
            {country: this.selected_country()},
            {
                success: (resp: any) => {
                    this.unassigned_product_categories_country(resp.result)
                },
            },
        )
    }

    oncreate() {
        $m.common.observable.subscribe('refresh_excise_list', this, () => this.excise_categories.requery())
        api.callAndThen('excise.get_excise_category_used_countries', {}, {
            success: (resp: any) => {
                this.used_countries(resp.result)

                if (this.used_countries().length > 0) {
                    this.set_country(this.used_countries()[0])
                }
            },
        })
    }

    view(_vnode: m.Vnode<any>) {
        return <div class="c-excise-categories view">
            <div class="btn-toolbar">
                <button class="btn-default" onclick={() => m.route.set('/data/excise-categories/create')}>
                    {button_with_icon('Create Excise Category', 'plus')}
                </button>
            </div>

            <div class="c-filter-group">
                {select(this.selected_country, this.used_countries(), {
                    label: 'Filter Country',
                    onchange: (value: string) => this.set_country(value),
                    disabled: this.used_countries().length <= 0,
                })}
            </div>

            {this.unassigned_product_categories_country().length > 0 ? (
                <div class="unassigned-product-categories">
                    <div class="title">Unassigned product categories</div>
                    {this.unassigned_product_categories_country().map((col: string) =>
                        <Badge type="primary" additional_classes="mr-05">{col}</Badge>,
                    )}
                </div>
            ) : (
                <p>
                    All product categories assigned!
                    {icon('fa-check')}
                </p>
            )}

            <CollectionTable
                collection={this.excise_categories}
                options={{
                    sticky_header: true,
                    with_buttons: true,
                    unique_name: 'excise_categories',
                }}
                view_details={(record: ExciseCategory) => [
                    record.product_categories.length > 0 ? (
                        <span>Product categories:</span>
                    ) : (
                        <span>No product categories are linked to this excise category.</span>
                    ),
                    <ul>
                        {record.product_categories.map(product_category =>
                            <li>{capitalize(product_category.name)}</li>,
                        )}
                    </ul>,
                ]}
                columns={[
                    {
                        name: 'Category Name',
                        width: 4,
                        header: 'Category name',
                        field: 'name',
                    },
                    {
                        name: 'Country',
                        width: 2,
                        header: 'Country',
                        field: 'country_code',
                        function: (record: ExciseCategory) =>
                            <span>{countries[record.country_code]}</span>,
                    },
                    {
                        name: 'Duty rate',
                        width: 2,
                        header: 'Duty rate',
                        field: 'duty_rate',
                        function: (record: ExciseCategory) => {
                            const currency = record.country_code === 'GB' ? 'GBP' : 'EUR'
                            return <span>{format_money(record.duty_rate, currency)}</span>
                        },
                    },
                    {
                        name: 'Duty application',
                        width: 2,
                        header: 'Duty application',
                        field: 'duty_application',
                    },
                    {
                        name: 'From Alc %',
                        width: 1,
                        header: 'From Alc %',
                        field: 'from_alcohol_percentage',
                        function: (record: ExciseCategory) =>
                            <Percent value={+record.from_alcohol_percentage / 100} />,
                    },
                    {
                        name: 'To Alc %',
                        width: 1,
                        header: 'To Alc %',
                        field: 'to_alcohol_percentage',
                        function: (record: ExciseCategory) =>
                            <Percent value={+record.to_alcohol_percentage / 100} />,
                    },
                    {
                        name: 'Actions',
                        width: 1,
                        header: '',
                        function: (record: ExciseCategory) =>
                            <div class="btn-toolbar no-click">
                                <div class="btn-group">
                                    {icon_button('pencil', {
                                        class: 'btn-default',
                                        onclick: () => m.route.set(`/data/excise-categories/${record.artkey}/edit`),
                                    })}
                                </div>
                            </div>,
                    },
                ]}
            />
        </div>
    }
}
