import m from 'mithril'
import {Icon} from '@bitstillery/common/components'
import {MithrilTsxComponent} from 'mithril-tsx-component'
import {classes} from '@bitstillery/common/lib/utils.ts'

interface QuantityAttrs {
    bottle_quantity: number
    case_quantity: number
    className?: string
}

export class Quantity extends MithrilTsxComponent<QuantityAttrs> {

    view(vnode: m.Vnode<QuantityAttrs>) {
        return (
            <div className={classes(vnode.attrs.className, 'c-quantity')}>
                <div className="bottle-quantity">
                    <Icon
                        name="bottle"
                        size="s"
                        type="unset"
                    />
                    {`${vnode.attrs.bottle_quantity} btls`}
                </div>
                <div className="case-quantity">
                    <Icon
                        name="case"
                        size="s"
                        type="unset"
                    />
                    {`${vnode.attrs.case_quantity} cs`}
                </div>
            </div>
        )
    }
}
